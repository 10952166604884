/* istanbul ignore file */
import cloneDeep from 'lodash/cloneDeep';
import filter from 'lodash/filter';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import get from 'lodash/get';
import has from 'lodash/has';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import omit from 'lodash/omit';
import sortBy from 'lodash/sortBy';
import { v4 } from 'uuid';
import config from '../../../config/config';
import * as types from '../constants';

interface VehicleDetails {
  vinInput: boolean;
  selectedVehicle: boolean;
  viewQuote: boolean;
  vehicleAdded: boolean;
  vuid: string;
  year: string;
  make: string;
  model: string;
  body_type: string;
  own_lease: string;
  how_long: string;
  vin: string;
  defaultVin: string;
  comprehensive: string;
  collision: string;
  towing_labor: string;
  ext_trans_expense: string;
  primary_use: string;
  approx_miles: string;
  vinServerError: boolean;
  isIncluded: boolean;
}

interface VehicleState extends VehicleDetails {
  vuid: string;
  vehicleLoader: boolean;
  vinLoader: boolean;
  bodyTypeList: Array<any>;
  vehiclesList: Array<any>;
  isVinErrorCleared: boolean;
  vehicleSelectorDetails: {
    vehicleSelectorLoader: boolean;
    year: string;
    make: string;
    model: string;
    yearList: Array<any>;
    makeList: Array<any>;
    modelList: Array<any>;
  };
  clearedVehicle: any;
}

const INIT_VEHICLE_STATE: VehicleDetails = {
  vinInput: false, // Indicates if vin added by input or not
  selectedVehicle: false, // Used to toggle view vehicle selector and vehicle details
  viewQuote: false,
  vuid: '',
  year: '',
  make: '',
  model: '',
  body_type: '',
  own_lease: '',
  how_long: '',
  vin: '',
  defaultVin: '',
  comprehensive: '',
  collision: '',
  towing_labor: '',
  ext_trans_expense: '',
  primary_use: '',
  approx_miles: '',
  vehicleAdded: false,
  vinServerError: false,
  isIncluded: true,
};

const INIT_STATE: VehicleState = {
  ...INIT_VEHICLE_STATE,
  vuid: v4(),
  vehicleLoader: false,
  vinLoader: false,
  bodyTypeList: [],
  vehiclesList: [],
  isVinErrorCleared: false,
  vehicleSelectorDetails: {
    vehicleSelectorLoader: false,
    year: '',
    make: '',
    model: '',
    yearList: [],
    makeList: [],
    modelList: [],
  },
  clearedVehicle: {},
};

const previousSelectedBodyType = (list: Array<any>, oldBodyType: string, oldDefaultVin: string) => {
  if (list.length === 1) {
    return {
      body_type: list[0].value,
      defaultVin: !isEmpty(list[0].value.split('|')) ? list[0].value.split('|')[1] : '',
    };
  } else {
    if (find(list, ['value', oldBodyType])) {
      return {
        body_type: oldBodyType,
        defaultVin: oldDefaultVin,
      };
    } else {
      return {
        body_type: '',
        defaultVin: '',
      };
    }
  }
};

const saveVehicleUpdates = (state: any, action: AppRedux.Action) => {
  const updatedVehiclesList = map(state.vehiclesList, vehicle => {
    if (vehicle.vuid === state.vuid) {
      const hasBodyType = has(action.payload, 'body_type');
      vehicle = {
        ...vehicle,
        ...action.payload,
        ...(hasBodyType
          ? {
              defaultVin: !isEmpty(action.payload.body_type.split('|'))
                ? action.payload.body_type.split('|')[1]
                : '',
            }
          : {}),
      };
    }
    return vehicle;
  });
  return {
    ...state,
    vehiclesList: [...updatedVehiclesList],
  };
};

const restoreVehicleDetails = (state: any, action: AppRedux.Action) => {
  const { vehiclesList, clearedVehicle } = state;
  let updatedVehicleList = [...vehiclesList];
  if (!isEmpty(clearedVehicle)) {
    updatedVehicleList = map(vehiclesList, vehicle => {
      if (vehicle.vuid === get(clearedVehicle, 'vuid', '')) {
        vehicle = { ...clearedVehicle, clearedVehicle: {} };
      }
      return vehicle;
    });
  }
  return {
    ...state,
    vehiclesList: [...updatedVehicleList],
  };
};

const getClearedVehicle = (vehicle: any, state: any) => {
  let storedVehicle = {};
  if (vehicle.vehicleAdded || vehicle.vinInput || (!vehicle.vehicleAdded && !vehicle.vinInput)) {
    storedVehicle = !isEmpty(state.clearedVehicle)
      ? { ...state.clearedVehicle }
      : { ...omit(vehicle, ['clearedVehicle']) };
  }
  if (isEmpty(storedVehicle) && !isEmpty(state.clearedVehicle)) {
    storedVehicle = { ...state.clearedVehicle };
  }
  return storedVehicle;
};

const vinCleared = (state: any, action: AppRedux.Action) => {
  let clearedVehicle = {};
  const updatedVehicleList = map(state.vehiclesList, vehicle => {
    if (vehicle.vuid === state.vuid) {
      clearedVehicle = getClearedVehicle(vehicle, state);
      return {
        ...vehicle,
        vin: '',
        year: '',
        make: '',
        model: '',
        body_type: '',
        bodyTypeList: [],
        vinInput: false,
        selectedVehicle: false,
      };
    }
    return vehicle;
  });
  return {
    ...state,
    vin: '',
    year: '',
    make: '',
    model: '',
    body_type: '',
    bodyTypeList: [],
    vinInput: false,
    clearedVehicle,
    selectedVehicle: false,
    ...(updatedVehicleList.length > 0
      ? { vehiclesList: [...updatedVehicleList] }
      : { vehiclesList: state.vehiclesList }),
  };
};

const vehicleTypeSelected = (state: any, action: AppRedux.Action) => {
  const {
    vehicleSelectorDetails: { year, make, model, modelList },
  } = state;
  const { type, value } = action.payload;
  let selectedVehicleDetails: any = {
    year,
    make,
    model,
    [type]: value,
    modelList: [...modelList],
  };
  if (type === 'year') {
    selectedVehicleDetails = {
      ...selectedVehicleDetails,
      make: '',
      model: '',
      makeList: [],
      modelList: [],
    };
  } else if (type === 'make') {
    selectedVehicleDetails = {
      ...selectedVehicleDetails,
      model: '',
      modelList: [],
    };
  }
  return {
    ...state,
    vehicleSelectorDetails: {
      ...state.vehicleSelectorDetails,
      ...selectedVehicleDetails,
      [type]: value,
    },
  };
};

const storeVehicleTypeList = (state: any, action: AppRedux.Action) => {
  const { entityList, list } = action.payload;
  if (entityList === 'bodyTypeList') {
    return {
      ...state,
      ...previousSelectedBodyType(list, get(state, 'body_type', ''), get(state, 'defaultVin', '')),
      [entityList]: [...list],
    };
  }
  return {
    ...state,
    vehicleSelectorDetails: {
      ...state.vehicleSelectorDetails,
      [entityList]: [...list],
    },
  };
};

const saveBeforeEdit = (state: any, action: AppRedux.Action) => {
  const { vuid, body_type, own_lease, how_long, vin, primary_use, approx_miles } = action.payload;
  const { vehiclesList } = state;
  const updatedList = map(vehiclesList, vehicle => {
    // Updates only current selected vehicle on store
    if (vuid === vehicle.vuid) {
      vehicle.body_type = body_type;
      vehicle.own_lease = own_lease;
      vehicle.how_long = how_long;
      vehicle.vin = vin;
      vehicle.primary_use = primary_use;
      vehicle.approx_miles = approx_miles;
    }
    return vehicle;
  });
  return {
    ...state,
    vehiclesList: [...updatedList],
  };
};

const updateVehicleList = (state: any, action: AppRedux.Action) => {
  let vehiclesList = [...state.vehiclesList];
  const currentVehicleDetails: any = action.payload.clear
    ? {
        ...omit(action.payload, ['clear']),
        vuid: state.vuid,
        vinInput: state.vinInput,
      }
    : {
        ...omit(action.payload, ['clear']),
        vinInput: state.vinInput,
      };
  const existingVehicleIndex = findIndex(vehiclesList, {
    vuid: currentVehicleDetails.vuid,
  });
  // Updates vehicles list
  if (existingVehicleIndex >= 0) {
    // Existing vehicle update
    vehiclesList[existingVehicleIndex] = {
      ...vehiclesList[existingVehicleIndex],
      ...currentVehicleDetails,
    };
  } else {
    // Add new vehicle
    vehiclesList = [
      ...vehiclesList,
      {
        ...currentVehicleDetails,
        vuid: state.vuid,
      },
    ];
  }
  return {
    ...state,
    ...(action.payload.clear ? {} : { ...currentVehicleDetails }),
    vehiclesList,
  };
};

const updateContinueVehicleList = (state: any, action: AppRedux.Action) => {
  const {
    vehiclesList,
    body_type,
    own_lease,
    how_long,
    vin,
    defaultVin,
    comprehensive,
    collision,
    towing_labor,
    ext_trans_expense,
    primary_use,
    approx_miles,
  } = action.payload;
  const existingVehicleIndex = findIndex(vehiclesList, { vuid: state.vuid });
  // If existing vehicle and continue to next vehicle
  if (existingVehicleIndex >= 0) {
    vehiclesList[existingVehicleIndex] = {
      ...vehiclesList[existingVehicleIndex],
      body_type: body_type,
      own_lease: own_lease,
      how_long: how_long,
      vin: vin,
      defaultVin: defaultVin,
      comprehensive: comprehensive,
      collision: collision,
      towing_labor: towing_labor,
      ext_trans_expense: ext_trans_expense,
      primary_use: primary_use,
      approx_miles: approx_miles,
    };
  }
  return {
    ...state,
    defaultVin: defaultVin,
    vehiclesList,
  };
};

const addCurrentVehicle = (state: any, action: AppRedux.Action) => {
  const vehiclesList = [...state.vehiclesList];
  let vehicleDetails = null;
  let updatedBodyType = state.body_type;
  if (state.vinInput) {
    vehicleDetails = {
      year: state.year,
      make: state.make,
      model: state.model,
      comprehensive: state.comprehensive,
      collision: state.collision,
      towing_labor: state.towing_labor,
      ext_trans_expense: state.ext_trans_expense,
      isIncluded: state.isIncluded,
    };
  } else {
    vehicleDetails = {
      year: state.vehicleSelectorDetails.year,
      make: state.vehicleSelectorDetails.make,
      model: state.vehicleSelectorDetails.model,
      comprehensive: state.comprehensive,
      collision: state.collision,
      towing_labor: state.towing_labor,
      ext_trans_expense: state.ext_trans_expense,
      clearedVehicle: {},
      isIncluded: state.isIncluded,
    };
  }
  const existingVehicleIndex = findIndex(vehiclesList, { vuid: state.vuid });
  if (state.bodyTypeList) {
    const bodyTypeExists = find(state.bodyTypeList, { value: state.body_type });
    if (!bodyTypeExists) {
      updatedBodyType = '';
    }
  }
  if (existingVehicleIndex >= 0) {
    vehiclesList[existingVehicleIndex] = {
      ...vehiclesList[existingVehicleIndex],
      ...vehicleDetails,
      body_type: updatedBodyType,
      vin: state.vin,
      vinInput: state.vinInput,
      defaultVin: state.defaultVin,
      vehicleSelectorDetails: {
        ...state.vehicleSelectorDetails,
      },
      bodyTypeList: [...state.bodyTypeList],
    };
  } else {
    vehiclesList.push({
      ...INIT_VEHICLE_STATE,
      ...vehicleDetails,
      body_type: updatedBodyType,
      vin: state.vin,
      vuid: state.vuid,
      vinInput: state.vinInput,
      defaultVin: state.defaultVin,
      vehicleSelectorDetails: {
        ...state.vehicleSelectorDetails,
      },
      bodyTypeList: [...state.bodyTypeList],
    });
  }
  return {
    ...state,
    ...vehicleDetails,
    body_type: updatedBodyType,
    selectedVehicle: true,
    vehiclesList,
    comprehensive: state.comprehensive,
    collision: state.collision,
    towing_labor: state.towing_labor,
    ext_trans_expense: state.ext_trans_expense,
  };
};

const vehicleSelect = (state: any, action: AppRedux.Action) => {
  const editVehicle = find(state.vehiclesList, { vuid: action.payload });
  return {
    ...state,
    ...(editVehicle && editVehicle.coverage
      ? {
          collision: editVehicle.coverage.collision,
          comprehensive: editVehicle.coverage.comprehensive,
          towing_labor: editVehicle.coverage.towing_labor,
          ext_trans_expense: editVehicle.coverage.ext_trans_expense,
        }
      : {}),
    ...editVehicle,
    selectedVehicle: true,
  };
};

// TODO: Delete if no needed after "include/exclude switch"
const deleteVehicleDetailsSuccess = (state: any, action: AppRedux.Action) => {
  let details = {};
  const updatedVehicleList = filter(state.vehiclesList, vehicle => vehicle.vuid !== action.payload);
  if (updatedVehicleList.length === 0) {
    details = {
      ...INIT_VEHICLE_STATE,
      vuid: v4(),
      selectedVehicle: false,
      bodyTypeList: [],
      vehicleSelectorDetails: {
        ...INIT_STATE.vehicleSelectorDetails,
        yearList: [...state.vehicleSelectorDetails.yearList],
      },
    };
  } else {
    if (action.payload === state.vuid) {
      const currentVehicle = updatedVehicleList[updatedVehicleList.length - 1];
      details = {
        ...currentVehicle,
        selectedVehicle: true,
        vehicleSelectorDetails: { ...currentVehicle.vehicleSelectorDetails },
      };
    }
  }
  return {
    ...state,
    ...details,
    vehiclesList: updatedVehicleList.length !== 0 ? [...updatedVehicleList] : [],
  };
};

const toggleVehicleIncludedFlagSuccess = (state: any, action: AppRedux.Action) => {
  let updatedIsIncluded = state.isIncluded;
  const updatedVehicleList = map(state.vehiclesList, (vehicle: any) => {
    if (vehicle.vuid === action.payload) {
      vehicle.isIncluded = !vehicle.isIncluded;
    }

    return vehicle;
  });

  if (action.payload === state.vuid) {
    updatedIsIncluded = !state.isIncluded;
  }
  return {
    ...state,
    isIncluded: updatedIsIncluded,
    vehiclesList: updatedVehicleList,
  };
};

const sortVehicle = (state: any, action: AppRedux.Action) => {
  let vehiclesList = cloneDeep(state.vehiclesList);
  vehiclesList = sortBy(vehiclesList, (vehicle: { isIncluded: boolean }) =>
    vehicle.isIncluded ? -1 : 1
  );

  return {
    ...state,
    vehiclesList,
  };
};

const getStoredVehiclesList = (state: any, action: AppRedux.Action) => {
  const updatedList = action.payload;
  const vehicleList: any = [];
  updatedList.forEach((vehicle: any) => {
    vehicleList.push({
          ...vehicle,
          bodyTypeList: vehicle.body_type
            ? [{ label: vehicle.body_type, value: vehicle.body_type }]
            : [],
          clearedVehicle: {},
          vinInput: true,
          vinLoader: false,
          vinServerError: false,
          vehicleSelectorDetails: {
            make: vehicle.make,
            model: vehicle.model,
            year: vehicle.year,
            makeList: [],
            modelList: [],
            yearList: [],
            vehicleSelectorLoader: false,
          },
          defaultVin: vehicle.vin,
          collision: vehicle.coverage.collision,
          towing_labor: vehicle.coverage.towing_labor,
          comprehensive: vehicle.coverage.comprehensive,
          ext_trans_expense: vehicle.coverage.ext_trans_expense,
          clear: true,
          make: vehicle.make,
          model: vehicle.model,
          year: vehicle.year,
          isIncluded: get(vehicle, 'isIncluded', true),
    })
  });
  return {
    ...state,
    vehiclesList: vehicleList,
  };
};

const updateVehicleName = (state: any, action: AppRedux.Action) => {
  const updatedList = map(action.payload, vehicle => ({
    ...vehicle,
    vinInput: get(vehicle, 'vinInput', false),
    selectedVehicle: get(vehicle, 'selectedVehicle', false),
    viewQuote: true,
    vuid: get(vehicle, 'vuid', ''),
    year: get(vehicle, 'year', ''),
    make: get(vehicle, 'make', ''),
    model: get(vehicle, 'model', ''),
    body_type: get(vehicle, 'body_type', ''),
    own_lease: get(vehicle, 'own_lease', ''),
    how_long: get(vehicle, 'how_long', ''),
    vin: get(vehicle, 'vin', ''),
    defaultVin: get(vehicle, 'vin', '')
      ? vehicle.vin
      : get(vehicle, 'body_type', '')
      ? vehicle.body_type.split('|')[1]
      : '',
    comprehensive: get(vehicle.coverage, 'comprehensive', ''),
    collision: get(vehicle.coverage, 'collision', ''),
    towing_labor: get(vehicle.coverage, 'towing_labor', ''),
    ext_trans_expense: get(vehicle.coverage, 'ext_trans_expense', ''),
    primary_use: get(vehicle, 'primary_use', ''),
    approx_miles: get(vehicle, 'approx_miles', ''),
    vehicleAdded: get(vehicle, 'vehicleAdded', false),
    vehicleLoader: get(vehicle, 'vehicleLoader', false),
    vinLoader: get(vehicle, 'vinLoader', false),
    bodyTypeList: [],
    vehicleSelectorDetails: {
      ...state.vehicleSelectorDetails,
      vehicleSelectorLoader: false,
      year: get(vehicle, 'year', ''),
      make: get(vehicle, 'make', ''),
      model: get(vehicle, 'model', ''),
      yearList: [],
      makeList: [],
      modelList: [],
    },
  }));
  return {
    ...state,
    ...updatedList[0],
    vehiclesList: [...updatedList],
  };
};

const storeQuoteVehicleTypeList = (state: any, action: AppRedux.Action) => {
  const vehiclesList = [...state.vehiclesList];
  const { entityList, list } = action.payload;
  const updatedVehicleList = map(vehiclesList, vehicle => {
    if (vehicle.vuid === state.vuid) {
      return {
        ...vehicle,
        [entityList]: [...list],
      };
    }
    return vehicle;
  });
  return {
    ...state,
    ...previousSelectedBodyType(list, get(state, 'body_type', ''), get(state, 'defaultVin', '')),
    vehiclesList: [...updatedVehicleList],
    [entityList]: [...list],
    vehicleSelectorDetails: { ...state.vehicleSelectorDetails },
  };
};

// tslint:disable-next-line:cyclomatic-complexity
export default (state = INIT_STATE, action: AppRedux.Action) => {
  switch (action.type) {
    case types.APP_INIT: {
      return {
        ...state,
        vehicleLoader: false,
        vinLoader: false,
        vehicleSelectorDetails: {
          ...state.vehicleSelectorDetails,
          vehicleSelectorLoader: false,
        },
      };
    }

    case types.STORE_AUTOLINE_DETAILS: {
      return {
        ...state,
        vehicleSelectorDetails: {
          ...state.vehicleSelectorDetails,
          yearList: [...action.payload],
        },
      };
    }

    // On VIN added
    case types.VIN_SUBMIT_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        vehicleSelectorDetails: {
          ...state.vehicleSelectorDetails,
          year: '',
          make: '',
          model: '',
          yearList: [...state.vehicleSelectorDetails.yearList],
          makeList: [],
          modelList: [],
        },
      };
    }

    // VIN input loader
    case types.SET_VIN_LOADER: {
      return {
        ...state,
        vinLoader: action.payload,
      };
    }

    case types.SAVE_VEHICLE_UPDATES: {
      return saveVehicleUpdates(state, action);
    }

    case types.RESTORE_VEHICLE_DETAILS: {
      return restoreVehicleDetails(state, action);
    }

    // On VIN input cleared
    case types.VIN_CLEARED: {
      return vinCleared(state, action);
    }

    case types.SET_VIN_SERVER_ERROR: {
      return {
        ...state,
        vinServerError: action.payload,
        ...(action.payload
          ? {
              defaultVin: '',
              vehicleSelectorDetails: {
                ...state.vehicleSelectorDetails,
                year: '',
                make: '',
                model: '',
                makeList: [],
                modelList: [],
              },
            }
          : {}),
      };
    }

    // On vehicle type selection from vehicle selector
    case types.VEHICLE_TYPE_SELECTED: {
      return vehicleTypeSelected(state, action);
    }

    // Update store list for make, model
    case types.STORE_VEHICLE_TYPE_LIST: {
      return storeVehicleTypeList(state, action);
    }

    // Vehicle selector loader
    case types.VEHICLE_SELECTOR_LOADER: {
      return {
        ...state,
        vehicleSelectorDetails: {
          ...state.vehicleSelectorDetails,
          vehicleSelectorLoader: action.payload,
        },
      };
    }

    case types.SAVE_BEFORE_EDIT: {
      return saveBeforeEdit(state, action);
    }

    /** update/add new vehicle in the list.
     *  clear : true - Add Another Vehicle button
     *  clear : false - either from 'Continue' OR 'Step Button'
     *  vin input: true/false - indicates vehicle added using vin input or vin selector list.
     */
    case types.UPDATE_VEHICLE_LIST: {
      return updateVehicleList(state, action);
    }

    case types.UPDATE_CONTINUE_VEHICLE_LIST: {
      return updateContinueVehicleList(state, action);
    }

    // Add current vehicle to vehicle list
    case types.ADD_CURRENT_VEHICLE: {
      return addCurrentVehicle(state, action);
    }

    // Initialize vehicle
    case types.VEHICLE_INIT: {
      const includedLimitReached = () => {
        const count = state.vehiclesList.reduce(
          (total, vehicle) => (vehicle.isIncluded ? total + 1 : total),
          0
        );
        return count;
      };
      return {
        ...state,
        ...INIT_VEHICLE_STATE,
        vuid: v4(),
        vehiclesList: [...state.vehiclesList],
        bodyTypeList: [],
        vehicleSelectorDetails: {
          year: '',
          make: '',
          model: '',
          yearList: [...state.vehicleSelectorDetails.yearList],
          makeList: [],
          modelList: [],
        },
        isIncluded: includedLimitReached() >= config.maxVehicleLimit ? false : true,
      };
    }

    case types.VEHICLE_LOADER: {
      return {
        ...state,
        vehicleLoader: action.payload,
      };
    }

    // On vehicle selected for edit
    case types.EDIT_SELECTED_VEHICLE: {
      const { vehiclesList } = state;
      const editVehicle = find(vehiclesList, { vuid: action.payload });
      return {
        ...state,
        ...editVehicle,
      };
    }

    case types.VEHICLE_SELECT: {
      return vehicleSelect(state, action);
    }

    case types.DELETE_VEHICLE_DETAILS_SUCCESS: {
      return deleteVehicleDetailsSuccess(state, action);
    }

    case types.SET_VEHICLE_COVERAGES: {
      const { vehiclesList } = state;
      const updatedList = map(vehiclesList, vehicle => vehicle);
      return {
        ...state,
        vehiclesList: [...updatedList],
      };
    }

    case types.UPDATE_VEHICLE_NAME: {
      return updateVehicleName(state, action);
    }

    case types.STORE_QUOTE_VEHICLE_TYPE_LIST: {
      return storeQuoteVehicleTypeList(state, action);
    }

    case types.SET_VIN_ERROR_FLAG: {
      return {
        ...state,
        isVinErrorCleared: action.payload,
      };
    }

    case types.SET_VEHICLE_SELECTED: {
      return {
        ...state,
        selectedVehicle: action.payload,
      };
    }

    case types.CLEAR_VEHICLE_LIST: {
      return {
        ...INIT_STATE,
      };
    }

    case types.TOGGLE_VEHICLE_INCLUDED_STATUS_SUCCESS: {
      return toggleVehicleIncludedFlagSuccess(state, action);
    }
    case types.SORT_VEHICLE: {
      return sortVehicle(state, action);
    }

    case types.GET_STORED_VEHICLES_LIST: {
      return getStoredVehiclesList(state, action);
    }

    case types.SET_VEHICLE_LIST: {
      return {
        ...state,
        vehiclesList: action.payload,
      };
    }

    case types.COMMON_VEHICLE_ACTION: {
      return {
        ...state,
        ...action.payload,
      };
    }

    default:
      return state;
  }
};
