/* istanbul ignore file */
import axios, { AxiosError } from 'axios';
import { push } from 'connected-react-router';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import some from 'lodash/some';
import { all, call, delay, fork, put, select, take, takeEvery } from 'redux-saga/effects';
import { 
  APPLICANT,
  CYBER_DEFAULT_PLAN,
  CYBER_SECURITY,
  EARTH_QUAKE_LOB,
  EMPTY_LOB_ERROR_MESSAGE,
  INSURANCE_PRODUCTS,
  PRODUCT_MAPPING,
  PRODUCT_SELECTION_ERROR_MESSAGE,
  SELECT,
} from '../../../constants';
import {
  getApplicanLobKey,
  getAuthToken,
  getHerdId,
  getLobHerdId,
  getOrgDetails,
  isAutoQuote,
  isApplicantDataRequired,
  onBridgeToCarrierClick,
  setProductsPriority,
  setHeapProjectId,
  setIsBindRequested,
} from '../../../utils';
import axiosRequest from '../../api';
import {
  addCoverage,
  addDetails,
  addDriver,
  addHelperProduct,
  addVehicle,
  bridgeToCarrierLoader,
  clearAccidentList,
  clearApplicantState,
  clearDriverAccidentList,
  clearDriverInfo,
  clearDriverLossList,
  clearDriverViolationList,
  clearFloodState,
  clearHelpers,
  clearHomePconfig,
  clearHomeState,
  clearInsurenaceDetails,
  clearLossList,
  clearPartnerState,
  clearPetState,
  clearHomeWarrantyState,
  clearCyberSecurityState,
  clearLifeState,
  clearUmbrellaState,
  clearQuakeState,
  clearVehiclesList,
  clearViolationList,
  clearAutoQuoteDetails,
  driverContinue,
  driverSelectedForEdit,
  errorHandler,
  fetchFloodQuoteDetails,
  fetchQuakeQuoteDetails,
  flushQuoteDetails,
  fetchHomeWarrantyQuotes,
  cyberSecurityQuotesLoader,
  fetchCyberSecurityQuotes,
  homeWarrantyQuotesLoader,
  quakeDetailSubmitLoader,
  quotingOrganizationListLoader,
  saveAutoQuoteDetails,
  saveSlectedCarrierLoader,
  setAddProductSaveApplicantLoader,
  setAppointments,
  setConfigLoader,
  setFetchAutoQuotes,
  setHomeCode,
  setHomeState,
  setIncidentsFlag,
  setIsVeriskLoading,
  setLineOfBusiness,
  setMissingSpouse,
  setOrgConfigLoader,
  setPconfig,
  setPolicyFlag,
  setVehicleCoverages,
  setVehiclesMissing,
  setRatePageEnable,
  setRequestBindLoader,
  setRequestBindResponseDetails,
  stepChanged,
  stepSubmitLoader,
  storeBoundedCarrierDetails,
  storeCommonData,
  storeEmptyLobErrorMessage,
  storeQuotingErrorMessage,
  storeQuotingOrganizationList,
  stopPolling,
  stopHomeQuotePolling,
  stopLifePolling,
  stopCyberSecurityPolling,
  stopUmbrellaPolling,
  saveApplicantDetails,
  updateAccidentDetails,
  updateSelectedCarriers,
  updateSelectedProductLoader,
  vinCleared,
} from '../actions';
import * as actions from '../constants';
import getIncidentsErrors from './incidents';
import { getPathName } from '../../../quote-application/utils';

const commonActions: any = actions;

function* validateVehicleInfo() {
  const {
    vehicle: { vehiclesList },
  } = yield select();
  const isBodyTypeEmpty = some(vehiclesList, { body_type: '', isIncluded: true });
  if (isEmpty(vehiclesList) || isBodyTypeEmpty) {
    yield put(push('vehicle'));
    yield put(setVehiclesMissing(true));
    return false;
  }
  return true;
}

const checkForSpouse = (hasSpouse: any, isSpouseAdded: any, isSpouse: any, navigation: any) =>
  hasSpouse && !isSpouseAdded && !isSpouse && navigation !== 'back';

function* handleBackNavigation(navigation: string) {
  if (navigation === 'back') {
    yield put(push('driver'));
  } else {
    yield put(driverContinue({ entity: 'otherDetails' } as any));
  }
}

const filterAppointments = (products: Array<string>) => {
  const appointments: AppRedux.Appointments = {
    HOME: {},
    QUAKE: {},
    HOMEWARRANTY: {},
    CYBERSECURITY: {},
    FLOOD: {},
    AUTO: {},
    PET: {},
    LIFE: {},
    UMBRELLA: {},
  };

  Object.keys(appointments).forEach(key => {
    if (!products.includes(key.toLowerCase())) {
      delete appointments[key as keyof AppRedux.Appointments];
    }
  });

  return appointments;
};

function* storeOtherDetails(payload: any): any {
  const { otherDetails, driver } = yield select();
  const { navigation } = payload.data;
  const { getQuoteClick } = payload;
  const { accidentList, violationList, lossList } = otherDetails;
  let incidentsErrors: any = {
    errorList: [],
    errorViolationList: [],
    errorLossList: [],
  };

  if (payload.data.isIncluded) {
    incidentsErrors = yield getIncidentsErrors(accidentList, violationList, lossList);
  }
  if (
    isEmpty(incidentsErrors.errorList) &&
    isEmpty(incidentsErrors.errorViolationList) &&
    isEmpty(incidentsErrors.errorLossList)
  ) {
    const { hasSpouse, isSpouseAdded, isSpouse } = driver;
    yield put(
      updateAccidentDetails({
        duid: driver.duid,
        data: {
          accidentList,
          violationList,
          lossList,
        },
      } as any)
    );
    if (
      checkForSpouse(hasSpouse, isSpouseAdded, isSpouse, navigation) &&
      (driver.driversList.length <= 1 || !driver.isFetchedFromVerisk)
    ) {
      yield put(
        addDriver({
          clear: true,
          ...omit(driver, ['entity', 'detailsErrors', 'driversList']),
          isSpouseAdded: true,
          accidentList,
          violationList,
          lossList,
        })
      );
      // yield put(push('driver'));
    } else if (getQuoteClick) {
      if (checkForSpouse(hasSpouse, isSpouseAdded, isSpouse, navigation)) {
        yield put(
          addDriver({
            clear: true,
            ...omit(driver, ['entity', 'detailsErrors', 'driversList']),
            isSpouseAdded: true,
            accidentList,
            violationList,
            lossList,
          })
        );
        // yield put(push('driver'));
      } else {
        const isVehicleInfoValid = yield call(validateVehicleInfo);
        if (isVehicleInfoValid) {
          yield put(driverContinue({ entity: 'otherDetails', lastDriver: true } as any));
        }
      }
    } else {
      yield call(handleBackNavigation, navigation);
    }
  }
  // Creating throttle for state update for component
  yield delay(10);
}

function* storeDriverDetails(payload: any): any {
  const { hasSpouse, isSpouse, isSpouseAdded, claims, violations, losses } = payload.data;
  const { getQuoteClick } = payload;
  yield put(
    addDriver({
      clear: get(payload, 'clear', true),
      ...omit(payload.data, ['entity', 'detailsErrors', 'driversList']),
    })
  );
  if (claims === 'no') {
    yield put(clearAccidentList({ ...payload.data }));
    yield put(clearDriverAccidentList({ data: { accidentList: [] } } as any));
  }
  if (violations === 'no') {
    yield put(clearViolationList({ ...payload.data }));
    yield put(clearDriverViolationList({ data: { violationList: [] } } as any));
  }
  if (losses === 'no') {
    yield put(clearLossList({ ...payload.data }));
    yield put(clearDriverLossList({ data: { lossList: [] } } as any));
  }
  if (claims === 'no' && violations === 'no' && losses === 'no' && !getQuoteClick) {
    const { common, driver } = yield select();
    if (!hasSpouse || isSpouse || (hasSpouse && isSpouseAdded)) {
      let isLastDriver = false;
      if (driver.driversList && driver.driversList.length) {
        if (payload.data.duid === driver.driversList[driver.driversList.length - 1].duid) {
          isLastDriver = true;
        }
      }
      // todo: because of this we do not get to final page
      if (isSpouse && isLastDriver) {
        yield put(setPolicyFlag(true));
      } else {
        yield put(driverContinue({ entity: 'driver' } as any));
      }
    } else if (
      common.isVeriskFetched &&
      driver.driversList &&
      driver.driversList.length > 1 &&
      driver.isPrimaryDriver
    ) {
      yield put(driverContinue({ entity: 'driver' } as any));
    } else if (!driver.spouseId) {
      yield put(setMissingSpouse(true));
    }
  } else if (getQuoteClick) {
    const isVehicleInfoValid = yield call(validateVehicleInfo);
    if (isVehicleInfoValid) {
      yield put(driverContinue({ entity: 'otherDetails', lastDriver: true } as any));
    }
  } else {
    // todo: set flag to other details
    yield put(setIncidentsFlag(true));
    // yield put(push('otherDetails'));
  }
}

function* stepSubmitted({ payload }: AppRedux.ActionPayload): any {
  yield put(stepSubmitLoader(true));
  try {
    const { entity, getQuoteClick } = payload;
    yield put(setFetchAutoQuotes(true));
    const {
      common: { has_form_changed },
    } = yield select();

    if (has_form_changed && (entity === 'details' || getQuoteClick)) {
      yield put(flushQuoteDetails());
    }

    if (entity === 'vehicle') {
      yield put(
        addVehicle({
          ...omit(payload.data, ['vehicleLoader', 'invalidAddress']),
          clear: false, // Flag indicates status for current vehicle state
        })
      );
      if (getQuoteClick) {
        // todo: check whether we need this
        yield put(driverContinue({ entity: 'otherDetails', lastDriver: true } as any));
      } else {
        const { driver } = yield select();
        yield put(stepChanged({ entity: 'drivers', value: true } as any));
        if (driver.driversList.length >= 1) {
          yield put(driverSelectedForEdit(driver.driversList[0].duid));
        }
        // yield put(push('driver'));
      }
    } else if (entity === 'coverage') {
      yield put(stepChanged({ entity: 'drivers', value: true } as any));
      yield put(addCoverage({ ...payload.data }));
      yield put(setVehicleCoverages({ ...payload.data }));
      yield put(push('driver'));
    } else if (entity === 'driver') {
      yield call(storeDriverDetails, payload);
    } else if (entity === 'otherDetails') {
      yield call(storeOtherDetails, payload);
    } else if (entity === 'details') {
      yield put(addDetails({ ...payload.data }));
      const isVehicleInfoValid = yield call(validateVehicleInfo);
      if (isVehicleInfoValid) {
        yield put(driverContinue({ entity: 'otherDetails', lastDriver: true } as any));
      }
    }
  } catch (error) {
    yield put(errorHandler(error));
  }
  yield put(stepSubmitLoader(false));
}

export function* stepSubmittedWatcher() {
  yield takeEvery(commonActions.STEP_SUBMITTED, stepSubmitted);
}

function* fetchOrgConfigDetails({ payload }: AppRedux.ActionPayload): any {
  yield put(setOrgConfigLoader(true));
  yield put(storeEmptyLobErrorMessage(''));
  try {
    const { organization, defaultPConfig } = payload;
    const code = organization.trim();
    yield put(
      setHomeState({
        defaultPConfig,
        pconfig: {},
      } as any)
    );
    yield put(setHomeCode(code));
    yield put(
      setPconfig({
        defaultPConfig,
        pconfig: { pconfig: {} },
      } as any)
    );
    const {
      partner: { isProviderOneLogin },
    } = yield select();
    const details = yield axiosRequest('POST', '/partners/config', isProviderOneLogin, { code });
    if (details.data) {
      yield put(
        setHomeState({
          defaultPConfig,
          pconfig: { ...details.data },
        } as any)
      );
      yield put(setHomeCode(code));
      yield put(
        setPconfig({
          defaultPConfig,
          pconfig: { pconfig: { ...details.data } },
        } as any)
      );
    }
    setHeapProjectId(details.data, false)
  } catch (error) {
    const axiosError: any = error as AxiosError<any>;
    if(!!axiosError?.response && axiosError?.response?.status === 404) {
      yield put(storeEmptyLobErrorMessage(EMPTY_LOB_ERROR_MESSAGE));
    } else {
      yield put(errorHandler(axiosError));
    }
  }
  yield put(setOrgConfigLoader(false));
  yield put(setConfigLoader(false));
}

export function* fetchOrgConfigWatcher() {
  yield takeEvery(commonActions.FETCH_ORG_CONFIG, fetchOrgConfigDetails);
}

function* submitNewProducts({ payload }: AppRedux.ActionPayload) {
  try {
    const { products } = payload;
    const { home } = yield select();
    yield put(setRatePageEnable(false));
    yield put(setLineOfBusiness(products[0]));
    const appointments = filterAppointments(products);
    yield put(setAppointments({ appointments }));
    yield put(
      push({
        pathname: getPathName(),
        state: { pconfig: home?.pconfig },
      })
    );
  } catch (error) {
    yield put(errorHandler(error));
  }
}

export function* submitNewProductsWatcher() {
  yield takeEvery(commonActions.SUBMIT_ORG_PRODUCTS, submitNewProducts);
}

export function* addNewProduct({ payload }: AppRedux.ActionPayload) {
  if (payload.isNewProduct && payload.newProducts) {
    yield put(storeEmptyLobErrorMessage(''));
    const appointments = filterAppointments(payload.products);
    const helperAppointments = filterAppointments(payload.newProducts);
    const { home, driver } = yield select();
    const is_verisk_prefill_available = home.pconfig?.dale_config?.is_verisk_prefill_available;
    const driversList = driver.driversList;
    yield put(setAppointments({ appointments }));
    yield put(addHelperProduct({ helperAppointments }));
    const newAppoinetments = Object.keys(helperAppointments);
    const appointmentKeys = Object.keys(appointments);
    const enablePrefillCall =
      isAutoQuote(newAppoinetments) &&
      (appointmentKeys.includes('HOMEWARRANTY') ||
        appointmentKeys.includes('CYBERSECURITY') ||
        appointmentKeys.includes('LIFE') ||
        appointmentKeys.includes('UMBRELLA')) &&
      isApplicantDataRequired();
    setProductsPriority(newAppoinetments);
    if (
      !enablePrefillCall &&
      helperAppointments &&
      isAutoQuote(newAppoinetments) &&
      !driversList.length &&
      is_verisk_prefill_available
    ) {
      yield put(setIsVeriskLoading(true));
      yield put(saveAutoQuoteDetails(APPLICANT as any));
    }

    if (
      ((!isApplicantDataRequired() && newAppoinetments.includes('PET')) ||
        (!isEmpty(home?.personal_details?.date_of_birth) &&
          (newAppoinetments.includes('LIFE') || newAppoinetments.includes('UMBRELLA')) &&
          !newAppoinetments.includes('PET'))) &&
      ((newAppoinetments.length === 1 &&
        (newAppoinetments.includes('PET') ||
          newAppoinetments.includes('LIFE') ||
          newAppoinetments.includes('UMBRELLA'))) ||
        (newAppoinetments.length === 2 &&
          ((newAppoinetments.includes('PET') && newAppoinetments.includes('LIFE')) ||
            (newAppoinetments.includes('PET') && newAppoinetments.includes('UMBRELLA')) ||
            (newAppoinetments.includes('LIFE') && newAppoinetments.includes('UMBRELLA')))) ||
        (newAppoinetments.length === 3 &&
          newAppoinetments.includes('PET') &&
          newAppoinetments.includes('LIFE') &&
          newAppoinetments.includes('UMBRELLA')))
    ) {
      let lobKey: any = getApplicanLobKey('', newAppoinetments);
      yield put(saveApplicantDetails(lobKey));
    }

    if (newAppoinetments.includes('HOMEWARRANTY')) {
      yield put(homeWarrantyQuotesLoader(true));
      yield put(fetchHomeWarrantyQuotes());
    }

    if (newAppoinetments.includes('CYBERSECURITY') && !isEmpty(home?.personal_details?.date_of_birth)) {
      yield put(cyberSecurityQuotesLoader(true));
      yield put(fetchCyberSecurityQuotes());
    }

    if (newAppoinetments.includes('HOME') && !isEmpty(home?.productType)) {
      if (appointmentKeys.includes('HOME') || newAppoinetments.includes('HOME')) {
        yield put(setLineOfBusiness(INSURANCE_PRODUCTS[0]));
      } else {
        yield put(setLineOfBusiness(EARTH_QUAKE_LOB));
      }
      yield put(
        setHomeState({
          quoteList: [],
          quoteListError: [],
          upcomingCarrierList: [],
        } as any)
      );
      yield put(setAddProductSaveApplicantLoader(true));
      yield put(saveApplicantDetails(INSURANCE_PRODUCTS[0] as any));
    }

    if (newAppoinetments.includes('QUAKE') && !isEmpty(home?.productType)) {
      yield put(quakeDetailSubmitLoader(true));
      yield put(fetchQuakeQuoteDetails());
    }

    if (newAppoinetments.includes('FLOOD') && !isEmpty(home?.productType)) {
      yield put(fetchFloodQuoteDetails());
    }

    yield put(
      push({ pathname: getPathName() })
    );
  }
}

export function* addProductWatcher() {
  yield takeEvery(commonActions.ADD_NEW_PRODUCT_TO_APPOINTMENTS, addNewProduct);
}

export function* clearQuotes(): any {
  yield all([
    yield put(clearApplicantState()),
    yield put(clearDriverInfo()),
    yield put(clearHomeState()),
    yield put(clearVehiclesList()),
    yield put(vinCleared()),
    yield put(clearFloodState()),
    yield put(clearQuakeState()),
    yield put(clearHelpers()),
    yield put(clearHomePconfig()),
    yield put(clearPartnerState()),
    yield put(clearAutoQuoteDetails()),
    yield put(clearPetState()),
    yield put(clearInsurenaceDetails()),
    yield put(storeBoundedCarrierDetails({})),
    yield put(clearHomeWarrantyState()),
    yield put(clearCyberSecurityState()),
    yield put(clearLifeState()),
    yield put(clearUmbrellaState()),
  ]);
}

export function* clearQuotesWatcher() {
  yield takeEvery(commonActions.CLEAR_QUOTES, clearQuotes);
}

function* saveSelectedCarrier({ payload }: AppRedux.ActionPayload): any {
  try {
    yield put(saveSlectedCarrierLoader(true));
    const { lobType, selectedQuote, herdId, action } = payload;
    const {
      partner: { isProviderOneLogin },
      common: { selectedCarriers },
    } = yield select();
    let lob = !!lobType ? lobType.toLowerCase() : '';
    const premiumValue = selectedQuote?.price ? selectedQuote?.price?.toString() : '';
    const selectedCyberPlan = selectedQuote?.selectedPlan ? selectedQuote?.selectedPlan?.toString() : CYBER_DEFAULT_PLAN;
    lob = PRODUCT_MAPPING[lob.toUpperCase()] ? PRODUCT_MAPPING[lob.toUpperCase()].toLowerCase() : lob;
    let requestData: any = {
      action: action,
      lob: lob,
      herdId: herdId,
    };
    if (lob === CYBER_SECURITY) {
      requestData.selectedPlan = selectedCyberPlan;
    }
    if (action === SELECT) {
      requestData.carrierId =
        selectedQuote?.carrier_id?.toString() || selectedQuote?.carrierId?.toString();
      requestData.premium = premiumValue;
    }
    if (
      !!selectedCarriers &&
      selectedCarriers[lob] &&
      selectedCarriers[lob]?.premium?.toString() === premiumValue
    ) {
      yield put(updateSelectedCarriers(selectedCarriers));
    } else {
      const details = yield axiosRequest(
        'POST',
        '/quotes/save-carrier-premium',
        isProviderOneLogin,
        requestData
      );
      if (details?.data) {
        yield put(updateSelectedCarriers(details?.data?.selectedCarriers));
      }
    }
  } catch (error) {
    yield put(errorHandler(error));
  }
  yield put(saveSlectedCarrierLoader(false));
}

export function* saveSelectedCarrierWatcher() {
  yield takeEvery(commonActions.SAVE_SELECTED_CARRIER, saveSelectedCarrier);
}


export function* clearAutoQuotes(): any {
  yield all([
    yield put(clearVehiclesList()),
    yield put(vinCleared()),
    yield put(clearAutoQuoteDetails()),
    yield put(clearInsurenaceDetails()),
  ]);
}

export function* clearAutoQuotesWatcher() {
  yield takeEvery(commonActions.CLEAR_AUTO_QUOTE_STATE, clearAutoQuotes);
}

function* fetchQuotingOrganization(): any {
  yield put(quotingOrganizationListLoader(true));
  yield put(storeQuotingErrorMessage(''));
  try {
    const {
      partner: { isProviderOneLogin },
    } = yield select();
    const details = yield axiosRequest('GET', '/partners/quoting-organizations', isProviderOneLogin);
    if(!!details?.data){
      yield put(storeQuotingOrganizationList(details?.data));
    }
  } catch (error) {
    const axiosError = error as AxiosError<any>;
    if(!!axiosError?.response?.data && axiosError?.response?.status === 404) {
      yield put(storeQuotingErrorMessage(axiosError?.response?.data?.errorMessage));
      yield put(storeQuotingOrganizationList([]));
    }
    yield put(errorHandler(axiosError));
  }
  yield put(quotingOrganizationListLoader(false));
}

export function* fetchQuotingOrganizationWatcher() {
  yield takeEvery(commonActions.FETCH_QUOTING_ORGANIZATION_LIST, fetchQuotingOrganization);
}

function* saveSelectedProducts({ payload }: AppRedux.ActionPayload): any {
  yield put(updateSelectedProductLoader(true));
  yield put(storeEmptyLobErrorMessage(''));
  try {
    const appointments = filterAppointments(payload.products);
    const {
      partner: { isProviderOneLogin, storedRequestId },
    } = yield select();
    const requestData = {
      selectedLobs: Object.keys(appointments),
      providerRequestId: storedRequestId,
      herdId: getHerdId(),
    }
    const details = yield axiosRequest('POST', '/quotes/update-selected-products', isProviderOneLogin, requestData);
    if(!!details?.data?.success){
      yield put(updateSelectedProductLoader(false));
    }
  } catch (error) {
    const axiosError = error as AxiosError<any>;
    if(!!axiosError?.response?.data && !axiosError?.response?.data?.success) {
      yield put(storeEmptyLobErrorMessage(PRODUCT_SELECTION_ERROR_MESSAGE));
    }
    yield put(errorHandler(axiosError));
  }
  yield put(updateSelectedProductLoader(false));
}

export function* saveSelectedProductsWatcher() {
  yield takeEvery(commonActions.UPDATE_SELECTED_PRODUCTS, saveSelectedProducts);
}

function* trackCarrierBridgingData({ payload }: AppRedux.ActionPayload): any {
  yield put(storeCommonData({ successBridgeTracking: false }));
  try {
    const {
      partner: { isProviderOneLogin, storedRequestId },
    } = yield select();
    const details = yield axiosRequest('POST', '/quotes/track-carrier-bridging', isProviderOneLogin, {
      ...payload,
      herdId: getHerdId(),
      providerRequestId: !!storedRequestId ? storedRequestId : ''
    });
    if(!!details?.data){
      yield put(storeCommonData({ successBridgeTracking: true }));
    }
  } catch (error) {
    const axiosError = error as AxiosError<any>;
    if(!!axiosError?.response?.data && axiosError?.response?.status === 404) {
      yield put(storeCommonData({ successBridgeTracking: false }));
    }
    yield put(errorHandler(axiosError));
  }
}

export function* trackCarrierBridgingDataWatcher() {
  yield takeEvery(commonActions.TRACK_CARRIER_BRIDGING, trackCarrierBridgingData);
}

function* requestBindForUnderwriterWholesaler({ payload }: AppRedux.ActionPayload): any {
  let loaderData = {
    loader: true,
    carrier_id: payload?.carrierId,
  };
  yield put(
    setRequestBindResponseDetails({
      successRequestBind: false,
      requestBindErrorMessage: '',
    })
  );
  try {
    yield put(bridgeToCarrierLoader(loaderData));
    yield put(setRequestBindLoader(true));
    const orgDetails: any = getOrgDetails();
    const {
      partner: { isProviderOneLogin, storedRequestId, producerDetails },
      applicant: { personal_details },
      auth,
    } = yield select();
    const carrierId: string = payload?.carrier_id?.toString() || '';
    const lob: string = payload?.lob?.toLowerCase() || '';
    const quoteBy = !isEmpty(get(auth, 'email', ''))
      ? get(auth, 'email')
      : !isEmpty(producerDetails?.producerEmail)
      ? producerDetails?.producerEmail
      : '';
    let carrierBridgeURL: string =
      payload?.quote_agent_url || payload?.agent_url || payload?.bridgeUrl || payload?.link || '';
    if (
      !isEmpty(lob) &&
      (lob === INSURANCE_PRODUCTS[2] || lob === INSURANCE_PRODUCTS[3] || lob === EARTH_QUAKE_LOB)
    ) {
      carrierBridgeURL = payload?.premiumDetails[0]?.link || '';
    }
    if (
      lob === INSURANCE_PRODUCTS[4] ||
      lob === INSURANCE_PRODUCTS[6] ||
      lob === INSURANCE_PRODUCTS[7]
    ) {
      const bridgeDetails: any = onBridgeToCarrierClick(payload, lob, true);
      const bridgeRequestDetails = {
        herdId: bridgeDetails?.herdId ?? '',
      }
      let apiRoute = '/quotes/bridge-carrier';
      if (lob === INSURANCE_PRODUCTS[4]) {
        apiRoute = '/pets/bridge-carrier';
        Object.assign(bridgeRequestDetails, {
          plan: bridgeDetails?.plan ?? '',
        });
      } else if (lob === INSURANCE_PRODUCTS[6]) {
        Object.assign(bridgeRequestDetails, {
          lob: bridgeDetails?.lob ?? '',
          carrierId: bridgeDetails?.carrierId ?? '',
          premium: bridgeDetails?.premium ?? '',
          coverage: bridgeDetails?.coverage ?? '',
          term: bridgeDetails?.term ?? '',
        });
      } else if (lob === INSURANCE_PRODUCTS[7]) {
        Object.assign(bridgeRequestDetails, {
          quoteBy,
          lob: bridgeDetails?.lob ?? '',
          carrierId: bridgeDetails?.carrierId ?? '',
          premium: bridgeDetails?.premium ?? '',
          coverage: bridgeDetails?.coverage ?? '',
          selectedPlan: bridgeDetails?.selectedPlan ?? '',
          selectedGroup: bridgeDetails?.selectedGroup ?? '',
        });
      }
      const responseDetails = yield axiosRequest('POST', apiRoute, isProviderOneLogin, bridgeRequestDetails);
      if (!!responseDetails?.data) {
        if (!!responseDetails?.data?.paymentLink) {
          carrierBridgeURL = responseDetails.data.paymentLink;
        }
      }
    }
    const requestData = {
      carrierId,
      lob,
      carrierBridgeURL,
      herdId: getLobHerdId(lob),
      providerRequestId: !!storedRequestId ? storedRequestId : '',
      product: personal_details?.product || '',
      organizationCode: orgDetails?.organizationCode || '',
      organizationId: orgDetails?.organizationId || '',
      propertyInformation: payload?.propertyInformation,
    };
    const details = yield axiosRequest('POST', '/quotes/bind', isProviderOneLogin, requestData);
    if (!isEmpty(details?.data) && details?.data?.Message?.includes('successfully')) {
      yield put(
        setRequestBindResponseDetails({
          successRequestBind: true,
        })
      );
      setIsBindRequested(carrierId, lob);
    }
  } catch (error) {
    const axiosError = error as AxiosError<any>;
    if (!!axiosError?.response?.data && axiosError?.response?.status === 404) {
      yield put(
        setRequestBindResponseDetails({
          successBridgeTracking: false,
          requestBindErrorMessage: axiosError?.response?.data?.Message || '',
        })
      );
    }
    yield put(errorHandler(axiosError));
  }
  loaderData.loader = false;
  yield put(bridgeToCarrierLoader(loaderData));
  yield put(setRequestBindLoader(false));
}

export function* requestBindForUnderwriterWholesalerWatcher() {
  yield takeEvery(commonActions.REQUEST_BIND_FOR_WHOLESALE, requestBindForUnderwriterWholesaler);
}

export function* stopAllLobPolling(): any {
  yield all([
    yield put(stopPolling()),
    yield put(stopHomeQuotePolling()),
    yield put(stopLifePolling()),
    yield put(stopCyberSecurityPolling()),
    yield put(stopUmbrellaPolling())
  ]);
}

export function* stopAllLobPollingWatcher() {
  yield takeEvery(commonActions.STOP_ALL_LOB_POLLING, stopAllLobPolling);
}

export default function* rootSaga() {
  yield all([
    fork(stepSubmittedWatcher),
    fork(fetchOrgConfigWatcher),
    fork(addProductWatcher),
    fork(submitNewProductsWatcher),
    fork(clearQuotesWatcher),
    fork(saveSelectedCarrierWatcher),
    fork(clearAutoQuotesWatcher),
    fork(fetchQuotingOrganizationWatcher),
    fork(saveSelectedProductsWatcher),
    fork(trackCarrierBridgingDataWatcher),
    fork(stopAllLobPollingWatcher),
    fork(requestBindForUnderwriterWholesalerWatcher),
  ]);
  yield take(commonActions.APP_INIT);
  // If no auth header set then get token from storage
  if (!axios.defaults.headers.common['Authorization'] && getAuthToken()) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${getAuthToken()}`;
  }
}
