import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Card, CardContent } from '@material-ui/core';
import PetDetails from '../../../pages/components/pet-details';
import { setLineOfBusiness } from '../../../pages/redux/actions';
import { INSURANCE_PRODUCTS } from '../../../constants';
import { ReduxRootState } from '../../../pages/redux/store';

const Pet = ({
  onNextTab,
  onPrevTab,
  currentIndex,
  setLineOfBusiness,
  goToLastTab,
  add,
}: AppComponents.PetFormProps) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    setLineOfBusiness(INSURANCE_PRODUCTS[4]);
  }, [setLineOfBusiness]);

  return (
    <>
      <section>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <Card elevation={0} className='applicant-home-form pet-form-card'>
                <CardContent>
                  <PetDetails
                    onNextTab={onNextTab}
                    onPrevTab={onPrevTab}
                    currentIndex={currentIndex}
                    goToLastTab={goToLastTab}
                    add={add}
                  />
                </CardContent>
              </Card>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const mapDispatchToProps = (dispatch: any): AppComponents.PetFormDispatch =>
  bindActionCreators({ setLineOfBusiness }, dispatch);

const mapStateToProps = ({ home }: ReduxRootState) => ({
  home,
});

export default connect(mapStateToProps, mapDispatchToProps)(Pet);
