export enum LineOfBusiness {
  AUTO = 'auto',
  CYBER_SECURITY = 'cyber-security',
  EARTHQUAKE = 'earthquake',
  FLOOD = 'flood',
  HOME = 'home',
  HOME_WARRANTY = 'home-warranty',
  LIFE = 'life',
  PET = 'pet',
  UMBRELLA = 'umbrella',
  QUAKE = 'quake',
}

export enum SelectedLob {
  AUTO = 'AUTO',
  CYBER_SECURITY = 'CYBERSECURITY',
  EARTHQUAKE = 'QUAKE',
  FLOOD = 'FLOOD',
  HOME = 'HOME',
  HOME_WARRANTY = 'HOMEWARRANTY',
  LIFE = 'LIFE',
  PET = 'PET',
  UMBRELLA = 'UMBRELLA',
}

export enum Product {
  HO3 = 'HO3',
  HO4 = 'HO4',
  HO5 = 'HO5',
  HO6 = 'HO6',
  DP1 = 'DP1',
  DP3 = 'DP3',
}
