/* eslint-disable max-lines-per-function */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Carousel } from 'react-responsive-carousel';
import { isEmpty, sortBy, startCase } from 'lodash';
import {
  CheckCircle,
  FiberManualRecord,
  NavigateBefore,
  NavigateNext,
} from '@material-ui/icons';
import {
  Card,
  CardContent,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  RadioGroup,
  Select,
  Typography,
} from '@material-ui/core';
import ThemesImages from '../themes-images';
import config from '../../../config/config';
import PetPlanModal from '../premium-plans-modal';
import FloodPlanModal from '../flood-plan-details';
import RatesBlock from './rates-block-iner';
import ViewAmsBoundData from '../view-bound-quote';
import { ErrorCatergoryIconMapping } from '../rate-page-common-component';
import {
  formatNumberToCurrency,
  getPremiumTerm,
  getSelectedCarrierIndex,
  numberToCurrency,
  getQuoteCarrierErrorCategory,
  getFloodPlansList
} from '../../../utils';
import PolicyBoundImageUrl from '../../../assets/images/policy-bound-ribbon.png';
import {
  BUSINESS_TYPE_ICON_MAPPING,
  FAILED,
  INSURANCE_BUSINESS_TYPES,
  INSURANCE_PRODUCTS,
  LOB_PRODUCTS_MAPPING,
  POLICY_BOUND,
  PREMIUM_SELECTION_LABEL_MAPPING,
  PRODUCT_MAPPING,
  QUAKE_COVERAGE_TYPES,
  FLOOD_DIRECT_CARRIERS,
} from '../../../constants';
import { storeCyberSecurityQuoteDetails } from '../../redux/actions';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import '../product-rates/products-rates.scss';

// todo: define type
// eslint-disable-next-line max-lines-per-function
const QuoteList = ({
  type,
  loading,
  quoteList,
  updateSelectedQuoteList,
  carrier,
  selectedQuoteList,
  ratePageType,
  ratePageTypes,
  home,
  carrierSelected,
  getLoaderImage,
  common,
  petDetails,
  storeCyberSecurityQuoteDetails,
  cyberSecurityDetails,
  lifeDetails,
  umbrellaDetails,
}: any) => {
  let themeType = home?.pconfig?.dale_config?.branding_on_dale_details?.template_name
    ? home?.pconfig?.dale_config?.branding_on_dale_details?.template_name
    : config.hippo.template_name;
  const [selectedCarrier, setSelectedCarrier] = useState(carrierSelected);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [resetViewMore, setResetViewMore] = useState(0);
  const [deductible, setDeductible] = useState<any>(0);
  const [premium, setPremium] = useState<any>({});
  const [disableCheckBox, setDisableCheckBox] = useState(false);
  const [showPremiumPlanModal, setShowPremiumPlanModal] = useState(false);
  const [availablePlanList, setAvailablePlanList] = useState<any>({});
  const [showFloodPlanModal, setShowFloodPlanModal] = useState(false);
  const [floodPlanList, setFloodPlanList] = useState<any>({});
  const isQuoteWithMultiplePremium =
    type === INSURANCE_BUSINESS_TYPES.FLOOD ||
    type === INSURANCE_BUSINESS_TYPES.QUAKE ||
    type === INSURANCE_BUSINESS_TYPES.PET ||
    type === INSURANCE_BUSINESS_TYPES.HOMEWARRANTY ||
    type === INSURANCE_BUSINESS_TYPES.CYBERSECURITY ||
    type === INSURANCE_BUSINESS_TYPES.LIFE ||
    type === INSURANCE_BUSINESS_TYPES.UMBRELLA;
  const singleFloodPremium = false;
  const handlePremiumPlanModal = () => {
    setShowPremiumPlanModal(true);
  };
  const closeModal = () => {
    setShowPremiumPlanModal(false);
  };

  const openFloodPlanModal = () => {
    setShowFloodPlanModal(true);
  };
  const closeFloodPlanModal = () => {
    setShowFloodPlanModal(false);
  };

  useEffect(() => {
    const quote = carrier || quoteList?.[selectedCarrier];
    if (type === INSURANCE_BUSINESS_TYPES.PET && quote) {
      setAvailablePlanList(quote?.available_plans);
    }
    const isFloodDirectCarrier = type === INSURANCE_BUSINESS_TYPES.FLOOD && FLOOD_DIRECT_CARRIERS.includes(quote?.carrier_id?.toString());
    if (isFloodDirectCarrier && quote) {
      const premiumDetails = quote?.premiumDetails || [];
      setFloodPlanList(getFloodPlansList(premiumDetails));
    }
    // eslint-disable-next-line
  }, [carrier, selectedCarrier]);

  useEffect(() => {
    if (
      type === INSURANCE_BUSINESS_TYPES.CYBERSECURITY &&
      ratePageType !== ratePageTypes.navigation
    ) {
      getCarrierPremium(deductible);
    }
    // eslint-disable-next-line
  }, [quoteList, selectedCarrier]);

  useEffect(() => {
    const { selectedCarriers } = common;
    if (!!selectedCarriers && !!type && selectedCarriers[type?.toLowerCase()]) {
      setSelectedCarrier(carrierSelected);
    }
    // eslint-disable-next-line
  }, [carrierSelected]);

  useEffect(() => {
    const { selectedCarriers } = common;
    const isPetQuoteError = type === INSURANCE_BUSINESS_TYPES.PET && !!quoteList[0]?.quoteError;
    let lobType = PRODUCT_MAPPING[type] || type;
    if (
      !isPetQuoteError &&
      !!selectedCarriers &&
      !!lobType &&
      selectedCarriers[lobType?.toLowerCase()] &&
      ratePageType !== ratePageTypes.navigation
    ) {
      const carrierSelect = selectedCarriers[lobType?.toLowerCase()];
      const price = Number(carrierSelect?.premium);
      const selectedQuoteIndex = getSelectedCarrierIndex(carrierSelect, quoteList);
      setSelectedCarrier(selectedQuoteIndex);
      if (isQuoteWithMultiplePremium) {
        const premiumDetails: any = quoteList[selectedQuoteIndex]?.premiumDetails;
        const premiumDetailList: any = !!premiumDetails && sortBy(premiumDetails, ['premium']);
        const selectedPremiumIndex: any =
          premiumDetailList.length &&
          premiumDetailList.findIndex((el: any) => Number(el?.premium) === price);
        if (selectedPremiumIndex >= 0) {
          setDeductible(selectedPremiumIndex);
          setPremium(premiumDetailList[selectedPremiumIndex]);
        }
      }
    }
    // eslint-disable-next-line
  }, [type]);

  useEffect(() => {
    const { policyBounded } = common;
    if (
      ((!!carrier?.policyBounded || !!quoteList[selectedCarrier]?.policyBounded) && !!type) ||
      (!!policyBounded?.home && type?.toLowerCase() === INSURANCE_PRODUCTS[0]) ||
      (!!policyBounded?.auto && type?.toLowerCase() === INSURANCE_PRODUCTS[1]) ||
      (!!policyBounded?.flood && type?.toLowerCase() === INSURANCE_PRODUCTS[2]) ||
      (!!policyBounded?.earthquake && type?.toLowerCase() === INSURANCE_PRODUCTS[3])
    ) {
      setDisableCheckBox(true);
    } else {
      setDisableCheckBox(false);
    }
    // eslint-disable-next-line
  }, [carrier, setDisableCheckBox, common, type, quoteList]);

  useEffect(() => {
    if (!loading && quoteList[selectedCarrier] && ratePageType !== ratePageTypes.navigation) {
      const timeout = Math.floor(Math.random() * 1000);
      setTimeout(() => {
        onCheckboxChange({
          target: {
            checked: true,
          },
        });
      }, timeout);
    }
    // eslint-disable-next-line
  }, [loading, ratePageType]);

  const drawCarrierOptions = () =>
    quoteList.map((carrierOfQuote: any, index: number) => (
      <MenuItem value={index} key={carrierOfQuote.carrier_id || carrierOfQuote.CarrierId}>
        {(!!carrierOfQuote?.carrier_name && startCase(carrierOfQuote.carrier_name)) ||
          (!!carrierOfQuote?.Carrier && startCase(carrierOfQuote.Carrier)) ||
          (!!carrierOfQuote?.name && startCase(carrierOfQuote.name))}
      </MenuItem>
    ));

  const extractPremiumDetails = () => {
    let premiumDetails: any = [];
    quoteList.forEach((carrierOfQuote: any, index: number) => {
      if (index === selectedCarrier && carrierOfQuote.premiumDetails) {
        carrierOfQuote.premiumDetails = sortBy(carrierOfQuote.premiumDetails, ['premium']);
        premiumDetails = [...carrierOfQuote.premiumDetails];
      }
    });
    return premiumDetails;
  };

  const drawDeductibleOptions = () => {
    const premiumDetails = extractPremiumDetails();

    if (premiumDetails.length) {
      if (type === INSURANCE_BUSINESS_TYPES.QUAKE) {
        return premiumDetails.map((detail: any, index: number) => (
          <MenuItem value={index} key={`${detail?.quote_id}${index}`}>
            {QUAKE_COVERAGE_TYPES[detail?.coverageType]}
          </MenuItem>
        ));
      } else if (type === INSURANCE_BUSINESS_TYPES.PET) {
        return premiumDetails.map((detail: any, index: number) => (
          <MenuItem value={index} key={`${detail?.plan}${index}`}>
            {startCase(detail?.plan)}
          </MenuItem>
        ));
      } else if (type === INSURANCE_BUSINESS_TYPES.HOMEWARRANTY) {
        return premiumDetails.map((detail: any, index: number) => (
          <MenuItem value={index} key={`${detail?.termLabel}${index}`}>
            {startCase(detail?.termLabel)}
          </MenuItem>
        ));
      } else if (type === INSURANCE_BUSINESS_TYPES.LIFE) {
        return premiumDetails.map((detail: any, index: number) => (
          <MenuItem value={index} key={`${detail?.coverage}${index}`}>
            {`${numberToCurrency(detail?.coverage)} / ${getPremiumTerm(detail?.term)}`}
          </MenuItem>
        ));
      } else if (type === INSURANCE_BUSINESS_TYPES.CYBERSECURITY) {
        return premiumDetails.map((detail: any, index: number) => (
          <MenuItem value={index} key={`${detail?.coverageLabel}${index}`}>
            {detail?.coverageLabel}
          </MenuItem>
        ));
      } else {
        return premiumDetails.map((detail: any, index: number) => (
          <MenuItem value={index} key={`${detail?.quote_id}${index}`}>
            {formatNumberToCurrency(detail.deductible)}
          </MenuItem>
        ));
      }
    } else {
      return <Typography>No deductible</Typography>;
    }
  };

  const getCarrierPremium = (deductibleIndex: any) => {
    let premiumDetails = extractPremiumDetails();
    if (premiumDetails.length) {
      premiumDetails = sortBy(premiumDetails, ['premium']);
      premiumDetails.forEach((detail: any, index: number) => {
        if (index === deductibleIndex) {
          setPremium(detail);
        }
      });
    }
  };

  useEffect(() => {
    if (isQuoteWithMultiplePremium && !loading) {
      let premiumIndex = 0;
      if (
        (type === INSURANCE_BUSINESS_TYPES.QUAKE || type === INSURANCE_BUSINESS_TYPES.PET) &&
        selectedQuoteList &&
        selectedQuoteList.length
      ) {
        const quoteIndex = selectedQuoteList.findIndex((quote: any) => quote?.type === type);
        const selectedPremiumType = selectedQuoteList[quoteIndex]?.quote?.selectedPremiumType;
        let premiumDetails = selectedQuoteList[quoteIndex]?.quote?.premiumDetails;
        if (premiumDetails && selectedPremiumType && premiumDetails.length) {
          premiumDetails = sortBy(premiumDetails, ['premium']);
          premiumIndex = premiumDetails.findIndex(
            (premium: any) =>
              (type === INSURANCE_BUSINESS_TYPES.QUAKE ? premium?.coverageType : premium?.plan) ===
              selectedPremiumType
          );
        }
        getCarrierPremium(premiumIndex);
      } else {
        getCarrierPremium(deductible);
      }
    }
    // eslint-disable-next-line
  }, [
    loading,
    deductible,
    petDetails.bridgeToCarrierLoader,
    cyberSecurityDetails.bridgeToCarrierLoader,
    lifeDetails.bridgeToCarrierLoader,
  ]);

  const onDeductibleChange = (e: any) => {
    setDeductible(e);
  };

  const onCheckboxChange = (e: any, details?: any) => {
    setIsCheckboxChecked(e.target.checked);
    let carrierWithPrice = null;
    if (isQuoteWithMultiplePremium && ratePageType === ratePageTypes.navigation) {
      if (details?.premium) {
        carrierWithPrice = { ...carrier, price: details?.premium };
        type === INSURANCE_BUSINESS_TYPES.PET || type === INSURANCE_BUSINESS_TYPES.CYBERSECURITY
          ? setPremium(details)
          : getCarrierPremium(deductible);
        type !== INSURANCE_BUSINESS_TYPES.PET && setSelectedCarrier(carrierWithPrice);
        let selectionType =
          type === INSURANCE_BUSINESS_TYPES.QUAKE || type === INSURANCE_BUSINESS_TYPES.CYBERSECURITY
            ? details?.coverageType
            : type === INSURANCE_BUSINESS_TYPES.LIFE || type === INSURANCE_BUSINESS_TYPES.UMBRELLA
            ? details?.coverage
            : details?.plan;
        let term =
          type === INSURANCE_BUSINESS_TYPES.LIFE || type === INSURANCE_BUSINESS_TYPES.UMBRELLA
            ? details?.term
            : '';
        updateSelectedQuoteList(carrierWithPrice, e.target.checked, type, selectionType, term);
      }
    }

    if (
      (isQuoteWithMultiplePremium && !quoteList[selectedCarrier]?.price) ||
      quoteList[selectedCarrier]?.quoteError
    ) {
      return;
    }
    if (!carrierWithPrice) {
      if (
        !!type &&
        common?.selectedCarriers &&
        common?.selectedCarriers[type?.toLowerCase()] &&
        !isEmpty(details)
      ) {
        const carrierDetails = common?.selectedCarriers[type?.toLowerCase()];
        const selectedQuoteIndex = quoteList.findIndex(
          (el: any) => el?.carrier_id?.toString() === carrierDetails?.carrierId?.toString()
        );
        updateSelectedQuoteList(quoteList[selectedQuoteIndex], e.target.checked, type);
      } else {
        updateSelectedQuoteList(
          carrier ? carrier : quoteList[selectedCarrier],
          e.target.checked,
          type
        );
      }
    }
  };

  const onCheckboxChangeForCyber = (e: any) => {
    if (!isEmpty(e.target.value)) {
       let quoteObj = carrier ? carrier : quoteList[selectedCarrier];
       let premiumDetails = quoteObj[e.target.value];
       quoteObj.selectedPlan = e.target.value;
       quoteObj.premiumDetails = premiumDetails;
       if (premiumDetails.length) {
         storeCyberSecurityQuoteDetails({
           quoteList: [quoteObj]
         });
       }
    }
  };

  const drawRatesBlocks = () => {
    if (ratePageType === ratePageTypes.navigation) {
      return (
        <RatesBlock
          key={`rates_${carrier.carrier_id ? carrier.carrier_id : carrier.CarrierId}_${type}`}
          quote={carrier}
          type={type}
          premiumDetails={premium}
          chooseCarrierOption={updateSelectedQuoteList}
          ratePageType={ratePageType}
          ratePageTypes={ratePageTypes}
          resetViewMore={resetViewMore}
          onCheckboxChange={onCheckboxChange}
          verifyChecked={verifyChecked}
          isQuoteWithMultiplePremium={isQuoteWithMultiplePremium}
          isCheckboxChecked={isCheckboxChecked}
          handlePremiumPlanModal={handlePremiumPlanModal}
          singleFloodPremium={singleFloodPremium}
          onCheckboxChangeForCyber={onCheckboxChangeForCyber}
          openFloodPlanModal={openFloodPlanModal}
        />
      );
    } else if (ratePageType === ratePageTypes.carousel) {
      return quoteList.map((quote: any) => (
        <RatesBlock
          key={`rates_${quote.carrier_id || quote.CarrierId}_${type}`}
          quote={quote}
          type={type}
          premiumDetails={premium}
          chooseCarrierOption={() => {
            if (shouldUpdateCheckbox()) {
              updateSelectedQuoteList(quoteList[selectedCarrier], isCheckboxChecked, type);
            }
          }}
          resetViewMore={resetViewMore}
          ratePageType={ratePageType}
          ratePageTypes={ratePageTypes}
          isQuoteWithMultiplePremium={isQuoteWithMultiplePremium}
          isCheckboxChecked={isCheckboxChecked}
          handlePremiumPlanModal={handlePremiumPlanModal}
          singleFloodPremium={singleFloodPremium}
          onCheckboxChangeForCyber={onCheckboxChangeForCyber}
          openFloodPlanModal={openFloodPlanModal}
        />
      ));
    }
  };

  const shouldUpdateCheckbox = (e = selectedCarrier) => {
    let updateCheckbox = false;
    let selectedAutoQuoteList =
      sessionStorage.selectedQuoteList && JSON.parse(sessionStorage.selectedQuoteList);

    Array.isArray(selectedAutoQuoteList) &&
      selectedAutoQuoteList.forEach((quote: any) => {
        const quoteCarrierName = quote.quote?.carrier_name
          ? quote.quote?.carrier_name
          : quote.quote?.name;

        const selectedCarrierName = quoteList[e]?.carrier_name
          ? quoteList[e]?.carrier_name
          : quoteList[e]?.name;

        if (quoteList[e]) {
          if (
            type !== INSURANCE_BUSINESS_TYPES.FLOOD &&
            type !== INSURANCE_BUSINESS_TYPES.QUAKE &&
            type !== INSURANCE_BUSINESS_TYPES.PET &&
            type !== INSURANCE_BUSINESS_TYPES.HOMEWARRANTY &&
            type !== INSURANCE_BUSINESS_TYPES.CYBERSECURITY &&
            type !== INSURANCE_BUSINESS_TYPES.LIFE &&
            type !== INSURANCE_BUSINESS_TYPES.UMBRELLA &&
            quote.type === type &&
            quoteCarrierName === selectedCarrierName &&
            !quote?.quote?.policyBounded &&
            !quoteList[e]?.quoteError
          ) {
            updateCheckbox = true;
          } else if (
            isQuoteWithMultiplePremium &&
            quote.type === type &&
            quote.quote?.description === quoteList[e]?.description &&
            !quote?.quote?.policyBounded &&
            !quoteList[e]?.quoteError
          ) {
            updateCheckbox = true;
          }
        }
      });

    return updateCheckbox;
  };

  const onQuoteOptionChange = (e: any) => {
    setSelectedCarrier(e);
    // hide opened view more components
    setResetViewMore(resetViewMore + 1);
    if (quoteList[e]?.quoteError || quoteList[e]?.Status?.includes('Failed')) {
      // todo: show notification
      return;
    }
    if (isQuoteWithMultiplePremium && !quoteList[e].price) {
      // todo: show error, choose price for flood
      return;
    }

    if (isQuoteWithMultiplePremium && quoteList[e].price) {
      const premiumDetail = sortBy(quoteList[e]?.premiumDetails, ['premium']);
      const carrierDetail = premiumDetail[deductible];
      quoteList[e].price = carrierDetail?.premium;
      // todo: show error, choose price for flood
      getCarrierPremium(deductible);
    }
    if (shouldUpdateCheckbox(e)) {
      updateSelectedQuoteList(quoteList[e], isCheckboxChecked, type);
    }
  };

  const verifyChecked = (premiumCarrier?: number): boolean => {
    let checked = false;
    if (!disableCheckBox) {
      !isEmpty(selectedQuoteList) &&
        selectedQuoteList.forEach((quoteSelected: any) => {
          if (isQuoteWithMultiplePremium && !singleFloodPremium && type === quoteSelected.type) {
            if (
              premiumCarrier === quoteSelected.quote?.price &&
              !quoteSelected?.quote?.policyBounded
            ) {
              checked = true;
            }
          } else if (type === quoteSelected.type) {
            if (
              !quoteSelected?.quote?.policyBounded &&
              ((quoteSelected.quote?.carrier_id &&
                quoteSelected.quote?.carrier_id === carrier.carrier_id) ||
                (quoteSelected.quote?.CarrierId &&
                  quoteSelected.quote?.CarrierId === carrier.CarrierId))
            ) {
              checked = true;
            }
          }
        });
    } else {
      if (!!carrier?.policyBounded &&
        (!carrier?.floodQuote || singleFloodPremium) &&
        !carrier.earthquakeQuote &&
        type !== INSURANCE_BUSINESS_TYPES.HOMEWARRANTY &&
        type !== INSURANCE_BUSINESS_TYPES.CYBERSECURITY &&
        type !== INSURANCE_BUSINESS_TYPES.LIFE &&
        type !== INSURANCE_BUSINESS_TYPES.UMBRELLA
      ) {
        checked = true;
      }
    }
    return checked;
  };

  const verifyCarouselChecked = () => {
    let checked = false;
    if (!disableCheckBox) {
      if (quoteList[selectedCarrier]?.Status?.includes('Failed')) {
        checked = false;
      }
      if (shouldUpdateCheckbox()) {
        checked = true;
      }
    }
    if (!!disableCheckBox && quoteList[selectedCarrier]?.policyBounded && !loading) {
      checked = true;
    }
    return checked;
  };

  const getRatePageClassName = (pageType: any) => {
    if (isQuoteWithMultiplePremium && !singleFloodPremium) {
      return 'flood-quake-rates-blocks';
    } else if (pageType === INSURANCE_BUSINESS_TYPES.HOME || singleFloodPremium) {
      return 'home-rates-blocks';
    } else if (pageType === INSURANCE_BUSINESS_TYPES.AUTO) {
      return 'auto-rates-blocks';
    } else {
      return 'rates-left-block';
    }
  };

  const navigationContent = () => (
    <Grid
      item
      xs={(isQuoteWithMultiplePremium && !singleFloodPremium) || carrier.quoteError ? 12 : 11}
      className={getRatePageClassName(type)}
    >
      <Grid container spacing={0} className='h-100'>
        <Grid item xs={12} className='d-flex'>
          {drawRatesBlocks()}
        </Grid>
      </Grid>
    </Grid>
  );
  const carouselContent = () => (
    <>
      <Grid item xs={12} sm={12}>
        <div className='row m-0'>
          <div className='col-lg-2 width-16'></div>
          <div className='col-lg-10 mb-3 pl-0'>
            <div className='row no-gutters'>
              <div className='col-lg-7 padding-left-0 mob-width-50'>
                <div className='row select-carrier-row'>
                  <div className='col-lg-4 center-div pr-0 d-flex align-items-center'>
                    <Typography className='label'>Select Carrier:</Typography>
                  </div>
                  <div className='col-lg-7 padding-left-0 center-div'>
                    <FormControl className='select-wrap'>
                      <Select
                        value={selectedCarrier}
                        disabled={
                          common?.selectedCarrierLoader ||
                          quoteList.length === 1 ||
                          petDetails?.bridgeToCarrierLoader ||
                          cyberSecurityDetails?.bridgeToCarrierLoader ||
                          lifeDetails?.bridgeToCarrierLoader ||
                          umbrellaDetails?.bridgeToCarrierLoader
                        }
                        onChange={e => onQuoteOptionChange(e.target.value)}
                      >
                        {drawCarrierOptions()}
                      </Select>
                    </FormControl>
                  </div>
                </div>
              </div>
              {isQuoteWithMultiplePremium && !quoteList[selectedCarrier]?.Status && (
                <div className='col-lg-5 mob-margin-top mob-width-50'>
                  <div className='row select-carrier-row m-0'>
                    <div className='col-lg-4 center-div padding-left-0 padding-right-0  d-flex align-items-center '>
                      {type === INSURANCE_BUSINESS_TYPES.PET ? (
                        <Typography className='label deductible-text d-flex'>
                          Select{' '}
                          <span className='plan-label' onClick={() => handlePremiumPlanModal()}>
                            Plan:
                          </span>
                        </Typography>
                      ) : (
                        <Typography className='label deductible-text'>
                            {PREMIUM_SELECTION_LABEL_MAPPING[type] || 'Deductible:'}
                        </Typography>
                      )}
                    </div>
                    <div className='col-lg-8 center-div padding-left-0 padding-right-0 d-flex justify-content-lg-center'>
                      <FormControl className='select-wrap deductible'>
                        <Select
                          value={deductible}
                          disabled={
                            common?.selectedCarrierLoader || petDetails?.bridgeToCarrierLoader
                          }
                          onChange={e => onDeductibleChange(e.target.value)}
                        >
                          {drawDeductibleOptions()}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className='row logo-wrap'>
          <div className='col-lg-2 padding-0'>
            <div className='insurence-logo-div'>
              <img
                src={ThemesImages[themeType][BUSINESS_TYPE_ICON_MAPPING[type]]}
                alt='business'
                className='img-fluid mx-auto d-flex'
              />
              <Typography className='product-label'>{LOB_PRODUCTS_MAPPING[type.toLowerCase()]}</Typography>
            </div>
          </div>
          <div className='col-lg-10 padding-left-0 pl-0 padding-right-34'>
            <Carousel
              className='slider-wraper  position-relative'
              showArrows={true}
              showThumbs={false}
              showStatus={false}
              onChange={onQuoteOptionChange}
              showIndicators={false}
              transitionTime={0}
              selectedItem={selectedCarrier}
              renderArrowPrev={(clickHandler, hasPrev, label) =>
                hasPrev && (
                  <div className='col-lg-1 right-position d-none'>
                    <div className='next-icon-div'>
                      <NavigateBefore className='prev-next-icon next' onClick={clickHandler} />
                    </div>
                  </div>
                )
              }
              renderArrowNext={(clickHandler, hasNext, label) =>
                hasNext && (
                  <div className='col-lg-1 left-position d-none'>
                    <div className='next-icon-div '>
                      <NavigateNext className='prev-next-icon before' onClick={clickHandler} />
                    </div>
                  </div>
                )
              }
            >
              {drawRatesBlocks()}
            </Carousel>
          </div>
        </div>
      </Grid>
    </>
  );
  let showPolicyBoundTag = false;
  if (!!carrier?.policyBounded || !!quoteList[selectedCarrier]?.policyBounded) {
    if (
      !!ratePageType &&
      ratePageType === ratePageTypes.navigation &&
      !!type &&
      (type === INSURANCE_BUSINESS_TYPES.HOME || type === INSURANCE_BUSINESS_TYPES.AUTO)
    ) {
      showPolicyBoundTag = false;
    } else {
      showPolicyBoundTag = true;
    }
  }
  const [openViewBoundQuote, setViewBoundQuote] = useState(false);

  const openPolicyBoundDetailsModal = () => {
    setViewBoundQuote(true);
  };
  const selectedQuote: any =
    (ratePageType === ratePageTypes.navigation ? carrier : quoteList[selectedCarrier]) || null;
  const isErrorQuote: boolean =
    (!!selectedQuote
      ? selectedQuote?.quoteError || selectedQuote?.Status?.includes(startCase(FAILED))
      : false) || false;
  const carrierErrorType: string =
    isErrorQuote && !!selectedQuote && !isEmpty(getQuoteCarrierErrorCategory(selectedQuote))
      ? getQuoteCarrierErrorCategory(selectedQuote)
      : '';

  return (
    <Grid container spacing={2} className='mt-3'>
      {!!openViewBoundQuote && (
        <ViewAmsBoundData
          open={openViewBoundQuote}
          onClose={() => setViewBoundQuote(false)}
          lob={type}
        />
      )}
      <Grid item xs={12}>
        <Card
          className={`${
            type === INSURANCE_BUSINESS_TYPES.UMBRELLA
              ? 'rli-rates-block'
              : isCheckboxChecked
              ? 'rates-block rates-block-active'
              : 'rates-block rates-block-deactive '
          }
          `}
          elevation={0}
        >
          {loading && !quoteList.length && ratePageType !== ratePageTypes.navigation ? (
            <CardContent className='pr-0'>
              <div className='row m-0 quote-loader-wrap'>
                <Grid item xs={12} sm={3}>
                  <div className='insurence-logo-div'>
                    <img
                      src={ThemesImages[themeType][BUSINESS_TYPE_ICON_MAPPING[type]]}
                      alt='business'
                      className='img-fluid mx-auto d-flex'
                    />
                    <Typography className='product-label'>
                      {LOB_PRODUCTS_MAPPING[type.toLowerCase()]}
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={12} sm={9}>
                  <div className='loader-div'>
                    <img src={getLoaderImage(type)} alt='loader' />
                  </div>
                </Grid>
              </div>
            </CardContent>
          ) : (
            <>
              {!!showPolicyBoundTag && (
                <div className='row'>
                  <div className='col-lg-12'>
                    <img
                      src={PolicyBoundImageUrl}
                      alt={POLICY_BOUND}
                      className='img-fluid position-absolute policy-bound-label'
                      onClick={() => openPolicyBoundDetailsModal()}
                    />
                  </div>
                </div>
              )}
              <CardContent className='rli-padding-0'>
                <Grid container spacing={0}>
                  {!isErrorQuote || isEmpty(carrierErrorType) ? (
                    <>
                      {(ratePageType === ratePageTypes.carousel ||
                        (ratePageType === ratePageTypes.navigation &&
                          (type !== INSURANCE_BUSINESS_TYPES.FLOOD || singleFloodPremium) &&
                          type !== INSURANCE_BUSINESS_TYPES.QUAKE &&
                          type !== INSURANCE_BUSINESS_TYPES.PET &&
                          type !== INSURANCE_BUSINESS_TYPES.HOMEWARRANTY &&
                          type !== INSURANCE_BUSINESS_TYPES.CYBERSECURITY &&
                          type !== INSURANCE_BUSINESS_TYPES.LIFE &&
                          type !== INSURANCE_BUSINESS_TYPES.UMBRELLA)) && (
                        <FormControl component='fieldset' className='checkbox-wrap-radio'>
                          <RadioGroup row name='lines'>
                            <FormControlLabel
                              className='radio-button-box'
                              control={
                                <Checkbox
                                  icon={<FiberManualRecord />}
                                  checkedIcon={<CheckCircle className='check-icon' />}
                                  name='checkedH'
                                  checked={
                                    ratePageType === ratePageTypes.navigation
                                      ? verifyChecked()
                                      : verifyCarouselChecked()
                                  }
                                  disabled={
                                    !!disableCheckBox
                                      ? true
                                      : ratePageType === ratePageTypes.navigation &&
                                        (type !== INSURANCE_BUSINESS_TYPES.FLOOD ||
                                          singleFloodPremium) &&
                                        type !== INSURANCE_BUSINESS_TYPES.QUAKE &&
                                        type !== INSURANCE_BUSINESS_TYPES.PET &&
                                        type !== INSURANCE_BUSINESS_TYPES.HOMEWARRANTY &&
                                        type !== INSURANCE_BUSINESS_TYPES.CYBERSECURITY &&
                                        type !== INSURANCE_BUSINESS_TYPES.LIFE &&
                                        type !== INSURANCE_BUSINESS_TYPES.UMBRELLA
                                      ? isErrorQuote
                                      : ratePageType === ratePageTypes.carousel &&
                                        (type !== INSURANCE_BUSINESS_TYPES.FLOOD ||
                                          singleFloodPremium) &&
                                        type !== INSURANCE_BUSINESS_TYPES.QUAKE &&
                                        type !== INSURANCE_BUSINESS_TYPES.PET &&
                                        type !== INSURANCE_BUSINESS_TYPES.HOMEWARRANTY &&
                                        type !== INSURANCE_BUSINESS_TYPES.CYBERSECURITY &&
                                        type !== INSURANCE_BUSINESS_TYPES.LIFE &&
                                        type !== INSURANCE_BUSINESS_TYPES.UMBRELLA
                                      ? isErrorQuote
                                      : false
                                  }
                                  onChange={e => onCheckboxChange(e, '')}
                                />
                              }
                              label=''
                            />
                          </RadioGroup>
                        </FormControl>
                      )}
                    </>
                  ) : (
                    <>
                      {!isEmpty(carrierErrorType) && (
                        <ErrorCatergoryIconMapping
                          carrierErrorType={carrierErrorType}
                        />
                      )}
                    </>
                  )}
                  {ratePageType === ratePageTypes.navigation
                    ? navigationContent()
                    : carouselContent()}
                </Grid>
              </CardContent>
            </>
          )}
        </Card>
      </Grid>
      {showPremiumPlanModal && (
        <PetPlanModal
          premiumPlansList={availablePlanList}
          showModal={showPremiumPlanModal}
          closeModal={closeModal}
        />
      )}
      {showFloodPlanModal && !isEmpty(floodPlanList) && (
        <FloodPlanModal
          floodPlansList={floodPlanList}
          closeModal={closeFloodPlanModal}
        />
      )}
    </Grid>
  );
};

const mapStateToProps = ({
  auth,
  common,
  home,
  petDetails,
  cyberSecurityDetails,
  lifeDetails,
  umbrellaDetails
}: AppComponents.AppStore): AppComponents.AppStoreProps => ({
  auth,
  common,
  home,
  petDetails,
  cyberSecurityDetails,
  lifeDetails,
  umbrellaDetails
});

export default connect(mapStateToProps, { storeCyberSecurityQuoteDetails })(QuoteList);
