import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Card, CardContent } from '@material-ui/core';
import UmbrellaDetails from '../../../pages/components/umbrella-details';
import { setLineOfBusiness } from '../../../pages/redux/actions';
import { INSURANCE_PRODUCTS } from '../../../constants';
import { ReduxRootState } from '../../../pages/redux/store';

const Umbrella = (props: AppComponents.UmbrellaFormProps) => {
  const { currentIndex, add } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
    props?.setLineOfBusiness(INSURANCE_PRODUCTS[6]);
    // eslint-disable-next-line
  }, [props.setLineOfBusiness]);

  return (
    <>
      <section>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <Card elevation={0} className='applicant-home-form'>
                <CardContent>
                  <UmbrellaDetails
                    onNextTab={props.onNextTab}
                    onPrevTab={props.onPrevTab}
                    currentIndex={currentIndex}
                    goToLastTab={props.goToLastTab}
                    add={add}
                  />
                </CardContent>
              </Card>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const mapDispatchToProps = (dispatch: any): AppComponents.UmbrellaFormDispatch =>
  bindActionCreators({ setLineOfBusiness }, dispatch);

const mapStateToProps = ({ home }: ReduxRootState) => ({
  home,
});

export default connect(mapStateToProps, mapDispatchToProps)(Umbrella);
