import config from '../../config/config';
import {
  HO6,
  INSURANCE_BUSINESS_TYPES,
  QUESTIONNAIRE_PATH,
  QUOTE_APPLICATION_ROUTE,
  QUOTE_ROUTE,
} from '../../constants';
import ThemesImages from '../../pages/components/themes-images';
import store from '../../pages/redux/store';
import { getThemeType } from '../../utils';
const { AUTO, CYBERSECURITY, FLOOD, HOME, HOMEWARRANTY, LIFE, PET, QUAKE, UMBRELLA } =
  INSURANCE_BUSINESS_TYPES;

export const isEnabledPRSv2Flow = () => !!config?.featureEnablePRSv2;

export const getPathName = () => {
  if (isEnabledPRSv2Flow()) {
    return QUOTE_APPLICATION_ROUTE;
  }
  if (document.location.href.includes(QUOTE_ROUTE)) {
    return QUOTE_ROUTE;
  }
  return QUESTIONNAIRE_PATH;
};

export const isProdcuctTypeHO6 = () => {
  const {
    home: { productType },
  } = store.getState();
  return !!productType && productType.toLowerCase() === HO6.toLowerCase();
};

export const getLoaderImage = (type: string) => {
  const themeType: string = getThemeType();
  switch (type) {
    case HOME:
      return ThemesImages[themeType].homeLoader;
    case FLOOD:
      return ThemesImages[themeType].floodLoader;
    case AUTO:
      return ThemesImages[themeType].autoLoader;
    case QUAKE:
      return ThemesImages[themeType].quakeLoader;
    case PET:
      return ThemesImages[themeType].petLoader;
    case HOMEWARRANTY:
      return ThemesImages[themeType].homeWarrantyLoader;
    case CYBERSECURITY:
      return ThemesImages[themeType].cyberSecurityLoader;
    case LIFE:
      return ThemesImages[themeType].lifeLoader;
    case UMBRELLA:
      return ThemesImages[themeType].umbrellaLoader;
    default:
      return ThemesImages[themeType].homeLoader;
  }
};
