export const OCCUPATION_DATA = {
  // Homemaker/House person
  1: [{ label: 'Homemaker/House person', value: 'Homemaker/Houseprsn' }],
  // Retired
  2: [{ label: 'Retired', value: 'Retired' }],
  // Disabled
  3: [{ label: 'Disabled', value: 'Disabled' }],
  // Unemployed
  4: [{ label: 'Unemployed', value: 'Unemployed' }],
  // Student
  5: [
    { label: 'Graduate Student', value: 'Graduate Student' },
    { label: 'High school', value: 'High school' },
    { label: 'Other', value: 'Other' },
    { label: 'Undergraduate', value: 'Undergraduate' },
  ],
  // Agriculture/Forestry/Fishing
  6: [
    { label: 'Agriculture Inspector/Grader', value: 'Agr Inspect/Grader' },
    { label: 'Arborist', value: 'Arborist' },
    { label: 'Clerk', value: 'Clerk' },
    { label: 'Equipment Operator', value: 'Equip. Operator' },
    { label: 'Farm/Ranch Owner', value: 'Farm/Ranch Owner' },
    { label: 'Farm/Ranch Worker', value: 'Farm/Ranch Worker' },
    { label: 'Fisherman', value: 'Fisherman' },
    { label: 'Florist', value: 'Florist' },
    { label: 'Laborer/Worker', value: 'Laborer/Worker' },
    { label: 'Landscaper/Nursery Worker', value: 'Landscape/NursryWkr' },
    { label: 'Landscaper', value: 'Landscaper' },
    { label: 'Logger', value: 'Logger' },
    { label: 'Mill worker', value: 'Millworker' },
    { label: 'Other', value: 'Other' },
    { label: 'Ranger', value: 'Ranger' },
    { label: 'Supervisor', value: 'Supervisor' },
    { label: 'Timber Grader/Scale', value: 'Timber Grader/Scale' },
  ],
  // Art/Design/Media
  7: [
    { label: 'Actor', value: 'Actor' },
    { label: 'Administrative Assistant', value: 'Admin Assist' },
    { label: 'Announcer/Broadcaster', value: 'Announcer/Broadcstr' },
    { label: 'Artist/Animator', value: 'Artist/Animator' },
    { label: 'Author/Writer', value: 'Author/Writer' },
    { label: 'Choreography/Dancer', value: 'Choreography/Dancer' },
    { label: 'Clerk', value: 'Clerk' },
    { label: 'Composer/Director', value: 'Composer/Director' },
    { label: 'Curator', value: 'Curator' },
    { label: 'Designer', value: 'Designer' },
    { label: 'Editor', value: 'Editor' },
    { label: 'Journalist/Reporter', value: 'Journalist/Reporter' },
    { label: 'Musician/Singer', value: 'Musician/Singer' },
    { label: 'Other', value: 'Other' },
    { label: 'Printer', value: 'Printer' },
    { label: 'Producer', value: 'Producer' },
    { label: 'Production Crew', value: 'Production Crew' },
    { label: 'Projectionist', value: 'Projectionist' },
    { label: 'Receptionist/Secretary', value: 'Receptionist/Sec' },
    { label: 'Ticket Sales/Usher', value: 'Ticket Sales/Usher' },
  ],
  // Banking/Finance/Real Estate
  8: [
    { label: 'Accountant/Auditor', value: 'Accountant/Auditor' },
    { label: 'Administrative Assistant', value: 'Admin Assist' },
    { label: 'Analyst/Broker', value: 'Analyst/Broker' },
    { label: 'Bookkeeper', value: 'Bookkeeper' },
    { label: 'Branch Manager', value: 'Branch Manager' },
    { label: 'Clerk', value: 'Clerk' },
    { label: 'Collections', value: 'Collections' },
    { label: 'Consultant', value: 'Consultant' },
    { label: 'Controller', value: 'Controller' },
    { label: 'CSR/Teller', value: 'CSR/Teller' },
    { label: 'Director/Administrator', value: 'Director/Administr' },
    { label: 'Executive', value: 'Executive' },
    { label: 'Financial Advisor', value: 'Financial Advisor' },
    { label: 'Investment Banker', value: 'Investment Banker' },
    { label: 'Investor', value: 'Investor' },
    { label: 'Loan/Escrow Processor', value: 'Loan/EscrowProcess' },
    { label: 'Manager-Credit/Loan', value: 'Mgr-Credit/Loan' },
    { label: 'Manager-Portfolio/Production', value: 'Mgr-Portfolio/Prod.' },
    { label: 'Manager-Property', value: 'Mgr-Property' },
    { label: 'Other', value: 'Other' },
    { label: 'Realtor', value: 'Realtor' },
    { label: 'Receptionist/Secretary', value: 'Receptionist/Sec' },
    { label: 'Sales Agent/Representative', value: 'SalesAgt/Represent.' },
    { label: 'Trader, Financial Instruments', value: 'Trader,Finan Instru' },
    { label: 'Underwriter', value: 'Underwriter' },
  ],
  // Business/Sales/Office
  9: [
    { label: 'Account Executive', value: 'Account Executive' },
    { label: 'Administrative Assistant', value: 'Admin Assist' },
    { label: 'Buyer', value: 'Buyer' },
    { label: 'Clerk-Office', value: 'Clerk-Office' },
    { label: 'Consultant', value: 'Consultant' },
    { label: 'Customer Service Representative', value: 'CSR' },
    { label: 'Director/Administrator', value: 'Director/Administr' },
    { label: 'Executive', value: 'Executive' },
    { label: 'H.R. Representative', value: 'H.R. Representative' },
    { label: 'Marketing Researcher', value: 'Marketing Researchr' },
    { label: 'Messenger/Courier', value: 'Messenger/Courier' },
    { label: 'Manager - District', value: 'Mgr - District' },
    { label: 'Manager - Finance', value: 'Mgr - Finance' },
    { label: 'Manager - Department/Store', value: 'Mgr-Dept/Store' },
    { label: 'Manager - General Operations', value: 'Mgr-General Opers' },
    { label: 'Manager - H.R./Public Relations', value: 'Mgr-H.R./PublicRel' },
    { label: 'Manager - Marketing/Sales', value: 'Mgr-Mkt/Sales' },
    { label: 'Manager/Supervisor - Office', value: 'Mgr/Supervisr-Offic' },
    { label: 'Other', value: 'Other' },
    { label: 'Receptionist/Secretary', value: 'Receptionist/Sec' },
    { label: 'Sales-Counter/Rental', value: 'Sales-Counter/Rentl' },
    { label: 'Sales-Home Based', value: 'Sales-Home Based' },
    { label: 'Sales-Manufacture Rep', value: 'Sales-Mfg Rep' },
    { label: 'Sales-Retail/Wholesale', value: 'Sales-Retail/Whlsle' },
    { label: 'Sales-Route/Vendor', value: 'Sales-Route/Vendor' },
  ],
  // Construction/Energy Trades
  10: [
    { label: 'Boiler Operator/Maker', value: 'Boiler Oper/Maker' },
    { label: 'Bricklayer/Mason', value: 'Bricklayer/Mason' },
    { label: 'Carpenter', value: 'Carpenter' },
    { label: 'Carpet Installer', value: 'Carpet Installer' },
    { label: 'Concrete Worker', value: 'Concrete Worker' },
    { label: 'Construction - Project Manager', value: 'Constrct Proj Mgr' },
    { label: 'Contractor', value: 'Contractor' },
    { label: 'Crane Operator', value: 'Crane Operator' },
    { label: 'Electrician/Linesman', value: 'Electrician/Linesmn' },
    { label: 'Elevator Technician/Installer', value: 'ElevatorTech/Instl' },
    { label: 'Equipment Operator', value: 'Equip. Operator' },
    { label: 'Floor Layer/Finisher', value: 'FloorLayer/Finisher' },
    { label: 'Foreman/Supervisor', value: 'Foreman/Supervisor' },
    { label: 'Handyman', value: 'Handyman' },
    { label: 'Heat/Air Technician', value: 'Heat/Air Technician' },
    { label: 'Inspector', value: 'Inspector' },
    { label: 'Laborer/Worker', value: 'Laborer/Worker' },
    { label: 'Metalworker', value: 'Metalworker' },
    { label: 'Miner', value: 'Miner' },
    { label: 'Oil/Gas Driller/Rig Operator', value: 'Oil/GasDril/RigOpr' },
    { label: 'Other', value: 'Other' },
    { label: 'Painter', value: 'Painter' },
    { label: 'Plaster/Drywall/Stucco', value: 'Plstr/Drywall/Stuc' },
    { label: 'Plumber', value: 'Plumber' },
    { label: 'Roofer', value: 'Roofer' },
  ],
  // Education/Library
  11: [
    { label: 'Administrative Assistant', value: 'Admin Assist' },
    { label: 'Audio-Visual Tech.', value: 'Audio-Visual Tech.' },
    { label: 'Child/Daycare Worker', value: 'Child/DayCare Wrker' },
    { label: 'Clerk', value: 'Clerk' },
    { label: 'Counselor', value: 'Counselor' },
    { label: 'Graduate Teaching Assistant', value: 'Grad. Teaching/Asst' },
    { label: 'Instructor-Vocation', value: 'Instructor-Vocation' },
    { label: 'Librarian/Curator', value: 'Librarian/Curator' },
    { label: 'Other', value: 'Other' },
    { label: 'Professor, College', value: 'Professor, College' },
    { label: 'Receptionist/Secretary', value: 'Receptionist/Sec' },
    { label: 'Superintendent', value: 'Superintendent' },
    { label: 'Teacher, College', value: 'Teacher, College' },
    { label: 'Teacher, K-12', value: 'Teacher, K-12' },
    { label: 'Teaching Assistant/Aide', value: 'Teaching Asst/Aide' },
    { label: 'Tutor', value: 'Tutor' },
  ],
  // Engineer/Architect/Science/Math
  12: [
    { label: 'Actuary', value: 'Actuary' },
    { label: 'Administrative Assistant', value: 'Admin Assist' },
    { label: 'Analyst', value: 'Analyst' },
    { label: 'Architect', value: 'Architect' },
    { label: 'Clerk', value: 'Clerk' },
    { label: 'Clinical Data Coordinator', value: 'Clinical Data Coord.' },
    { label: 'Drafter', value: 'Drafter' },
    { label: 'Engineer', value: 'Engineer' },
    { label: 'Manager-Project', value: 'Manager-Project' },
    { label: 'Manager-R&D', value: 'Manager-R&D' },
    { label: 'Mathematician', value: 'Mathematician' },
    { label: 'Other', value: 'Other' },
    { label: 'Receptionist/Secretary', value: 'Receptionist/Sec' },
    { label: 'Research Program Director', value: 'Research Prog. Dir.' },
    { label: 'Researcher', value: 'Researcher' },
    { label: 'Scientist', value: 'Scientist' },
    { label: 'Sociologist', value: 'Sociologist' },
    { label: 'Surveyor/Mapmaker', value: 'Surveyor/Mapmaker' },
    { label: 'Technician', value: 'Technician' },
  ],
  // Government/Military
  13: [
    { label: 'Accountant/Auditor', value: 'Accountant/Auditor' },
    { label: 'Administrative Assistant', value: 'Admin Assist' },
    { label: 'Analyst', value: 'Analyst' },
    { label: 'Attorney', value: 'Attorney' },
    { label: 'Chief Executive', value: 'Chief Executive' },
    { label: 'Clerk', value: 'Clerk' },
    { label: 'Commissioner', value: 'Commissioner' },
    { label: 'Council member', value: 'Council member' },
    { label: 'Director/Administrator', value: 'Director/Administr' },
    { label: 'Enlisted Military Personnel (E1-4)', value: 'Enlst Mil Prsnl E1-4' },
    { label: 'Legislator', value: 'Legislator' },
    { label: 'Mayor/City Manager', value: 'Mayor/City Manager' },
    { label: 'Meter Reader', value: 'Meter Reader' },
    { label: 'NCO (E5-9)', value: 'NCO (E5-9)' },
    { label: 'Officer-Commissioned', value: 'Officer-Commissiond' },
    { label: 'Officer-Warrant', value: 'Officer-Warrant' },
    { label: 'Other', value: 'Other' },
    { label: 'Park Ranger', value: 'Park Ranger' },
    { label: 'Planner', value: 'Planner' },
    { label: 'Postmaster', value: 'Postmaster' },
    { label: 'Receptionist/Secretary', value: 'Receptionist/Sec' },
    { label: 'Regulator', value: 'Regulator' },
    { label: 'US Postal Worker', value: 'US Postal Worker' },
  ],
  // Information Technology
  14: [
    { label: 'Administrative Assistant', value: 'Admin Assist' },
    { label: 'Analyst', value: 'Analyst' },
    { label: 'Clerk', value: 'Clerk' },
    { label: 'Director/Administrator', value: 'Director/Administr' },
    { label: 'Engineer-Hardware', value: 'Engineer-Hardware' },
    { label: 'Engineer-Software', value: 'Engineer-Software' },
    { label: 'Engineer-Systems', value: 'Engineer-Systems' },
    { label: 'Executive', value: 'Executive' },
    { label: 'Manager-Systems', value: 'Manager-Systems' },
    { label: 'Network Administrator', value: 'Network Admin' },
    { label: 'Other', value: 'Other' },
    { label: 'Programmer', value: 'Programmer' },
    { label: 'Project Coordinator', value: 'Project Coordinator' },
    { label: 'Receptionist/Secretary', value: 'Receptionist/Sec' },
    { label: 'Support Technician', value: 'Support Technician' },
    { label: 'Systems Security', value: 'Systems Security' },
    { label: 'Technical Writer', value: 'Technical Writer' },
    { label: 'Web Developer', value: 'Web Developer' },
  ],
  // Insurance
  15: [
    { label: 'Accountant/Auditor', value: 'Accountant/Auditor' },
    { label: 'Actuarial Clerk', value: 'Actuarial Clerk' },
    { label: 'Actuary', value: 'Actuary' },
    { label: 'Administrative Assistant', value: 'Admin Assist' },
    { label: 'Agent/Broker', value: 'Agent/Broker' },
    { label: 'Analyst', value: 'Analyst' },
    { label: 'Attorney', value: 'Attorney' },
    { label: 'Claims Adjuster', value: 'Claims Adjuster' },
    { label: 'Clerk', value: 'Clerk' },
    { label: 'Commissioner', value: 'Commissioner' },
    { label: 'Customer Service Representative', value: 'CSR' },
    { label: 'Director/Administrator', value: 'Director/Administr' },
    { label: 'Executive', value: 'Executive' },
    { label: 'Other', value: 'Other' },
    { label: 'Product Manager', value: 'Product Manager' },
    { label: 'Receptionist/Secretary', value: 'Receptionist/Sec' },
    { label: 'Sales Representative', value: 'Sales Rep' },
    { label: 'Underwriter', value: 'Underwriter' },
  ],
  // Legal/Law Enforcement/Security
  16: [
    { label: 'Airport Security Officer', value: 'Airport Sec Ofcr' },
    { label: 'Animal Control Officer', value: 'Animal Control Ofcr' },
    { label: 'Attorney', value: 'Attorney' },
    { label: 'Bailiff', value: 'Bailiff' },
    { label: 'Corrections Officer', value: 'Corrections Officer' },
    { label: 'Court Clerk/Reporter', value: 'Court Clrk/Reporter' },
    { label: 'Deputy Sheriff', value: 'Deputy Sheriff' },
    { label: 'Dispatcher', value: 'Dispatcher' },
    { label: 'Examiner', value: 'Examiner' },
    { label: 'Federal Agent/Marshall', value: 'Fed Agt/Marshall' },
    { label: 'Fire Chief', value: 'Fire Chief' },
    { label: 'Fire Fighter/Supervisor', value: 'Fire Fighter/Supv.' },
    { label: 'Gaming Officer/Investigator', value: 'Gaming Ofcr/Invest' },
    { label: 'Highway Patrol Officer', value: 'Highway Patrol Ofcr' },
    { label: 'Judge/Hearing Officer', value: 'Judge/Hearing Ofcr' },
    { label: 'Legal Assistant/Secretary', value: 'Legal Asst./Sec' },
    { label: 'Other', value: 'Other' },
    { label: 'Paralegal/Law Clerk', value: 'Paralegal/Law Clerk' },
    { label: 'Police Chief', value: 'Police Chief' },
    { label: 'Police Detective/Investigator', value: 'Police Det/Investgr' },
    { label: 'Police Officer/Supervisor', value: 'Police Offcr/Supvr' },
    { label: 'Process Server', value: 'Process Server' },
    { label: 'Private Investigator/Detective', value: 'Prvt Invest/Detec.' },
    { label: 'Security Guard', value: 'Security Guard' },
    { label: 'Sheriff', value: 'Sheriff' },
  ],
  // Maintenance/Repair/Housekeeping
  17: [
    { label: 'Building Maintenance Engineer', value: 'Bldg Maint Engineer' },
    { label: 'Custodian/Janitor', value: 'Custodian/Janitor' },
    { label: 'Electrician', value: 'Electrician' },
    { label: 'Field Service Technician', value: 'Field Service Tech.' },
    { label: 'Handyman', value: 'Handyman' },
    { label: 'Heat/Air Conditioner Repairman', value: 'Heat/AirCond Repair' },
    { label: 'Housekeeper/Maid', value: 'Housekeeper/Maid' },
    { label: 'Landscape/Grounds Maintenance', value: 'Lndscpe/Grnds Maint' },
    { label: 'Maintenance Mechanic', value: 'Maint Mechanic' },
    { label: 'Mechanic', value: 'Mechanic' },
    { label: 'Other', value: 'Other' },
  ],
  // Manufacturing/Production
  18: [
    { label: 'Administrative Assistant', value: 'Admin Assist' },
    { label: 'Clerk', value: 'Clerk' },
    { label: 'Factory Worker', value: 'Factory Worker' },
    { label: 'Foreman/Supervisor', value: 'Foreman/Supervisor' },
    { label: 'Furniture Finisher', value: 'Furniture Finisher' },
    { label: 'Inspector', value: 'Inspector' },
    { label: 'Jeweler', value: 'Jeweler' },
    { label: 'Machine Operator', value: 'Machine Operator' },
    { label: 'Other', value: 'Other' },
    { label: 'Packer', value: 'Packer' },
    { label: 'Plant Manager', value: 'Plant Manager' },
    { label: 'Printer/Bookbinder', value: 'Printer/Bookbinder' },
    { label: 'Quality Control', value: 'Quality Control' },
    { label: 'Receptionist/Secretary', value: 'Receptionist/Sec' },
    { label: 'Refining Operator', value: 'Refining Operator' },
    { label: 'Shoemaker', value: 'Shoemaker' },
    { label: 'Tailor/Custom Sewer', value: 'Tailor/Custom Sewer' },
    { label: 'Textile Worker', value: 'Textile Worker' },
    { label: 'Upholsterer', value: 'Upholsterer' },
  ],
  // Medical/Social Services/Religion
  19: [
    { label: 'Administrative Assistant', value: 'Admin Assist' },
    { label: 'Assistant - Medic/Dent/Vet', value: 'Assist-Med/Dent/Vet' },
    { label: 'Clergy', value: 'Clergy' },
    { label: 'Clerk', value: 'Clerk' },
    { label: 'Client Care Worker', value: 'Client Care Worker' },
    { label: 'Dental Hygienist', value: 'Dental Hygenist' },
    { label: 'Dentist', value: 'Dentist' },
    { label: 'Doctor', value: 'Doctor' },
    { label: 'Hospice Volunteer', value: 'Hospice Volunteer' },
    { label: 'Mortician', value: 'Mortician' },
    { label: 'Nurse - C.N.A.', value: 'Nurse - C.N.A.' },
    { label: 'Nurse - LPN', value: 'Nurse - LPN' },
    { label: 'Nurse - RN', value: 'Nurse - RN' },
    { label: 'Nurse Practitioner', value: 'Nurse Practitioner' },
    { label: 'Optometrist', value: 'Optometrist' },
    { label: 'Other', value: 'Other' },
    { label: 'Paramedic/E.M. Technician', value: 'Paramedic/EM Tech' },
    { label: 'Pharmacist', value: 'Pharmacist' },
    { label: 'Receptionist/Secretary', value: 'Receptionist/Sec' },
    { label: 'Social Worker', value: 'Social Worker' },
    { label: 'Support Services', value: 'Support Services' },
    { label: 'Technician', value: 'Technician' },
    { label: 'Therapist', value: 'Therapist' },
    { label: 'Veterinarian', value: 'Veterinarian' },
  ],
  // Personal Care/Service
  20: [
    { label: 'Caregiver', value: 'Caregiver' },
    { label: 'Dry Cleaner/Laundry', value: 'Dry Cleaner/Laundry' },
    { label: 'Hair Stylist/Barber', value: 'Hair Stylist/Barber' },
    { label: 'Housekeeper', value: 'Housekeeper' },
    { label: 'Manicurist', value: 'Manicurist' },
    { label: 'Masseuse', value: 'Masseuse' },
    { label: 'Nanny', value: 'Nanny' },
    { label: 'Other', value: 'Other' },
    { label: 'Pet Services', value: 'Pet Services' },
    { label: 'Receptionist/Secretary', value: 'Receptionist/Sec' },
  ],
  // Restaurant/Hotel Services
  21: [
    { label: 'Baker', value: 'Baker' },
    { label: 'Bartender', value: 'Bartender' },
    { label: 'Bellhop', value: 'Bellhop' },
    { label: 'Bus Person', value: 'Bus Person' },
    { label: 'Caterer', value: 'Caterer' },
    { label: 'Chef', value: 'Chef' },
    { label: 'Concessionaire', value: 'Concessionaire' },
    { label: 'Concierge', value: 'Concierge' },
    { label: 'Cook - Restaurant/Cafeteria', value: 'Cook-Rest/Cafeteria' },
    { label: 'Cook/Worker-Fast Food', value: 'Cook/Wrkr-Fast Food' },
    { label: 'Delivery Person', value: 'Delivery Person' },
    { label: 'Desk Clerk', value: 'Desk Clerk' },
    { label: 'Dishwasher', value: 'Dishwasher' },
    { label: 'Food Production/Packing', value: 'Food Prod/Packing' },
    { label: 'Host/Maitre d', value: 'Host/Maitre d' },
    { label: 'Housekeeper/Maid', value: 'Housekeeper/Maid' },
    { label: 'Manager', value: 'Manager' },
    { label: 'Other', value: 'Other' },
    { label: 'Valet', value: 'Valet' },
    { label: 'Waiter/Waitress', value: 'Waiter/Waitress' },
    { label: 'Wine Steward', value: 'Wine Steward' },
  ],
  // Sports/Recreation
  22: [
    {
      label: 'Activity/Recreational Assistant',
      value: 'Activity/Recre.Asst',
    },
    { label: 'Administrative Assistant', value: 'Admin Assist' },
    { label: 'Agent', value: 'Agent' },
    { label: 'Athlete', value: 'Athlete' },
    { label: 'Camp Counselor/Lead', value: 'CampCounselor/Lead' },
    { label: 'Clerk', value: 'Clerk' },
    { label: 'Coach', value: 'Coach' },
    { label: 'Concessionaire', value: 'Concessionaire' },
    { label: 'Director, Program', value: 'Director, Program' },
    { label: 'Event Manager/Promoter', value: 'Event Mgr/Promoter' },
    { label: 'Life Guard', value: 'Life Guard' },
    { label: 'Manager - Fitness Club', value: 'Mgr - Fitness Club' },
    { label: 'Other', value: 'Other' },
    { label: 'Park Ranger', value: 'Park Ranger' },
    { label: 'Receptionist/Secretary', value: 'Receptionist/Sec' },
    { label: 'Sales-Ticket/Membership', value: 'Sales-Tkt/Mmbrshp' },
    { label: 'Sports Broadcaster/Journalist', value: 'SportsBrdcstr/Journ' },
    { label: 'Trainer/Instructor', value: 'Trainer/Instructor' },
    { label: 'Umpire/Referee', value: 'Umpire/Referee' },
  ],
  // Travel/Transportation/Warehousing
  23: [
    { label: 'Administrative Assistant', value: 'Admin Assist' },
    { label: 'Air Traffic Control', value: 'Air Traffic Control' },
    { label: 'Airport Operations Crew', value: 'Airport Ops Crew' },
    { label: 'Bellhop/Porter', value: 'Bellhop/Porter' },
    { label: 'Clerk', value: 'Clerk' },
    { label: 'Crane Loader/Operator', value: 'Crane Loader/Oper' },
    { label: 'Dispatcher', value: 'Dispatcher' },
    { label: 'Driver - Bus/Streetcar', value: 'Driver-Bus/Strcar' },
    { label: 'Driver-Taxi/Limo', value: 'Driver-Taxi/Limo' },
    { label: 'Driver-Truck/Delivery', value: 'Driver-Truck/Delvry' },
    { label: 'Flight Attendant', value: 'Flight Attendant' },
    { label: 'Forklift Operator', value: 'Forklift Operator' },
    { label: 'Laborer', value: 'Laborer' },
    { label: 'Longshoreman', value: 'Longshoreman' },
    { label: 'Mate/Sailor', value: 'Mate/Sailor' },
    { label: 'Manager - Warehouse/District', value: 'Mgr Warehse/Dist' },
    { label: 'Other', value: 'Other' },
    { label: 'Parking Lot Attendant', value: 'Parking Lot Attend' },
    { label: 'Pilot/Captain/Engineer', value: 'Pilot/Capt/Eng' },
    { label: 'Railroad Worker', value: 'Railroad Worker' },
    { label: 'Receptionist/Secretary', value: 'Receptionist/Sec' },
    { label: 'Shipping/Receiving Clerk', value: 'Shipping/RecClk' },
    { label: 'Subway/Light Rail Operato', value: 'Subway/LgtRail Oper' },
    { label: 'Ticket Agent', value: 'Ticket Agent' },
    { label: 'Transportation Specialist', value: 'Transportation Spec' },
  ],
  // Other
  24: [{ label: 'Other', value: 'Other' }],
};
