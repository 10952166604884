/* eslint-disable max-lines-per-function */
import React, { useEffect, useState } from 'react';
import DialogActions from '@material-ui/core/DialogActions';
import { Button, Dialog, DialogContent, Typography } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { isEmpty, map, startCase, range } from 'lodash';
import PropTypes from 'prop-types';
import config from '../../../config/config';
import {
  ALREADY_BOUND,
  HEAP_LABELS,
  INSURANCE_BUSINESS_TYPES,
  INSURANCE_PRODUCTS_MAPPING,
  LOB_PRODUCTS_MAPPING,
  NO,
  PRODUCT_CHOOSE_ERROR,
  PRODUCT_OR_ORG_CHOOSE_ERROR,
  QUOTING_STEPS_PAGES_NAME,
  YES,
} from '../../../constants';
import { adhocLobMapping, arrayToStringObject, getIsHeapAnalyticsEnabled, orderLobs } from '../../../utils';
import ThemesImages from '../themes-images';
import policybound from '../../../assets/images/policy-bound-ribbon.png';
import './organization-modal.scss';

const OrganizationModal = ({
  organization,
  selectOrganization,
  products,
  selectProduct,
  showModal,
  closeModal,
  submitQuote,
  addProduct,
  appointments,
  home,
  veriskIsLoading,
  insuranceProductList,
  orgConfigLoader,
  common,
  isAddProductSaveApplicantLoading,
}: AppComponents.OrganizationModalProps) => {
  const { 
    adhocBoundedLobs,
    emptyLobErrorMessage,
    policyBounded,
    quotingListErrorMessage,
    quotingOrganizationList,
    quotingOrganizationlistLoader,
    updateSelectedProductLoader,
  } = common;
  const [productOrgSelected, setProductOrgSelected] = useState(false);
  const [newProducts, setNewProducts] = useState<Array<string>>([]);
  let themeType = !!home?.pconfig?.dale_config?.branding_on_dale_details?.template_name
    ? home?.pconfig?.dale_config?.branding_on_dale_details?.template_name
    : config.hippo.template_name;
  let isHeapAnalyticsEnabled: boolean =
    !!window.heap && addProduct ? !!getIsHeapAnalyticsEnabled() : false;

  useEffect(() => {
    if (isHeapAnalyticsEnabled && addProduct) {
      let appontmentList: any = !isEmpty(appointments) ? Object.keys(appointments) : [];
      window.heap.track(QUOTING_STEPS_PAGES_NAME?.RATES_ADD_PRODUCT, {
        [HEAP_LABELS?.PRODUCT_COUNT]: appontmentList?.length || 0,
        [HEAP_LABELS?.HOME_QUOTES]: !!appontmentList.includes(INSURANCE_BUSINESS_TYPES.HOME)
          ? YES
          : NO,
        [HEAP_LABELS?.AUTO_QUOTES]: !!appontmentList.includes(INSURANCE_BUSINESS_TYPES.AUTO)
          ? YES
          : NO,
        [HEAP_LABELS?.FLOOD_QUOTES]: !!appontmentList.includes(INSURANCE_BUSINESS_TYPES.FLOOD)
          ? YES
          : NO,
        [HEAP_LABELS?.EARTHQUAKE_QUOTES]: !!appontmentList.includes(INSURANCE_BUSINESS_TYPES.QUAKE)
          ? YES
          : NO,
        [HEAP_LABELS?.PET_QUOTES]: !!appontmentList.includes(INSURANCE_BUSINESS_TYPES.PET)
          ? YES
          : NO,
        [HEAP_LABELS?.HOME_WARRANTY_QUOTES]: !!appontmentList.includes(INSURANCE_BUSINESS_TYPES.HOMEWARRANTY)
        ? YES
        : NO,
        [HEAP_LABELS?.CYBER_SECURITY_QUOTES]: !!appontmentList.includes(INSURANCE_BUSINESS_TYPES.CYBERSECURITY)
        ? YES
        : NO,
        [HEAP_LABELS?.LIFE_QUOTES]: !!appontmentList.includes(HEAP_LABELS?.LIFE) ? YES : NO,
        [HEAP_LABELS?.UMBRELLA_QUOTES]: !!appontmentList.includes(HEAP_LABELS?.UMBRELLA) ? YES : NO,
      });
    }
    // eslint-disable-next-line
  }, [addProduct]);

  const getPolicyBoundedProducts = () => {
    const adhocLobs = Object.values(adhocLobMapping);
    let tempAdhocBoundedLobs: any = adhocLobs.filter(lob => {
      return adhocBoundedLobs.includes(lob.code.toLowerCase());
    });
    tempAdhocBoundedLobs = tempAdhocBoundedLobs.map((lob: any) => {
      return lob.key.toLowerCase();
    });
    const tempPolicyBounded = Object.keys(policyBounded).map((lob: any) => {
      return INSURANCE_PRODUCTS_MAPPING[lob.toUpperCase()].toLowerCase();
    });
    return [...tempAdhocBoundedLobs, ...tempPolicyBounded];
  };

  const isProductsSelect = () => {
    if (addProduct) {
      if (!newProducts.length) {
        setProductOrgSelected(true);
      } else {
        if (isHeapAnalyticsEnabled) {
          let newProductList = !isEmpty(arrayToStringObject(newProducts))
            ? startCase(arrayToStringObject(newProducts))
            : '';
          window.heap.track(QUOTING_STEPS_PAGES_NAME?.RATES_ADD_PRODUCT_SUBMIT, {
            [HEAP_LABELS?.ADDED_PRODUCTS]: newProductList,
          });
        }
        submitQuote(newProducts);
      }
    } else {
      if (!products.length || !organization) {
        setProductOrgSelected(true);
      } else {
        submitQuote();
      }
    }
  };

  const ActiveMarker = () => (
    <div className='active-marker'>
      <CheckCircleIcon />
    </div>
  );

  const setClassName = (productsArray: Array<string>, product: string) => {
    let className = ['product-item'];

    if (addProduct) {
      if (productsArray.includes(product)) {
        className.push('disabled');
      }
    } else {
      if (productsArray.includes(product)) {
        className.push('active');
      }
    }
    return className.join(' ');
  };

  const showError = () => {
    let error: string = '';
    if (productOrgSelected && !emptyLobErrorMessage) {
      if (addProduct) {
        error = PRODUCT_CHOOSE_ERROR;
      } else {
        error = PRODUCT_OR_ORG_CHOOSE_ERROR;
      }
    }
    if(!!emptyLobErrorMessage) {
      error = emptyLobErrorMessage;
    }
    return error;
  };

  const handleProductChange = (product: string) => {
    if (addProduct && appointments) {
      if (Object.keys(appointments).includes(product.toUpperCase())) {
        return;
      }
      const currentProducts = [...newProducts];
      const productIndex = currentProducts.indexOf(product);
      let addedProducts;
      if (productIndex > -1) {
        addedProducts = currentProducts.filter(p => p !== currentProducts[productIndex]);
      } else {
        addedProducts = [...currentProducts, product];
      }
      setNewProducts(addedProducts);
    }
    selectProduct(product);
  };

  const handleOrganizationChange = (org: string) => {
    setProductOrgSelected(false);
    selectOrganization(org);
  };

  const isPolicyBounded = (product: string) =>{
    return getPolicyBoundedProducts().includes(product?.toLowerCase())
  }

  const isLoading: boolean =
    veriskIsLoading ||
    orgConfigLoader ||
    !!quotingOrganizationlistLoader ||
    !!isAddProductSaveApplicantLoading ||
    false;

  return (
    <div>
      <Dialog
        className='organizationModalWrap'
        classes={{
          root: 'organization-dialog-container',
        }}
        maxWidth='md'
        open={showModal}
        onClose={closeModal}
        aria-labelledby='org-dialog-title'
      >
        <DialogContent className='dialogContentWrap'>
          {!addProduct && (
            <>
              <div id='org-dialog-title' className='padding-16-24'>
                {!quotingOrganizationlistLoader && isEmpty(quotingListErrorMessage) ? (
                  <Typography className='text-center heading'>
                    Select an organization to quote
                  </Typography>
                ) : (
                  ''
                )}
              </div>
              <div className='row d-flex justify-content-center m-0'>
                {!isEmpty(quotingOrganizationList) ? (
                  map(quotingOrganizationList, subOrganization => (
                    <div className='col-md-3 col-sm-3 mb-3' key={subOrganization.name}>
                      <div
                        className={
                          organization === subOrganization.code
                            ? 'organization-item active'
                            : 'organization-item'
                        }
                        onClick={() => handleOrganizationChange(subOrganization.code)}
                      >
                        {organization === subOrganization.code && <ActiveMarker />}
                        <img
                          src={subOrganization.logoUrl}
                          className='img-fluid'
                          alt={startCase(subOrganization.name)}
                          title={startCase(subOrganization.name)}
                        />
                      </div>
                    </div>
                  ))
                ) : (
                  <div className='row red-color justify-content-center'>
                    {!isEmpty(quotingListErrorMessage) ? quotingListErrorMessage : ''}
                  </div>
                )}
              </div>
            </>
          )}
          {insuranceProductList && insuranceProductList.length ? (
            <>
              <div className='row padding-16-24 mt-3'>
                <div id='org-dialog-title' className='col-lg-12'>
                  <Typography className='text-center heading'>
                    Select one or more products to quote
                  </Typography>
                </div>
              </div>
              <div
                className={`row justify-content-center ${
                  insuranceProductList.length > 3 ? 'org-mdl' : ''
                }`}
              >
                {map(orderLobs(insuranceProductList), product => (
                  <div
                    className={`position-relative ${
                      insuranceProductList.length > 3
                        ? insuranceProductList.length === 4
                          ? 'prod-div-4'
                          : 'prod-div'
                        : 'col-lg-3 col-sm-3 mb-3'
                    }`}
                    key={product}
                  >
                    <Button
                      variant='outlined'
                      className={`position-relative policy-bounded-wrap ${setClassName(products, product)}`}
                      disableRipple
                      onClick={() => handleProductChange(product)}
                      disabled={
                        updateSelectedProductLoader ||
                        orgConfigLoader ||
                        !!quotingOrganizationlistLoader ||
                        (addProduct && products.includes(product.toUpperCase())) ||
                        isPolicyBounded(product)
                      }
                    >
                      {products.indexOf(product) > -1 && <ActiveMarker />}
                      <div>
                        <img
                          alt='icon'
                          className='img-fluid icon mx-auto'
                          src={ThemesImages[themeType][`${product}Icon`]}
                        />
                        <span className='product-label'>{LOB_PRODUCTS_MAPPING[product?.toLowerCase()]}</span>
                      </div>
                      {isPolicyBounded(product) && (
                      <img
                        alt={ALREADY_BOUND}
                        className='img-fluid policybound-banner'
                        src={policybound}
                      />
                    )}
                    </Button>
                  </div>
                ))}
              </div>
            </>
          ) : (
            ''
          )}
          <div className='row red-color justify-content-center'>{isEmpty(quotingListErrorMessage) ? showError() : ''}</div>
        </DialogContent>
        <DialogActions className='d-flex justify-content-center'>
          <Button
            onClick={() => {
              isProductsSelect();
            }}
            className='btn-continue'
            variant='contained'
          >
            {isLoading ? (
              <div className='loader-wrapper'>
                <div className='sk-circle'>
                  {range(12, 0).map(n => (
                    <div key={n} className={`sk-circle${n} sk-child`} />
                  ))}
                </div>
              </div>
            ) : (
              'CONTINUE'
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

OrganizationModal.propTypes = {
  organization: PropTypes.string,
  selectOrganization: PropTypes.func.isRequired,
  products: PropTypes.arrayOf(PropTypes.string),
  selectProduct: PropTypes.func.isRequired,
  showModal: PropTypes.bool,
  closeModal: PropTypes.func.isRequired,
  submitQuote: PropTypes.func.isRequired,
  shouldHideOrganization: PropTypes.bool,
  home: PropTypes.object,
  insuranceProductList: PropTypes.arrayOf(PropTypes.string),
};

export default OrganizationModal;
