/* tslint:disable */
import development from './development';
import production from './production';
import staging from './staging';
import develop from './develop';
import prodcopy from './prodcopy';
import devx from './devx';

// updates env constant based on environment.
let env = 'production'; // TODO: Set the env from environment and remove url dependency when PAK-184 is resolved. Ref- https://myhippo.atlassian.net/browse/PAK-184
const url = window.location.href;
const defaultDevx = 'dev1';
const devxMatch = url.match(/dev(\d+)/);
if (devxMatch && devxMatch.length) {
  env = 'devx';
} else if (url.indexOf('develop') > -1) {
  env = 'develop';
} else if (url.indexOf('staging') > -1) {
  env = 'staging';
} else if (url.indexOf('prodcopy') > -1) {
  env = 'prodcopy';
}
const config: any = {
  devx: devx(devxMatch?.[0] || defaultDevx),
  develop,
  development,
  production,
  staging,
  prodcopy,
};

if (!config[env]) {
  console.log(`Unknown env: ${env}`);
}

export default config[env];
export type AppConfig = typeof production;
