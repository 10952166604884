/* istanbul ignore file */
import * as types from '../constants';
import { getQuoteListWithBindDetails } from '../../../utils';
import { UpcomingCarriersListType } from './cyber-security';

interface QuakeDetailsState {
  quakeSubmitLoader: boolean;
  quoteList: Array<any>;
  herdId: string;
  bridgeToCarrierLoader: boolean;
  upcomingCarrierList: Array<UpcomingCarriersListType>;
}

const INIT_QUAKE_STATE: QuakeDetailsState = {
  quakeSubmitLoader: false,
  quoteList: [],
  herdId: '',
  bridgeToCarrierLoader: false,
  upcomingCarrierList: [],
};

const INIT_STATE = { ...INIT_QUAKE_STATE };

export default (state = INIT_STATE, action: AppRedux.Action) => {
  switch (action.type) {
    case types.APP_INIT: {
      return {
        ...state,
        quakeSubmitLoader: false,
      };
    }

    case types.SET_QUAKE_QUOTES: {
      const { quoteList } = action.payload;
      return {
        ...state,
        quoteList: quoteList,
      };
    }

    case types.QUAKE_SUBMIT_LOADER: {
      return {
        ...state,
        quakeSubmitLoader: action.payload,
      };
    }

    case types.CLEAR_QUAKE_STATE: {
      return {
        ...state,
        ...INIT_STATE,
      };
    }

    case types.SET_QUAKE_QUOTE_BIND: {
      return {
        ...state,
        quoteList: getQuoteListWithBindDetails(state.quoteList, action.payload),
      };
    }

    case types.SET_QUAKE_HERD_ID: {
      return {
        ...state,
        herdId: action.payload,
      };
    }

    case types.BRIDGE_TO_CARRIER_LOADER: {
      const { carrier_id, loader } = action.payload;
      let quakeQuoteList = [...state.quoteList];
      const updateIndex = quakeQuoteList.findIndex(
        (item: any) => item?.carrier_id?.toString() === carrier_id?.toString()
      );
      if (updateIndex !== -1) {
        quakeQuoteList[updateIndex] = {
          ...quakeQuoteList[updateIndex],
          bridgeToCarrierLoader: loader,
        };
      }
      return {
        ...state,
        bridgeToCarrierLoader: loader,
        quoteList: quakeQuoteList,
      };
    }

    case types.STORE_QUAKE_DETAILS: {
      return {
        ...state,
        ...action.payload,
      };
    }

    default:
      return state;
  }
};
