import { MutationLifecycleApi } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { createApi } from '@reduxjs/toolkit/query/react';
import { isEmpty } from 'lodash';
import { SUCCESSFULLY } from '../../../../constants';
import {
  fetchHomeQuoteDetails,
  fetchUmbrellaQuestionListInfo,
  getVeriskAutoDetails,
  petBreedsDetailsLoader,
  saveApplicantError,
  setAddProductSaveApplicantLoader,
  setApplicantDetails,
  setApplicantSuccess,
  setHomeState,
  setNoQuotesAvailableForHome,
  setPetBreedsDetails,
  setPetQuotes,
  setPollingErrorDetails,
  setPropertyInfoLoader,
  setRequestId,
  setUmbrellaHerdId,
  storeLifeQuoteDetails,
  updateQuoteDetails,
} from '../../../../pages/redux/actions';
import { PrefillDetails } from '../../../../pages/redux/reducers/applicant';
import { mappedResponseToStoreForPropertyDetails } from '../../../../utils';
import { LineOfBusiness } from '../../../enums';
import { AxiosBaseQuery, axiosBaseQuery } from '../base-query';
import {
  Address,
  SaveApplicantRequest,
  SaveApplicantResponse,
  ValidateAddressResponse,
} from './types';

const onSaveApplicantQueryStarted = async (
  {
    request: { lob, clearAutoInfo, clearUmbrellaInfo },
    state: { quotingDetails, isVeriskPrefillAvailable, driversList, umbrellaInfo },
  }: SaveApplicantRequest,
  {
    dispatch,
    queryFulfilled,
  }: MutationLifecycleApi<
    SaveApplicantRequest,
    AxiosBaseQuery,
    SaveApplicantResponse,
    'applicantApi'
  >
) => {
  try {
    dispatch(saveApplicantError(false));
    dispatch(setNoQuotesAvailableForHome(false));
    if (lob === LineOfBusiness.PET) {
      dispatch(setPetBreedsDetails({ cat: {}, dog: {}, isBreedError: false }));
    }

    if (lob === LineOfBusiness.HOME) {
      dispatch(
        setApplicantDetails({
          prefillDetails: null,
          userPropertyInformation: null,
        })
      );
    }

    const { data } = await queryFulfilled;

    const { prefillData, isPropertyInfoRequired, prefillStatus } = data;
    const herdId = data.herdId || data.herd_id;
    if (!data.message?.includes(SUCCESSFULLY)) {
      dispatch(setAddProductSaveApplicantLoader(false));
      return;
    }

    if (lob === LineOfBusiness.HOME) {
      dispatch(setHomeState({ herd_id: herdId }));
      dispatch(setPropertyInfoLoader(false));

      const prefillDetails = !isEmpty(prefillData)
        ? (mappedResponseToStoreForPropertyDetails(prefillData) as PrefillDetails)
        : { propertyData: {}, label: {} };

      dispatch(
        setApplicantDetails({
          prefillDetails: prefillDetails,
          isPropertyInfoRequired: !!isPropertyInfoRequired,
          prefillStatus: prefillStatus,
          noOfRetries: 0,
        })
      );

      if (!isPropertyInfoRequired) {
        dispatch(
          setHomeState({
            hippoQuoteLoaded: false,
            ezlynxQuotesLoaded: false,
            quoteList: [],
            quoteListError: [],
            upcomingCarrierList: [],
          })
        );
        dispatch(fetchHomeQuoteDetails());
      }
    } else if (lob === LineOfBusiness.AUTO) {
      dispatch(
        updateQuoteDetails({
          ...quotingDetails,
          herd_id: herdId,
        })
      );

      if (data.error_details?.display_errors_on_ui && !isEmpty(data.error_details.errors)) {
        dispatch(setPollingErrorDetails({ ...data }));
      }
      if (isVeriskPrefillAvailable && (!driversList.length || clearAutoInfo)) {
        dispatch(getVeriskAutoDetails());
      }
    } else if (lob === LineOfBusiness.PET) {
      dispatch(petBreedsDetailsLoader(true));
      dispatch(setApplicantSuccess(true));
      dispatch(
        setPetQuotes({
          quoteList: [],
          herdId,
          upcomingCarrierList: [],
        })
      );
    } else if (lob === LineOfBusiness.LIFE) {
      dispatch(storeLifeQuoteDetails({ herdId }));
    } else if (lob === LineOfBusiness.UMBRELLA) {
      dispatch(setUmbrellaHerdId(herdId ?? ''));
      if (isEmpty(umbrellaInfo?.questionGroups) || clearUmbrellaInfo) {
        dispatch(fetchUmbrellaQuestionListInfo());
      }
    }
    if (data.provider_request_id) {
      dispatch(setRequestId(data.provider_request_id));
    }
  } catch (error) {
    dispatch(saveApplicantError(true));
  }
  dispatch(setAddProductSaveApplicantLoader(false));
};

export const applicantApi = createApi({
  reducerPath: 'applicantApi',
  baseQuery: axiosBaseQuery(),

  endpoints: builder => ({
    validateAddress: builder.mutation<ValidateAddressResponse, Address>({
      query: body => ({ url: `/address/validate`, method: 'POST', body }),
      transformResponse: (data: ValidateAddressResponse) => {
        return { ...data, state: data.state.toUpperCase() };
      },
    }),

    saveApplicant: builder.mutation<SaveApplicantResponse, SaveApplicantRequest>({
      query: body => ({
        url: '/applicant/save-applicant-info',
        method: 'POST',
        body: body.request,
      }),
      onQueryStarted: onSaveApplicantQueryStarted,
    }),
  }),
});

export const { useValidateAddressMutation, useSaveApplicantMutation } = applicantApi;
