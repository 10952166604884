/* istanbul ignore file */
import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import AMSReport from './ams-report';
import Applicant from './applicant';
import Auth from './auth';
import BulkQuote from './bulk-quote';
import Common from './helper';
import Coverage from './coverage';
import Dashboard from './dashboard';
import Driver from './driver';
import Home from './home';
import Insurance from './insurance';
import OtherDetails from './incidents';
import Quote from './quote';
import Vehicle from './vehicle';
import FloodDetails from './flood-details';
import Partner from './partner';
import Organizations from './organizations';
import QuakeDetails from './quake-details';
import Feedback from './feedback';
import PetDetails from './pet-details';
import ViewQuoteDetails from './view-quote';
import homeWarrantyDetails from './home-warranty';
import lifeDetails from './life';
import cyberSecurityDetails from './cyber-security';
import umbrellaDetails from './umbrella-details';
import { History } from 'history';
import { applicantApi } from '../../../quote-application/redux/apis/applicant';

export default (history: History<unknown>) =>
  combineReducers({
    router: connectRouter(history),
    applicant: Applicant,
    common: Common,
    vehicle: Vehicle,
    driver: Driver,
    otherDetails: OtherDetails,
    insurance: Insurance,
    coverage: Coverage,
    quote: Quote,
    dashboard: Dashboard,
    auth: Auth,
    home: Home,
    bulkQuote: BulkQuote,
    amsReport: AMSReport,
    floodDetails: FloodDetails,
    partner: Partner,
    organizations: Organizations,
    quakeDetails: QuakeDetails,
    feedback: Feedback,
    petDetails: PetDetails,
    viewQuoteDetails: ViewQuoteDetails,
    homeWarrantyDetails: homeWarrantyDetails,
    lifeDetails: lifeDetails,
    cyberSecurityDetails: cyberSecurityDetails,
    umbrellaDetails: umbrellaDetails,
    [applicantApi.reducerPath]: applicantApi.reducer,
  });
