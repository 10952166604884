import config from '../config/config';
import * as STATE_DATA from './states';
import { INDUSTRY_DATA } from './industry';
import { OCCUPATION_DATA } from './occupation';
import { EDUCATION_DATA } from './education';
import { PRIOR_LIABILITY_LIMIT } from './prior-liability-limit';
export * from './property-info';
export * from './request-bind-prefill-information';
export * from './common';
export const DEFAULT_LOB_PATH = '/lob';
export const DEFAULT_BULK_QUOTE_PATH = '/bulk-quote';
export const PARTNER_LAUNCH_PATH = '/launch-partner';
export const QUESTIONNAIRE_PATH = '/questionnaire';
export const DEFAULT_REPORT_PATH = '/report';
export const NO_SAVED_FILTERS_MESSAGE = 'No Saved Filters';
export const REPORT_ACTIONS = {
  GENERATE_REPORT: 'Generate',
  SAVE_AND_GENERATE_REPORT: 'SaveAndGenerate',
};
export const FILTER_REMOVE_MODAL_HEADING = 'Delete Filter';
export const FILTER_REMOVE_MODAL_MESSAGE =
  'Are you sure you want to delete __PLACEHOLDER__ filter?';
export const FILTER_NAME_TOOLTIP =
  'OPTIONAL - Enter a name to save and identify this set of filter options';
export const MISSING_SPOUSE_MESSAGE = "Primary driver's spouse information is missing.";
export const MISSING_VEHICLE_MESSAGE = 'Vehicle information is missing.';
export const LICENSE_MESSAGE = 'Please make sure at least one driver should have a valid license.';
export const UNSAVED_VEHICLE_MESSAGE =
  'You have unsaved changes. Are you sure you want to discard them?';
export const INSURANCE_PRODUCTS: Array<string> = [
  'home',
  'auto',
  'flood',
  'quake',
  'pet',
  'homewarranty',
  'life',
  'cybersecurity',
  'umbrella',
];
export const OTHER_PRODUCTS: Array<any> = [
  {
    label: 'Flood quote',
    value: 'flood',
  },
];
export const INSURANCE_BUSINESS_TYPES = {
  HOME: 'HOME',
  FLOOD: 'FLOOD',
  AUTO: 'AUTO',
  QUAKE: 'QUAKE',
  PET: 'PET',
  HOMEWARRANTY: 'HOMEWARRANTY',
  LIFE: 'LIFE',
  CYBERSECURITY: 'CYBERSECURITY',
  UMBRELLA: 'UMBRELLA',
};
export const BUSINESS_TYPE_ICON_MAPPING: any = {
  HOME: 'homeInsuranceIcon',
  FLOOD: 'floodIcon',
  AUTO: 'autoIcon',
  QUAKE: 'quakeIcon',
  PET: 'petIcon',
  HOMEWARRANTY: 'homewarrantyIcon',
  LIFE: 'lifeIcon',
  CYBERSECURITY: 'cybersecurityIcon',
  UMBRELLA: 'umbrellaIcon',
};
export const INSURANCE_PRODUCTS_MAPPING: any = {
  HOME: 'HOME',
  FLOOD: 'FLOOD',
  AUTO: 'AUTO',
  QUAKE: 'QUAKE',
  EQ: 'QUAKE',
  EARTHQUAKE: 'QUAKE',
  PET: 'PET',
  'HOME-WARRANTY': 'HOMEWARRANTY',
  'CYBER-SECURITY': 'CYBERSECURITY',
  LIFE: 'LIFE',
  UMBRELLA: 'UMBRELLA',
};
export const LOB_PRODUCTS_MAPPING: any = {
  home: 'home',
  flood: 'flood',
  auto: 'auto',
  quake: 'quake',
  pet: 'pet',
  homewarranty: 'home warranty',
  life: 'life',
  cybersecurity: 'cyber security',
  umbrella: 'umbrella',
};
export const DASHBOARD_LOB_MAPPING: any = {
  HOMEWARRANTY: 'HW',
  CYBERSECURITY: 'CS',
};
export const PRODUCT_MAPPING: any = {
  HOMEWARRANTY: 'HOME-WARRANTY',
  CYBERSECURITY: 'CYBER-SECURITY',
};
export const APPLICANT_PAGE_TITLE = 'Enter the applicant information';
export const FLOOD_PAGE_TITLE = 'Enter the additional home information';
export const FLOOD_CONFIRMATION_MODAL_TITLE =
  'While we fetch the home rates, would you like to request flood rates as well?';
export const FLOOD_REQUEST_SUCCESS_TITLE = 'Flood rates requested!';
export const SUBMIT_VALUES = ['slab'];
export const SLAB = 'slab';
export const FOUNDATION_TYPES = [
  { link: 'basement', label: 'Basement', value: 'basement' },
  { link: 'basement', label: 'Half basement', value: 'half basement' },
  { link: 'crawlspace', label: 'Crawlspace', value: 'crawlspace' },
  { link: 'pier', label: 'Pier', value: 'pier' },
  { link: 'pilings', label: 'Pilings', value: 'pilings' },
  { link: 'slab', label: 'Slab', value: 'slab' },
  { link: 'stilts', label: 'Stilts', value: 'stilts' },
];
export const ANNEX_TRACKING = {
  POST_POPUP: { page: 'postpopup' },
};
export const ACCIDENT_MINIMUM_AMOUNT = 1;
export const ACCIDENT_MAXIMUM_AMOUNT = 999999;
export const LOSSES_MINIMUM_AMOUNT = 1;
export const ERROR_TEXT_MAX_LENGTH = 300;
export const PRODUCT = 'product';
export const PHONE_NUMBER = 'phone_number';
export const EMAIL = 'email';
export const LOGOUT_SESSION_EXPIRED_MESSAGE =
  'Your session has been expired. Please login to continue.';
export const LOGOUT_SUCCESSFULLY_MESSAGE = 'You have successfully logged out.';
export const AUTO_APPLICANT_FORM_TITLE = 'Find your best auto insurance';
export const AUTO_APPLICANT_FORM_SUBTITLE =
  'Quote comparison, top coverage details at your fingertips, and savings in seconds';
export const FOOTER_CONTENT =
  'To give an accurate quote, we may use information such ' +
  'as credit and insurance history provided by you or third parties.';
export const QUOTES_LOADING_MESSAGE = 'We are working on getting the best rates for you';
export const AMS_GLOBAL_ERROR_MESSAGE = 'Something went wrong, please try again.';
export const POLICY_TERMS = [
  { label: 'Annually', shortLabel: 'yr', value: 12 },
  { label: 'Half-Yearly', shortLabel: 'hy', value: 6 },
  { label: 'Monthly', shortLabel: 'month', value: 1 },
  { label: 'Quarterly', shortLabel: 'qtr', value: 3 },
];
export const FILTER_OPTIONS = [
  { value: 'Home', label: 'Home', key: 'lob' },
  { value: 'Auto', label: 'Auto', key: 'lob' },
  { value: 'Flood', label: 'Flood', key: 'lob' },
  { value: 'Earthquake', label: 'Quake', key: 'lob' },
  { value: 'Pet', label: 'Pet', key: 'lob' },
  { value: 'Home-warranty', label: 'Home Warranty', key: 'lob' },
  { value: 'Life', label: 'Life', key: 'lob' },
  { value: 'Cyber-security', label: 'Cyber Security', key: 'lob' },
  { value: 'Umbrella', label: 'Umbrella', key: 'lob' },
  { value: 'ho-3, ho3', label: 'HO3', key: 'product' },
  { value: 'ho-4, ho4', label: 'HO4', key: 'product' },
  { value: 'ho-5, ho5', label: 'HO5', key: 'product' },
  { value: 'ho-6, ho6', label: 'HO6', key: 'product' },
  { value: 'dp-1, dp1', label: 'DP1', key: 'product' },
  { value: 'dp-3, dp3', label: 'DP3', key: 'product' },
];
export const STATE_FILTER_OPTIONS = [
  { value: 'ak', label: 'AK', key: 'states' },
  { value: 'al', label: 'AL', key: 'states' },
  { value: 'ar', label: 'AR', key: 'states' },
  { value: 'az', label: 'AZ', key: 'states' },
  { value: 'ca', label: 'CA', key: 'states' },
  { value: 'co', label: 'CO', key: 'states' },
  { value: 'ct', label: 'CT', key: 'states' },
  { value: 'dc', label: 'DC', key: 'states' },
  { value: 'de', label: 'DE', key: 'states' },
  { value: 'fl', label: 'FL', key: 'states' },
  { value: 'ga', label: 'GA', key: 'states' },
  { value: 'hi', label: 'HI', key: 'states' },
  { value: 'ia', label: 'IA', key: 'states' },
  { value: 'id', label: 'ID', key: 'states' },
  { value: 'il', label: 'IL', key: 'states' },
  { value: 'in', label: 'IN', key: 'states' },
  { value: 'ks', label: 'KS', key: 'states' },
  { value: 'ky', label: 'KY', key: 'states' },
  { value: 'la', label: 'LA', key: 'states' },
  { value: 'ma', label: 'MA', key: 'states' },
  { value: 'md', label: 'MD', key: 'states' },
  { value: 'me', label: 'ME', key: 'states' },
  { value: 'mi', label: 'MI', key: 'states' },
  { value: 'mn', label: 'MN', key: 'states' },
  { value: 'mo', label: 'MO', key: 'states' },
  { value: 'ms', label: 'MS', key: 'states' },
  { value: 'mt', label: 'MT', key: 'states' },
  { value: 'nc', label: 'NC', key: 'states' },
  { value: 'nd', label: 'ND', key: 'states' },
  { value: 'ne', label: 'NE', key: 'states' },
  { value: 'nh', label: 'NH', key: 'states' },
  { value: 'nj', label: 'NJ', key: 'states' },
  { value: 'nm', label: 'NM', key: 'states' },
  { value: 'nv', label: 'NV', key: 'states' },
  { value: 'ny', label: 'NY', key: 'states' },
  { value: 'oh', label: 'OH', key: 'states' },
  { value: 'ok', label: 'OK', key: 'states' },
  { value: 'or', label: 'OR', key: 'states' },
  { value: 'pa', label: 'PA', key: 'states' },
  { value: 'ri', label: 'RI', key: 'states' },
  { value: 'sc', label: 'SC', key: 'states' },
  { value: 'sd', label: 'SD', key: 'states' },
  { value: 'tn', label: 'TN', key: 'states' },
  { value: 'tx', label: 'TX', key: 'states' },
  { value: 'ut', label: 'UT', key: 'states' },
  { value: 'va', label: 'VA', key: 'states' },
  { value: 'vt', label: 'VT', key: 'states' },
  { value: 'wa', label: 'WA', key: 'states' },
  { value: 'wi', label: 'WI', key: 'states' },
  { value: 'wv', label: 'WV', key: 'states' },
  { value: 'wy', label: 'WY', key: 'states' },
];
export const ADHOC = 'adhoc';
export const CRM = 'crm';
export const QUOTES = 'quotes';
export const AMS_EFFECTIVE_DATE_DURATION = 6;
export const AMS_SUBMIT_SUCCESS_TIMEOUT = 2000;
export const AMS_EXCLUDE_PROPS = [
  'entity',
  'fullName',
  'middleName',
  'address',
  'addressDetails',
  'carrierOptions',
  'carrierUnderwriterOptions',
  'divisionOptions',
  'executiveOptions',
  'representativeOptions',
  'groupOptions',
  'departmentOptions',
  'employeeOptions',
  'brokerOptions',
  'quoteList',
  'email',
  'dob',
  'dataClear',
  'lobOption',
  'existingCustomer',
  'county',
  'customerData',
  'herdId',
];
export const AMS_ADHOC_EXCLUDE_PROPS = [
  'phoneNumber',
  'businessLine',
  'firstName',
  'middleName',
  'lastName',
];
export const PAGES_TEXT: any = {
  propertyError:
    `We're having trouble verifying your property address, but we can still ` +
    `provide you with great quote options over the phone or email.`,
  primaryDriverHeading: 'Enter the primary driver information.',
  addSpouseHeading: 'Add Spouse Information',
  anotherDriverInformationHeading: "Add Another Driver's Information",
  vehiclePageHeading: 'Enter the vehicle information',
  petPageHeading: 'Enter the first pet’s information',
  insurancePageHeading: ' Enter the policy information.',
  incidentsPageHeading: 'Accidents,Claims,Damages Or Violations?',
  dashboardHeading: 'Compare and Buy Home and Auto Insurance',
  autoProductHeading: 'Compare and Buy Auto Insurance',
  homeProductHeading: 'Smart Homeowners Insurance',
  homeResultHeading: 'Compare and Buy Home Insurance',
  deductibleTableHeading: 'THE QUOTES BELOW ARE ALL BASED ON',
  addressBarHeading: 'HOME INSURANCE QUOTES FOR',
  bulkQuoteStepOneHeading: 'Select Organization',
  bulkQuoteStepTwoHeading: 'Select Carrier',
  bulkQuoteStepThreeHeading: 'Upload XLSX File',
  organizationInputPlaceholder: 'Code',
  fileUploadPlaceholder: 'Drop a file here or click to upload',
  fileUploadSuccessMessage: 'File Uploaded Successfully!',
  producerCodeTooltipLabel: 'Producer Code',
  userNameTooltipLabel: 'Username',
  marketTooltipLabel: 'Market',
  AMSModalTitle: 'Create Policy Header in AMS',
  AMSPolicyBoundModalTitle: 'Policy Issued',
  AMSPolicyBoundModalContent: 'Is the policy issued on the Carrier Site?',
  notfoundError: `We can't seem to find the page you're looking for.`,
  notfoundErrorCode: 'Error Code: 404',
  notFoundOops: 'Oops!',
  quoteExpiredErrors: 'This URL is already expired. Please try another one.',
};
export const DEFAULT_MISSING_DATA = '-';
export const NA_VALUE = 'N/A';
export const FILE_SERVER_TYPE = 's3';
export const DEFAULT_MISSING_MARKET = 'All';
export const TABLE_COVERAGES: Array<string> = ['Other', 'Hurricane', 'Wind'];
export const MINIMUM_ANNUAL_MILES = 1000;
export const MAXIMUM_ANNUAL_MILES = 100000;
export const BULK_QUOTE_STEP_LABELS = ['Step 1', 'Step 2', 'Step 3'];
export const DEFAULT_SELECTED_CARRIER_IDS = [1];
export const EXCEL_FILE_COLUMNS = [
  'QUOTE_AND_POLICY_NUMBER',
  'EFFECTIVE_DATE',
  'EXPIRATION_DATE',
  'NAME_LINE_1',
  'ADDRESS_LINE',
  'ADDRESS_LINE_2',
  'HOME_TYPE',
  'CITY',
  'STATE_CODE',
  'ZIP_CODE',
  'TOTAL_PREMIUM',
  'DEDUCTIBLE_AMOUNT',
  'WIND_HAIL_DEDUCTIBLE',
  '800 - Dwelling',
  '801 - Other Structures',
  '802 - Personal Property Unscheduled',
  '803 - Loss of Use',
  '804 - Personal Liability',
  '805 - Medical Payments',
  'Date_of_Birth',
  'Primary_TELEPHONE_NUMBER',
  'Primary_Email',
  'Source',
];
export const SPACEBAR_KEY_CODE = 32;
export const BULK_QUOTE_ALLOWED_EXTENSIONS = ['.xlsx'];
export const BULK_QUOTE_ERRORS = {
  organizationInputError: 'Please select the organization or enter the organization code.',
  organizationCodeServerError: "Organization code doesn't exist.",
  carrierSelectorError: 'Please select one or more carriers.',
  fileSelectionError: 'Please upload a file.',
  fileUploadServerError: 'File upload failed, please try again.',
  emptySheetError: 'Uploaded file is empty.',
  missingColumnsError: 'Mandatory columns are missing.',
  invalidFileError: 'Invalid file selected, valid file is of __PLACEHOLDER__ type.',
};
export const BULK_QUOTE_BATCH_STATUS = {
  pending: 'pending',
  completed: 'completed',
  inprogress: 'inprogress',
  terminated: 'terminated',
};
export const TERMINATE_MODAL_HEADING = 'Terminate';
export const TERMINATE_MODAL_MESSAGE =
  'Are you sure you want to terminate batch number __PLACEHOLDER__?';
export const BATCH_TERMINATED_SUCCESS_RESPONSE = 'Batch terminated successfully!';
export const BATCH_TERMINATED_ERROR_RESPONSE = 'Something went wrong!';
export const EMPTY_CARRIER_LIST_MESSAGE =
  'There are no carriers available for __PLACEHOLDER__ organization.';
export const PROCESS_MODAL_HEADING = 'Bulk Quote Process';
export const PROCESS_MODAL_MESSAGE = `You are about to set this bulk quote to process immediately. 
Processing a large amount of quotes has the potential to slow live Gator requests
to the point of failure. Do you wish to proceed?`;
export const BULK_QUOTE_ALERT_TIMEOUT = 3000;
export const REPORT_ERROR_TIMEOUT = 5000;
export const VEHICLE_DATA = {
  own_lease: [
    { label: 'Owned', value: 'Owned' },
    { label: 'Leased', value: 'Leased' },
    { label: 'Lien', value: 'Lien' },
  ],
  how_long: [
    { label: '1 year', value: 1 },
    { label: '2 years', value: 2 },
    { label: '3 years', value: 3 },
    { label: '4 years', value: 4 },
    { label: '5 years', value: 5 },
    { label: '6 years', value: 6 },
    { label: '7 years', value: 7 },
    { label: '8 years', value: 8 },
    { label: '9 years', value: 9 },
    { label: '10 years', value: 10 },
    { label: '11 years', value: 11 },
    { label: '12 years', value: 12 },
    { label: '13 years', value: 13 },
    { label: '14 years', value: 14 },
    { label: '15 years', value: 15 },
    { label: '16 years', value: 16 },
    { label: '17 years', value: 17 },
    { label: '18 years', value: 18 },
    { label: '19 years', value: 19 },
    { label: '20 years', value: 20 },
  ],
  vehicleUsage: [
    { label: 'Business', value: 'Business' },
    { label: 'Farming', value: 'Farming' },
    { label: 'Pleasure', value: 'Pleasure' },
    { label: 'To/From Work', value: 'To/From Work' },
    { label: 'To/From School', value: 'To/From School' },
  ],
};
export const DEFAULT_VEHICLE_INFO_VALUES = {
  how_long: '1',
  approx_miles: '12000',
};
export const DEFAULT_PERSONAL_DETAILS_VALUES = {
  first_name: '',
  middle_name: '',
  last_name: '',
  date_of_birth: '',
  phone_number: '',
  email: '',
  industry: INDUSTRY_DATA.find(entry => entry.label === 'Other')?.value ?? '',
  occupation: OCCUPATION_DATA['24'][0].value ?? '',
  education: EDUCATION_DATA.find(entry => entry.label === 'High School Diploma')?.value ?? '',
  preferredDeductible: '',
  residenceType: 'PRIMARY',
};
export const DEFAULT_INSURANCE_DETAILS_VALUES = {
  prior_carrier: PRIOR_LIABILITY_LIMIT.find(entry => entry.label === 'Allstate')?.value ?? '',
  years_with_current_carrier: '3',
  residency_status: 'home',
};
export const DRIVER_DATA = {
  marital_status: [
    { label: 'Single', value: 'Single' },
    { label: 'Married', value: 'Married' },
    { label: 'Domestic Partner', value: 'Domestic Partner' },
    { label: 'Widowed', value: 'Widowed' },
    { label: 'Separated', value: 'Separated' },
    { label: 'Divorced', value: 'Divorced' },
  ],
  us_license_status: [
    { label: 'Valid', value: 'Valid' },
    { label: 'Permit', value: 'Permit' },
    { label: 'Expired', value: 'Expired' },
    { label: 'Suspended', value: 'Suspended' },
    { label: 'Cancelled', value: 'Cancelled' },
    { label: 'Not Licensed', value: 'Not Licensed' },
    { label: 'Permanently Revoked', value: 'Permanently Revoked' },
  ],
};
// Accidents, claims, damages or violations page dropdown details
export const OTHER_DETAILS_DATA = {
  accident_description: [
    { label: 'At Fault With Injury', value: 'At Fault With Injury' },
    { label: 'At Fault With No Injury', value: 'At Fault With No Injury' },
    { label: 'Not At Fault', value: 'Not At Fault' },
  ],
  vehicle_involved: [
    { label: '0', value: '0' },
    { label: '1', value: '1' },
    { label: '2', value: '2' },
    { label: '3', value: '3' },
    { label: '4', value: '4' },
    { label: '5', value: '5' },
    { label: '6', value: '6' },
    { label: '7', value: '7' },
    { label: '8', value: '8' },
    { label: '9', value: '9' },
    { label: '10', value: '10' },
  ],
  violations_description: [
    { label: 'Careless Driving', value: 'Careless Driving' },
    { label: 'Cell Phone', value: 'Cell Phone' },
    { label: 'Child Safety Restraint', value: 'Child Safety Restraint' },
    { label: 'Defective Equipment', value: 'Defective Equipment' },
    { label: 'Divided Highways', value: 'Divided Highways' },
    { label: 'Double Lines', value: 'Double Lines' },
    { label: 'Driving Left of Center', value: 'Driving Left of Center' },
    { label: 'Driving on Sus. License', value: 'Driving on Sus. License' },
    { label: 'Driving too slow', value: 'Driving too slow' },
    { label: 'Driving without lights', value: 'Driving without lights' },
    { label: 'DUI', value: 'DUI' },
    { label: 'Eluding Police', value: 'Eluding Police' },
    { label: 'Failure to Obey Signal', value: 'Failure to Obey Signal' },
    { label: 'Failure to Stop', value: 'Failure to Stop' },
    { label: 'Failure to Yield', value: 'Failure to Yield' },
    { label: 'Failure To Observe A Safety Zone', value: 'Failure To Observe A Safety Zone' },
    { label: 'Failure to show documents', value: 'Failure to show documents' },
    { label: 'False Reporting', value: 'False Reporting' },
    { label: 'Felony', value: 'Felony' },
    { label: 'Following too Closely', value: 'Following too Closely' },
    { label: 'Homicide', value: 'Homicide' },
    { label: 'Illegal Turn', value: 'Illegal Turn' },
    { label: 'Improper Parking', value: 'Improper Parking' },
    { label: 'Improper Passing', value: 'Improper Passing' },
    { label: 'Improper Loads', value: 'Improper Loads' },
    {
      label: 'Leaving scene of an Accident/Hit and Run',
      value: 'Leaving scene of an Accident/Hit and Run',
    },
    { label: 'Motorcycle Violation', value: 'Motorcycle Violation' },
    { label: 'Other Major', value: 'Other Major' },
    { label: 'Other Minor', value: 'Other Minor' },
    { label: 'Open Container', value: 'Open Container' },
    {
      label: 'Operating Vehicle without Permission',
      value: 'Operating Vehicle without Permission',
    },
    { label: 'Out of State', value: 'Out of State' },
    { label: 'Passing School Bus', value: 'Passing School Bus' },
    { label: 'Racing/Drag Racing', value: 'Racing/Drag Racing' },
    { label: 'Recreational Vehicle', value: 'Recreational Vehicle' },
    { label: 'Refusal to submit to chemical test', value: 'Refusal to submit to chemical test' },
    { label: 'Speeding 1-5', value: 'Speeding 1-5' },
    { label: 'Speeding 6-10', value: 'Speeding 6-10' },
    { label: 'Speeding 11-15', value: 'Speeding 11-15' },
    { label: 'Speeding 16-20', value: 'Speeding 16-20' },
    { label: 'Speeding 21+', value: 'Speeding 21+' },
    { label: 'Speed over 100mph', value: 'Speed over 100mph' },
    { label: 'Seat Belt', value: 'Seat Belt' },
    { label: 'Suspension', value: 'Suspension' },
    { label: 'Ticket Violation Not Listed', value: 'Ticket Violation Not Listed' },
    { label: 'Towing', value: 'Towing' },
    {
      label: 'Transportation of Hazardous Materials',
      value: 'Transportation of Hazardous Materials',
    },
    {
      label: 'Unsafe Operation of a Motor Vehicle',
      value: 'Unsafe Operation of a Motor Vehicle',
    },
    { label: 'Vehicle Theft', value: 'Vehicle Theft' },
    { label: 'Wrong Way/Wrong Lane', value: 'Wrong Way/Wrong Lane' },
  ],
  loss_description: [
    { label: 'FIRE', value: 'FIRE' },
    { label: 'HIT ANIMAL', value: 'HIT ANIMAL' },
    { label: 'THEFT', value: 'THEFT' },
    { label: 'TOWING', value: 'TOWING' },
    { label: 'VANDALISM', value: 'VANDALISM' },
    { label: 'GLASS', value: 'GLASS' },
    { label: 'TORNADO/HURRICANE', value: 'TORNADO/HURRICANE' },
    { label: 'FLOOD', value: 'FLOOD' },
    { label: 'WIND/HAIL', value: 'WIND/HAIL' },
    { label: 'ALL OTHER', value: 'ALL OTHER' },
  ],
  loss_vehicle_involved: [
    { label: '0', value: '0' },
    { label: '1', value: '1' },
    { label: '2', value: '2' },
    { label: '3', value: '3' },
    { label: '4', value: '4' },
    { label: '5', value: '5' },
    { label: '6', value: '6' },
    { label: '7', value: '7' },
    { label: '8', value: '8' },
    { label: '9', value: '9' },
    { label: '10', value: '10' },
  ],
  years_with_current_carrier: [
    { label: '1', value: '1' },
    { label: '2', value: '2' },
    { label: '3', value: '3' },
    { label: '4', value: '4' },
    { label: '5', value: '5' },
    { label: '6', value: '6' },
    { label: '7', value: '7' },
    { label: '8', value: '8' },
    { label: '9', value: '9' },
    { label: '10', value: '10' },
    { label: '11', value: '11' },
    { label: '12', value: '12' },
    { label: '13', value: '13' },
    { label: '14', value: '14' },
    { label: '15', value: '15' },
  ],
};
export const DETAILS_DATA = {
  reason_no_prior: [
    { label: 'Active Military Duty', value: 'Active Military Duty' },
    { label: 'Driving A Company Car', value: 'Driving A Company Car' },
    { label: 'Driving A Car Owned By A Relative', value: 'Driving A Car Owned By A Relative' },
    { label: 'Has Not Owned Or Operated A Vehicle', value: 'Has Not Owned Or Operated A Vehicle' },
    {
      label: 'Owned Veh Parked/Stored Off Road/Not Driven',
      value: 'Owned Veh Parked/Stored Off Road/Not Driven',
    },
    { label: 'Other', value: 'Other' },
    { label: 'Non-Payment', value: 'Non-Payment' },
  ],
  reason_for_lapse: [
    { label: 'Active Military Duty', value: 'Active Military Duty' },
    { label: 'Driving A Company Car', value: 'Driving A Company Car' },
    { label: 'Driving A Car Owned By A Relative', value: 'Driving A Car Owned By A Relative' },
    { label: 'Has Not Owned Or Operated A Vehicle', value: 'Has Not Owned Or Operated A Vehicle' },
    {
      label: 'Owned Veh Parked/Stored Off Road/Not Driven',
      value: 'Owned Veh Parked/Stored Off Road/Not Driven',
    },
    { label: 'Other', value: 'Other' },
    { label: 'Non-Payment', value: 'Non-Payment' },
  ],
};
// State dropdown for applicant form
export const STATEDATA: {
  states: {
    value: StateAbbreviation;
    label: string;
  }[];
} = {
  states: [
    { value: 'AK', label: 'Alaska' },
    { value: 'AL', label: 'Alabama' },
    { value: 'AR', label: 'Arkansas' },
    { value: 'AZ', label: 'Arizona' },
    { value: 'CA', label: 'California' },
    { value: 'CO', label: 'Colorado' },
    { value: 'CT', label: 'Connecticut' },
    { value: 'DC', label: 'District Of Columbia' },
    { value: 'DE', label: 'Delaware' },
    { value: 'FL', label: 'Florida' },
    { value: 'GA', label: 'Georgia' },
    { value: 'HI', label: 'Hawaii' },
    { value: 'IA', label: 'Iowa' },
    { value: 'ID', label: 'Idaho' },
    { value: 'IL', label: 'Illinois' },
    { value: 'IN', label: 'Indiana' },
    { value: 'KS', label: 'Kansas' },
    { value: 'KY', label: 'Kentucky' },
    { value: 'LA', label: 'Louisiana' },
    { value: 'MA', label: 'Massachusetts' },
    { value: 'MD', label: 'Maryland' },
    { value: 'ME', label: 'Maine' },
    { value: 'MI', label: 'Michigan' },
    { value: 'MN', label: 'Minnesota' },
    { value: 'MO', label: 'Missouri' },
    { value: 'MS', label: 'Mississippi' },
    { value: 'MT', label: 'Montana' },
    { value: 'NC', label: 'North Carolina' },
    { value: 'ND', label: 'North Dakota' },
    { value: 'NE', label: 'Nebraska' },
    { value: 'NH', label: 'New Hampshire' },
    { value: 'NJ', label: 'New Jersey' },
    { value: 'NM', label: 'New Mexico' },
    { value: 'NV', label: 'Nevada' },
    { value: 'NY', label: 'New York' },
    { value: 'OH', label: 'Ohio' },
    { value: 'OK', label: 'Oklahoma' },
    { value: 'OR', label: 'Oregon' },
    { value: 'PA', label: 'Pennsylvania' },
    { value: 'RI', label: 'Rhode Island' },
    { value: 'SC', label: 'South Carolina' },
    { value: 'SD', label: 'South Dakota' },
    { value: 'TN', label: 'Tennessee' },
    { value: 'TX', label: 'Texas' },
    { value: 'UT', label: 'Utah' },
    { value: 'VA', label: 'Virginia' },
    { value: 'VT', label: 'Vermont' },
    { value: 'WA', label: 'Washington' },
    { value: 'WI', label: 'Wisconsin' },
    { value: 'WV', label: 'West Virginia' },
    { value: 'WY', label: 'Wyoming' },
  ],
};
export type StateAbbreviation =
  | 'AK'
  | 'AL'
  | 'AR'
  | 'AZ'
  | 'CA'
  | 'CO'
  | 'CT'
  | 'DC'
  | 'DE'
  | 'FL'
  | 'GA'
  | 'HI'
  | 'IA'
  | 'ID'
  | 'IL'
  | 'IN'
  | 'KS'
  | 'KY'
  | 'LA'
  | 'MA'
  | 'MD'
  | 'ME'
  | 'MI'
  | 'MN'
  | 'MO'
  | 'MS'
  | 'MT'
  | 'NC'
  | 'ND'
  | 'NE'
  | 'NH'
  | 'NJ'
  | 'NM'
  | 'NV'
  | 'NY'
  | 'OH'
  | 'OK'
  | 'OR'
  | 'PA'
  | 'RI'
  | 'SC'
  | 'SD'
  | 'TN'
  | 'TX'
  | 'UT'
  | 'VA'
  | 'VT'
  | 'WA'
  | 'WI'
  | 'WV'
  | 'WY';
interface StateAgeBase {
  [key: string]: Array<string>;
}
// Age licensed state wise list
export const STATE_AGE_LICENSED: StateAgeBase = {
  AK: STATE_DATA.AK_AGE_LICENSED,
  AL: STATE_DATA.AL_AGE_LICENSED,
  AR: STATE_DATA.AR_AGE_LICENSED,
  AZ: STATE_DATA.AZ_AGE_LICENSED,
  CA: STATE_DATA.CA_AGE_LICENSED,
  CO: STATE_DATA.CO_AGE_LICENSED,
  CT: STATE_DATA.CT_AGE_LICENSED,
  DC: STATE_DATA.DC_AGE_LICENSED,
  DE: STATE_DATA.DE_AGE_LICENSED,
  FL: STATE_DATA.FL_AGE_LICENSED,
  GA: STATE_DATA.GA_AGE_LICENSED,
  HI: STATE_DATA.HI_AGE_LICENSED,
  IA: STATE_DATA.IA_AGE_LICENSED,
  ID: STATE_DATA.ID_AGE_LICENSED,
  IL: STATE_DATA.IL_AGE_LICENSED,
  IN: STATE_DATA.IN_AGE_LICENSED,
  KS: STATE_DATA.KS_AGE_LICENSED,
  KY: STATE_DATA.KY_AGE_LICENSED,
  LA: STATE_DATA.LA_AGE_LICENSED,
  MA: STATE_DATA.MA_AGE_LICENSED,
  MD: STATE_DATA.MD_AGE_LICENSED,
  ME: STATE_DATA.ME_AGE_LICENSED,
  MI: STATE_DATA.MI_AGE_LICENSED,
  MN: STATE_DATA.MN_AGE_LICENSED,
  MO: STATE_DATA.MO_AGE_LICENSED,
  MS: STATE_DATA.MS_AGE_LICENSED,
  MT: STATE_DATA.MT_AGE_LICENSED,
  NC: STATE_DATA.NC_AGE_LICENSED,
  ND: STATE_DATA.ND_AGE_LICENSED,
  NE: STATE_DATA.NE_AGE_LICENSED,
  NH: STATE_DATA.NH_AGE_LICENSED,
  NJ: STATE_DATA.NJ_AGE_LICENSED,
  NM: STATE_DATA.NM_AGE_LICENSED,
  NV: STATE_DATA.NV_AGE_LICENSED,
  NY: STATE_DATA.NY_AGE_LICENSED,
  OH: STATE_DATA.OH_AGE_LICENSED,
  OK: STATE_DATA.OK_AGE_LICENSED,
  OR: STATE_DATA.OR_AGE_LICENSED,
  PA: STATE_DATA.PA_AGE_LICENSED,
  RI: STATE_DATA.RI_AGE_LICENSED,
  SC: STATE_DATA.SC_AGE_LICENSED,
  SD: STATE_DATA.SD_AGE_LICENSED,
  TN: STATE_DATA.TN_AGE_LICENSED,
  TX: STATE_DATA.TX_AGE_LICENSED,
  UT: STATE_DATA.UT_AGE_LICENSED,
  VA: STATE_DATA.VA_AGE_LICENSED,
  VT: STATE_DATA.VT_AGE_LICENSED,
  WA: STATE_DATA.WA_AGE_LICENSED,
  WI: STATE_DATA.WI_AGE_LICENSED,
  WV: STATE_DATA.WV_AGE_LICENSED,
  WY: STATE_DATA.WY_AGE_LICENSED,
};
interface StateCoverageBase {
  [key: string]: { [key: string]: Array<string> };
}
// State wise coverages
export const STATE_COVERAGES: StateCoverageBase = {
  AK: STATE_DATA.AK_STATE_COVERAGES,
  AL: STATE_DATA.AL_STATE_COVERAGES,
  AR: STATE_DATA.AR_STATE_COVERAGES,
  AZ: STATE_DATA.AZ_STATE_COVERAGES,
  CA: STATE_DATA.CA_STATE_COVERAGES,
  CO: STATE_DATA.CO_STATE_COVERAGES,
  CT: STATE_DATA.CT_STATE_COVERAGES,
  DC: STATE_DATA.DC_STATE_COVERAGES,
  DE: STATE_DATA.DE_STATE_COVERAGES,
  FL: STATE_DATA.FL_STATE_COVERAGES,
  GA: STATE_DATA.GA_STATE_COVERAGES,
  HI: STATE_DATA.HI_STATE_COVERAGES,
  IA: STATE_DATA.IA_STATE_COVERAGES,
  ID: STATE_DATA.ID_STATE_COVERAGES,
  IL: STATE_DATA.IL_STATE_COVERAGES,
  IN: STATE_DATA.IN_STATE_COVERAGES,
  KS: STATE_DATA.KS_STATE_COVERAGES,
  KY: STATE_DATA.KY_STATE_COVERAGES,
  LA: STATE_DATA.LA_STATE_COVERAGES,
  MA: STATE_DATA.MA_STATE_COVERAGES,
  MD: STATE_DATA.MD_STATE_COVERAGES,
  ME: STATE_DATA.ME_STATE_COVERAGES,
  MI: STATE_DATA.MI_STATE_COVERAGES,
  MN: STATE_DATA.MN_STATE_COVERAGES,
  MO: STATE_DATA.MO_STATE_COVERAGES,
  MS: STATE_DATA.MS_STATE_COVERAGES,
  MT: STATE_DATA.MT_STATE_COVERAGES,
  NC: STATE_DATA.NC_STATE_COVERAGES,
  ND: STATE_DATA.ND_STATE_COVERAGES,
  NE: STATE_DATA.NE_STATE_COVERAGES,
  NH: STATE_DATA.NH_STATE_COVERAGES,
  NJ: STATE_DATA.NJ_STATE_COVERAGES,
  NM: STATE_DATA.NM_STATE_COVERAGES,
  NV: STATE_DATA.NV_STATE_COVERAGES,
  NY: STATE_DATA.NY_STATE_COVERAGES,
  OH: STATE_DATA.OH_STATE_COVERAGES,
  OK: STATE_DATA.OK_STATE_COVERAGES,
  OR: STATE_DATA.OR_STATE_COVERAGES,
  PA: STATE_DATA.PA_STATE_COVERAGES,
  RI: STATE_DATA.RI_STATE_COVERAGES,
  SC: STATE_DATA.SC_STATE_COVERAGES,
  SD: STATE_DATA.SD_STATE_COVERAGES,
  TN: STATE_DATA.TN_STATE_COVERAGES,
  TX: STATE_DATA.TX_STATE_COVERAGES,
  UT: STATE_DATA.UT_STATE_COVERAGES,
  VA: STATE_DATA.VA_STATE_COVERAGES,
  VT: STATE_DATA.VT_STATE_COVERAGES,
  WA: STATE_DATA.WA_STATE_COVERAGES,
  WI: STATE_DATA.WI_STATE_COVERAGES,
  WV: STATE_DATA.WV_STATE_COVERAGES,
  WY: STATE_DATA.WY_STATE_COVERAGES,
};
export const FIRST_CONNECT_THEME = 'sky-blue';
export const HIPPO_THEME = 'hippo-green';
export const PENNYMAC_THEME = 'navy-blue';
export const GATOR_THEME = 'hippo-green';
export const DOMA_THEME = 'purplish-blue';
export const APPLICANT_LABEL_NAME = 'Applicant Info';
export const RATES_LABEL_NAME = 'Rates';

// PUBLIC URLS AND WHATEVER RELATED TO URLS
export const PUBLIC_URL_WITHOUT_ONE_LOGIN = ['/launch-partner'];

export const PRODUCT_TYPE_MAPPING: any = {
  n: 'ho3',
  y: 'ho6',
  h: 'ho5',
  z: 'dp3',
  x: 'dp1',
  o: 'ho4',
};

export const PRODUCT_OR_ORG_CHOOSE_ERROR =
  'Please select at least one organization and one product to proceed !';

export const PRODUCT_CHOOSE_ERROR = 'Please select at least one product to proceed !';
export const HIPPO_LABEL = 'Hippo';
export const ZERO = '0';
export const DOLLAR_ZERO = '$0';
export const HTML_EM = '--';
export const LOB_KEY = 'LOB';
export const SHOW_LOAN_NUMBER_FOR_LOB: Array<string> = ['home', 'homeowners', 'flood'];
export const ORG_NAME = 'orgName';
export const ORG_CODE = 'orgCode';
export const ORG_LOGO = 'orgLogo';
export const CREATED_DATE = 'createdDate';
export const EDIT = 'Edit';
export const ADMIN_ROUTE = '/admin';
export const LOG_OUT_ROUTE = '/logout';
export const AUTO_ROUTE = '/auto';
export const HOME_ROUTE = '/home';
export const VEHICLE = 'vehicle';
export const DRIVER = 'driver';
export const COVERAGES = 'coverages';
export const APPLICANT = 'applicant';
export const ACCIDENTS_VIOLATIONS_LOSSES = 'accidents-violations-losses';
export const AUTHENTICATION_OPTIONS = ['OneLogin', 'Auth0', 'API'];
export const ORGANIZATION_DETAILS_PATH = '/admin/organizations';
export const REQUIRED = 'Required';
export const INVALID = 'Invalid';
export const AUTHENTICATION = 'authentication';
export const NAME = 'name';
export const USERNAME = 'username';
export const TEXT_TYPE = 'text';
export const PASSWORD = 'password';
export const STATUS = 'status';
export const IN_PROGRESS = 'in progress';
export const APPROVED = 'approved';
export const REQUESTED = 'requested';
export const EXPIRED = 'expired';
export const SUCCESS = 'success';
export const ERROR = 'error';
export const COMPLETED = 'Completed';
export const REFERRED = 'referred';
export const DECLINED = 'declined';
export const MAX_LENGTH_REACHED = 'Max length reached.';
export const PASSWORD_HINTS =
  'Must contain at least one number and one uppercase and lowercase letter and at least 8 or more characters';
export const VALID_TOTAL_NUMBER_OF_QUOTES = 'Total Number Of Quotes should be of type number';
export const NO_ORGANIZATION_DATA_MESSAGE = 'No organization data found';
export const ENCRYPTED_DATA = '******';
export const IN_ACTIVE = 'inactive';
export const NUMBER_1 = 1;
export const NUMBER_4 = 4;
export const NUMBER_5 = 5;
export const NUMBER_60 = 60;
export const NUMBER_99 = 99;
export const NUMBER_ZERO = 0;
export const NUMBER_100 = 100;
export const MAX_API_RESPONSE_TIME = 'max_api_response_time';
export const HIPPO_RATE_COMPARE = 'hippo_rate_compare';
export const ALL_LOB = 'All LOB';
export const YES = 'Yes';
export const NO = 'No';
export const INFORMATION_FROM_VERISK = `
<p>We fetched your information from Verisk</p>
<p>Please verify your information</p>
`;
export const MAX_API_RESPONSE_TIME_ERROR =
  'Maximum API Response Time should be between 5 to 60 seconds';
export const TOTAL_NO_OF_QUOTES_FETCH_ERR = 'Total number of quotes should be between 1 to 99';
export const MISSING_INFORMATION = 'Please fill the missing information';
export const LOB_LIST: Array<string> = [
  'home',
  'auto',
  'flood',
  'pet',
  'earthquake',
  'life',
  'home-warranty',
  'cyber-security',
  'umbrella',
];
export const EARTH_QUAKE_LOB = 'earthquake';
export const HOME_WARRANTY_LOB = 'HOME WARRANTY';
export const CYBER_SECURITY_LOB = 'CYBER SECURITY';
export const HOME_WARRANTY = 'home-warranty';
export const CYBER_SECURITY = 'cyber-security';
export const COMPLETE = 'complete';
export const BOUND = 'bound';
export const QUAKE_COVERAGE_TYPES: any = {
  parametric: 'Parametric',
  full_replacement_basic: 'Full Replacement Basic',
  full_replacement_enhanced: 'Full Replacement Enhanced',
};
export const FEEDBACK_BUTTON = 'Feedback Button';
export const VISIBLE_ON_GATOR_UI = 'Visible on Gator UI';
export const PUBLIC_ACCESS_TO_VIEW_QUOTE = 'Public Access to View Quote';
export const ABBREVATION = 'abbreviation';
export const EARTHQUAKE = 'EQ';
export const SPLIT_LIMIT = 'Split Limit';
export const SPLIT_LIMIT_KEY = 'split_limit';
export const LOSS_OFF_USE = 'Loss Of Use';
export const BUILDING_CODE_UPGRADE = 'Building Code Upgrade';
export const DEDUCTIBLES = 'Deductibles';
export const COVERAGE_LIMIT = 'Coverage Limit';
export const PARAMETRIC_KEY = 'parametric';
export const LIABILITY_BI = 'Liability (BI)';
export const REBUILDING_COST = 'Rebuilding Costs';
export const MEDICAL_PAYMENTS = 'Medical Payments';
export const OTHER_STRUCTURE = 'Other Structures';
export const LIABILITY = 'Liability';
export const PROPERTY_DAMAGE = 'Property Damage';
export const PERSONAL_PROPERTY = 'Personal Property';
export const EOI_RECIPIENTS_KEY = 'eoiRecipients';
export const DOWNLOAD_QUOTE_PREFIX = 'Download Quote Prefix';
export const VIEW_AMS_POLICY_HEADER = 'View AMS Policy Header';
export const POLICY_BOUND = 'Policy Bound';
export const PDF_TYPE = 'application/pdf';
export const EOI_FILE = 'eoiFile';
export const INVALID_PDF_FILE_ERROR = 'Invalid file type. Please upload PDF files only.';
export const FILE_TYPE = 'file';
export const UPLOAD_FILE = 'Upload File';
export const UPLOAD_EOI_FILE = 'Upload EOI File';
export const SM = 'sm';
export const EOI = 'eoi';
export const FEEDBACK = 'Feedback';
export const SUBJECT = 'subject';
export const DESCRIPTION = 'description';
export const SUBMIT = 'Submit';
export const CANCEL = 'Cancel';
export const SUCCESSFULLY = 'successfully';
export const THANK_YOU = 'Thank you';
export const XS = 'xs';
export const ASTERISK = '*';
export const ENTER = 'Enter';
export const LOSS_ASSESSMENT = 'Loss Assessment';
export const FIRST_NAME = 'First Name';
export const LAST_NAME = 'Last Name';
export const PHONE_NUMBER_LABEL = 'Phone Number';
export const EMAIL_ADDRESS = 'Email Address';
export const DATE_OF_BIRTH = 'Date Of Birth';
export const STREET_ADDRESS = 'Street Address';
export const CITY = 'City';
export const STATE = 'State';
export const ZIP_CODE = 'Zip Code';
export const REPRESENTATIVE = 'Representative';
export const GROUP = 'Group';
export const DEPARTMENT = 'Department';
export const EMPLOYEE = 'Employee';
export const BROKER = 'Broker';
export const CUSTOM_ID = {
  LABEL: 'Custom Id',
  KEY: 'customId',
};
export const INPUT_PROPS = {
  CARRIER_ID: 'carrierId',
};
export const EOI_DOCUMENT = 'EOI Document';
export const EOI_DOCUMENT_KEY = 'eoiDocument';
export const ADDRESS = 'Address';
export const DIVISION = 'Division';
export const EXECUTIVE = 'Executive';
export const CARRIER = 'Carrier';
export const SELECTED_CARRIER = 'Selected Carrier';
export const POLICY_NUMBER = 'Policy Number';
export const PREMIUM = 'Premium';
export const TERM = 'Term';
export const CARRIER_WRITING_COMPANY = 'Carrier Writing Company';
export const INSURED = 'Insured';
export const CLEAR = 'Clear';
export const NEW = 'New';
export const EFFECTIVE_DATE = 'Effective Date';
export const CREATE_NEW_CUSTOMER = 'Create New Customer';
export const DOWNLOAD = 'download';
export const CARRIER_ID = 'carrier_id';
export const ATTACHMENT = 'attachment';
export const UNIT = 'Unit';
export const FULL_NAME = 'fullName';
export const FIRST_NAME_KEY = 'firstName';
export const LAST_NAME_KEY = 'lastName';
export const PHONE_NUMBER_KEY = 'phoneNumber';
export const DOB = 'dob';
export const ZIP = 'zip';
export const BUSINESS_LINE = 'businessLine';
export const EFFECTIVE_DATE_KEY = 'effectiveDate';
export const PET_LABEL_NAME = 'Pet Info';
export const AMS = 'AMS';
export const MD = 'md';
export const VEHICLE_INCLUDE_LIMIT = `Only include up to ${config.maxVehicleLimit} vehicles at a time`;
export const DRIVER_INCLUDE_LIMIT = `Only include up to ${config.maxDriverLimit} drivers at a time`;
export const SELECT = 'select';
export const DESELECT = 'deselect';
export const FETCHING_POLICY_DETAILS = 'Fetching bounded policy details';
export const PET_PLAN_CONSTANTS = {
  AVAILABLE_PLANS: 'Available Plans:',
  BOARDING: 'Emergency Boarding',
  CO_INSURANCE: 'Co-insurance',
  DEDUCTIBLE: 'Deductible',
  ENDORSEMENTS: 'Endorsements',
  EXAM_FEES: 'No Fault Exam Fees',
  GOLD_PLAN: 'Gold Plan',
  HOLISTIC_TREATMENTS: 'Holistic Treatments',
  PET_ADVERTISING: 'Lost Pet Advertising',
  PLATINUM_PLAN: 'Platinum Plan',
  POLICY_LIMIT: 'Policy Limit',
  SILVER_PLAN: 'Silver Plan',
  TRIP_CANCELLATION: 'Trip Cancellation',
};
export const PET_INFO = {
  ADD_ANOTHER_PET: 'Add another pet',
  ADD_PET: 'Add Pet',
  PET_AGE: 'petAge',
  PET_DETAILS: 'Pet Details',
  PET_NAME: 'petName',
  PET_NAME_LABEL: 'Pet Name',
  PET_TYPE: 'petType',
  PET_GENDER: 'petGender',
  ANIMAL_TYPE_LABEL: 'Animal Type',
  BREED: 'Breed',
  BREED_NAME: 'breedName',
  IS_SPAYED_OR_NEUTERED: 'isSpayedOrNeutered',
  IS_SPAYED_LABEL: 'Spayed / Neutered',
};
export const DOG = 'Dog';
export const CAT = 'Cat';
export const MAX_PET_BREEDS_INFO = 15;
export const BACK = 'Back';
export const CONTINUE = 'Continue';
export const GENDER = 'Gender';
export const MALE = 'male';
export const FEMALE = 'female';
export const AGE = 'Age';
export const SPAY = 'Spay';
export const NEUTERED = 'Neutered';
export const GET_QUOTE = 'Get Quote';
export const UNABLE_TO_RETRIEVE_BRIDGE_URL = 'Unable to retrieve bridge URL from carrier';
export const PLEASE_TRY_AGAIN = 'Please Try Again Later';
export const PET_DETAILS = 'Pet Details';
export const PET_POLICY_LIMIT = 'Policy Limit';
export const DELETE = 'Delete';
export const NONE = 'None';
export const GOLD = 'gold';
export const PLATINUM = 'platinum';
export const SILVER = 'silver';
export const HCR_ASYNCHRONOUS_RESPONSE_LABEL = 'HCR Asynchronous Response';
export const ALLOW_SYNC_INTEGRATION = 'allow_async_integration';
export const NUMBER = 'number';
export const MAX_API_RESPONSE_TIME_IN_SEC = 'Maximum API Response Time (in seconds)';
export const CAROUSEL = 'carousel';
export const ANNUALLY = 'Annually';
export const SEMI_ANNUALLY = 'Semi-Annually';
export const MONTHLY = 'Monthly';
export const CARRIER_APPOINTMENT = {
  API_USERNAME: 'API Username',
  API_PASSWORD: 'API Password',
  CARRIER_LOGO_LABEL: 'Carrier Logo',
  CARRIER_LOGO_KEY: 'carrier_logo',
  INTEGRATION_TYPE_LABEL: 'Integration Type',
  INTEGRATION_TYPE_KEY: 'integration_type',
  MARKETS_LABEL: 'Markets',
  MARKET_KEY: 'market',
  ENABLED_FOR_LEAD_FLOW_LABEL: 'Enable for Lead Flow',
  ENABLED_FOR_LEAD_KEY: 'is_enable_for_lead_flow',
  CONSUMER_MARKET_LABEL: 'Consumer Markets',
  CONSUMER_MARKET_KEY: 'consumer_market',
  IS_ACTIVE_LABEL: 'Active',
  IS_ACTIVE_KEY: 'is_active',
  IS_AVAILABLE_FOR_QUOTING_LABEL: 'Available for Quoting',
  IS_AVAILABLE_FOR_QUOTING_KEY: 'is_available_for_quoting',
};
export const PRODUCER_CODE_KEY = 'producer_code';
export const PRODUCER_CODE = 'Producer Code';
export const EZLYNX = 'ezlynx';
export const HCR_IFRAME_CLIENT_LABEL = 'HCR IFrame Client';
export const HCR_IFRAME_CLIENT_KEY = 'hcr_iframe_client_enabled';

export const SFDC_KEY = {
  SFDC: 'SFDC',
  LEAD_RECORD_TYPE_LABEL: 'Lead Record Type',
  LEAD_TYPE_ID_KEY: 'leadRecordTypeId',
  ENABLE_SFDC_INTEGRATION_LABEL: 'Enable SFDC Integration',
  SALES_FORCE_ENABLED_KEY: 'salesForceEnabled',
  ALLOW_EDITING_QUOTES_LABEL: 'Allow Editing Quotes',
  ALLOW_EDITING_QUOTES_KEY: 'iframeEditingEnabled',
  ORGANIZATION_ACCOUNT_ID_LABEL: 'Organization Account',
  ORGANIZATION_ACCOUNT_ID_KEY: 'orgAccountId',
  ACCOUNT_LEAD_RECORD_TYPE_LABEL: 'Account Lead Type',
  ACCOUNT_LEAD_RECORD_TYPE_KEY: 'accountRecordTypeId',
  ALLOW_ADDING_ADD_PRODUCT_LABEL: 'Allow Adding All Product',
  ALLOW_ADDING_ADD_PRODUCT_KEY: 'allowAddingAddProduct',
};
export const SFDC_AMS_DETAILS = '/ams';
export const PLAN_DETAILS = 'Plan Details';
export const VALIDATE_QUOTE_ROUTE = '/view';
export const CONSUMER_TOKEN = {
  LABEL: 'Consumer Token',
  KEY: 'consumer_token',
};
export const LOGO_URL = {
  LABEL: 'Logo URL',
  KEY: 'logo_url',
};
export const POD_ORGANIZATION_NAME = {
  LABEL: 'POD Org Name',
  KEY: NAME,
};
export const POD_ORGANIZATION_CODE = {
  LABEL: 'POD Org Code',
  KEY: 'code',
};
export const POD_ORGANIZATION_ID = {
  LABEL: 'POD Org ID',
  KEY: 'partner_id',
};
export const POD_AUTH_TOKEN = {
  LABEL: 'POD Auth Token',
  KEY: 'auth_token',
};
export const THEME = {
  LABEL: 'Theme',
  KEY: 'template_name',
};
export const PHONE = 'phone';
export const WEBHOOK = 'webhook';
export const SIGNATURE = 'signature';
export const JV_HOME_URL = {
  LABEL: 'JV Home URL',
  KEY: 'base_url',
  TOOLTIP_TITLE: 'Used for creating a landing URL',
};
export const INVALID_URL = 'Please enter a valid URL';
export const NON_RETURNING_QUOTE_OPTION = {
  EXCLUDE: 'exclude',
  INCLUDE: 'include',
  KEY: 'nonReturningQuotes',
  LABEL: 'Non-returning Quotes',
};
export const INCLUDE_IN_REPORT = {
  LABEL: 'Include in Reports',
  KEY: 'report_enabled',
};
export const ALLOW_DOWNLOAD_QUOTE = {
  LABEL: 'Allow Download Quote',
  HOME_QUOTE: {
    LABEL: 'Home',
    KEY: 'enable_home_quote_doc_download',
  },
  AUTO_QUOTE: {
    LABEL: 'Auto',
    KEY: 'enable_auto_quote_doc_download',
  },
};
export const INCLUDE_GATOR_QUOTING = {
  LABEL: 'Include Gator Quoting',
  KEY: 'gator_quoting_enabled',
};
export const ALLOW_ADHOC_BIND = {
  LABEL: 'Allow Ad Hoc Bind',
  KEY: 'adhoc_bind_enabled',
};
export const ALLOW_AMS_FOR_SALESFORCE = {
  LABEL: 'Allow AMS for SalesForce',
  KEY: 'ams_for_salesforce_enabled',
};
export const ALLOW_AMS_FOR_GATOR = {
  LABEL: 'Allow AMS for Gator',
  KEY: 'ams_for_gator_enabled',
};
export const AMS_ENABLED = {
  KEY: 'ams_enabled',
};
export const EMPTY_LOB_ERROR_MESSAGE =
  'There are no allowed LOBs for this organization. Please check Gator Admin.';
export const PRODUCT_SELECTION_ERROR_MESSAGE = 'Something went wrong, please try again.';
export const PARTNER_ID_LABEL = 'Partner ID';
export const ORGANIZATION = 'Organization';
export const ORIGIN_URL_ERROR_MSG = {
  EMPTY: 'Origin URL field cannot be empty',
  INVALID: 'Please enter a valid URL',
  DUPLICATE: 'This Origin is duplicated',
};
export const HCR_IFRAME_LABELS = {
  ADD_ORIGIN: 'Add Orgin',
  ORIGINS: 'Origins',
  CLIENT_ID: 'Client Id',
  CLIENT_ID_KEY: 'clientId',
  COPY_CODE: 'Copy Code',
  ADD_IFRAME_SETTINGS: 'Add New IFrame Settings',
  DISCLAIMER: 'disclaimer',
  ORIGIN_TOOLTIP: 'Enter one or more fully qualified base URLs - ex. https://www.example.com',
};
export const VEHICLE_AGE_EXCEED_ERR = 'Cannot be greater than age of vehicle';
export const CRAWLSPACE_SQUARE_FT_QUES_LIST = [
  'aboveCrawlspaceFinished',
  'belowCrawlspaceFinished',
];
export const LOAN_NUMBER_LABEL = 'Loan Number';
export const LOAN_NUMBER_KEY = 'loanNumber';
export const LENDER_SETTING = {
  LABEL: 'Lender Settings',
  STATIC_SETTING_LABEL: 'Use Static Lender Settings',
  STATIC_SETTING_KEY: 'lenderEnabled',
  LENDER_NAME_LABEL: 'Lender Name',
  LENDER_ADDRESS_LABEL: 'Lender Address',
  LENDER_NAME_KEY: 'lenderName',
  LENDER_STREET_LABEL: 'Lender Street',
  LENDER_STREET_KEY: 'lenderStreet',
  LENDER_CITY_LABEL: 'Lender City',
  LENDER_CITY_KEY: 'lenderCity',
  LENDER_STATE_LABEL: 'Lender State',
  LENDER_STATE_KEY: 'lenderState',
  LENDER_ZIP_LABEL: 'Lender Zip',
  LENDER_ZIP_KEY: 'lenderZip',
};
export const ADD = 'add';
export const ACTION = 'Action';
export const ACTIVE = 'active';
export const DEFAULT_PAGE_TRACKING = {
  home: 'applicant',
  quake: 'applicant',
  flood: 'flood',
  auto: 'vehicle',
  pet: 'pet',
  homeWarranty: 'homeWarranty',
  life: 'life',
  cyberSecurity: 'cyberSecurity',
  umbrella: 'umbrella',
};
export const PAGE_TRACKING_KEYS: any = {
  applicant: 'HOME',
  flood: 'FLOOD',
  vehicle: 'AUTO',
  driver: 'DRIVER',
  coverages: 'COVERAGES',
  pet: 'PET',
  homeWarranty: 'HOMEWARRANTY',
  life: 'LIFE',
  cyberSecurity: 'CYBERSECURITY',
  umbrella: 'UMBRELLA',
};
export const HCR_ORGANIZATION: any = {
  LABEL: 'HCR Org Name',
  KEY: 'hcr_org_name',
};
export const DWELLING_LIMIT = 'Dwelling Limit';
export const CONTENTS_COVERAGE = 'Contents Coverage';
export const CONFIRMATION_MESSAGE = 'Are you sure you want to continue?';
export const HO3 = 'HO3';
export const HO5 = 'HO5';
export const HO6 = 'HO6';
export const HO4 = 'HO4';
export const BUSINESS = 'business';
export const HEAP_ANALYTICS = {
  LABEL: 'Enable Heap Analytics',
  KEY: 'heap_analytics_enabled',
};
export const HEAP_PROJECT_ID = {
  LABEL: 'Heap Project ID',
  KEY: 'heap_project_id',
};
export const QUOTING_STEPS_PAGES_NAME = {
  APPLICANT: 'Rater - Applicant Page - View',
  APPLICANT_SUBMIT: 'Rater - Applicant Page - Click - Submit',
  VEHICLE_INFO_P1: 'Rater - Auto - Vehicle Page I - View',
  VEHICLE_INFO_P1_SUBMIT: 'Rater - Auto - Vehicle Page I - Click - Submit',
  VEHICLE_INFO_P2: 'Rater - Auto - Vehicle Page II - View',
  VEHICLE_INFO_P2_SUBMIT: 'Rater - Auto - Vehicle Page II - Click - Submit',
  DRIVER_INFO: 'Rater - Auto - Driver Page - View',
  DRIVER_INFO_SUBMIT: 'Rater - Auto - Driver Page - Click - Submit',
  ADD_VEHICLE: 'Rater - Auto - Vehicle Page II - Click - Add Vehicle',
  ADD_DRIVER: 'Rater - Auto - Driver Page - Click - Add Driver',
  FLOOD_INFO: 'Rater - Flood - Flood Page - View',
  POLICY_INFO: 'Rater - Auto - Policy Page - View',
  LIFE_INFO: 'Rater - Life - Life Page - View',
  POLICY_INFO_SUBMIT: 'Rater - Auto - Policy Page - Click - Submit',
  PET_INFO: 'Rater - Pet Page - View',
  PET_INFO_SUBMIT: 'Rater - Pet Page - Click - Submit',
  ADD_PET: 'Rater - Pet Page - Click - Add pet',
  RATES_PAGE: 'Rater - Rates Page - View',
  RATES_FIRST_QUOTE: 'Rater - Rates Page - Load first quote',
  RATES_LAST_QUOTE: 'Rater - Rates Page - Load last quote',
  RATES_BRIDGE_TO_CARRIER: 'Rater - Rates Page - Click - Bridge Quote',
  RATES_MORE_DETAILS: 'Rater - Rates Page - Click - More Details',
  RATES_EDIT_QUOTE: 'Rater - Rates Page - Click - Edit Quote',
  RATES_ADD_PRODUCT: 'Rater - Rates Page - Click - Add Products',
  RATES_ADD_PRODUCT_SUBMIT: 'Rater - Rates Page - Add Products - Click - Submit',
  UMBRELLA_INFO: 'Rater - Umbrella - Umbrella Page - View',
  UMBRELLA_INFO_SUBMIT: 'Rater - Umbrella Page - Click - Submit',
};
export const HEAP_LABELS = {
  ENTRY_TYPE: 'Entry Type',
  VIN: 'VIN',
  DETAILS: 'Details',
  BIRTH_YEAR: 'Birth Year',
  RESIDENCY_TYPE: 'Residency Type',
  PRODUCT_TYPE: 'Product Type',
  PRODUCT_COUNT: 'Product Count',
  NO_OF_QUOTES: 'No of Quotes',
  NO_OF_NON_QUOTES: 'No of Non Quotes',
  PRODUCT: 'Product',
  NO_OF_PRODUCT_QUOTES: 'No of Product Quotes',
  NO_OF_PRODUCT_NON_RETURN_QUOTE: 'No of Product Non Return Quote',
  PREMIUM_RANK: 'Premium Rank',
  RESULT_RANK: 'Result Rank',
  EDIT_TYPE: 'Edit Type',
  HOME_QUOTES: 'Home Quotes',
  AUTO_QUOTES: 'Auto Quotes',
  FLOOD_QUOTES: 'Flood Quotes',
  PET_QUOTES: 'Pet Quotes',
  EARTHQUAKE_QUOTES: 'Earthquake Quotes',
  HOME_WARRANTY_QUOTES: 'Home Warranty Quotes',
  LIFE_QUOTES: 'Life Quotes',
  CYBER_SECURITY_QUOTES: 'Cyber Security Quotes',
  ADDED_PRODUCTS: 'Added Products',
  LIFE: 'LIFE',
  INDUSTRY: 'Industry',
  EDUCATION: 'Education',
  UMBRELLA: 'UMBRELLA',
  UMBRELLA_QUOTES: 'Umbrella Quotes',
};
export const ANNEX = 'annex';
export const HEAP_IDENTITY = 'heapIdentity';
export const FAILED = 'failed';
export const SFDC_ORG_ACCOUNT_INFO_MESSAGE = 'Enter 3 or more characters to start searching';
export const CONSUMER_POD_ID = {
  LABEL: 'Consumer POD ID',
  KEY: 'consumer_partner_id',
};
export const STEPPER_KEYS_MAPPING = {
  RATE_PAGE: 'ratePage',
  FLOOD_INFO: 'floodInfo',
  PET_INFO: 'petInfo',
  VEHICLE_INFO: 'vehicleInfo',
  LIFE_INFO: 'lifeInfo',
  UMBRELLA_INFO: 'umbrellaInfo',
};
export const DP1 = 'dp1';
export const DP3 = 'dp3';
export const HOME = 'home';
export const HOUSE = 'House';
export const CONDO = 'Condo';
export const LIFE_LABEL_NAME = 'Life Info';
export const CARRIERS_TO_EXCLUDE_PASSWORD_VALIDATION = ['Tend', 'Blink', 'SES'];
export const ORGANIZATION_MODAL_HEADING = 'Select an organization to quote';
export const HEIGHT = 'Height';
export const WEIGHT = 'Weight';
export const HOUSEHOLD_INCOME = 'Household Income';
export const TOBACCO_USE = 'Tobacco Use';
export const LIFE_INFO = {
  HEIGHT_LABEL: 'Height',
  WEIGHT_LABEL: 'Weight',
  HOUSEHOLD_INCOME_LABEL: 'Household Income',
  TOBACCO_USE_LABEL: 'Tobacco Use',
  GENDER_KEY: 'gender',
  HEIGHT_FT_KEY: 'height_ft',
  HEIGHT_IN_KEY: 'height_in',
  WEIGHT_LBS_KEY: 'weight_lbs',
  HOUSEHOLD_INCOME_KEY: 'household_income',
  TOBACCO_USE_KEY: 'tobacco_use',
  LIFE_DETAILS: 'Life Details',
};
export const PREMIUM_SELECTION_LABEL_MAPPING: any = {
  QUAKE: 'Type:',
  HOMEWARRANTY: 'Term:',
  LIFE: 'Coverage:',
  CYBERSECURITY: 'Coverage:',
};
export const EOI_SETTINGS = {
  TAB_LABEL: 'EOI Settings',
  ADD_EOI_SETTING_LABEL: 'Add EOI Settings',
  RECIPIENTS: 'Recipients',
  SUBJECT_LINE_LABEL: 'Subject Line',
  SUBJECT_LINE_KEY: 'subjectLine',
};
export const PRODUCT_OR_ORG_TO_BIND_ERROR = 'Please select at least one organization to proceed !';
export const ESTIMATED_ANNUAL_PRICE = 'Estimated Annual Price';
export const CYBER_COVERAGES_INCLUDED =
  'ID Theft, Cyber Extortion, Cyber Financial Fraud, Deceptive Transfer Fraud, Cyber Disruption, Cyber Bullying, Cyber Breach of Privacy, Electronic Data Restoration, Electronic Device Replacement';
export const CYBER_DEFAULT_PLAN = 'pricingMeMyHousehold';
export const HOME_OWNERS = 'homeowners';
export const POLICY_NUMBER_KEY = 'policyNumber';
export const COUNTRY = 'county';
export const QUOTED_CARRIERS = 'Quoted Carriers';
export const OTHER_CARRIERS = 'Other Carriers';
export const PERSONAL_INFORMATION_KEY = 'personalInformation';
export const CARRIER_NAME_KEY = 'carrierName';
export const FLOOD_PENALTY = {
  NO_CO_INSURANCE_LABEL: 'No Co-Insurance Penalty: ',
  NO_CO_INSURANCE_DETAILS_1:
    'Claims are paid at the cost to repair (replacement cost coverage), up to the dwelling limit purchased.',
  NO_CO_INSURANCE_DETAILS_2:
    'Hippo Flood Automatically includes Living expenses, basement, and unattached structures coverage.',
  CO_INSURANCE_LABEL: 'Co-Insurance Penalty Applied: ',
  CO_INSURANCE_DETAILS:
    'Claims are paid out at depreciated value unless the ratio of dwelling limit to replacement cost estimate is at least 80% at the time of the claim. For example, a customer with damage that costs 100k to repair would be paid out at $66k if their dwelling limit was $250k and their replacement cost estimate was $500k.',
  NON_ADMITTED_LABEL: 'Non-Admitted',
  NON_ADMITTED_VIEW_MORE_TITLE: 'VALID REASONS FOR NON-ADMITTED',
  NON_ADMITTED_VIEW_MORE_SUB_TITLE:
    'The following are sample reasons to why an agent would offer non-admitted vs admitted:',
  NON_ADMITTED_VIEW_MORE_DETAILS_LIST: [
    'Building Coverage exceeds what the admitted market offers.',
    'Admitted market does not offer Loss of Use or Additional Living Expense or other coverage.',
    'Coinsurance clause (e.g. ACV payout) for building or contents.',
    'Admitted market products have a waiting period and the customer needs the policy sooner due to mortgage closing or other reasons.',
    'No diligent effort required because the insured qualifies as an "Exempt Commercial Purchaser" (NY).',
  ],
  NON_ADMITTED_CONFIRMATION_TEXT:
    'We suggest to Bridge over Admitted carrier. Are you sure to proceed with Non-Admitted carrier?',
  NON_ADMITTED_CONFIRMATION_BUTTON_TEXT: 'Proceed',
};
export const PREMIUM_VALIDATION_DETAILS = {
  MAXIMUM_PREMIUM_VALUE: 100000,
  MINIMUM_PREMIUM_VALUE_TO_SHOW_MODAL: 10000,
  CONFIRMATION_MESSAGE: 'Please confirm policy premium is correct: ',
  ERROR_MESSAGE: 'Premium cannot exceed $100,000.00',
};
export const OCCUPATION_LABEL = 'Occupation';
export const PREFERRED_DEDUCTIABLE_LABEL = 'Preferred Deductible';
export const UMBRELLA = 'Umbrella';
export const NO_COVERAGE = 'No Coverage';
export const UNLIMITED = 'Unlimited';
export const REQUEST_BIND = 'REQUEST BIND';
export const REQUEST_BIND_SUCCESS_MESSAGE = {
  REQUESR_SENT: 'Request sent successfully',
  UNDERWRITER: 'Our underwriter will reach out for further information.',
};
export const BIND_REQUESTED = 'BIND REQUESTED';
export const ONE = '1';
export const ALREADY_BOUND = 'alreadybound';
export const AMS_360_ADHOC = {
  key: 'ams360adhoc',
  label: 'AMS360 Adhoc',
};
export const LOB_REFRESH_TIMEOUT = 15000;
export const REFRESH = 'Refresh';
export const ADD_PRODUCTS = 'Add Products';
export const ASSISTED_BY = {
  LABEL: 'Assisted By',
  KEY: 'assistedBy',
};
export const BROKER_CODE_KEY = 'BrokerCode';
export const UNDER_WRITER_ROLE = 'uw';
export const CARRIER_FAILED_TO_RESPOND_ERROR_MESSAGE =
  'Waiting for carrier to return a quote. Please refresh in a minute.';
export const QUOTE_TYPE = 'wholesaler';
export const PREFILL_ERROR_CARRIER_ID = 'PF01';
export const DARK_GREEN_THEME = {
  label: 'Dark Green',
  value: 'darkgreen',
};
export const USE_EXTERNAL_APPOINTMENTS = {
  label: 'Allow external appointments',
  key: 'use_external_appointments',
};
export const CARRIER_TIMEOUT_MESSAGE_ENABLED = {
  label: 'Carrier Timeout Message Enabled',
  key: 'carrier_timeout_message_enabled',
};
export const DIC_POLICY = {
  LABEL: 'DIC Policy',
  YES: 'Y',
};
export const AUTOCOMPLETE = 'autocomplete';
export const TRUE = 'true';
export const FALSE = 'false';
export const FLOOD_DIRECT_CARRIERS = ['NGEN1001'];
export const PROPERTY_PREFILL_MESSAGE = {
  FAILED:
    'Unable to retrieve property information due to system unavailability. Please retry in a few minutes, or add the missing property and coverage information before proceeding to quote',
  INCOMPLETE:
    'Incomplete Property Information. Please add the missing property and coverage information before proceeding to quote',
  SUCCESS: 'We found property information for your property',
};
export const ENTER_PREVIOUS_ADDRESS = 'Enter Previous address';
export const MOVED_IN_PAST_MONTHS = {
  LABEL: 'Moved in past 6 months?',
  KEY: 'isMovedInPastMonths',
};
export const INVALID_PREVIOUS_ADDRESS =
  'Current and previous addresses are same, please enter different address';
