import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Card, CardContent } from '@material-ui/core';
import { isEmpty } from 'lodash';
import config from '../../../config/config';
import {
  fetchFloodQuoteDetails,
  questionnaireSubmitLoader,
  setFloodQuotes,
  setLineOfBusiness,
} from '../../../pages/redux/actions';
import ThemesImages from '../../../pages/components/themes-images';
import { INSURANCE_PRODUCTS } from '../../../constants';
import quotesList from '../../../pages/components/product-rates/quotes-list';
import FloodQuestionnaire from '../../../pages/components/flood-questionnaire';
import { ReduxRootState } from '../../../pages/redux/store';

const FloodDetails = ({
  onNextTab,
  onPrevTab,
  currentIndex,
  setLineOfBusiness,
  fetchFloodQuoteDetails,
  floodDetails,
  setFloodQuotes,
  questionnaireSubmitLoader,
  home,
  goToLastTab,
  redirectToStep,
}: AppComponents.FloodFormProps) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    setLineOfBusiness(INSURANCE_PRODUCTS[2]);
  }, [setLineOfBusiness]);
  const { pconfig } = home;
  const { detailsLoader } = floodDetails;
  const themeType = !!pconfig?.dale_config?.branding_on_dale_details?.template_name
    ? pconfig?.dale_config?.branding_on_dale_details?.template_name
    : config.hippo.template_name;
  useEffect(() => {
    const { annexPrefill, detailsLoader, floodRequestSuccess } = floodDetails;
    if (isEmpty(annexPrefill) && !detailsLoader) {
      fetchFloodQuoteDetails();
    }
    if (!floodRequestSuccess && quotesList.length) {
      setFloodQuotes({ quoteList: [], quoteErrorList: [], upcomingCarrierList: [] });
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {!detailsLoader ? (
        <>
          <section className='result-wrapper position-relative flood-details-wrapper'>
            <div className='container'>
              <div className='row'>
                <div className='col-lg-12'>
                  <Card elevation={0} className='applicant-home-form'>
                    <CardContent>
                      <FloodQuestionnaire
                        onNextTab={onNextTab}
                        onPrevTab={onPrevTab}
                        currentIndex={currentIndex}
                        goToLastTab={goToLastTab}
                        redirectToStep={redirectToStep}
                      />
                    </CardContent>
                  </Card>
                </div>
              </div>
            </div>
          </section>
        </>
      ) : (
        <>
          <div className='launch-loader-wrapper row justify-content-center align-items-start'>
            <img
              className='launch-loader-border'
              src={ThemesImages[themeType].floodInfoLoader}
              alt='Please wait...'
            />
          </div>
        </>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch: any): AppComponents.FloodFormDispatch =>
  bindActionCreators(
    { setLineOfBusiness, fetchFloodQuoteDetails, setFloodQuotes, questionnaireSubmitLoader },
    dispatch
  );

const mapStateToProps = ({ floodDetails, home }: ReduxRootState) => ({
  floodDetails,
  home,
});

export default connect(mapStateToProps, mapDispatchToProps)(FloodDetails);
