import React from 'react';
import {
  Backdrop,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Input,
  IconButton,
  InputLabel,
  NativeSelect,
  Radio,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import OpenInNewOutlinedIcon from '@material-ui/icons/OpenInNewOutlined';
import { forEach, get, has, isEmpty, map, startCase } from 'lodash';
import NumberFormatCustom from '../currency-mask';
import AutoComplete from '../../components/auto-complete';
import DatePicker from '../../components/date-picker';
import DropDownAutocomplete from '../../components/drop-down-autocomplete';
import InsuredDetailsSearch from '../../components/insured-details-search';
import InsuredDetailsDropdown from '../../components/insured-details-dropdown';
import SpinnerLoader from '../../components/spinner-loader';
import PhoneMask from '../../components/phone-mask';
import { CommonUploadFile } from '../ams-details-common-component';
import {
  amsProductList,
  enableAMSFormField,
  formatPhoneNumberWithBrackets,
  getApplicantDetails,
  getSalesforceCustomerUUID,
  openUrlInNewTab,
} from '../../../utils';
import {
  getAmsSignatureForm,
  getIsShowSl2OrDueDiligenceForm,
  isShowCarrierRiskConsentCheckbox,
} from '../../../utils/ams-details';
import {
  ADDRESS,
  ASSISTED_BY,
  ASTERISK,
  BROKER_CODE_KEY,
  BROKER,
  CUSTOM_ID,
  LOAN_NUMBER_LABEL,
  LOAN_NUMBER_KEY,
  BUSINESS_LINE,
  CANCEL,
  CARRIER,
  CARRIER_WRITING_COMPANY,
  CITY,
  CLEAR,
  CREATE_NEW_CUSTOMER,
  DATE_OF_BIRTH,
  DEPARTMENT,
  DESCRIPTION,
  DIVISION,
  DOB,
  EFFECTIVE_DATE,
  EFFECTIVE_DATE_KEY,
  EMAIL,
  EMAIL_ADDRESS,
  EMPLOYEE,
  EXECUTIVE,
  FIRST_NAME,
  FIRST_NAME_KEY,
  FULL_NAME,
  GROUP,
  INPUT_PROPS,
  INSURANCE_PRODUCTS,
  INSURED,
  LAST_NAME,
  LAST_NAME_KEY,
  LOB_KEY,
  MD,
  NAME,
  NEW,
  NONE,
  OTHER_PRODUCTS,
  PAGES_TEXT,
  PHONE_NUMBER_LABEL,
  PHONE_NUMBER_KEY,
  POLICY_NUMBER,
  POLICY_TERMS,
  PREMIUM,
  REQUIRED,
  PRODUCT,
  REPRESENTATIVE,
  SALESFORCE_CUSTOMER_ID,
  SELECT,
  SM,
  STATE,
  STREET_ADDRESS,
  SUBMIT,
  TERM,
  TEXT_TYPE,
  UNIT,
  ZIP,
  ZIP_CODE,
  SELECTED_CARRIER,
  AMS_FORM_FIELD,
  LOB_PRODUCTS_MAPPING,
  ADHOC_NON_ADMITTED_AMS_LOB_LIST,
} from '../../../constants';
import { NON_ADMITTED_AMS_CONSENT } from '../../../constants/non-admitted';
const { DUE_DILIGENCE_FORM_DOCUMENT, EOI_DOCUMENT, SL2_FORM_DOCUMENT } = AMS_FORM_FIELD;
const AMSDROPDOWNCLASS = 'ams-dropdown';

const addressFieldError = (errorText: string) => {
  return errorText.length > 0;
};
const addressErrorShow = (component: any, formErrors: any) => {
  const { classes } = component.props;
  return isEmpty(formErrors) ? (
    <div className={classes.errorText} />
  ) : (
    <div className={classes.errorText}>{formErrors}</div>
  );
};

const getApplicantDetailsFields = (component: any, formDetails: any, formDetailsErrors: any) => {
  const { classes, common } = component.props;
  return (
    <React.Fragment>
      {common.isLOBAMSDetails && component.state.newInsured && !component.state.existingUser ? (
        getApplicantAddressFields(component, formDetails, formDetailsErrors, common)
      ) : (
        <React.Fragment>
          <div className='row mb-4'>
            <div className='col-md-6 resp-mb-20'>
              <FormControl fullWidth disabled={common.AMSDetailsLoading}>
                <InputLabel shrink htmlFor={FULL_NAME}>
                  {startCase(NAME)}
                </InputLabel>
                <Input
                  inputProps={{
                    id: FULL_NAME,
                    name: FULL_NAME,
                    maxLength: 50,
                    autoComplete: NONE?.toLowerCase(),
                  }}
                  disabled={true}
                  value={formDetails.fullName}
                />
              </FormControl>
            </div>
            <div className='col-md-6 resp-mb-20'>
              <FormControl
                fullWidth
                disabled={
                  common.AMSDetailsLoading ||
                  (common.isLOBAMSDetails && !component.state.newInsured)
                }
              >
                <InputLabel
                  shrink
                  htmlFor={PHONE_NUMBER_KEY}
                  error={!isEmpty(formDetailsErrors.phoneNumberError)}
                >
                  {PHONE_NUMBER_LABEL}
                  {enableAMSFormField(PHONE_NUMBER_KEY) && (
                    <span className='star-error'>{ASTERISK}</span>
                  )}
                </InputLabel>
                <Input
                  inputProps={{
                    id: PHONE_NUMBER_KEY,
                    name: PHONE_NUMBER_KEY,
                    maxLength: 50,
                    autoComplete: NONE?.toLowerCase(),
                  }}
                  disabled={!enableAMSFormField(PHONE_NUMBER_KEY)}
                  value={formDetails.phoneNumber}
                  inputComponent={PhoneMask}
                  onChange={component.handlePersonalDetails}
                  onBlur={component.handlePersonalDetailsBlur}
                />
              </FormControl>
              <FormHelperText className={classes.fieldError}>
                {formDetailsErrors.phoneNumberError}
              </FormHelperText>
            </div>
          </div>
          <div className='row mb-4 address-wrap'>
            <div className='col-md-12 mb-20'>
              <div className='street '>
                <FormControl
                  fullWidth
                  disabled={
                    common.AMSDetailsLoading ||
                    (common.isLOBAMSDetails && !component.state.newInsured)
                  }
                >
                  <InputLabel
                    shrink
                    htmlFor={ADDRESS?.toLowerCase()}
                    error={!isEmpty(formDetailsErrors.addressError)}
                  >
                    {ADDRESS}
                    {enableAMSFormField(ADDRESS) && <span className='star-error'>{ASTERISK}</span>}
                  </InputLabel>
                  <Input
                    inputProps={{
                      id: ADDRESS?.toLowerCase(),
                      name: ADDRESS?.toLowerCase(),
                      maxLength: 150,
                      autoComplete: NONE?.toLowerCase(),
                    }}
                    onChange={component.onChangeAddressField}
                    disabled={!enableAMSFormField(ADDRESS)}
                    value={formDetails?.address || ''}
                  />
                </FormControl>
                <FormHelperText className={classes.fieldError}>
                  {formDetailsErrors.addressError}
                </FormHelperText>
              </div>
            </div>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const getApplicantAddressFields = (
  component: any,
  formDetails: any,
  formDetailsErrors: any,
  common: any
) => {
  const {
    addressDetails: { street, city, zip, unit, state },
  } = component.state.formDetails;
  const { classes, isQuoteAdhocBind } = component.props;

  const {
    personal_details: { date_of_birth },
  } = getApplicantDetails();

  return (
    <React.Fragment>
      <fieldset
        className={`create-policy-header-wrap ${
          common.isLOBAMSDetails && !component.state.newInsured ? 'labelDisabled' : ''
        }`}
        disabled={disableCarrierFields(common)}
      >
        <div className='row'>
          <div className='col-md-4 resp-mb-20'>
            <FormControl
              fullWidth
              disabled={common.AMSDetailsLoading}
              error={formDetailsErrors.firstNameError.length > 0}
            >
              <InputLabel shrink htmlFor={FIRST_NAME_KEY}>
                {FIRST_NAME}
                <span className='star-error'>{ASTERISK}</span>
              </InputLabel>
              <Input
                inputProps={{
                  name: FIRST_NAME_KEY,
                  id: FIRST_NAME_KEY,
                  spellCheck: false,
                  maxLength: 50,
                  autoComplete: NONE?.toLowerCase(),
                }}
                disabled={common.isLOBAMSDetails && !isQuoteAdhocBind ? false : true}
                value={formDetails.firstName}
                onChange={component.handlePersonalDetails}
                onBlur={component.handlePersonalDetailsBlur}
              />
            </FormControl>
            <FormHelperText className={classes.fieldError}>
              {formDetailsErrors.firstNameError}
            </FormHelperText>
          </div>
          <div className='col-md-4 resp-mb-20'>
            <FormControl
              fullWidth
              disabled={common.AMSDetailsLoading}
              error={formDetailsErrors.lastNameError.length > 0}
            >
              <InputLabel shrink htmlFor={LAST_NAME_KEY}>
                {LAST_NAME}
                <span className='star-error'>{ASTERISK}</span>
              </InputLabel>
              <Input
                inputProps={{
                  name: LAST_NAME_KEY,
                  id: LAST_NAME_KEY,
                  spellCheck: false,
                  maxLength: 20,
                  autoComplete: NONE?.toLowerCase(),
                }}
                disabled={common.isLOBAMSDetails && !isQuoteAdhocBind ? false : true}
                value={formDetails.lastName}
                onChange={component.handlePersonalDetails}
                onBlur={component.handlePersonalDetailsBlur}
              />
            </FormControl>
            <FormHelperText className={classes.fieldError}>
              {formDetailsErrors.lastNameError}
            </FormHelperText>
          </div>
          <div className='col-md-4 resp-mb-20'>
            <FormControl
              fullWidth
              disabled={common.AMSDetailsLoading}
              error={formDetailsErrors.phoneNumberError.length > 0}
            >
              <InputLabel shrink htmlFor={PHONE_NUMBER_KEY}>
                {PHONE_NUMBER_LABEL}
                <span className='star-error'>{ASTERISK}</span>
              </InputLabel>
              <Input
                inputProps={{
                  id: PHONE_NUMBER_KEY,
                  name: PHONE_NUMBER_KEY,
                  maxLength: 50,
                  autoComplete: NONE?.toLowerCase(),
                }}
                disabled={common.isLOBAMSDetails && !isQuoteAdhocBind ? false : true}
                value={formDetails.phoneNumber}
                inputComponent={PhoneMask}
                onChange={component.handlePersonalDetails}
              />
            </FormControl>
            <FormHelperText className={classes.fieldError}>
              {formDetailsErrors.phoneNumberError}
            </FormHelperText>
          </div>
        </div>

        <div className='row'>
          <div className='col-md-6 resp-mb-20'>
            <FormControl fullWidth>
              <TextField
                error={formDetailsErrors.emailError.length > 0}
                type={TEXT_TYPE}
                label={
                  <>
                    {EMAIL_ADDRESS}
                    <span className='star-error'>{ASTERISK}</span>
                  </>
                }
                autoComplete='off'
                className={`${classes.textField} text-margin-class`}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  name: EMAIL,
                  id: EMAIL,
                  spellCheck: false,
                  maxLength: 50,
                  autoComplete: NONE?.toLowerCase(),
                }}
                value={formDetails.email}
                onChange={component.handlePersonalDetails}
                disabled={!isQuoteAdhocBind ? false : true}
              />
            </FormControl>
            <FormHelperText className={classes.fieldError}>
              {formDetailsErrors.emailError}
            </FormHelperText>
          </div>
          <div className='col-md-6 resp-mb-20'>
            <FormControl fullWidth>
              <DatePicker
                styles={{
                  marginBottom: '0px !important',
                  marginTop: '0px !important',
                }}
                label={
                  <>
                    {DATE_OF_BIRTH}
                    <span className='star-error'>{ASTERISK}</span>
                  </>
                }
                name={DOB}
                onChange={component.handleDateOfBirth}
                dateValue={formDetails.dob}
                error={formDetailsErrors.dobError.length > 0 ? true : false}
                disabled={common.AMSDetailsLoading || (isQuoteAdhocBind && !isEmpty(date_of_birth))}
              />
            </FormControl>
            <FormHelperText className={classes.fieldError}>
              {formDetailsErrors.dobError}
            </FormHelperText>
          </div>
        </div>
        <div className='address-container mb-3'>
          <div className='row mb-4'>
            <div className='col-md-8 resp-mb-20'>
              <div className='street '>
                <AutoComplete
                  value={street}
                  onChange={component.handleHomeAddressDetailsChange}
                  onAutoCompleteAddressSelected={component.onAddressSelected}
                  onInputBlur={component.handleAddressBlur}
                  clearAutoCompleteInput={component.clearAddressInputs}
                  error={addressFieldError(formDetailsErrors.street)}
                  title={
                    <>
                      {STREET_ADDRESS}
                      <span className='star-error'>{ASTERISK}</span>
                    </>
                  }
                  disabled={!!isQuoteAdhocBind}
                />
                {addressErrorShow(component, formDetailsErrors.street)}
              </div>
            </div>

            <div className='col-md-4 resp-mb-20'>
              <div className='unit'>
                <TextField
                  type={TEXT_TYPE}
                  label={`${UNIT} #`}
                  autoComplete='off'
                  className={classes.addressTextField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    name: UNIT?.toLowerCase(),
                    id: UNIT?.toLowerCase(),
                    spellCheck: false,
                    maxLength: 50,
                    autoComplete: NONE?.toLowerCase(),
                  }}
                  error={formDetailsErrors.unit.length > 0}
                  value={unit}
                  onChange={component.handleHomeAddressDetailsChange}
                  onBlur={component.handleAddressBlur}
                  disabled={!!isQuoteAdhocBind}
                />
                <div className={classes.errorText}>{formDetailsErrors.unit}</div>
              </div>
            </div>
          </div>

          <div className='row'>
            <div className='city col-md-4 resp-mb-20'>
              <TextField
                type={TEXT_TYPE}
                label={
                  <>
                    {CITY}
                    <span className='star-error'>{ASTERISK}</span>
                  </>
                }
                autoComplete='off'
                className={classes.addressTextField}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  id: CITY?.toLowerCase(),
                  name: CITY?.toLowerCase(),
                  spellCheck: false,
                  maxLength: 50,
                  autoComplete: NONE?.toLowerCase(),
                }}
                value={city}
                onChange={component.handleHomeAddressDetailsChange}
                onBlur={component.handleAddressBlur}
                error={addressFieldError(formDetailsErrors.city)}
                disabled={!!isQuoteAdhocBind}
              />
              {addressErrorShow(component, formDetailsErrors.city)}
            </div>
            <div className='state col-md-4 resp-mb-20'>
              <FormControl
                className={`${classes.formControl}`}
                error={addressFieldError(formDetailsErrors.state)}
              >
                <InputLabel shrink htmlFor='state-simple'>
                  {STATE}
                  <span className='star-error'>{ASTERISK}</span>
                </InputLabel>
                <NativeSelect
                  fullWidth
                  className='state-class'
                  value={state}
                  onChange={component.handleHomeAddressDetailsChange}
                  disabled={!!isQuoteAdhocBind}
                  inputProps={{
                    name: 'state',
                    id: 'state-simple',
                    disabled: !!isQuoteAdhocBind,
                  }}
                >
                  <option value=''>{startCase(SELECT)}</option>

                  {component.state.stateName.map((stateName: any) => (
                    <option key={stateName.label} value={stateName.value}>
                      {stateName.value}
                    </option>
                  ))}
                </NativeSelect>
              </FormControl>

              {addressErrorShow(component, formDetailsErrors.state)}
            </div>
            <div className='zip col-md-4  resp-mb-20'>
              <TextField
                type={TEXT_TYPE}
                label={
                  <>
                    {ZIP_CODE}
                    <span className='star-error'>{ASTERISK}</span>
                  </>
                }
                autoComplete='off'
                className={classes.addressTextField}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  id: ZIP,
                  name: ZIP,
                  autoComplete: NONE?.toLowerCase(),
                }}
                value={zip}
                onChange={component.handleHomeAddressDetailsChange}
                error={addressFieldError(formDetailsErrors.zip)}
                disabled={!!isQuoteAdhocBind}
              />
              {addressErrorShow(component, formDetailsErrors.zip)}
            </div>
          </div>
        </div>
      </fieldset>{' '}
    </React.Fragment>
  );
};

const getDropdownOptions = (list: any, codeKey: any) => {
  return map(list, (details: any, key: string) => (
    <option value={details[codeKey]} key={`${details.ShortName}-${key}`}>
      {has(details, startCase(NAME)) ? details.Name : `${details.FirstName} ${details.LastName}`}
    </option>
  ));
};

const getRepresentativeAndGroupFields = (
  component: any,
  formDetails: any,
  formDetailsErrors: any
) => {
  const { classes, common } = component.props;
  return (
    <div className='row mb-4'>
      <div className='col-md-6 resp-mb-20'>
        <FormControl
          fullWidth
          error={formDetailsErrors.representativeError.length > 0}
          disabled={
            common.AMSDetailsLoading || (common.isLOBAMSDetails && !component.state.newInsured)
          }
        >
          <InputLabel shrink htmlFor={REPRESENTATIVE?.toLowerCase()}>
            {REPRESENTATIVE}
            <span className='star-error'>{ASTERISK}</span>
          </InputLabel>
          <Select
            native
            inputProps={{
              id: REPRESENTATIVE?.toLowerCase(),
              name: REPRESENTATIVE?.toLowerCase(),
            }}
            classes={{ root: AMSDROPDOWNCLASS }}
            value={formDetails.representative}
            onChange={component.handleInputChange}
          >
            <option value='' key='representative-empty'>
              {startCase(SELECT)}
            </option>
            {getDropdownOptions(formDetails.representativeOptions, 'EmployeeCode')}
          </Select>
        </FormControl>
        <FormHelperText className={classes.fieldError}>
          {formDetailsErrors.representativeError}
        </FormHelperText>
      </div>
      <div className='col-md-6 resp-mb-20'>
        <FormControl
          fullWidth
          error={formDetailsErrors.groupError.length > 0}
          disabled={
            common.AMSDetailsLoading || (common.isLOBAMSDetails && !component.state.newInsured)
          }
        >
          <InputLabel shrink htmlFor={GROUP?.toLowerCase()}>
            {GROUP}
            <span className='star-error'>{ASTERISK}</span>
          </InputLabel>
          <Select
            native
            inputProps={{
              id: GROUP?.toLowerCase(),
              name: GROUP?.toLowerCase(),
            }}
            classes={{ root: AMSDROPDOWNCLASS }}
            value={formDetails.group}
            onChange={component.handleInputChange}
          >
            <option value='' key='group-empty'>
              {startCase(SELECT)}
            </option>
            {getDropdownOptions(formDetails.groupOptions, 'GLGroupCode')}
          </Select>
        </FormControl>
        <FormHelperText className={classes.fieldError}>
          {formDetailsErrors.groupError}
        </FormHelperText>
      </div>
    </div>
  );
};

const getDepartmentAndEmployeeFields = (
  component: any,
  formDetails: any,
  formDetailsErrors: any
) => {
  const { classes, common } = component.props;
  return (
    <div className='row mb-4'>
      <div className='col-md-6 resp-mb-20'>
        <FormControl
          fullWidth
          error={formDetailsErrors.departmentError.length > 0}
          disabled={
            common.AMSDetailsLoading || (common.isLOBAMSDetails && !component.state.newInsured)
          }
        >
          <InputLabel shrink htmlFor={DEPARTMENT?.toLowerCase()}>
            {DEPARTMENT}
            <span className='star-error'>{ASTERISK}</span>
          </InputLabel>
          <Select
            native
            inputProps={{
              id: DEPARTMENT?.toLowerCase(),
              name: DEPARTMENT?.toLowerCase(),
            }}
            classes={{ root: AMSDROPDOWNCLASS }}
            value={formDetails.department}
            onChange={component.handleInputChange}
          >
            <option value='' key='department-empty'>
              {startCase(SELECT)}
            </option>
            {getDropdownOptions(formDetails.departmentOptions, 'GLDepartmentCode')}
          </Select>
        </FormControl>
        <FormHelperText className={classes.fieldError}>
          {formDetailsErrors.departmentError}
        </FormHelperText>
      </div>
      <div className='col-md-6 resp-mb-20'>
        <FormControl
          fullWidth
          error={formDetailsErrors.employeeError.length > 0}
          disabled={
            common.AMSDetailsLoading || (common.isLOBAMSDetails && !component.state.newInsured)
          }
        >
          <InputLabel shrink htmlFor={EMPLOYEE?.toLowerCase()}>
            {EMPLOYEE}
            <span className='star-error'>{ASTERISK}</span>
          </InputLabel>
          <Select
            native
            inputProps={{
              id: EMPLOYEE?.toLowerCase(),
              name: EMPLOYEE?.toLowerCase(),
            }}
            classes={{ root: AMSDROPDOWNCLASS }}
            value={formDetails.employee}
            onChange={component.handleInputChange}
          >
            <option value='' key='employee-empty'>
              {startCase(SELECT)}
            </option>
            {getDropdownOptions(formDetails.employeeOptions, 'EmployeeCode')}
          </Select>
        </FormControl>
        <FormHelperText className={classes.fieldError}>
          {formDetailsErrors.employeeError}
        </FormHelperText>
      </div>
    </div>
  );
};

const getBrokerField = (component: any, formDetails: any, formDetailsErrors: any) => {
  const { classes, common } = component.props;
  const {
    eoiFileName,
    isEoiFileUploadEnabled,
    isShowLoanNumberField,
    sl2FormFileName,
    dueDiigenceFormFileName,
  } = component.state;
  const isLoading =
    common.AMSDetailsLoading || (common.isLOBAMSDetails && !component.state.newInsured) || false;
  const salesforceCustomerUUID: string = getSalesforceCustomerUUID(common?.AMSDetails);
  const nonAdmitedDocumentName = getIsShowSl2OrDueDiligenceForm(formDetails);
  return (
    <div className='row mb-4'>
      <div className='col-md-6 resp-mb-20'>
        <FormControl
          fullWidth
          error={formDetailsErrors.brokerError.length > 0}
          disabled={isLoading}
        >
          <InputLabel shrink htmlFor={BROKER?.toLowerCase()}>
            {BROKER}
            <span className='star-error'>{ASTERISK}</span>
          </InputLabel>
          <Select
            native
            inputProps={{
              id: BROKER?.toLowerCase(),
              name: BROKER?.toLowerCase(),
            }}
            classes={{ root: AMSDROPDOWNCLASS }}
            value={formDetails.broker}
            onChange={component.handleInputChange}
          >
            <option value='' key='broker-empty'>
              {startCase(SELECT)}
            </option>
            {getDropdownOptions(formDetails.brokerOptions, BROKER_CODE_KEY)}
          </Select>
        </FormControl>
        <FormHelperText className={classes.fieldError}>
          {formDetailsErrors.brokerError}
        </FormHelperText>
      </div>
      {isEoiFileUploadEnabled && (
        <CommonUploadFile
          label={EOI_DOCUMENT.LABEL}
          name={EOI_DOCUMENT.KEY}
          fileName={eoiFileName}
          error={formDetailsErrors?.eoiFileError}
          isLoading={!!isLoading}
          onChange={component.onChangeDocumentModel}
          onClear={component.onClearAmsDocumentUpload}
          className={classes.fieldError}
        />
      )}
      <div className={`${isEoiFileUploadEnabled ? 'custom-id-res' : ''} col-md-6 resp-mb-20`}>
        <FormControl fullWidth error={formDetailsErrors.customId.length > 0} disabled={isLoading}>
          <InputLabel shrink htmlFor={CUSTOM_ID.KEY?.toLowerCase()}>
            {CUSTOM_ID.LABEL}
          </InputLabel>
          <Input
            inputProps={{
              id: CUSTOM_ID.KEY,
              name: CUSTOM_ID.KEY,
              maxLength: 50,
              autoComplete: NONE?.toLowerCase(),
            }}
            value={formDetails.customId}
            onChange={component.handleInputChange}
          />
        </FormControl>
        <FormHelperText className={classes.fieldError}>{formDetailsErrors.customId}</FormHelperText>
      </div>
      {isShowLoanNumberField && (
        <div className='col-md-6 custom-id-res resp-mb-20'>
          <FormControl
            fullWidth
            error={formDetailsErrors.loanNumber.length > 0}
            disabled={isLoading}
          >
            <InputLabel shrink htmlFor={LOAN_NUMBER_LABEL?.toLowerCase()}>
              {LOAN_NUMBER_LABEL}
            </InputLabel>
            <Input
              inputProps={{
                id: LOAN_NUMBER_KEY,
                name: LOAN_NUMBER_KEY,
                maxLength: 50,
                autoComplete: NONE?.toLowerCase(),
              }}
              value={formDetails.loanNumber}
              onChange={component.handleInputChange}
            />
          </FormControl>
          <FormHelperText className={classes.fieldError}>
            {formDetailsErrors.loanNumber}
          </FormHelperText>
        </div>
      )}
      <div className='col-md-6 custom-id-res resp-mb-20'>
        <FormControl fullWidth disabled={isLoading}>
          <InputLabel shrink htmlFor={ASSISTED_BY.KEY}>
            {ASSISTED_BY.LABEL}
          </InputLabel>
          <Select
            native
            inputProps={{
              id: ASSISTED_BY.KEY,
              name: ASSISTED_BY.KEY,
            }}
            classes={{ root: AMSDROPDOWNCLASS }}
            value={formDetails.assistedBy}
            onChange={component.handleInputChange}
          >
            <option value='' key={ASSISTED_BY.KEY}>
              {startCase(SELECT)}
            </option>
            {getDropdownOptions(formDetails.brokerOptions, BROKER_CODE_KEY)}
          </Select>
        </FormControl>
      </div>
      <div className='col-md-6 custom-id-res resp-mb-20'>
        <FormControl
          fullWidth
          disabled={isLoading || !isEmpty(salesforceCustomerUUID)}
          error={!isEmpty(formDetailsErrors?.salesforceCustomerUUIDErr)}
        >
          <InputLabel shrink htmlFor={SALESFORCE_CUSTOMER_ID.KEY?.toLowerCase()}>
            {SALESFORCE_CUSTOMER_ID.LABEL}
          </InputLabel>
          <Input
            inputProps={{
              id: SALESFORCE_CUSTOMER_ID.KEY,
              name: SALESFORCE_CUSTOMER_ID.KEY,
              maxLength: SALESFORCE_CUSTOMER_ID.MAX_LENGTH,
              autoComplete: NONE?.toLowerCase(),
            }}
            value={formDetails.salesforceCustomerUUID}
            onChange={component.handleInputChange}
            disabled={!isEmpty(salesforceCustomerUUID) || isLoading}
          />
        </FormControl>
        <FormHelperText className={classes.fieldError}>
          {formDetailsErrors?.salesforceCustomerUUIDErr}
        </FormHelperText>
      </div>
      {nonAdmitedDocumentName === SL2_FORM_DOCUMENT.KEY && (
        <>
          <CommonUploadFile
            label={SL2_FORM_DOCUMENT.LABEL}
            name={SL2_FORM_DOCUMENT.KEY}
            fileName={sl2FormFileName}
            error={formDetailsErrors?.sl2FormFileNameError}
            isLoading={!!isLoading}
            onChange={component.onChangeDocumentModel}
            onClear={component.onClearAmsDocumentUpload}
            className={classes.fieldError}
          />
          {!isEmpty(getAmsSignatureForm(SL2_FORM_DOCUMENT.KEY)) && (
            <div className='col-md-6 Res-display resp-mb-20 mt-4'>
              <FormControl fullWidth disabled={isLoading}>
                <InputLabel shrink htmlFor={SL2_FORM_DOCUMENT.SIGNATURE_LABEL}>
                  {SL2_FORM_DOCUMENT.SIGNATURE_LABEL}
                </InputLabel>
              </FormControl>
              <div
                className='d-flex justify-content-center'
                title={SL2_FORM_DOCUMENT.SIGNATURE_TOOLTIP}
              >
                <OpenInNewOutlinedIcon
                  className={`publish-icon ${isLoading ? 'cursor-default' : 'cursor-pointer'}`}
                  onClick={() =>
                    !isLoading ? openUrlInNewTab(getAmsSignatureForm(SL2_FORM_DOCUMENT.KEY)) : ''
                  }
                />
              </div>
            </div>
          )}
        </>
      )}
      {nonAdmitedDocumentName === DUE_DILIGENCE_FORM_DOCUMENT.KEY && (
        <>
          <CommonUploadFile
            label={DUE_DILIGENCE_FORM_DOCUMENT.LABEL}
            name={DUE_DILIGENCE_FORM_DOCUMENT.KEY}
            fileName={dueDiigenceFormFileName}
            error={formDetailsErrors?.dueDiigenceFormFileNameError}
            isLoading={!!isLoading}
            onChange={component.onChangeDocumentModel}
            onClear={component.onClearAmsDocumentUpload}
            className={classes.fieldError}
          />
          {!isEmpty(getAmsSignatureForm(DUE_DILIGENCE_FORM_DOCUMENT.KEY)) && (
            <div className='col-md-6 Res-display resp-mb-20 mt-4'>
              <FormControl fullWidth disabled={isLoading}>
                <InputLabel shrink htmlFor={DUE_DILIGENCE_FORM_DOCUMENT.SIGNATURE_LABEL}>
                  {DUE_DILIGENCE_FORM_DOCUMENT.SIGNATURE_LABEL}
                </InputLabel>
              </FormControl>
              <div
                className='d-flex justify-content-center'
                title={DUE_DILIGENCE_FORM_DOCUMENT.SIGNATURE_TOOLTIP}
              >
                <OpenInNewOutlinedIcon
                  className={`publish-icon ${isLoading ? 'cursor-default' : 'cursor-pointer'}`}
                  onClick={() =>
                    !isLoading
                      ? openUrlInNewTab(getAmsSignatureForm(DUE_DILIGENCE_FORM_DOCUMENT.KEY))
                      : ''
                  }
                />
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

const getAMSDetailsFields = (component: any, formDetails: any, formDetailsErrors: any) => {
  const { classes, common } = component.props;
  return (
    <React.Fragment>
      <div className='row mb-4'>
        <div className='col-md-6 resp-mb-20'>
          <FormControl
            fullWidth
            error={formDetailsErrors.divisionError.length > 0}
            disabled={
              common.AMSDetailsLoading ||
              component.props.common.disableDivision ||
              (common.isLOBAMSDetails && !component.state.newInsured)
            }
          >
            <InputLabel shrink htmlFor={DIVISION?.toLowerCase()}>
              {DIVISION}
              <span className='star-error'>{ASTERISK}</span>
            </InputLabel>
            <Select
              native
              inputProps={{
                id: DIVISION?.toLowerCase(),
                name: DIVISION?.toLowerCase(),
              }}
              classes={{ root: AMSDROPDOWNCLASS }}
              value={formDetails.division}
              onChange={component.handleInputChange}
            >
              <option value='' key='division-empty'>
                {startCase(SELECT)}
              </option>
              {getDropdownOptions(formDetails.divisionOptions, 'GLDivisionCode')}
            </Select>
          </FormControl>
          <FormHelperText className={classes.fieldError}>
            {formDetailsErrors.divisionError}
          </FormHelperText>
        </div>
        <div className='col-md-6 resp-mb-20'>
          <FormControl
            fullWidth
            error={formDetailsErrors.executiveError.length > 0}
            disabled={
              common.AMSDetailsLoading || (common.isLOBAMSDetails && !component.state.newInsured)
            }
          >
            <InputLabel shrink htmlFor={EXECUTIVE?.toLowerCase()}>
              {EXECUTIVE}
              <span className='star-error'>{ASTERISK}</span>
            </InputLabel>
            <Select
              native
              inputProps={{
                id: EXECUTIVE?.toLowerCase(),
                name: EXECUTIVE?.toLowerCase(),
              }}
              classes={{ root: AMSDROPDOWNCLASS }}
              value={formDetails.executive}
              onChange={component.handleInputChange}
            >
              <option value='' key='executive-empty'>
                {startCase(SELECT)}
              </option>
              {getDropdownOptions(formDetails.executiveOptions, 'EmployeeCode')}
            </Select>
          </FormControl>
          <FormHelperText className={classes.fieldError}>
            {formDetailsErrors.executiveError}
          </FormHelperText>
        </div>
      </div>
      {getRepresentativeAndGroupFields(component, formDetails, formDetailsErrors)}

      {getDepartmentAndEmployeeFields(component, formDetails, formDetailsErrors)}

      {getBrokerField(component, formDetails, formDetailsErrors)}
    </React.Fragment>
  );
};

const disableCarrierFields = (common: any) =>
  common.AMSDetailsLoading || common.carrierUnderwriterLoader;
const getCarrierFields = (component: any, formDetails: any, formDetailsErrors: any) => {
  const { classes, common } = component.props;
  return (
    <React.Fragment>
      <div className='row mb-4'>
        {common.isLOBAMSDetails ? (
          <div className='col-md-6 resp-mb-20'>
            <DropDownAutocomplete
              isRequired={true}
              value={formDetails.selectedCarrier}
              onChange={event => {
                component.dropDownValuesUpdated(event, SELECTED_CARRIER);
              }}
              recordsList={getDropdownValues(formDetails.carrierOptions, CARRIER)}
              disabled={!component.state.newInsured || disableCarrierFields(common)}
              label={CARRIER}
              error={formDetailsErrors.carrierNameError.length > 0}
            />
            <FormHelperText className={classes.fieldError}>
              {formDetailsErrors.carrierNameError}
            </FormHelperText>
          </div>
        ) : (
          <div className='col-md-6 resp-mb-20'>
            <FormControl
              fullWidth
              error={formDetailsErrors.carrierNameError.length > 0}
              disabled={disableCarrierFields(common)}
            >
              <InputLabel shrink htmlFor={INPUT_PROPS.CARRIER_ID}>
                {CARRIER}
                <span className='star-error'>{ASTERISK}</span>
              </InputLabel>
              <Select
                native
                inputProps={{
                  id: INPUT_PROPS.CARRIER_ID,
                  name: INPUT_PROPS.CARRIER_ID,
                }}
                classes={{ root: AMSDROPDOWNCLASS }}
                value={formDetails.carrierId}
                onChange={component.onCarrierSelected}
              >
                <option value=''>{startCase(SELECT)}</option>

                {component.getCarrierOptions()}
              </Select>
            </FormControl>
            <FormHelperText className={classes.fieldError}>
              {formDetailsErrors.carrierNameError}
            </FormHelperText>
          </div>
        )}
        <div className='col-md-6 resp-mb-20'>
          <FormControl
            fullWidth
            error={formDetailsErrors.policyNumberError.length > 0}
            disabled={
              common.AMSDetailsLoading || (common.isLOBAMSDetails && !component.state.newInsured)
            }
          >
            <InputLabel shrink htmlFor='policyNumber'>
              {POLICY_NUMBER}
              <span className='star-error'>{ASTERISK}</span>
            </InputLabel>
            <Input
              inputProps={{
                id: 'policyNumber',
                name: 'policyNumber',
                maxLength: 25,
                autoComplete: NONE?.toLowerCase(),
              }}
              value={formDetails.policyNumber}
              onChange={component.handleInputChange}
            />
          </FormControl>
          <FormHelperText className={classes.fieldError}>
            {formDetailsErrors.policyNumberError}
          </FormHelperText>
        </div>
      </div>
      <div
        className={`row mb-4 ${
          formDetailsErrors.premiumError &&
          formDetailsErrors.premiumError.toLowerCase() !== REQUIRED.toLowerCase() &&
          'pb-3'
        }`}
      >
        <div className='col-md-3 resp-mb-20'>
          <FormControl fullWidth error={formDetailsErrors.premiumError.length > 0}>
            <InputLabel shrink htmlFor={PREMIUM?.toLowerCase()}>
              {PREMIUM}
              <span className='star-error'>{ASTERISK}</span>
            </InputLabel>
            <Input
              inputProps={{
                id: PREMIUM?.toLowerCase(),
                name: PREMIUM?.toLowerCase(),
                maxLength: 12,
                decimalScale: 2,
                autoComplete: NONE?.toLowerCase(),
              }}
              disabled={
                disableCarrierFields(common) ||
                (common.isLOBAMSDetails && !component.state.newInsured)
              }
              value={formDetails.premium}
              inputComponent={NumberFormatCustom as any}
              onChange={component.handleInputChange}
            />
          </FormControl>
          <FormHelperText className={classes.fieldError}>
            {formDetailsErrors.premiumError}
          </FormHelperText>
        </div>
        <div className='col-md-3 resp-mb-20'>
          <FormControl
            fullWidth
            error={formDetailsErrors.termError.length > 0}
            disabled={
              (common.isLOBAMSDetails && !component.state.newInsured) ||
              disableCarrierFields(common)
            }
          >
            <InputLabel shrink htmlFor={TERM?.toLowerCase()}>
              {TERM}
              <span className='star-error'>{ASTERISK}</span>
            </InputLabel>
            <Select
              native
              inputProps={{
                id: TERM?.toLowerCase(),
                name: TERM?.toLowerCase(),
              }}
              classes={{ root: AMSDROPDOWNCLASS }}
              value={formDetails.term}
              onChange={component.onPremiumTermSelected}
            >
              <option value=''>{startCase(SELECT)}</option>
              {map(POLICY_TERMS, termDetails => (
                <option key={termDetails.label} value={termDetails.value}>
                  {termDetails.label}
                </option>
              ))}
            </Select>
          </FormControl>
          <FormHelperText className={classes.fieldError}>
            {formDetailsErrors.termError}
          </FormHelperText>
        </div>
        <div className='col-md-6 resp-mb-20'>
          <FormControl
            fullWidth
            error={formDetailsErrors.carrierUnderwriterError.length > 0}
            disabled={
              formDetails.carrierUnderwriterOptions.length === 0 ||
              disableCarrierFields(common) ||
              (common.isLOBAMSDetails && !component.state.newInsured)
            }
          >
            <InputLabel shrink htmlFor='carrierUnderwriter'>
              {CARRIER_WRITING_COMPANY}
              <span className='star-error'>{ASTERISK}</span>
            </InputLabel>
            <Select
              native
              inputProps={{
                id: 'carrierUnderwriter',
                name: 'carrierUnderwriter',
              }}
              classes={{ root: AMSDROPDOWNCLASS }}
              value={formDetails.carrierUnderwriter}
              onChange={component.handleInputChange}
            >
              <option value='' key='carrierUnderwriter-empty'>
                Select
              </option>
              {getDropdownOptions(formDetails.carrierUnderwriterOptions, 'CompanyCode')}
            </Select>
          </FormControl>
          <FormHelperText className={classes.fieldError}>
            {formDetailsErrors.carrierUnderwriterError}
          </FormHelperText>
        </div>
      </div>
    </React.Fragment>
  );
};

const getSubmitButtonLoader = (common: any) =>
  common.AMSDetailsLoading || common.carrierUnderwriterLoader;

const getNavButtons = (component: any, validNumber = false, customerData: any) => {
  const { classes, common } = component.props;
  return (
    <div className={classes.buttonWrapper}>
      <Button
        variant='outlined'
        className='navigation-back-btn'
        disabled={getSubmitButtonLoader(common)}
        onClick={() => {
          validNumber ? component.handleClosePhoneNumberModal() : component.handleClose(false);
        }}
      >
        <span className='link-label'>{CANCEL}</span>
      </Button>
      <Button
        variant='outlined'
        className='navigation-next-btn ams-submit-btn'
        disabled={
          getSubmitButtonLoader(common) ||
          common.maxAttemptReached ||
          (common.isLOBAMSDetails && !component.state.newInsured) ||
          (validNumber && !component.state.formDetails.customerId)
        }
        onClick={
          validNumber
            ? () => {
                component.selectExistingCustomer(customerData);
              }
            : component.handleFormSubmit
        }
      >
        {SUBMIT}
        {getSubmitButtonLoader(common) && <SpinnerLoader styleData={classes.submitSpinner} />}
      </Button>
    </div>
  );
};

const getLOBOptions = (list: Array<any>, currentLob: string, ratePageTypes: any) => {
  return (
    <>
      {!isEmpty(list) ? (
        <>
          {list.map(item => (
            <option value={item} key={item}>
              {item.toUpperCase()}
            </option>
          ))}
        </>
      ) : (
        <>
          {currentLob === INSURANCE_PRODUCTS[1] && (
            <option value={INSURANCE_PRODUCTS[1]} key='auto-product-opt'>
              {INSURANCE_PRODUCTS[1].toUpperCase()}
            </option>
          )}
          {list.includes(INSURANCE_PRODUCTS[0]) && (
            <option value={INSURANCE_PRODUCTS[0]} key='home-product-opt'>
              {INSURANCE_PRODUCTS[0].toUpperCase()}
            </option>
          )}
          {OTHER_PRODUCTS.map(
            product =>
              list.includes(product.value) && (
                <option value={product.value} key={product.label}>
                  {product.value.toUpperCase()}
                </option>
              )
          )}
        </>
      )}
    </>
  );
};

const getDropdownValues = (list: Array<any>, key: string) => {
  const updatedArray: Array<any> = [];
  forEach(list, item => {
    key === CARRIER ? item && updatedArray.push(item) : item[key] && updatedArray.push(item[key]);
  });
  return updatedArray;
};

const table = (
  diferentCustomer: any,
  classes: any,
  handleRadioButtonChange: any,
  customerId: any,
  getHeadCells: any
) => {
  const headCells = getHeadCells();
  return (
    <div className='table-wrapper'>
      <Table classes={{ root: classes.table }}>
        <TableHead className={classes.tableHead}>
          <TableRow>
            {headCells.map((headCell: any) => (
              <TableCell className={classes.tableHeadRow} key={headCell.id}>
                {headCell.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {diferentCustomer?.map((customer: any) => (
            <TableRow
              key={customer.customerId}
              classes={{ root: classes.tableRow }}
              onClick={() => {
                handleRadioButtonChange(customer);
              }}
            >
              <TableCell className={classes.tableBodyRow}>
                <Radio
                  className='ams-radio-btn'
                  checked={customer.customerId === customerId}
                  name={customer.first_name}
                  color='default'
                />
              </TableCell>
              <TableCell>{get(customer, 'first_name', '-')}</TableCell>
              <TableCell>{get(customer, 'last_name', '-')}</TableCell>
              <TableCell className={classes.fieldWidth}>
                {`${customer.address.street.trim()},
                ${customer.address.city.trim()}, ${customer.address.state.trim()}, ${
                  customer.address.zip
                }`}
              </TableCell>
              <TableCell className={classes.fieldWidth}>
                {!isEmpty(get(customer, 'email', '')) ? customer.email : '-'}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

const body = (component: any) => {
  const { classes, common } = component.props;
  const { formDetails } = component.state;
  const { customerData } = common.AMSDetails;
  return (
    <div>
      <IconButton
        aria-label='close'
        className={classes.closeButton}
        onClick={() => {
          component.handleClosePhoneNumberModal();
        }}
        disabled={getSubmitButtonLoader(common)}
      >
        <CloseIcon fontSize='small' />
      </IconButton>

      <DialogContent classes={{ root: classes.dialogContent }}>
        <div className='row'>
          <div className='col-sm-12 mt-4'>
            <p className='modal-description'>
              An existing customer with phone number{' '}
              <span>
                <b className='modal-description__emphasis'>{`"${formatPhoneNumberWithBrackets(
                  formDetails.phoneNumber
                )}"`}</b>
              </span>{' '}
              was found.
              <br />
              Would you like to use an existing customer or proceed with creating a new customer?
            </p>
          </div>
        </div>
        <div className='row addInsured mb-4'>
          <div className='col-sm-12 textAlignCenter'>
            <Button
              variant='contained'
              className='btnAddInsured'
              onClick={() => {
                component.handleCreateNewCustomer();
              }}
              disabled={getSubmitButtonLoader(common)}
            >
              <AddIcon className='add-icon-border mr-2' />
              <span style={{ verticalAlign: 'middle' }}>{CREATE_NEW_CUSTOMER}</span>
            </Button>
          </div>
        </div>
        {table(
          customerData,
          classes,
          component.handleRadioButtonChange,
          formDetails.customerId,
          component.getHeadCells
        )}
      </DialogContent>
      <DialogActions className='action-button'>
        {getNavButtons(component, true, customerData)}
      </DialogActions>
    </div>
  );
};

const template = (component: any) => {
  const { classes, common, dashboard, ratePageTypes } = component.props;
  const { open, formDetails, formDetailsErrors, commonError, searchText, isAdhocHomeLob } =
    component?.state;
  const { openPopup } = !!common?.AMSDetails && common?.AMSDetails;
  const lobOptions = component.getCurrentLobs();
  const insuredDetailsSearchHandler = component?.insuredDetailsSearchHandler;
  const setInputCustomer = component?.setInputCustomer;
  const nonAdmittedConcent: string = [LOB_PRODUCTS_MAPPING.home, ...ADHOC_NON_ADMITTED_AMS_LOB_LIST].includes(
    formDetails?.businessLine?.toLowerCase()
  )
    ? NON_ADMITTED_AMS_CONSENT.HOME
    : NON_ADMITTED_AMS_CONSENT.FLOOD;
  return (
    <Dialog
      className='ams-modal'
      classes={{
        root: 'ams-dialog-container',
      }}
      fullWidth={true}
      maxWidth={SM}
      open={open}
      onClose={() => {
        component.handleClose(false);
      }}
      aria-labelledby='ams-dialog-title'
      disableBackdropClick={true}
      disableEscapeKeyDown={getSubmitButtonLoader(common)}
    >
      <DialogTitle
        id='ams-dialog-title'
        classes={{
          root: classes.title,
        }}
      >
        {PAGES_TEXT.AMSModalTitle}
        <hr className={classes.lineDivider}></hr>
        <IconButton
          aria-label='close'
          className={classes.closeButton}
          onClick={() => {
            component.handleClose(false);
          }}
          disabled={getSubmitButtonLoader(common)}
        >
          <CloseIcon fontSize='small' />
        </IconButton>
      </DialogTitle>
      <DialogContent classes={{ root: classes.dialogContent }}>
        {common.isLOBAMSDetails && !component.props.isQuoteAdhocBind && (
          <>
            <div className='row addInsured mb-4'>
              <div className='col-md-6 resp-mb-20'>
                <span>
                  <b>{INSURED}</b>
                </span>
                {component.state.newInsured ? (
                  <Button
                    variant='contained'
                    className='btnClearInsured'
                    onClick={() => {
                      component.clearData();
                    }}
                    disabled={getSubmitButtonLoader(common)}
                  >
                    <CloseIcon fontSize='small' />
                    <span style={{ verticalAlign: 'middle' }}>{CLEAR}</span>
                  </Button>
                ) : (
                  <Button
                    variant='contained'
                    className='btnAddInsured'
                    onClick={() => {
                      component.insuredChanged(true);
                    }}
                    disabled={getSubmitButtonLoader(common)}
                  >
                    <AddIcon className='add-icon-border mr-2' />
                    <span style={{ verticalAlign: 'middle' }}>{NEW}</span>
                  </Button>
                )}
              </div>
              {!component.state.newInsured ? (
                <>
                  <Backdrop
                    className='insured-backdrop'
                    open={dashboard.insuredDropDownLoader}
                    onClick={component.closeInsuredDropdown}
                  ></Backdrop>
                  <div className='col-xs-12 col-sm-12 col-md-6 new-bulk-quote'>
                    <InsuredDetailsSearch
                      handleSearch={insuredDetailsSearchHandler}
                      searchText={searchText}
                      disableSearch={getSubmitButtonLoader(common)}
                    />
                  </div>
                </>
              ) : (
                ''
              )}
            </div>
            {dashboard.insuredDropDownLoader && (
              <div className='row mb-4'>
                <div className='col-sm-12 '>
                  <InsuredDetailsDropdown
                    insuredDetailsSearchHandler={insuredDetailsSearchHandler}
                    setInputCustomer={setInputCustomer}
                    existCustomer={formDetails.existingCustomer}
                    loader={common.AMSDetailsLoading}
                  />
                </div>
              </div>
            )}
          </>
        )}

        {((common.isLOBAMSDetails && component.state.newInsured) || !common.isLOBAMSDetails) &&
          getApplicantDetailsFields(component, formDetails, formDetailsErrors)}

        <fieldset
          className={`${
            common.isLOBAMSDetails && !component.state.newInsured ? 'labelDisabled' : ''
          }`}
          disabled={common.isLOBAMSDetails && !component.state.newInsured}
          onClick={() => {
            if (dashboard.insuredDropDownLoader) {
              component.closeInsuredDropdown();
            }
          }}
        >
          <div className='row mb-4'>
            {common.isLOBAMSDetails ? (
              <>
                <div
                  className={`${isAdhocHomeLob ? 'col-md-4 resp-mb-20' : 'col-md-6 resp-mb-20'}`}
                >
                  <DropDownAutocomplete
                    value={formDetails.businessLine}
                    onChange={event => {
                      component.dropDownValuesUpdated(event, BUSINESS_LINE);
                    }}
                    recordsList={getDropdownValues(formDetails.lobOption, startCase(DESCRIPTION))}
                    disabled={!component.state.newInsured || disableCarrierFields(common)}
                    label={LOB_KEY}
                    isRequired={true}
                    error={formDetailsErrors.businessLineError.length > 0}
                  />
                  <FormHelperText className={classes.fieldError}>
                    {formDetailsErrors.businessLineError}
                  </FormHelperText>
                </div>
              </>
            ) : (
              <div className='col-md-6 resp-mb-20'>
                <FormControl fullWidth disabled={common.AMSDetailsLoading}>
                  <InputLabel shrink htmlFor={BUSINESS_LINE}>
                    {LOB_KEY}
                    <span className='star-error'>{ASTERISK}</span>
                  </InputLabel>
                  <Select
                    native
                    inputProps={{
                      id: BUSINESS_LINE,
                      name: BUSINESS_LINE,
                    }}
                    classes={{ root: AMSDROPDOWNCLASS }}
                    value={formDetails.businessLine}
                    onChange={component.handleInputChange}
                    disabled={getSubmitButtonLoader(common) || lobOptions.length === 1}
                  >
                    {getLOBOptions(lobOptions, common.lineOfBusiness, ratePageTypes)}
                  </Select>
                </FormControl>
              </div>
            )}
            {isAdhocHomeLob && (
              <div className='col-md-4 resp-mb-20'>
                <FormControl
                  className={`${classes.formControl}`}
                  error={!isEmpty(formDetailsErrors.productError)}
                >
                  <InputLabel shrink htmlFor={PRODUCT}>
                    {startCase(PRODUCT)}
                    <span className='star-error'>{ASTERISK}</span>
                  </InputLabel>
                  <NativeSelect
                    fullWidth
                    value={formDetails.product}
                    onChange={event => {
                      component.dropDownValuesUpdated(event.target.value, PRODUCT);
                    }}
                    disabled={!component.state.newInsured || disableCarrierFields(common)}
                    inputProps={{
                      name: PRODUCT,
                      id: PRODUCT,
                    }}
                  >
                    <option value=''>{startCase(SELECT)}</option>
                    {amsProductList.map((productName: string) => (
                      <option key={productName} value={productName}>
                        {productName}
                      </option>
                    ))}
                  </NativeSelect>
                </FormControl>
                <FormHelperText className={classes.fieldError}>
                  {formDetailsErrors.productError}
                </FormHelperText>
              </div>
            )}
            <div className={`${isAdhocHomeLob ? 'col-md-4 resp-mb-20' : 'col-md-6 resp-mb-20'}`}>
              <FormControl
                fullWidth
                className='effective-date-control'
                error={formDetailsErrors.effectiveDateError.length > 0}
              >
                <DatePicker
                  label={
                    <>
                      {EFFECTIVE_DATE}
                      <span className='star-error'>{ASTERISK}</span>
                    </>
                  }
                  name={EFFECTIVE_DATE_KEY}
                  onChange={component.handleCalendarDate}
                  dateValue={formDetails.effectiveDate}
                  error={formDetailsErrors.effectiveDateError.length > 0}
                  disabled={
                    (common.isLOBAMSDetails && !component.state.newInsured) ||
                    common.AMSDetailsLoading
                  }
                />
              </FormControl>
              <FormHelperText className={classes.fieldError}>
                {formDetailsErrors.effectiveDateError}
              </FormHelperText>
            </div>
          </div>
          {getCarrierFields(component, formDetails, formDetailsErrors)}

          {getAMSDetailsFields(component, formDetails, formDetailsErrors)}
          {isShowCarrierRiskConsentCheckbox(formDetails) && (
            <>
              <Box className='d-flex align-items-start'>
                <Checkbox
                  className='p-0 mr-1'
                  checked={formDetails?.isCarrierRiskConsented}
                  value={formDetails?.isCarrierRiskConsented}
                  onChange={component.onCheckboxChange}
                />
                <Typography className='carrier-risk-consent-label'>{nonAdmittedConcent}</Typography>
              </Box>
              <FormHelperText
                className={`${classes.fieldError} ${
                  formDetailsErrors?.carrierRiskConsentedError ? 'mb-3' : ''
                }`}
              >
                {formDetailsErrors?.carrierRiskConsentedError}
              </FormHelperText>
            </>
          )}
        </fieldset>
        <Typography className={`pl-0 line-height-16 ${classes.fieldError} ${classes.commonError}`}>
          {commonError}
        </Typography>
      </DialogContent>
      <DialogActions className='action-button'>{getNavButtons(component, false, [])}</DialogActions>
      {openPopup && (
        <Dialog
          classes={{
            root: 'ams-dialog-container',
          }}
          fullWidth={true}
          maxWidth={MD}
          open={!!openPopup ? true : false}
          onClose={() => {
            component.handleClosePhoneNumberModal();
          }}
          disableBackdropClick={true}
          disableEscapeKeyDown={true}
        >
          {' '}
          {body(component)}
        </Dialog>
      )}
    </Dialog>
  );
};

// tslint:disable-next-line: max-file-line-count
export default template;
