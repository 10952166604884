/* istanbul ignore file */
import { configureStore } from '@reduxjs/toolkit';
import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';

import history from '../../../history';
import { applicantApi } from '../../../quote-application/redux/apis/applicant';
import * as commonActionType from '../constants';
import { persistanceStateConfig, persistedState } from '../persist';
import reducers from '../reducers/index';
import rootSaga from '../saga';

const routeMiddleware = routerMiddleware(history);
const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware, routeMiddleware, applicantApi.middleware];

const resetEnhancer =
  (rootReducer: ReturnType<typeof reducers>) => (state: any, action: AppRedux.Action) => {
    // Reset store on flush or user signout event
    if (
      action.type === commonActionType.FLUSH_APP_DETAILS ||
      action.type === commonActionType.SIGNOUT_USER
    ) {
      const newState = rootReducer(undefined, { type: '' });
      sessionStorage.removeItem('state');
      if (action.type === commonActionType.SIGNOUT_USER) {
        localStorage.clear();
      } else {
        newState.auth = state.auth;
      }
      newState.auth.bulkUser = state.auth.bulkUser;
      newState.auth.reportUser = state.auth.reportUser;
      newState.auth.adminUser = state?.auth?.adminUser;
      newState.router = state.router;
      return newState;
    }
    return rootReducer(state || {}, action);
  };

const store = configureStore({
  reducer: resetEnhancer(reducers(history)),
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({ immutableCheck: false, serializableCheck: false }).concat(middlewares),
  preloadedState: persistedState,
});

sagaMiddleware.run(rootSaga);
persistanceStateConfig(store);

if (module.hot) {
  module.hot.accept();
}

export default store;

export type ReduxStore = typeof store;
export type ReduxRootState = ReturnType<ReduxStore['getState']>;
export type ReduxDispatch = ReduxStore['dispatch'];
