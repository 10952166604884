import {
  FormControl,
  FormHelperText,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import React from 'react';
import { isEmpty, startCase } from 'lodash';
import ChipInput from 'material-ui-chip-input';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { getEoiSettingsLobDropDown } from '../../utils';
import { getNestedTernaryData } from '../../../../utils';
import { ASTERISK, EOI_SETTINGS, LOB_KEY, SELECT, ADD } from '../../../../constants';

export interface FormProps {
  formError: any;
  formValues: any;
  isLoading: boolean;
  onChange: (name: string, value: unknown) => void;
  userEmailInput: (value: unknown) => void;
  checkEmailId: (value: unknown) => void;
  userEmailInputBlur: () => void;
  openEditEoiSettingModel?: boolean;
  removeOrigin: (index: number) => void;
}

const formLabelsTheme = createMuiTheme({
  overrides: {
    MuiFormLabel: {
      root: {
        '&$error': {
          color: '#808080 !important',
        },
      },
      asterisk: {
        color: '#db3131',
        fontSize: '20px',
        verticalAlign: 'sub',
        '&$error': {
          color: '#db3131',
        },
      },
    },
  },
});
const AddEditEoiSettingsForm = (props: any) => {
  const {
    formError,
    formValues,
    isLoading,
    onChange,
    openEditEoiSettingModel,
    userEmailInput,
    checkEmailId,
    userEmailInputBlur,
  } = props;
  const lobList: Array<any> = !isEmpty(
    getEoiSettingsLobDropDown(getNestedTernaryData(!!openEditEoiSettingModel, formValues.id, ''))
  )
    ? getEoiSettingsLobDropDown(getNestedTernaryData(!!openEditEoiSettingModel, formValues.id, ''))
    : [];
  return (
    <>
      <form className='iframe-setting-modal'>
        <Grid item md={12} className='mb-4'>
          <FormControl fullWidth disabled={isLoading}>
            <InputLabel shrink>
              {LOB_KEY}
              <span className='star-error'>{ASTERISK}</span>
            </InputLabel>
            <Select
              name={LOB_KEY?.toLowerCase()}
              value={formValues?.lob || ''}
              onChange={evt => onChange(evt)}
              disabled={isLoading}
            >
              <MenuItem value={startCase(SELECT)} key={startCase(SELECT)}>
                {startCase(SELECT)}
              </MenuItem>
              {!isEmpty(lobList)
                ? lobList.map((item: string, i: number) => (
                    <MenuItem value={item} key={i}>
                      {item}
                    </MenuItem>
                  ))
                : ''}
            </Select>
            <FormHelperText error>{formError?.lobError}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item className='mb-4'>
          <FormControl fullWidth disabled={isLoading}>
            <InputLabel shrink>
              {EOI_SETTINGS.SUBJECT_LINE_LABEL}
              <span className='star-error'>{ASTERISK}</span>
            </InputLabel>
            <Input
              onChange={evt => onChange(evt)}
              value={formValues.subjectLine}
              error={!isEmpty(formError.subjectLineError)}
              disabled={isLoading}
              name={EOI_SETTINGS.SUBJECT_LINE_KEY}
            />
            <FormHelperText error>{formError.subjectLineError || ''}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item className='mb-4'>
          <FormControl fullWidth disabled={isLoading}>
            <ThemeProvider theme={formLabelsTheme}>
              <ChipInput
                error={!isEmpty(formError.recipientsError)}
                defaultValue={
                  !isEmpty(formValues?.recipients) ? formValues?.recipients?.split(',') : ''
                }
                fullWidth
                required
                label={EOI_SETTINGS.RECIPIENTS}
                newChipKeyCodes={[13, 188, 186, 9, 59]}
                disabled={isLoading}
                blurBehavior={ADD}
                onBeforeAdd={val => {
                  return checkEmailId(val);
                }}
                onChange={chips => {
                  userEmailInput(chips);
                }}
                onBlur={() => {
                  userEmailInputBlur();
                }}
                InputProps={{
                  inputProps: {
                    maxLength: 50,
                  },
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </ThemeProvider>
            <FormHelperText error>{formError.recipientsError || ''}</FormHelperText>
          </FormControl>
        </Grid>
      </form>
    </>
  );
};

export default AddEditEoiSettingsForm;
