import { isBoolean, isString, isUndefined } from 'lodash';

export const stringToBoolean = (value: string | boolean | undefined, defaultValue: boolean) => {
  if (isBoolean(value)) {
    return value;
  }
  if (isUndefined(value) || !isString(value)) {
    return defaultValue;
  }
  const lowerCaseValue = value?.toLowerCase() || 'false';
  return lowerCaseValue === 'true';
};
