/* istanbul ignore file */
import * as types from '../constants';
import { getSortedQuoteListByHippoCarrier, getQuoteListWithBindDetails } from '../../../utils';
import isEmpty from 'lodash/isEmpty';
import filter from 'lodash/filter';
import { assignIn, find } from 'lodash';
import { getUpdatedQuoteList } from '../../../utils';
import { UpcomingCarriersListType } from './cyber-security';

export interface FloodDetailsState {
  floodRequestSuccess: boolean;
  showFloodConfirmation: boolean;
  showFloodDetailsModal: boolean;
  submitLoader: boolean;
  detailsLoader: boolean;
  currentStep: number;
  foundationType: string;
  propertyType: string;
  foundationOptions: Array<any>;
  currentQuestion: any;
  answeredQuestions: Array<any>;
  currentIndex: number;
  stories: number;
  annexPrefill: any;
  isNextTabStep: boolean;
  quoteList: Array<any>;
  quoteErrorList: Array<any>;
  herdId: string;
  bridgeToCarrierLoader: boolean;
  quotingCarriers: Array<any>;
  isPollingStart: boolean;
  isQuoteRefresh: boolean,
  upcomingCarrierList: Array<UpcomingCarriersListType>;
}

const INIT_FLOOD_STATE: FloodDetailsState = {
  floodRequestSuccess: false,
  showFloodConfirmation: false,
  showFloodDetailsModal: false,
  submitLoader: false,
  detailsLoader: false,
  currentStep: 0,
  foundationType: '',
  propertyType: '',
  foundationOptions: [],
  currentQuestion: {},
  answeredQuestions: [],
  currentIndex: 0,
  stories: 0,
  annexPrefill: null,
  isNextTabStep: false,
  quoteList: [],
  quoteErrorList: [],
  herdId: '',
  bridgeToCarrierLoader: false,
  quotingCarriers: [],
  isPollingStart: false,
  isQuoteRefresh: false,
  upcomingCarrierList: [],
};

const INIT_STATE = { ...INIT_FLOOD_STATE };

export default (state = INIT_STATE, action: AppRedux.Action) => {
  switch (action.type) {
    case types.APP_INIT: {
      return {
        ...state,
        floodRequestSuccess: false,
        showFloodConfirmation: false,
        showFloodDetailsModal: false,
        detailsLoader: false,
        submitLoader: false,
      };
    }

    case types.SET_PREFILL_DATA: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case types.SET_FLOOD_DETAILS_LOADER: {
      return {
        ...state,
        detailsLoader: action.payload,
      };
    }

    case types.SET_FLOOD_QUOTES: {
      const { quoteList, selectedCarrier, quoteErrorList } = action.payload;
      return {
        ...state,
        quoteList: getSortedQuoteListByHippoCarrier(quoteList),
        quoteErrorList: quoteErrorList,
        selectedCarrier: selectedCarrier ? selectedCarrier : null,
      };
    }

    case types.SHOW_FLOOD_CONFIRMATION_MODAL: {
      return {
        ...state,
        showFloodConfirmation: action.payload,
      };
    }

    case types.SHOW_FLOOD_DETAILS_MODAL: {
      return {
        ...state,
        showFloodDetailsModal: action.payload,
      };
    }

    case types.QUESTIONNAIRE_SUBMIT_LOADER: {
      return {
        ...state,
        submitLoader: action.payload,
      };
    }

    case types.SET_NEXT_TAB_STEP: {
      return {
        ...state,
        isNextTabStep: action.payload,
      };
    }

    case types.SET_FLOOD_REQUEST_SUCCESS: {
      return {
        ...state,
        floodRequestSuccess: action.payload,
      };
    }

    case types.SET_ANNEX_PREFILL: {
      return {
        ...state,
        annexPrefill: {
          ...state.annexPrefill,
          ...action.payload
        },
      };
    }

    case types.SET_FLOOD_QUOTE_BIND: {
      return {
        ...state,
        quoteList: getSortedQuoteListByHippoCarrier(
          getQuoteListWithBindDetails(state.quoteList, action.payload)
        ),
      };
    }

    case types.CLEAR_FLOOD_STATE: {
      return {
        ...state,
        quoteList: [],
        annexPrefill: null,
        floodRequestSuccess: false,
        showFloodConfirmation: false,
        showFloodDetailsModal: false,
        detailsLoader: false,
        submitLoader: false,
        currentQuestion: {},
        answeredQuestions: [],
        currentIndex: 0,
        herdId: '',
      };
    }

    case types.SET_FLOOD_HERD_ID: {
      return {
        ...state,
        herdId: action.payload,
      };
    }

    case types.FLOOD_BRIDGE_TO_CARRIER_LOADER: {
      const { carrier_id, loader } = action.payload;
      let floodQuoteList = [...state.quoteList];
      const updateIndex = floodQuoteList.findIndex(
        (item: any) => item?.carrier_id?.toString() === carrier_id?.toString()
      );
      if (updateIndex !== -1) {
        floodQuoteList[updateIndex] = {
          ...floodQuoteList[updateIndex],
          bridgeToCarrierLoader: loader,
        };
      }
      return {
        ...state,
        bridgeToCarrierLoader: loader,
        quoteList: floodQuoteList,
      };
    }

    case types.SET_FLOOD_DETAILS: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case types.SET_FLOOD_POLLING_DETAILS: {
      const { quotes, error_details } = action.payload;
      let oldQuotesList = [...state.quoteList];
      let quoteErrorList = [...state.quoteErrorList];
      if (!isEmpty(action.payload.quotes)) {
        let newQuoteErrorList: Array<any> = [];
        newQuoteErrorList = filter(
          quotes,
          quote =>
            !find(oldQuotesList, {
              carrier_id: quote.carrier_id,
              name: quote.name,
            }) && !find(oldQuotesList, { carrier_id: quote.carrier_id })
        );
        oldQuotesList = [...oldQuotesList, ...newQuoteErrorList];
      }
      if (error_details?.errors?.length && error_details.display_errors_on_ui) {
        quoteErrorList = filter(error_details.errors, error =>
          assignIn(Object.values(error), { quoteError: true })
        );
      }
      return {
        ...state,
        quoteList: getSortedQuoteListByHippoCarrier([...oldQuotesList]),
        quoteErrorList,
        pollingDetails: {
          ...action.payload,
        },
      };
    }

    case types.SET_FLOOD_POLLING_ERROR_DETAILS: {
      let oldQuoteErrorList = getUpdatedQuoteList(state, action.payload);
      if (oldQuoteErrorList) {
        oldQuoteErrorList = filter(oldQuoteErrorList, (error: any) =>
          assignIn(error, { quoteError: true })
        );
      }
      return {
        ...state,
        quoteErrorList: [...oldQuoteErrorList],
        pollingDetails: {
          ...action.payload,
        },
      };
    }

    default:
      return state;
  }
};
