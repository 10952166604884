/* istanbul ignore file */
import * as types from '../constants';
import { UpcomingCarriersListType } from './cyber-security';

interface PetDetailsState {
  quoteList: Array<any>;
  quoteListLoader: boolean;
  herdId: string;
  bridgeToCarrierLoader: boolean;
  upcomingCarrierList: Array<UpcomingCarriersListType>;
}

const INIT_HOME_WARRANTY_STATE: PetDetailsState = {
  quoteList: [],
  quoteListLoader: false,
  herdId: '',
  bridgeToCarrierLoader: false,
  upcomingCarrierList: [],
};

const INIT_STATE = { ...INIT_HOME_WARRANTY_STATE };

export default (state = INIT_STATE, action: AppRedux.Action) => {
  switch (action.type) {
    case types.APP_INIT: {
      return {
        ...state,
        quoteListLoader: false,
      };
    }

    case types.SET_HOME_WARRANTY_QUOTES_DETAILS: {
      const { quoteList, herdId } = action.payload;
      return {
        ...state,
        quoteList: quoteList,
        herdId: herdId ? herdId : state.herdId,
      };
    }

    case types.HOME_WARRANTY_DETAILS_LOADER: {
      return {
        ...state,
        quoteListLoader: action.payload,
      };
    }

    case types.STORE_HOME_WARRANTY_QUOTE_LIST: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case types.CLEAR_HOME_WARRANTY_STATE: {
      return {
        ...state,
        ...INIT_STATE,
      };
    }

    case types.BRIDGE_TO_CARRIER_LOADER: {
      const { carrier_id, loader } = action.payload;
      let homeWarrantyQuoteList = [...state.quoteList];
      const updateIndex = homeWarrantyQuoteList.findIndex(
        (item: any) => item?.carrier_id?.toString() === carrier_id?.toString()
      );
      if (updateIndex !== -1) {
        homeWarrantyQuoteList[updateIndex] = {
          ...homeWarrantyQuoteList[updateIndex],
          bridgeToCarrierLoader: loader,
        };
      }
      return {
        ...state,
        bridgeToCarrierLoader: loader,
        quoteList: homeWarrantyQuoteList,
      };
    }

    case types.STORE_HOME_WARRANTY_DETAILS: {
      return {
        ...state,
        ...action.payload,
      };
    }

    default:
      return state;
  }
};
