/* istanbul ignore file */
import * as FloodActionType from '../constants';

export const setPrefillData = (payload: any): AppRedux.Action => ({
  type: FloodActionType.SET_PREFILL_DATA,
  payload,
});

export const setFloodDetailsLoader = (payload: any): AppRedux.Action => ({
  type: FloodActionType.SET_FLOOD_DETAILS_LOADER,
  payload,
});

export const questionnaireSubmit = (payload: any): AppRedux.Action => ({
  type: FloodActionType.QUESTIONNAIRE_SUBMIT,
  payload,
});

export const questionnaireBack = (): AppRedux.Action => ({
  type: FloodActionType.QUESTIONNAIRE_BACK,
});

export const showFloodConfirmationModal = (payload: boolean): AppRedux.Action => ({
  type: FloodActionType.SHOW_FLOOD_CONFIRMATION_MODAL,
  payload,
});

export const showFloodDetailsModal = (payload: boolean): AppRedux.Action => ({
  type: FloodActionType.SHOW_FLOOD_DETAILS_MODAL,
  payload,
});

export const questionnaireSubmitLoader = (payload: boolean): AppRedux.Action => ({
  type: FloodActionType.QUESTIONNAIRE_SUBMIT_LOADER,
  payload,
});

export const setFloodRequestSuccess = (payload: boolean): AppRedux.Action => ({
  type: FloodActionType.SET_FLOOD_REQUEST_SUCCESS,
  payload,
});

export const fetchAnnexQuotes = (payload: any): AppRedux.Action => ({
  type: FloodActionType.FETCH_ANNEX_QUOTES,
  payload,
});

export const setNextTabStep = (payload: any): AppRedux.Action => ({
  type: FloodActionType.SET_NEXT_TAB_STEP,
  payload,
});

export const autoSubmitAnnex = (): AppRedux.Action => ({
  type: FloodActionType.AUTO_SUBMIT_ANNEX,
});

export const switchFloodModal = (): AppRedux.Action => ({
  type: FloodActionType.SWITCH_FLOOD_MODAL,
});

export const trackAnnexDetails = (payload: any): AppRedux.Action => ({
  type: FloodActionType.TRACK_ANNEX_DETAILS,
  payload,
});

export const setFloodQuotes = (payload: any): AppRedux.Action => ({
  type: FloodActionType.SET_FLOOD_QUOTES,
  payload,
});

export const clearFloodState = (): AppRedux.Action => ({
  type: FloodActionType.CLEAR_FLOOD_STATE,
});

export const setFloodQuoteBind = (payload: AppRedux.ActionPayload): AppRedux.Action => ({
  type: FloodActionType.SET_FLOOD_QUOTE_BIND,
  payload,
});

export const setFloodHerdId = (payload: any): AppRedux.Action => ({
  type: FloodActionType.SET_FLOOD_HERD_ID,
  payload,
});

export const fetchFloodQuoteDetails = (): AppRedux.Action => ({
  type: FloodActionType.FETCH_FLOOD_QUOTE_DETAILS,
});

export const setAnnexPrefill = (payload: any): AppRedux.Action => ({
  type: FloodActionType.SET_ANNEX_PREFILL,
  payload,
});

export const getBridgeToCarrierDetailsForFlood = (payload: any): AppRedux.Action => ({
  type: FloodActionType.GET_BRIDGE_TO_CARRIER_DETAILS_FOR_FLOOD,
  payload,
});

export const floodBridgeToCarrierLoader = (payload: any): AppRedux.Action => ({
  type: FloodActionType.FLOOD_BRIDGE_TO_CARRIER_LOADER,
  payload,
});

export const initializeFloodPolling = (): AppRedux.Action => ({
  type: FloodActionType.INIT_FLOOD_POLLING,
});

export const floodTimeoutPolling = (): AppRedux.Action => ({
  type: FloodActionType.FLOOD_TIMEOUT_POLLING,
});

export const stopFloodPolling = (): AppRedux.Action => ({
  type: FloodActionType.STOP_FLOOD_POLLING,
});

export const setFloodDetails = (payload: any): AppRedux.Action => ({
  type: FloodActionType.SET_FLOOD_DETAILS,
  payload,
});

export const setFloodPollingDetails = (payload: AppRedux.ActionPayload): AppRedux.Action => ({
  type: FloodActionType.SET_FLOOD_POLLING_DETAILS,
  payload,
});

export const setFloodPollingErrorDetails = (payload: AppRedux.ActionPayload): AppRedux.Action => ({
  type: FloodActionType.SET_FLOOD_POLLING_ERROR_DETAILS,
  payload,
});

export const refreshFloodQuotes = (): AppRedux.Action => ({
  type: FloodActionType.REFRESH_FLOOD_QUOTES,
});
