import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { filter, isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { GetHomeCoverageDetails } from '../rate-page-common-component';
import config from '../../../config/config';
import {
  BUILDING_CODE_UPGRADE,
  CONTENTS_COVERAGE,
  COVERAGE_LIMIT,
  CYBER_COVERAGES_INCLUDED,
  DEDUCTIBLES,
  DOLLAR_ZERO,
  DWELLING_LIMIT,
  FLOOD_PENALTY,
  INSURANCE_BUSINESS_TYPES,
  LIABILITY,
  LIABILITY_BI,
  LOSS_ASSESSMENT,
  LOSS_OFF_USE,
  MEDICAL_PAYMENTS,
  NA_VALUE,
  OTHER_STRUCTURE,
  PARAMETRIC_KEY,
  PERSONAL_PROPERTY,
  PET_DETAILS,
  PET_PLAN_CONSTANTS,
  PROPERTY_DAMAGE,
  REBUILDING_COST,
  SPLIT_LIMIT,
  SPLIT_LIMIT_KEY,
  ZERO,
} from '../../../constants';
import { formatNumberToCurrency } from '../../../utils';
import { deductiblesMap, formatCurrency } from '../../home/utils';
import './products-rates.scss';

const useStyles = makeStyles({
  table: {
    width: '100%',
  },
  header: {
    fontSize: '16px',
    fontWeight: 'normal',
    lineHeight: 'normal',
  },
  tableBody: {
    '&>.table-row:last-child>.table-cell': {
      borderBottom: '0px',
    },
  },
});

const ViewMore = ({
  rebuildingCost,
  quote,
  personalPropertyCost,
  liabilityCost,
  deductibles,
  isFlood,
  liabilityBI,
  propertyDamage,
  medicalPayments,
  isAuto,
  vehicle,
  appliedDiscounts,
  isQuake,
  viewOtherCoverages,
  selectedPremiumType,
  coverageLimit,
  isPet,
  petPlanDetails,
  petsViewMoreList,
  singleFloodPremium,
  cyberViewMoreDetails,
  isCyberSecurity,
  lob,
  isFloodDirectCarrier,
}: any) => {
  const [deductiblesCount, setDeductiblesCount] = useState<number>(0);
  const { vehiclesList } = vehicle;
  const classes = useStyles();
  let isHomeQuote = !isEmpty(lob) && lob === INSURANCE_BUSINESS_TYPES.HOME;
  let coverageMapping: any = [
    {
      label: LOSS_OFF_USE,
      value: viewOtherCoverages?.loss_of_use,
    },
    {
      label: BUILDING_CODE_UPGRADE,
      value: viewOtherCoverages?.building_code_upgrade,
    },
    {
      label: LOSS_ASSESSMENT,
      value: viewOtherCoverages?.loss_assessment,
    },
  ];

  const getDeductibleItems = () => {
    const deductiblesArr = [];
    if (!isFlood && !isAuto && !isQuake) {
      for (let [key, value] of Object.entries(deductibles)) {
        if (!value) {
          continue;
        }
        if (deductiblesMap[key].details.label === 'All Peril') {
          deductiblesArr.unshift(
            <li>{`${deductiblesMap[key].details.label}: ${formatNumberToCurrency(value)}`}</li>
          );
        } else {
          let deductableValue: any = value;
          if (parseInt(deductableValue)) {
            deductiblesArr.push([
              <li>{`${deductiblesMap[key].details.label}: ${formatNumberToCurrency(
                deductableValue
              )}`}</li>,
            ]);
          }
        }
      }
    } else if (singleFloodPremium && deductibles?.length > 0) {
      deductiblesArr.push([
        <li>{`All Peril: ${formatNumberToCurrency(deductibles[0])}`}</li>,
      ]);
    }
    return deductiblesArr;
  };

  const showOtherCoverages = () => coverageMapping.some((coverage: any) => coverage.value);

  const otherCoverageLableView = (coverage: any, index: number) => (
    <span>{`${formatCurrency(coverage.value, '')} ${coverage.label}${
      index + 1 < coverageMapping.length ? '; ' : ''
    }`}</span>
  );

  useEffect(() => {
    setDeductiblesCount(getDeductibleItems().length);
    // eslint-disable-next-line
  }, [deductibles]);

  const filterByIncluded = (list: Array<any>) => {
    const filteredList = filter(list, item => item.isIncluded);

    return filteredList;
  };

  const includedVehiclesList = filterByIncluded(vehiclesList);

  const drawVehiclesInfo = () => (
    <>
      <Grid className='mt-2' item xs={12}>
        <Typography className='label text-left mt-2 vehicle-info'>Vehicle Info</Typography>
        <div className='block-wraper block-table mt-2 mb-2'>
          <Table className={classes.table} aria-label='caption table'>
            <TableHead>
              <TableRow>
                <TableCell className={classes.header} align='center'></TableCell>
                <TableCell className={classes.header} align='center'>
                  Comprehensive Deductible
                </TableCell>
                <TableCell className={classes.header} align='center'>
                  Collision Deductible{' '}
                </TableCell>
                <TableCell className={classes.header} align='center'>
                  Towing and Labor
                </TableCell>
                <TableCell className={classes.header} align='center'>
                  Ext Trans Expense
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody className={classes.tableBody}>
              {includedVehiclesList.map((vehicle: any) => (
                <TableRow className='table-row' key={vehicle.uid}>
                  <TableCell className='table-cell rebuilding-cost-label text-left'>
                    {`${vehicle?.year} ${vehicle?.make} ${vehicle?.model}`}
                  </TableCell>
                  <TableCell className='table-cell rebuilding-cost-label text-center'>
                    {`${
                      vehicle?.comprehensive || vehicle?.coverage?.comprehensive
                        ? formatNumberToCurrency(
                            vehicle?.comprehensive || vehicle?.coverage?.comprehensive
                          )
                        : DOLLAR_ZERO
                    }`}
                  </TableCell>
                  <TableCell className='table-cell rebuilding-cost-label text-center'>
                    {`${
                      vehicle?.collision || vehicle?.coverage?.collision
                        ? formatNumberToCurrency(vehicle?.collision || vehicle?.coverage?.collision)
                        : DOLLAR_ZERO
                    }`}
                  </TableCell>
                  <TableCell className='table-cell rebuilding-cost-label text-center'>
                    {`${
                      vehicle?.towing_labor || vehicle?.coverage?.towing_labor
                        ? formatNumberToCurrency(
                            vehicle?.towing_labor || vehicle?.coverage?.towing_labor
                          )
                        : DOLLAR_ZERO
                    }`}
                  </TableCell>
                  <TableCell className='table-cell rebuilding-cost-label text-center'>
                    {`${vehicle?.ext_trans_expense ?? vehicle?.coverage?.ext_trans_expense ?? NA_VALUE}`}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
        {appliedDiscounts && appliedDiscounts.length ? (
          <div className='block-wraper block-table discount-wrap mt-2 mb-2'>
            <h2>Discounts Applied</h2>
            <ul>
              {appliedDiscounts.map((appliedDiscount: any) => (
                <li>{`${appliedDiscount.Discount}`}</li>
              ))}
            </ul>
          </div>
        ) : (
          ''
        )}
      </Grid>
    </>
  );

  const drawPetsInfo = () => (
    <>
      <Grid className='mt-2 pet-details-wrap' item xs={12}>
        <Typography className='pet-details-heading'>{PET_DETAILS}</Typography>
        <div className='block-wraper block-table mt-2 mb-2'>
          <Table className={classes.table} aria-label='caption table'>
            <TableHead>
              <TableRow>
                <TableCell className={classes.header} align='center'></TableCell>
                <TableCell className={classes.header} align='center'>
                  {PET_PLAN_CONSTANTS.POLICY_LIMIT}
                </TableCell>
                <TableCell className={classes.header} align='center'>
                  {PET_PLAN_CONSTANTS.DEDUCTIBLE}
                </TableCell>
                <TableCell className={classes.header} align='center'>
                  {PET_PLAN_CONSTANTS.CO_INSURANCE}
                </TableCell>
                <TableCell className={classes.header} align='center'>
                  Quote
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody className={classes.tableBody}>
              {petsViewMoreList.map((petInfo: any) => (
                <TableRow className='table-row' key={petInfo?.puid}>
                  <TableCell className='table-cell rebuilding-cost-label text-left'>
                    <div
                      className='petname-div'
                      title={petInfo?.petName}
                    >{`${petInfo?.petName} `}</div>
                    <div className='d-block details-info-label'>{`${petInfo?.petGender}, ${petInfo?.petType}`}</div>
                  </TableCell>
                  <TableCell className='table-cell rebuilding-cost-label text-center'>
                    {formatCurrency(petPlanDetails?.policy_limit, '')}
                  </TableCell>
                  <TableCell className='table-cell rebuilding-cost-label text-center'>
                    {formatCurrency(petPlanDetails?.deductible, '')}
                  </TableCell>
                  <TableCell className='table-cell rebuilding-cost-label text-center'>
                    {petPlanDetails?.coinsurance ? `${petPlanDetails?.coinsurance}%` : NA_VALUE}
                  </TableCell>
                  <TableCell className='table-cell rebuilding-cost-label text-center'>
                    {!!petInfo?.quotePrice ? formatNumberToCurrency(petInfo?.quotePrice) : NA_VALUE}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </Grid>
    </>
  );

  return (
    <>
      {isAuto ? (
        drawVehiclesInfo()
      ) : isQuake && selectedPremiumType === PARAMETRIC_KEY ? (
        <Grid container spacing={2} className='mt-4 mb-2'>
          <Grid item xs={12} sm={4}>
            <div className='block-wraper'>
              <Typography className='rebuilding-cost-label text-center'>
                {COVERAGE_LIMIT}
              </Typography>
              <Typography className='cost-number-label text-center'>{coverageLimit}</Typography>
            </div>
          </Grid>
        </Grid>
      ) : (
        <>
          {isPet ? (
            drawPetsInfo()
          ) : isCyberSecurity ? (
            <>
              <Grid container spacing={2} className='mt-2 mb-2'>
                <Grid item xs={12} sm={4}>
                  <div className='block-wraper'>
                    <Typography className='rebuilding-cost-label text-center'>
                      {'Coverage Limit'}
                    </Typography>
                    <Typography className='cost-number-label text-center'>
                      {formatCurrency(cyberViewMoreDetails?.coverageLimit, '')}
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <div className='block-wraper'>
                    <Typography className='rebuilding-cost-label text-center'>
                      {'ID Theft Deductible'}
                    </Typography>
                    <Typography className='cost-number-label text-center'>
                      {cyberViewMoreDetails?.idTheftDeductible > 0
                        ? formatCurrency(cyberViewMoreDetails?.idTheftDeductible, '')
                        : '$0'}
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <div className='block-wraper'>
                    <Typography className='rebuilding-cost-label text-center'>
                      {'All Other Deductible'}
                    </Typography>
                    <Typography className='cost-number-label text-center'>
                      {formatCurrency(cyberViewMoreDetails?.allOtherDeductible, '')}
                    </Typography>
                  </div>
                </Grid>
              </Grid>
            </>
          ) : !isFloodDirectCarrier ? (
            <>
              <Grid container spacing={2} className='mt-2 mb-2'>
                <Grid item xs={12} sm={4}>
                  <div className='block-wraper'>
                    <Typography className='rebuilding-cost-label text-center'>
                      {isAuto ? LIABILITY_BI : REBUILDING_COST}
                    </Typography>
                    <Typography className='cost-number-label text-center'>
                      {isAuto ? liabilityBI : rebuildingCost}
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <div className='block-wraper'>
                    <Typography className='rebuilding-cost-label text-center'>
                      {isAuto ? PROPERTY_DAMAGE : isFlood ? DWELLING_LIMIT : PERSONAL_PROPERTY}
                    </Typography>
                    <Typography className='cost-number-label text-center'>
                      {isAuto
                        ? propertyDamage
                        : isFlood
                        ? formatCurrency(quote?.limits?.dwelling, '')
                        : personalPropertyCost}
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <div className='block-wraper'>
                    <Typography className='rebuilding-cost-label text-center'>
                      {isAuto
                        ? MEDICAL_PAYMENTS
                        : isFlood
                        ? CONTENTS_COVERAGE
                        : isQuake
                        ? OTHER_STRUCTURE
                        : LIABILITY}
                    </Typography>
                    <Typography className='cost-number-label text-center'>
                      {isAuto
                        ? medicalPayments
                        : isFlood
                        ? formatCurrency(quote?.limits?.contents, '')
                        : liabilityCost}
                    </Typography>
                  </div>
                </Grid>
              </Grid>
              {!!isFlood && (
                <Grid container spacing={2} className='mt-2 mb-2'>
                  <ul className='footer-section-list mb-0'>
                    {quote?.name?.toLowerCase() === config.prioritizeFloodCarrier.toLowerCase() ? (
                      <>
                        <li>
                          <span>{FLOOD_PENALTY.NO_CO_INSURANCE_LABEL}</span>&nbsp;
                          {FLOOD_PENALTY.NO_CO_INSURANCE_DETAILS_1}
                        </li>
                        <li>{FLOOD_PENALTY.NO_CO_INSURANCE_DETAILS_2}</li>
                      </>
                    ) : (
                      <li>
                        <span>{FLOOD_PENALTY.CO_INSURANCE_LABEL}</span>&nbsp;
                        {FLOOD_PENALTY.CO_INSURANCE_DETAILS}
                      </li>
                    )}
                  </ul>
                </Grid>
              )}
            </>
          ) : (
            <>
              {!!isFlood && (
                <Grid container spacing={2} className='mt-2 mb-2'>
                  <ul className='footer-section-list mb-0'>
                    {quote?.name?.toLowerCase() === config.prioritizeFloodCarrier.toLowerCase() ? (
                      <>
                        <li>
                          <span>{FLOOD_PENALTY.NO_CO_INSURANCE_LABEL}</span>&nbsp;
                          {FLOOD_PENALTY.NO_CO_INSURANCE_DETAILS_1}
                        </li>
                        <li>{FLOOD_PENALTY.NO_CO_INSURANCE_DETAILS_2}</li>
                      </>
                    ) : (
                      <li>
                        <span>{FLOOD_PENALTY.CO_INSURANCE_LABEL}</span>&nbsp;
                        {FLOOD_PENALTY.CO_INSURANCE_DETAILS}
                      </li>
                    )}
                  </ul>
                </Grid>
              )}
            </>
          )}
        </>
      )}
      {(!isAuto && (!isFlood || singleFloodPremium)) || (isQuake && deductibles) ? (
        <Grid container spacing={2} className='mb-2'>
          {!!isHomeQuote && <GetHomeCoverageDetails quote={quote} />}
          {deductiblesCount > 0 && deductibles && (
            <Grid item xs={12} sm={12}>
              <Typography className='label text-left'>{DEDUCTIBLES}</Typography>
              <div className='normal-label text-left'>
                {isQuake && deductibles?.type === SPLIT_LIMIT_KEY ? (
                  <span>{`${deductibles?.percentage}% ${SPLIT_LIMIT}`}</span>
                ) : (
                  <ul className='dc-list'>{getDeductibleItems()}</ul>
                )}
              </div>
            </Grid>
          )}
        </Grid>
      ) : (
        ''
      )}
      {isQuake && showOtherCoverages() ? (
        <Grid container spacing={2} className='mb-2'>
          <Grid item xs={12} sm={12}>
            <Typography className='label text-left'>Other Coverages</Typography>
            <div className='normal-label text-left'>
              {coverageMapping.map((item: any, index: number) =>
                item?.value && item?.value?.toString() !== ZERO ? otherCoverageLableView(item, index) : null
              )}
            </div>
          </Grid>
        </Grid>
      ) : (
        ''
      )}
      {isCyberSecurity ? (
        <Grid container spacing={2} className='view-more-grid'>
          <Grid item xs={12} sm={12}>
            <Typography className='label text-left'>Coverages Included:</Typography>
            <div className='normal-label text-left'>{CYBER_COVERAGES_INCLUDED}</div>
          </Grid>
        </Grid>
      ) : (
        ''
      )}
    </>
  );
};
const mapStateToProps = ({ vehicle }: any) => ({ vehicle });
export default connect(mapStateToProps, {})(ViewMore);
