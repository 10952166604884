import { isEmpty } from 'lodash';
import store from '../../pages/redux/store';
import { INSURANCE_BUSINESS_TYPES, INSURANCE_PRODUCTS } from '../../constants';
import {
  refreshAutoQuotes,
  refreshCyberSecurityQuotes,
  refreshFloodQuotes,
  refreshHomeQuotes,
  refreshLifeQuotes,
  refreshUmbrellaQuotes,
} from '../../pages/redux/actions';
import {
  getIsCarrierTimeOutSettingEnabled,
  getIsLobQuotesLoading,
  getMappedQuotingCarrriers,
  getMappedUpComingSoonCarriersList,
  getQuoteAndErrQuoteCount,
} from '../../utils';
const { AUTO, CYBERSECURITY, FLOOD, HOME, HOMEWARRANTY, LIFE, PET, QUAKE, UMBRELLA } =
  INSURANCE_BUSINESS_TYPES;

export const ratePageTypes: { [key: string]: string } = {
  carousel: 'carousel',
  navigation: 'navigation',
};

export const quotePollingHandlerOnRefreshClick = () => {
  const { common } = store.getState();
  const selectedBusinessOption = common?.selectedBusinessOption?.toLowerCase() || '';
  if (
    isEmpty(selectedBusinessOption) ||
    common?.lobRefreshLoader ||
    getIsLobQuotesLoading(selectedBusinessOption)
  ) {
    return;
  }
  if (selectedBusinessOption === INSURANCE_PRODUCTS[0]) {
    store.dispatch(refreshHomeQuotes());
  } else if (selectedBusinessOption === INSURANCE_PRODUCTS[1]) {
    store.dispatch(refreshAutoQuotes());
  } else if (selectedBusinessOption === INSURANCE_PRODUCTS[6]) {
    store.dispatch(refreshLifeQuotes());
  } else if (selectedBusinessOption === INSURANCE_PRODUCTS[7]) {
    store.dispatch(refreshCyberSecurityQuotes());
  } else if (selectedBusinessOption === INSURANCE_PRODUCTS[8]) {
    store.dispatch(refreshUmbrellaQuotes());
  } else if (selectedBusinessOption === INSURANCE_PRODUCTS[2]) {
    store.dispatch(refreshFloodQuotes());
  }
};

export const compareBusinessType = (currentQuoteTab: string = '', showApplicationEdit: boolean) => {
  const {
    cyberSecurityDetails,
    floodDetails,
    home,
    homeWarrantyDetails,
    lifeDetails,
    petDetails,
    quote,
    quakeDetails,
    umbrellaDetails,
  } = store.getState();
  const { quotesLoading, quoteErrorList, quoteList: autoQuoteList } = quote;
  const {
    pconfig,
    hippoQuoteLoaded,
    ezlynxQuotesLoaded,
    quoteList: homeQuoteList,
    quoteListError: homeErrorQuoteList,
  } = home;
  const {
    submitLoader,
    quoteList: floodQuoteList,
    quoteErrorList: floodQuoteErrorList,
  } = floodDetails;
  let loading = false;
  let quoteList: Array<any> = [];
  const displayWithErrors = pconfig?.dale_config?.display_errors_on_ui || false;
  const carrierTimeoutMessageEnabled: boolean = getIsCarrierTimeOutSettingEnabled(
    currentQuoteTab,
    pconfig
  );
  let isRefereshButtonEnabled: boolean =
    (!!carrierTimeoutMessageEnabled && showApplicationEdit) || false;
  const quoteDetails: any = getQuoteAndErrQuoteCount(currentQuoteTab);
  const isQuotePresentForLob: boolean =
    quoteDetails?.quoteCount || quoteDetails?.errorCount || false;
  switch (currentQuoteTab) {
    case HOME:
      loading = !hippoQuoteLoaded || !ezlynxQuotesLoaded;
      quoteList = displayWithErrors ? [...homeQuoteList, ...homeErrorQuoteList] : homeQuoteList;
      if (!isEmpty(home?.quotingCarriers) && isRefereshButtonEnabled && isQuotePresentForLob) {
        quoteList = getMappedQuotingCarrriers(
          home.quotingCarriers,
          homeQuoteList,
          homeErrorQuoteList,
          displayWithErrors
        );
      }
      quoteList = getMappedUpComingSoonCarriersList(
        quoteList,
        home?.upcomingCarrierList,
        loading,
        home?.quoteStatus
      );
      break;
    case FLOOD:
      loading = submitLoader;
      currentQuoteTab = INSURANCE_BUSINESS_TYPES.FLOOD;
      quoteList =
        !submitLoader && displayWithErrors
          ? [...floodQuoteList, ...floodQuoteErrorList]
          : floodQuoteList;
      quoteList = getMappedUpComingSoonCarriersList(
        quoteList,
        floodDetails?.upcomingCarrierList,
        loading,
        floodDetails?.quoteStatus
      );
      break;
    case AUTO:
      loading = quotesLoading;
      quoteList = displayWithErrors ? [...autoQuoteList, ...quoteErrorList] : autoQuoteList;
      if (!isEmpty(quote?.quotingCarriers) && isRefereshButtonEnabled && isQuotePresentForLob) {
        quoteList = getMappedQuotingCarrriers(
          quote.quotingCarriers,
          autoQuoteList,
          quoteErrorList,
          displayWithErrors
        );
      }
      quoteList = getMappedUpComingSoonCarriersList(
        quoteList,
        quote?.upcomingCarrierList,
        loading,
        quote?.quoteStatus
      );
      break;
    case QUAKE:
      loading = quakeDetails?.quakeSubmitLoader;
      quoteList = quakeDetails?.quoteList;
      quoteList = getMappedUpComingSoonCarriersList(
        quoteList,
        quakeDetails?.upcomingCarrierList,
        loading,
        quakeDetails?.quoteStatus
      );
      break;
    case PET:
      loading = petDetails?.quoteListLoader || petDetails?.petBreedsDetailsLoader;
      quoteList = petDetails?.quoteList;
      quoteList = getMappedUpComingSoonCarriersList(
        quoteList,
        petDetails?.upcomingCarrierList,
        loading,
        petDetails?.quoteStatus
      );
      break;
    case HOMEWARRANTY:
      loading = homeWarrantyDetails?.quoteListLoader;
      quoteList = homeWarrantyDetails?.quoteList;
      quoteList = getMappedUpComingSoonCarriersList(
        quoteList,
        homeWarrantyDetails?.upcomingCarrierList,
        loading,
        homeWarrantyDetails?.quoteStatus
      );
      break;
    case CYBERSECURITY:
      let cyberSecurityQuoteList: Array<any> = !isEmpty(cyberSecurityDetails?.quoteList)
        ? [...cyberSecurityDetails.quoteList]
        : [];
      let cyberSecurityQuoteErrorList: Array<any> = !isEmpty(cyberSecurityDetails?.quoteErrorList)
        ? [...cyberSecurityDetails.quoteErrorList]
        : [];
      loading = cyberSecurityDetails?.quoteListLoader;
      quoteList = displayWithErrors
        ? [...cyberSecurityQuoteList, ...cyberSecurityQuoteErrorList]
        : cyberSecurityDetails?.quoteList;
      if (
        !isEmpty(cyberSecurityDetails?.quotingCarriers) &&
        isRefereshButtonEnabled &&
        isQuotePresentForLob
      ) {
        quoteList = getMappedQuotingCarrriers(
          cyberSecurityDetails.quotingCarriers,
          cyberSecurityQuoteList,
          cyberSecurityQuoteErrorList,
          displayWithErrors
        );
      }
      quoteList = getMappedUpComingSoonCarriersList(
        quoteList,
        cyberSecurityDetails?.upcomingCarrierList,
        loading,
        cyberSecurityDetails?.quoteStatus
      );
      break;
    case LIFE:
      loading = lifeDetails?.quoteListLoader;
      quoteList = displayWithErrors
        ? [...lifeDetails?.quoteList, ...lifeDetails?.quoteErrorList]
        : lifeDetails?.quoteList;
      if (
        !isEmpty(lifeDetails?.quotingCarriers) &&
        isRefereshButtonEnabled &&
        isQuotePresentForLob
      ) {
        quoteList = getMappedQuotingCarrriers(
          lifeDetails.quotingCarriers,
          lifeDetails?.quoteList,
          lifeDetails?.quoteErrorList,
          displayWithErrors
        );
      }
      quoteList = getMappedUpComingSoonCarriersList(
        quoteList,
        lifeDetails?.upcomingCarrierList,
        loading,
        lifeDetails?.quoteStatus
      );
      break;
    case UMBRELLA:
      loading = umbrellaDetails?.quoteListLoader;
      quoteList = displayWithErrors
        ? [...umbrellaDetails?.quoteList, ...umbrellaDetails?.quoteErrorList]
        : umbrellaDetails?.quoteList;
      if (
        !isEmpty(umbrellaDetails?.quotingCarriers) &&
        isRefereshButtonEnabled &&
        isQuotePresentForLob
      ) {
        quoteList = getMappedQuotingCarrriers(
          umbrellaDetails.quotingCarriers,
          umbrellaDetails?.quoteList,
          umbrellaDetails?.quoteErrorList,
          displayWithErrors
        );
      }
      quoteList = getMappedUpComingSoonCarriersList(
        quoteList,
        umbrellaDetails?.upcomingCarrierList,
        loading,
        umbrellaDetails?.quoteStatus
      );
      break;
  }
  return { loading, quoteList, currentQuoteTab, displayWithErrors };
};
