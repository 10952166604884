/* istanbul ignore file */
import * as vehicleActionType from '../constants';

// Vehicle Selector Actions

// App initial fetch
export const fetchAutolineDetails = (): AppRedux.Action => ({
  type: vehicleActionType.FETCH_AUTOLINE_DETAILS,
});

// Loader for autoline details
export const autolineDetailsLoader = (payload: boolean): AppRedux.Action => ({
  type: vehicleActionType.AUTOLINE_DETAILS_LOADER,
  payload,
});

// Store fetched auto line all details
export const storeAutolineDetails = (payload: AppRedux.ActionPayload): AppRedux.Action => ({
  type: vehicleActionType.STORE_AUTOLINE_DETAILS,
  payload,
});

// On year, make and modal selection
export const vehicleTypeSelected = (payload: AppRedux.ActionPayload): AppRedux.Action => ({
  type: vehicleActionType.VEHICLE_TYPE_SELECTED,
  payload,
});

// Store year, make and modal list details
export const storeVehicleTypeList = (payload: AppRedux.ActionPayload): AppRedux.Action => ({
  type: vehicleActionType.STORE_VEHICLE_TYPE_LIST,
  payload,
});

// Loader for year, make and modal selection
export const setVehicleSelectorLoader = (flag: boolean): AppRedux.Action => ({
  type: vehicleActionType.VEHICLE_SELECTOR_LOADER,
  payload: flag,
});

export const deleteDetailsLoader = (flag: boolean): AppRedux.Action => ({
  type: vehicleActionType.DELETE_DETAILS_LOADER,
  payload: flag,
});

// Set coverages for each vehicle
export const setVehicleCoverages = (payload: AppRedux.ActionPayload): AppRedux.Action => ({
  type: vehicleActionType.SET_VEHICLE_COVERAGES,
  payload,
});

// Set current vehicle year, make and model for view quotes page
export const updateVehicleName = (payload: AppRedux.ActionPayload): AppRedux.Action => ({
  type: vehicleActionType.UPDATE_VEHICLE_NAME,
  payload,
});

// On vehicle page continue, get next vehicle for edit
export const vehicleContinue = (payload: AppRedux.ActionPayload): AppRedux.Action => ({
  type: vehicleActionType.VEHICLE_CONTINUE,
  payload,
});

// On vehicle form page back, get previous vehicle for edit
export const vehicleBack = (): AppRedux.Action => ({
  type: vehicleActionType.VEHICLE_BACK,
});

// Vehicle Actions

// On successful vehicle added in vehicle list
export const initVehicle = (): AppRedux.Action => ({
  type: vehicleActionType.VEHICLE_INIT,
});

// Clear vehicles list
export const clearVehiclesList = (): AppRedux.Action => ({
  type: vehicleActionType.CLEAR_VEHICLE_LIST,
});

// On successful vehicle added in vehicle list either by 'Continue' or 'Add..' buttons
export const addVehicle = (payload: any): AppRedux.Action => ({
  type: vehicleActionType.ADD_VEHICLE,
  payload,
});

// On successful vehicle added in vehicle list
export const setVehicleLoader = (payload: boolean): AppRedux.Action => ({
  type: vehicleActionType.VEHICLE_LOADER,
  payload,
});

// On edit of other vehicle details, save current valid details to list
export const saveBeforeEdit = (payload: AppRedux.ActionPayload): AppRedux.Action => ({
  type: vehicleActionType.SAVE_BEFORE_EDIT,
  payload,
});

// Store new vehicle in vehicle list
export const updateVehicleList = (payload: any): AppRedux.Action => ({
  type: vehicleActionType.UPDATE_VEHICLE_LIST,
  payload,
});

// Save any updates to current vehicle details
export const saveVehicleUpdates = (payload: AppRedux.ActionPayload): AppRedux.Action => ({
  type: vehicleActionType.SAVE_VEHICLE_UPDATES,
  payload,
});

// On delete of particular vehicle details from server
export const deleteVehicleDetails = (payload: AppRedux.ActionPayload): AppRedux.Action => ({
  type: vehicleActionType.DELETE_VEHICLE_DETAILS,
  payload,
});

// On delete of particular vehicle details update vehicle list in store
export const deleteVehicleDetailsSuccess = (payload: AppRedux.ActionPayload): AppRedux.Action => ({
  type: vehicleActionType.DELETE_VEHICLE_DETAILS_SUCCESS,
  payload,
});

// Edit selected vehicle from list
export const vehicleSelectedForEdit = (payload: string): AppRedux.Action => ({
  type: vehicleActionType.EDIT_SELECTED_VEHICLE,
  payload,
});

// Select vehicle from list
export const vehicleSelect = (payload: string): AppRedux.Action => ({
  type: vehicleActionType.VEHICLE_SELECT,
  payload,
});

// Add current vehicle (year, make and model) details to vehicle list
export const addCurrentVehicle = (): AppRedux.Action => ({
  type: vehicleActionType.ADD_CURRENT_VEHICLE,
});

// Valid VIN submit
export const vinSubmitted = (payload: string): AppRedux.Action => ({
  type: vehicleActionType.VIN_SUBMIT,
  payload,
});

// VIN submit success
export const vinSubmitSuccess = (payload: AppRedux.ActionPayload): AppRedux.Action => ({
  type: vehicleActionType.VIN_SUBMIT_SUCCESS,
  payload,
});

// VIN submit loader
export const setVinLoader = (payload: boolean): AppRedux.Action => ({
  type: vehicleActionType.SET_VIN_LOADER,
  payload,
});

// On VIN input number cleared in selector then reset object
export const vinCleared = (): AppRedux.Action => ({
  type: vehicleActionType.VIN_CLEARED,
});

export const setVinServerError = (payload: boolean): AppRedux.Action => ({
  type: vehicleActionType.SET_VIN_SERVER_ERROR,
  payload,
});

// On view quote come back modal selection
export const setBodyTypeFormViewQuote = (payload: AppRedux.ActionPayload): AppRedux.Action => ({
  type: vehicleActionType.SET_BODY_TYPE_FROM_VIEW_QUOTE,
  payload,
});

// Store new vehicle when continue in vehicle list
export const updateContinueVehicleList = (payload: AppRedux.ActionPayload): AppRedux.Action => ({
  type: vehicleActionType.UPDATE_CONTINUE_VEHICLE_LIST,
  payload,
});

// Store modal list when come form view quote
export const storeQuoteVehicleTypeList = (payload: AppRedux.ActionPayload): AppRedux.Action => ({
  type: vehicleActionType.STORE_QUOTE_VEHICLE_TYPE_LIST,
  payload,
});

// Set vin error flag if wrong Vin entered
export const setVinErrorFlag = (payload: boolean): AppRedux.Action => ({
  type: vehicleActionType.SET_VIN_ERROR_FLAG,
  payload,
});

export const vinBack = (payload: boolean): AppRedux.Action => ({
  type: vehicleActionType.VIN_BACK,
  payload,
});

export const restoreVehicleDetails = (): AppRedux.Action => ({
  type: vehicleActionType.RESTORE_VEHICLE_DETAILS,
});

export const setVehicleSelected = (payload: boolean): AppRedux.Action => ({
  type: vehicleActionType.SET_VEHICLE_SELECTED,
  payload,
});

export const toggleVehicleIncludedStatus = (payload: AppRedux.ActionPayload): AppRedux.Action => ({
  type: vehicleActionType.TOGGLE_VEHICLE_INCLUDED_STATUS,
  payload,
});

export const toggleVehicleIncludedSuccess = (payload: AppRedux.ActionPayload): AppRedux.Action => ({
  type: vehicleActionType.TOGGLE_VEHICLE_INCLUDED_STATUS_SUCCESS,
  payload,
});

export const sortVehiclesList = (): AppRedux.Action => ({
  type: vehicleActionType.SORT_VEHICLE,
});

export const getStoredVehiclesList = (payload: AppRedux.ActionPayload): AppRedux.Action => ({
  type: vehicleActionType.GET_STORED_VEHICLES_LIST,
  payload,
});

export const setVehicleList = (payload: AppRedux.ActionPayload): AppRedux.Action => ({
  type: vehicleActionType.SET_VEHICLE_LIST,
  payload,
});

export const setVehicleDetails = (payload: AppRedux.ActionPayload): AppRedux.Action => ({
  type: vehicleActionType.COMMON_VEHICLE_ACTION,
  payload,
});
