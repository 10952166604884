export const VIEW_MORE = 'View More';
export const VIEW_LESS = 'View Less';
export const DETAILS = 'Details';
export const BOOLEAN_OPTIONS = [
  { label: 'Yes', value: 'true' },
  { label: 'No', value: 'false' },
];
export const INPUT_FIELD_TYPES = {
  TEXT: 'text',
  SELECT: 'select',
  DATE: 'date',
};
export const INPUT_DATA_TYPES = {
  NUMBER: 'number',
  STRING: 'string',
  BOOLEAN: 'bool',
};
export const DOWNLOAD_INDICATOR = 'Download Indication';
export const YEAR_CONSTRUCTED_ERROR_MESSAGE = `Updated Roof can't be less than Year Built`;
export const NO_QUOTES_AVAILABLE_MESSAGE = 'No Quotes Available';
export const SALESFORCE_CUSTOMER_ID = {
  LABEL: 'Salesforce Customer ID',
  KEY: 'salesforceCustomerUUID',
  MAX_LENGTH: 50,
};
export const PARTNER_PRODUCT_MAPPING: any = {
  HOMEWARRANTY: 'HOME-WARRANTY',
  CYBERSECURITY: 'CYBER-SECURITY',
  QUAKE: 'EARTHQUAKE',
};
export const PRODUCTS = 'products';
export const ALL_PRODUCT = 'All Product';
export const SHOW_PRODUCT_FOR_LOBS = ['home', 'earthquake', 'flood'];
export const IN_PROGRESS_STATUS = 'in-progress';
export const ALL_PERIL = 'All Peril';
export const MINIMUM_AGE = 15;
export const MIMIMUM_AGE_ERROR_MESSAGE = `You must be at least ${MINIMUM_AGE} years old to apply`;
export const INDUSTRY = 'industry';
export const OCCUPATION = 'occupation';
export const EDUCATION = 'education';
export const UPCOMING_CARRIER_DISPLAY_MESSAGE = 'Carrier Appointment available for the product and the state.';
export const COMING_SOON = 'Not Integrated';
export const INACTIVE = 'Inactive';
export const INCOMPLETE = 'incomplete';
export const YEAR = 'year';
export const AMS_FIELD_KEYS = {
  BROKER_CODE: 'BrokerCode',
  GROUP_CODE: 'GLGroupCode',
  DEPARTMENT_CODE: 'GLDepartmentCode'
};
export const AMS_FORM_FIELD = {
  SL2_FORM_DOCUMENT: {
    LABEL: 'SL2 Form Document',
    KEY: 'sl2FormFile',
    SIGNATURE_LABEL: 'Add Signature',
    SIGNATURE_TOOLTIP: 'Click here to add signature ',
  },
  DUE_DILIGENCE_FORM_DOCUMENT: {
    LABEL: 'Due Diligence Form Document',
    KEY: 'dueDiigenceFormFile',
    SIGNATURE_LABEL: 'Add Signature',
    SIGNATURE_TOOLTIP: 'Click here to add signature ',
  },
  EOI_DOCUMENT: {
    LABEL: 'EOI Document',
    KEY: 'eoiFile',
  }
};
export const UPLOAD = 'upload';
export const DROP = 'drop';
export const UPLOAD_AMS_DOCUMENT_TITLE = {
  [AMS_FORM_FIELD.SL2_FORM_DOCUMENT.KEY]: 'Upload SL2 Form',
  [AMS_FORM_FIELD.DUE_DILIGENCE_FORM_DOCUMENT.KEY]: 'Upload Due Diligence Form',
  [AMS_FORM_FIELD.EOI_DOCUMENT.KEY]: 'Upload EOI File',
};
export const CA = 'CA';
export const AMS_FILE_TYPE = {
  SL2: 'sl2',
  DUE_DILIGENCE: 'dueDiligence',
};
export const ADHOC_NON_ADMITTED_AMS_LOB_LIST = ['homeowners', 'dwelling fire', 'personal & premises liability'];
export const PURCHASE_TYPE = {
  ONLINE: 'ONLINE',
  PURCHASE_ONLINE: 'PURCHASE ONLINE',
};
export const QUOTE_APPLICATION_ROUTE = '/quote/application';
export const QUOTE_ROUTE = '/quote';
export const QUOTE_APPLICANTION_VIEW_ROUTE = '/quote/application?herd_id';
export const QUESTIONNAIRE_VIEW_PATH_ROUTE = '/questionnaire?herd_id';
