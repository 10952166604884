import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { isEmpty, isEqual, get } from 'lodash';
import {
  WithStyles,
  withStyles,
  Button,
  Snackbar,
  Card,
  CardContent,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import config from '../../config/config';
import AddIcon from '@material-ui/icons/Add';
import {
  clearQuotes,
  changeDateRange,
  dashboardInit,
  dashboardSearch,
  fetchOrgConfig,
  fetchQuotingOrganizationList,
  getAdhocOrganizationList,
  getFilteredList,
  setAdhocOrganizationDetails,
  setAMSDetailsSuccess,
  setHomeState,
  setRedirectToViewQuoteFlag,
  showLOBAMSDetails,
  stopAllLobPolling,
  storeQuotingOrganizationList,
  storeSearchText,
  submitNewProducts,
} from '../redux/actions';
import {
  FILTER_OPTIONS,
  PRODUCT_OR_ORG_CHOOSE_ERROR,
  INSURANCE_PRODUCTS_MAPPING,
  PRODUCT_OR_ORG_TO_BIND_ERROR,
  STATE_FILTER_OPTIONS,
} from '../../constants';
import AdHocOrganizationModel from '../components/adhoc-organization-model';
import AMSButtonImg from '../../assets/images/ams-button.png';
import AMSDetailsModal from '../components/ams-details-modal';
import AMSSuccessModal from '../components/ams-success-modal';
import DateRangeSelector from '../components/date-range-selector';
import FeedbackButton from '../components/feedback-button';
import FilterSelector from '../components/filter-selector';
import InsuredDetailsSearch from '../components/insured-details-search';
import InsuredDetailsTable from '../components/insured-details-table';
import OrganizationModal from '../components/organization-modal';
import { goToDashboardUrl, inIframe } from '../../utils';
import history from '../../history';
import './lob.scss';
import QuoteOrganizationModel from '../../quote-application/components/quote-organization-model';
import { isEnabledPRSv2Flow } from '../../quote-application/utils';

const styles = () => ({
  root: {
    width: 'auto',
  },
  cardContent: {
    paddingBottom: '0px !important',
  },
  continueSpinner: {
    color: '#00cf83 !important',
    marginBottom: '2px',
    marginRight: '10px',
  },
  buttonDisabled: {
    opacity: 0.8,
    cursor: 'default',
  },
});

class LineOfBusiness extends Component<
  AppComponents.LineOfBusinessProps & WithStyles<typeof styles>,
  AppComponents.LineOfBusinessState
> {
  state: AppComponents.LineOfBusinessState = {
    searchText: '',
    organization: '',
    products: [],
    totalHomeRecords: 0,
    homeInsuredList: [],
    routingDetails: null,
    showModal: false,
    filterApplied: true,
    filterOptions: [...FILTER_OPTIONS],
    filterList: [...FILTER_OPTIONS],
    appliedFilter: [...FILTER_OPTIONS],
    dateRange: {
      startDate: '',
      endDate: '',
    },
    isSnackBarOpen: false,
    openAdhocOrganizationModel: false,
    eoiUploadRequired: null,
    selectedAdhocOrganizationCode: '',
    selectedAdhocOrganizationId: '',
    selectedAdhocOrganizationCodeError: '',
    stateFilterApplied: true,
    stateFilterOptions: [...STATE_FILTER_OPTIONS],
    stateFilterList: [...STATE_FILTER_OPTIONS],
    stateAppliedFilter: [...STATE_FILTER_OPTIONS],
  };

  componentDidMount() {
    setRedirectToViewQuoteFlag(false);
    (window as any).$(document.body).addClass(`theme-${config.hippo.template_name}`);
    const { isProviderOneLogin } = this.props;
    if(inIframe()) {
      if (!isProviderOneLogin && window.top) {
        window.top.location = goToDashboardUrl();
      } else {
        this.props.setHomeState({
          showError: true,
          notFoundError: true,
          expiredError: false,
        } as any);
        history.push('/error');
      }
    }
    this.props.dashboardInit();
    this.props.stopAllLobPolling();
    if (sessionStorage.selectedQuoteList) {
      sessionStorage.removeItem('selectedQuoteList');
    }
  }

  openOrganizationModalHandler = () => {
    this.props.clearQuotes();
    this.setState(
      {
        organization: '',
        products: [],
        showModal: true,
      },
      () => {
        this.props.storeQuotingOrganizationList([]);
        this.props.fetchQuotingOrganizationList();
      }
    );
  };

  insuredDetailsSearchHandler = (searchText: string) => {
    this.state.searchText !== searchText &&
      this.setState(
        {
          searchText,
          ...((searchText.length === 0 ? { totalHomeRecords: 0, homeInsuredList: [] } : {}) as any),
        },
        () => {
          this.props.storeSearchText(searchText);
          if (!isEmpty(searchText)) {
            this.props.dashboardSearch({ searchText });
          } else {
            if (!isEmpty(this.props.searchText)) {
              this.props.dashboardInit();
            }
          }
        }
      );
  };

  selectOrganization = (organizationName: string) => {
    if (organizationName && this.state.organization !== organizationName) {
      const { home } = this.props;
      this.props.fetchOrgConfig({
        organization: organizationName,
        defaultPConfig: { ...get(home, 'defaultPConfig', {}) },
        routingDetails: { ...this.state.routingDetails },
      });
    }
    this.setState({
      products: [],
      organization: organizationName,
    });
  };

  selectProduct = (product: string) => {
    const currentProducts = [...this.state.products];
    const productIndex = currentProducts.indexOf(product);
    let newProducts;
    if (productIndex > -1) {
      newProducts = currentProducts.filter(p => p !== currentProducts[productIndex]);
    } else {
      newProducts = [...currentProducts, product];
    }
    this.setState({
      products: newProducts,
    });
  };

  closeModal = () => {
    this.setState({
      showModal: false,
    });
  };

  handleSnackBarClose = () => {
    this.setState({ isSnackBarOpen: false });
  };

  handleSnackBarOpen = () => {
    this.setState({ isSnackBarOpen: true });
  };

  showAlertBox = () => {
    return (
      <Snackbar
        open={this.state.isSnackBarOpen}
        autoHideDuration={3000}
        onClose={this.handleSnackBarClose}
      >
        <Alert onClose={this.handleSnackBarClose} severity='warning'>
          {PRODUCT_OR_ORG_CHOOSE_ERROR}
        </Alert>
      </Snackbar>
    );
  };

  submitQuote = () => {
    if (this.state.products.length && this.state.organization) {
      this.props.submitNewProducts({
        products: this.state.products,
      });
      if (sessionStorage.selectedQuoteList) {
        sessionStorage.removeItem('selectedQuoteList');
      }
    } else {
      this.handleSnackBarOpen();
    }
    this.setState({ showModal: false, routingDetails: null });
  };

  fetchFilteredQuotes = (filters: Array<any>) =>
    this.props.getFilteredList({
      filterList: [...filters]
    });


  onFilterChange = (list: any) => {
    this.setState({
      filterList: [...list],
    });
  };

  onApplyFilter = (filters: Array<any>) => {
    const { appliedFilter } = this.state;
    const newFilters = [...filters].sort();
    const currentAppliedFilters = [...appliedFilter].sort();
    if (!isEqual(newFilters, currentAppliedFilters)) {
      this.setState({
        filterApplied: true,
        appliedFilter: [...filters],
      });
      this.fetchFilteredQuotes([...filters, ...this.state.stateAppliedFilter]);
    }
  };

  onClearFilter = () => {
    const { filterApplied } = this.state;
    if (filterApplied) {
      this.setState({
        filterApplied: false,
        appliedFilter: [],
      });
      this.fetchFilteredQuotes([...this.state.stateAppliedFilter]);
    }
  };

  onFilterMenuClose = () => {
    const { appliedFilter, filterApplied } = this.state;
    let list: any = [];
    if (filterApplied) {
      list = [...appliedFilter];
    }
    this.setState({
      filterList: [...list],
    });
  };

  onStateFilterChange = (list: any) => {
    this.setState({
      stateFilterList: [...list],
    });
  };

  onStateApplyFilter = (stateFilters: Array<any>) => {
    const newFilters = [...stateFilters].sort();
    const currentAppliedFilters = [...this.state.stateAppliedFilter].sort();
    if (!isEqual(newFilters, currentAppliedFilters)) {
      this.setState({
        stateFilterApplied: true,
        stateAppliedFilter: [...stateFilters],
      });
      this.fetchFilteredQuotes([...stateFilters, ...this.state.appliedFilter]);
    }
  };

  onStateClearFilter = () => {
    if (this.state.stateFilterApplied) {
      this.setState({
        stateFilterApplied: false,
        stateAppliedFilter: [],
      });
      this.fetchFilteredQuotes([...this.state.appliedFilter]);
    }
  };

  onStateFilterMenuClose = () => {
    let list: any = [];
    if (this.state.stateFilterApplied) {
      list = [...this.state.stateAppliedFilter];
    }
    this.setState({
      stateFilterList: [...list],
    });
  };

  onDateRangeApply = (range: any) => {
    this.setState({
      dateRange: {
        startDate: range.startDate,
        endDate: range.endDate,
      },
    });
    this.props.changeDateRange(range);
  };

  AMSModalCloseHandler = () => {
    this.props.showLOBAMSDetails(false);
    this.props.setAdhocOrganizationDetails(null);
  };

  validateAdhocOrganization = () => {
    const { selectedAdhocOrganizationCode } = this.state;
    let error = isEmpty(selectedAdhocOrganizationCode) ? PRODUCT_OR_ORG_TO_BIND_ERROR : '';
    this.setState((prevState: any) => ({
      ...prevState,
      selectedAdhocOrganizationCodeError: error
    }));
    return !isEmpty(error) ? false : true;
  }

  onSaveAdhocOrganization = () => {
    const isValid: boolean = this.validateAdhocOrganization();
    if(isValid) {
      const { eoiUploadRequired, selectedAdhocOrganizationCode, selectedAdhocOrganizationId } =
        this.state;
      this.props.setAdhocOrganizationDetails({
        selectedAdhocOrganizationCode,
        selectedAdhocOrganizationId,
        eoiUploadRequired,
      });
      this.props.showLOBAMSDetails(true);
      this.onCloseAdhoOrganizationModel();
    }
  };

  onChangeAdhocOrganization = (orgData: any) => {
    this.setState((prevState: any) => ({
      ...prevState,
      selectedAdhocOrganizationCode: orgData?.code,
      selectedAdhocOrganizationId: orgData?.id,
      eoiUploadRequired: !!orgData?.eoiUploadRequired ? orgData?.eoiUploadRequired : null,
      selectedAdhocOrganizationCodeError: ''
    }));
  };

  onCloseAdhoOrganizationModel = () => {
    this.setState((prevState: any) => ({
      ...prevState,
      openAdhocOrganizationModel: false,
      selectedAdhocOrganizationCode: '',
      eoiUploadRequired: null,
    }));
  };

  AMSBindModalOpen = () => {
    this.setState(
      {
        searchText: '',
        openAdhocOrganizationModel: true,
        selectedAdhocOrganizationCodeError: ''
      },
      () => {
        this.props.storeSearchText('');
        this.props.getAdhocOrganizationList();
      }
    );
  };

  getAMSSuccessModal = () =>
    this.props.AMSSuccess ? (
      <AMSSuccessModal
        open={this.props.AMSSuccess}
        closeModal={() => {
          this.props.setAMSDetailsSuccess(false);
        }}
      />
    ) : (
      ''
    );

  render() {
    const {
      adhocOrganizationList,
      adhocOrganizationLoader,
      appliedDateRange,
      classes,
      common,
      orgConfigLoader,
      home,
      insuredTableLoader,
      insuredListDetails,
    } = this.props;
    const {
      searchText,
      showModal,
      organization,
      products,
      filterList,
      filterOptions,
      appliedFilter,
      dateRange,
      openAdhocOrganizationModel,
      selectedAdhocOrganizationCode,
      selectedAdhocOrganizationCodeError,
      stateFilterList,
      stateFilterOptions,
      stateAppliedFilter,
    } = this.state;
    const insuranceProducts = !!home?.pconfig?.dale_config?.lob
      ? Object.keys(home?.pconfig?.dale_config?.lob).map(
          lob => INSURANCE_PRODUCTS_MAPPING[lob] && INSURANCE_PRODUCTS_MAPPING[lob].toLowerCase()
        )
      : [];
    return (
      <div className='container lob-wrapper'>
        <FeedbackButton />
        <div className='row'>
          <div className='col-md-6 col-lg-9 display-flex'>
            <Button
              variant='outlined'
              className='btn-green new-quote-btn'
              classes={{ disabled: classes.buttonDisabled }}
              disableRipple={true}
              onClick={() => this.openOrganizationModalHandler()}
              disabled={orgConfigLoader}
            >
              <AddIcon className='add-icon-border mr-3' />
              New quote
            </Button>
          </div>
          {!inIframe() && (
            <div className='col-md-6 col-lg-3 d-flex justify-content-end'>
              <Button
                variant='outlined'
                className='btn-green w-100'
                style={{ marginRight: '0px' }}
                onClick={this.AMSBindModalOpen}
              >
                <img alt='ams button' className='ams-button-text' src={AMSButtonImg} />
              </Button>
            </div>
          )}
        </div>
        <div className='row'>
          <div className='col-md-12'>
            <Card className='CardWrapper'>
              <CardContent classes={{ root: classes.cardContent }}>
                <div className='row mb-3 ml-0'>
                  <div className='col-md-12 col-lg-3 margin-bottom-20'>
                    <div className='field-label'>Line of business</div>
                    <FilterSelector
                      disabled={insuredTableLoader}
                      options={filterOptions}
                      value={filterList}
                      appliedFilter={appliedFilter}
                      onChange={this.onFilterChange}
                      onApplyFilter={this.onApplyFilter}
                      onClearFilter={this.onClearFilter}
                      onFilterMenuClose={this.onFilterMenuClose}
                    />
                  </div>
                  <div className='col-md-12 col-lg-2 margin-bottom-20'>
                    <div className='field-label'>State</div>
                    <FilterSelector
                      disabled={insuredTableLoader}
                      options={stateFilterOptions}
                      value={stateFilterList}
                      appliedFilter={stateAppliedFilter}
                      onChange={(list: any) => this.onStateFilterChange(list)}
                      onClearFilter={() => this.onStateClearFilter()}
                      onFilterMenuClose={() => this.onStateFilterMenuClose()}
                      onApplyFilter={(filters: Array<any>) => this.onStateApplyFilter(filters)}
                    />
                  </div>
                  <div className='col-md-12 col-lg-4 margin-bottom-20'>
                    <div className='field-label'>Quote date</div>
                    <DateRangeSelector
                      dateRange={appliedDateRange}
                      onDateRangeApply={this.onDateRangeApply}
                      disableRange={orgConfigLoader || insuredTableLoader}
                    />
                  </div>
                  <div className='col-md-12 col-lg-3'>
                    <div className='field-label'>Search</div>
                    <InsuredDetailsSearch
                      handleSearch={this.insuredDetailsSearchHandler}
                      searchText={this.props.searchText}
                      disableSearch={orgConfigLoader || insuredTableLoader}
                    />
                  </div>
                </div>
                <div className='row m-2'>
                  <InsuredDetailsTable
                    filterList={appliedFilter}
                    statesFilterList={stateFilterList}
                    dateRange={dateRange}
                    totalRecords={insuredListDetails.total}
                    recordsList={insuredListDetails.list}
                    loader={false}
                    searchText={searchText}
                  />
                </div>
              </CardContent>
            </Card>
          </div>
        </div>

        <AdHocOrganizationModel
          isOpen={openAdhocOrganizationModel}
          orgList={adhocOrganizationList || []}
          onCloseModal={this.onCloseAdhoOrganizationModel}
          onSaveOrganization={this.onSaveAdhocOrganization}
          onChange={this.onChangeAdhocOrganization}
          loader={adhocOrganizationLoader}
          selectedAdhocOrganizationCode={selectedAdhocOrganizationCode}
          error={selectedAdhocOrganizationCodeError}
          organizationListErrorMessage={common?.quotingListErrorMessage || ''}
        />

        {showModal && (
          <>
            {isEnabledPRSv2Flow() ? (
              <QuoteOrganizationModel
                organization={organization}
                selectOrganization={this.selectOrganization}
                products={products}
                selectProduct={this.selectProduct}
                showModal={showModal}
                closeModal={this.closeModal}
                submitQuote={this.submitQuote}
                home={home}
                insuranceProductList={insuranceProducts}
                orgConfigLoader={orgConfigLoader}
                common={common}
              />
            ) : (
              <OrganizationModal
                organization={organization}
                selectOrganization={this.selectOrganization}
                products={products}
                selectProduct={this.selectProduct}
                showModal={showModal}
                closeModal={this.closeModal}
                submitQuote={this.submitQuote}
                home={home}
                insuranceProductList={insuranceProducts}
                orgConfigLoader={orgConfigLoader}
                common={common}
              />
            )}
          </>
        )}
        {this.showAlertBox()}
        {this.props.isLOBAMSDetails && (
          <AMSDetailsModal
            open={this.props.isLOBAMSDetails}
            type={''}
            quoteList={[]}
            closehandler={this.AMSModalCloseHandler}
            isQuote={false}
          />
        )}
        {this.getAMSSuccessModal()}
      </div>
    );
  }
}

const mapStateToProps = ({ dashboard, common, home, partner }: AppComponents.LineOfBusinessStore) => {
  const {
    adhocOrganizationList,
    adhocOrganizationLoader,
    appliedDateRange,
    insuredListDetails,
    insuredTableLoader,
    searchText,
  } = dashboard;
  const { orgConfigLoader, isLOBAMSDetails, AMSSuccess } = common;
  const { isProviderOneLogin } = partner;
  return {
    adhocOrganizationList,
    adhocOrganizationLoader,
    AMSSuccess,
    appliedDateRange,
    common,
    home,
    insuredListDetails,
    insuredTableLoader,
    isLOBAMSDetails,
    orgConfigLoader,
    searchText,
    isProviderOneLogin,
  };
};

const mapDispatchToProps = (dispatch: any): AppComponents.LineOfBusinessDispatch => {
  return bindActionCreators(
    {
      changeDateRange,
      clearQuotes,
      dashboardInit,
      dashboardSearch,
      fetchOrgConfig,
      fetchQuotingOrganizationList,
      getAdhocOrganizationList,
      getFilteredList,
      setAdhocOrganizationDetails,
      setAMSDetailsSuccess,
      setHomeState,
      setRedirectToViewQuoteFlag,
      showLOBAMSDetails,
      stopAllLobPolling,
      storeQuotingOrganizationList,
      storeSearchText,
      submitNewProducts,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)<any>(LineOfBusiness));
