export enum PrefillStatus {
  FAILED = 'failed',
  INCOMPLETE = 'incomplete',
  SUCCESS = 'success',
}

export enum RoofType {
  ARCHITECTURAL_SHINGLES = 'architectural_shingles',
  ASPHALT = 'asphalt',
  ASPHALT_EVENT_RATED = 'asphalt_event_rated',
  ASPHALT_FIBERGLASS_SHINGLES = 'asphalt_fiberglass_shingles',
  BUILT_UP = 'built_up',
  CLAY_TILE = 'clay_tile',
  CONCRETE_TILE = 'concrete_tile',
  FLAT_ROOF = 'flat_roof',
  METAL_DECKING = 'metal_decking',
  OTHER = 'other',
  SLATE_OR_TILE = 'slate_or_tile',
  SLATE_TILE = 'slate_tile',
  STEEL_OR_METAL = 'steel_or_metal',
  WOOD_SHINGLE_OR_SHAKE = 'wood_shingle_or_shake',
  WOOD_SHINGLES_OR_WOOD_SHAKES = 'wood_shingles_or_wood_shakes',
}

export enum RoofShape {
  GABLE = 'gable',
  HIP = 'hip',
  GAMBREL = 'gambrel',
  MANSARD = 'mansard',
  FLAT = 'flat',
  SHED = 'shed',
  COMPLEX_CUSTOM = 'custom',
}

export enum GarageType {
  ATTACHED = 'attached',
  BUILTIN = 'builtin',
  DETACHED = 'detached',
  BASEMENT = 'basement',
  CARPORT = 'carport',
  NONE = 'none',
}

export enum SlopeAngle {
  NONE = '0-15',
  MODERATE = '16-30',
  STEEP = '30+',
}

export enum ExteriorWallFinish {
  AL = 'AL',
  STL = 'STL',
  VL = 'VL',
  VLS = 'VLS',
  ASB = 'ASB',
  T111 = 'T111',
  BAB = 'BAB',
  W = 'W',
  CTG = 'CTG',
  BRED = 'BRED',
  TRED = 'TRED',
  BPIN = 'BPIN',
  TPIN = 'TPIN',
  FCEM = 'FCEM',
  FCEMS = 'FCEMS',
  SHN = 'SHN',
  WSS = 'WSS',
  RC = 'RC',
  LOG = 'LOG',
  SLOG6 = 'SLOG6',
  SLOG = 'SLOG',
  SLOG13 = 'SLOG13',
  SSTU = 'SSTU',
  STU = 'STU',
  BR = 'BR',
  BRC = 'BRC',
  SBR = 'SBR',
  SBC = 'SBC',
  STN = 'STN',
  STNC = 'STNC',
  SSTN = 'SSTN',
  LIME = 'LIME',
  COP = 'COP',
  RMP = 'RMP',
  CMP = 'CMP',
  BLD = 'BLD',
  N = 'N',
  other = 'other',
}

export enum FoundationType {
  BASEMENT = 'basement',
  HALF_BASEMENT = 'half basement',
  CONCRETE_SLAB = 'slab',
  CRAWLSPACE = 'crawlspace',
  PIER = 'pier',
  STILTS = 'stilts',
  STILTS_WALLS = 'stilts walls',
  PILINGS = 'pilings',
  UNKNOWN = 'unknown',
}

export enum FoundationShape {
  FOUR_TO_FIVE_CORNERS = '4-5 corners - square/rectangle',
  SIX_TO_SEVEN_CORNERS = '6-7 corners - l shape',
  EIGHT_TO_TEN_CORNERS = '8-10 corners - t,u,z shape',
  ELEVEN_TO_TWELVE_CORNERS = '11-12 corners - h or custom shape',
  THIRTEEN_PLUS_CORNERS = '13+ corners - irregular/complex',
}


export enum NumberOfStories {
  ONE = "1",
  ONE_AND_HALF = "1.5",
  TWO = "2",
  TWO_AND_HALF = "2.5",
  THREE_OR_MORE = "3+"
}

export enum ProtectionClass {
  ONE = '1',
  TWO = '2',
  THREE = '3',
  FOUR = '4',
  FIVE = '5',
  SIX = '6',
  SEVEN = '7',
  EIGHT = '8',
  NINE = '9',
  TEN = '10',
  ONEX = '1X',
  TWOX = '2X',
  THREEX = '3X',
  FOURX = '4X',
  FIVEX = '5X',
  SIXX = '6X',
  SEVENX = '7X',
  EIGHTX = '8X',
  ONEY = '1Y',
  TWOY = '2Y',
  THREEY = '3Y',
  FOURY = '4Y',
  FIVEY = '5Y',
  SIXY = '6Y',
  SEVENY = '7Y',
  NINEY = '9Y',
  TENY = '10Y',
  EIGHTY = '8Y',
  EIGHTB = '8B',
  EIGHTA = '8A',
  NINEA = '9A',
  NINEB = '9B',
  TENC = '10C',
  TENW = '10W',
  Protected1 = 'Protected1',
  Protected2 = 'Protected2',
  Protected3 = 'Protected3',
  Protected4 = 'Protected4',
  Protected5 = 'Protected5',
  PartiallyProtected1 = 'PartiallyProtected1',
  PartiallyProtected2 = 'PartiallyProtected2',
  PartiallyProtected3 = 'PartiallyProtected3',
  PartiallyProtected4 = 'PartiallyProtected4',
  PartiallyProtected5 = 'PartiallyProtected5',
  Unprotected6 = 'Unprotected6',
  Unprotected7 = 'Unprotected7',
  Unprotected8 = 'Unprotected8',
  Unprotected9 = 'Unprotected9',
  Unprotected10 = 'Unprotected10',
  Unprotected15 = 'Unprotected15',
  Unprotected20 = 'Unprotected20',
  Unprotected25 = 'Unprotected25',
  Unprotected30 = 'Unprotected30',
  Unprotected35 = 'Unprotected35',
  Unprotected40 = 'Unprotected40',
  Unprotected45 = 'Unprotected45',
  Unprotected45Plus = 'Unprotected45Plus',
}


export enum ConstructionType {
  FRAME = 'frame',
  STEEL = 'steel',
  MASONRY = 'masonry',
  CONCRETE = 'concrete',
  BRICK_VENEER = 'brick_veneer',
  STEEL_WITH_COMBUSTIBLE_FEATURES = 'steel_with_combustible_features',
  MASONRY_JOISTED = 'masonry_joisted',
  CONCRETE_OR_REINFORCED_CONCRETE_WITH_COMBUSTIBLE = 'concrete_or_reinforced_concrete_with_combustible',
}

export enum BasementFinishType {
  FINISHED = "Finished",
  UNFINISHED = "Unfinished",
  PARTIALLY_FINISHED = "Partially Finished"
}

export enum NumberOfFamilyUnits {
  SINGLE = "1",
  TWO = "2",
  THREE = "3",
  FOUR = "4",
  FIVE_OR_MORE = "5+"
}
