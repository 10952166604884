/* istanbul ignore file */
// App
export const APP_INIT = `APP_INIT`;

// Error
const errorEntity = '[error]';
export const ERROR_OCCURRED = `${errorEntity} ERROR_OCCURRED`;

// Auth
const userEntity = '[user]';
export const USER_LOGIN_PAGE = `${userEntity} USER_LOGIN_PAGE`;
export const SIGNIN_USER = `${userEntity} SIGNIN_USER`;
export const SIGNIN_PARTNER = `${userEntity} SIGNIN_PARTNER`; // for partner app, considering as a user
export const SIGNIN_USER_SUCCESS = `${userEntity} SIGNIN_USER_SUCCESS`;
export const SIGNOUT_USER = `${userEntity} SIGNOUT_USER`;
export const SET_BULK_USER = `${userEntity} SET_BULK_USER`;
export const SET_REPORT_USER = `${userEntity} SET_REPORT_USER`;
export const SET_ADMIN_UESR = `${userEntity} SET_ADMIN_UESR`;
export const SWITCH_APP = `${userEntity} SWITCH_APP`;

// UI
const uiEntity = '[ui]';
export const ON_SHOW_LOADER = `${uiEntity} ON_SHOW_LOADER`;
export const AUTO_LOGOUT = `${uiEntity} AUTO_LOGOUT`;

// Common [global]
const commonEntity = '[common]';
export const SET_PCONFIG = `${commonEntity} SET_PCONFIG`;
export const FLUSH_APP_DETAILS = `${commonEntity} FLUSH_APP_DETAILS`;
export const GET_AUTO_QUOTES_FAILED = `${commonEntity} GET_AUTO_QUOTES_FAILED`;
export const FETCH_AUTOLINE_DETAILS = `${commonEntity} FETCH_AUTOLINE_DETAILS`;
export const AUTOLINE_DETAILS_LOADER = `${commonEntity} AUTOLINE_DETAILS_LOADER`;
export const STORE_AUTOLINE_DETAILS = `${commonEntity} STORE_AUTOLINE_DETAILS`;
export const DELETE_DETAILS_LOADER = `${commonEntity} DELETE_DETAILS_LOADER`;
export const STEP_CHANGED = `${commonEntity} STEP_CHANGED`;
export const STEP_SUBMITTED = `${commonEntity} STEP_SUBMITTED`;
export const STEP_SUBMITTED_SUCCESS = `${commonEntity} STEP_SUBMITTED_SUCCESS`;
export const STEP_SUBMITTED_LOADER = `${commonEntity} STEP_SUBMITTED_LOADER`;
export const UPDATE_PCONFIG = `${commonEntity} UPDATE_PCONFIG`;
export const SET_HOMEQUOTE_FLAG = `${commonEntity} SET_HOMEQUOTE_FLAG`;
export const SET_HOMEADDRESS_CHECK = `${commonEntity} SET_HOMEADDRESS_CHECK`;
export const SET_VALID_DRIVER_FLAG = `${commonEntity} SET_VALID_DRIVER_FLAG`;
export const SET_MISSING_SPOUSE = `${commonEntity} SET_MISSING_SPOUSE`;
export const MISSING_DETAILS_DRIVERS = `${commonEntity} MISSING_DETAILS_DRIVERS`;
export const FETCH_ORG_CONFIG = `${commonEntity} FETCH_ORG_CONFIG`;
export const SET_ORG_CONFIG_LOADER = `${commonEntity} SET_ORG_CONFIG_LOADER`;
export const AUTO_REDIRECT_FLAG = `${commonEntity} AUTO_REDIRECT_FLAG`;
export const HOME_REDIRECT_FLAG = `${commonEntity} HOME_REDIRECT_FLAG`;
export const SET_EDIT_VEHICLE_FLAG = `${commonEntity} SET_EDIT_VEHICLE_FLAG`;
export const SET_AMS_DETAILS = `${commonEntity} SET_AMS_DETAILS`;
export const AMS_DETAILS_SUCCESS = `${commonEntity} AMS_DETAILS_SUCCESS`;
export const IS_SFDC_SUBMIT_SUCCESS = `${commonEntity} IS_SFDC_SUBMIT_SUCCESS`;
export const SET_AMS_DETAILS_LOADER = `${commonEntity} SET_AMS_DETAILS_LOADER`;
export const SET_SFDC_AMS_DETAILS_LOADER = `${commonEntity} SET_SFDC_AMS_DETAILS_LOADER`;
export const SHOW_AMS_MODAL = `${commonEntity} SHOW_AMS_MODAL`;
export const SET_AMS_ERROR = `${commonEntity} SET_AMS_ERROR`;
export const SET_SFDC_AMS_ERROR = `${commonEntity} SET_SFDC_AMS_ERROR`;
export const FETCH_AMS_DETAILS = `${commonEntity} FETCH_AMS_DETAILS`;
export const GET_CARRIER_UNDERWRITER_LIST = `${commonEntity} GET_CARRIER_UNDERWRITER_LIST`;
export const SET_CARRIER_UNDERWRITER_LIST = `${commonEntity} SET_CARRIER_UNDERWRITER_LIST`;
export const GET_CARRIER_UNDERWRITER_LIST_FOR_SFDC = `${commonEntity} GET_CARRIER_UNDERWRITER_LIST_FOR_SFDC`;
export const SET_CARRIER_UNDERWRITER_LIST_FOR_SFDC = `${commonEntity} SET_CARRIER_UNDERWRITER_LIST_FOR_SFDC`;
export const SET_CARRIER_UNDERWRITER_LOADER = `${commonEntity} SET_CARRIER_UNDERWRITER_LOADER`;
export const SET_MAX_ATTEMPT_REACHED = `${commonEntity} SET_MAX_ATTEMPT_REACHED`;
export const SET_LINE_OF_BUSINESS = `${commonEntity} SET_LINE_OF_BUSINESS`;
export const SET_REDIRECTED_FROM = `${commonEntity} SET_REDIRECTED_FROM`;
export const SHOW_LOB_AMS_DETAILS = `${commonEntity} SHOW_LOB_AMS_DETAILS`;
export const SHOW_LOB_AMS_FORM_DETAILS = `${commonEntity} SHOW_LOB_AMS_FORM_DETAILS`;
export const FETCH_LOB_AMS_DETAILS = `${commonEntity} FETCH_LOB_AMS_DETAILS`;
export const FETCH_SFDC_LOB_DETAILS = `${commonEntity} FETCH_SFDC_LOB_DETAILS`;
export const ADD_NEW_PRODUCT_TO_APPOINTMENTS = `${commonEntity} ADD_NEW_PRODUCT_TO_APPOINTMENTS`;
export const ADD_NEW_PRODUCT_TO_APPOINTMENTS_HELPER = `${commonEntity} ADD_NEW_PRODUCT_TO_APPOINTMENTS_HELPER`;
export const CLEAN_APPOINTMENTS_HELPER = `${commonEntity} CLEAN_APPOINTMENTS_HELPER`;
export const SET_REDIRECT_TO_VIEW_QUOTE_VALUE = `${commonEntity} SET_REDIRECT_TO_VIEW_QUOTE_VALUE`;
export const SHOW_GET_QUOTE_BUTTON_FLAG = `${commonEntity} SHOW_GET_QUOTE_BUTTON_FLAG`;
export const SET_SELECTED_BUSINESS_OPTION = `${commonEntity} SET_SELECTED_BUSINESS_OPTION`;
export const SET_FORM_HAS_CHANGED = `${commonEntity} SET_FORM_HAS_CHANGED`;
export const SET_POLICY_CARRIER_RESPONSE = `${commonEntity} SET_POLICY_CARRIER_RESPONSE`;
export const SET_APPLICANT_FORM_CHANGE = `${commonEntity} SET_APPLICANT_FORM_CHANGE`;
export const SET_IS_VERISK_LOADING = `${commonEntity} SET_IS_VERISK_LOADING`;
export const SET_IS_VERISK_FETCHED = `${commonEntity} SET_IS_VERISK_FETCHED`;
export const SET_SHOW_VERISK_VEHICLE_MODAL = `${commonEntity} SET_SHOW_VERISK_VEHICLE_MODAL`;
export const SET_SHOW_VERISK_DRIVER_MODAL = `${commonEntity} SET_SHOW_VERISK_DRIVER_MODAL`;
export const SET_SELECT_FIRST_DRIVER = `${commonEntity} SET_SELECT_FIRST_DRIVER`;
export const SUBMIT_ORG_PRODUCTS = `${commonEntity} SUBMIT_ORG_PRODUCTS`;
export const CLEAR_QUOTES = `${commonEntity} CLEAR_QUOTES`;
export const CLEAR_HELPERS = `${commonEntity} CLEAR_HELPERS`;
export const UPDATE_SELECTED_CARRIERS = `${commonEntity} UPDATE_SELECTED_CARRIERS`;
export const SAVE_SELECTED_CARRIER = `${commonEntity} SAVE_SELECTED_CARRIER`;
export const SAVE_SELECTED_CARRIER_LOADER = `${commonEntity} SAVE_SELECTED_CARRIER_LOADER`;
export const CLEAR_AUTO_QUOTE_STATE = `${commonEntity} CLEAR_AUTO_QUOTE_STATE`;
export const SET_SWITCH_DETAIL_FROM_DRIVER = `${commonEntity} SET_SWITCH_DETAIL_FROM_DRIVER`;
export const SET_RATE_PAGE_ENABLE = `${commonEntity} SET_RATE_PAGE_ENABLE`;
export const UPDATE_SELECTED_PRODUCTS = `${commonEntity} UPDATE_SELECTED_PRODUCTS`;
export const UPDATE_SELECTED_PRODUCT_LOADER = `${commonEntity} UPDATE_SELECTED_PRODUCT_LOADER`;
export const UPDATE_REQUOTE_PRODUCTS = `${commonEntity} UPDATE_REQUOTE_PRODUCTS`;
export const SAVE_APPLICANT_ERROR = `${commonEntity} SAVE_APPLICANT_ERROR`;
export const STORE_COMMON_DATA = `${commonEntity} STORE_COMMON_DATA`;
export const SET_ALLOW_EDIT_QUOTES = `${commonEntity} SET_ALLOW_EDIT_QUOTES`;
export const SET_PAGE_TRACKING = `${commonEntity} SET_PAGE_TRACKING`;
export const SET_LOB_PRIORITY = `${commonEntity} SET_LOB_PRIORITY`;
export const SET_HEAP_DETAILS_LOADED = `${commonEntity} SET_HEAP_DETAILS_LOADED`;
export const STOP_ALL_LOB_POLLING = `${commonEntity} STOP_ALL_LOB_POLLING`;
export const SET_PREFILL_CALL = `${commonEntity} SET_PREFILL_CALL`;
export const SET_LOB_REFRESH_LOADER = `${commonEntity} SET_LOB_REFRESH_LOADER`;
export const SET_PREFILL_DETAILS_FOR_REQUEST_BIND = `${commonEntity} SET_PREFILL_DETAILS_FOR_REQUEST_BIND`;

// Applicant
const applicantEntity = '[applicant]';
export const APPLICANT_FORM_SUBMIT = `${applicantEntity} APPLICANT_FORM_SUBMIT`;
export const APPLICANT_FORM_SUBMIT_SUCCESS = `${applicantEntity} APPLICANT_FORM_SUBMIT_SUCCESS`;
export const APPLICANT_FORM_SUBMIT_FAIL = `${applicantEntity} APPLICANT_FORM_SUBMIT_FAIL`;
// tslint:disable-next-line: max-line-length
export const APPLICANT_DETAILS_FROM_HOME_INSURANCE = `${applicantEntity} APPLICANT_DETAILS_FROM_HOME_INSURANCE`;
export const APPLICANT_FORM_LOADER = `${applicantEntity} APPLICANT_FORM_LOADER`;
export const VALIDATE_ADDRESS = `${applicantEntity} VALIDATE_ADDRESS`;
export const VALIDATE_ADDRESS_SUCCESS = `${applicantEntity} VALIDATE_ADDRESS_SUCCESS`;
export const VALIDATE_HOME_ADDRESS = `${applicantEntity} VALIDATE_HOME_ADDRESS`;
export const UPDATE_APPLICANT_DETAILS = `${applicantEntity} UPDATE_APPLICANT_DETAILS`;
export const UPDATE_APPLICANT_ADDRESS = `${applicantEntity} UPDATE_APPLICANT_ADDRESS`;
export const UPDATE_ADDRESS_INVALID_FLAG = `${applicantEntity} UPDATE_ADDRESS_INVALID_FLAG`;
export const SAVE_APPLICANT_DETAILS = `${applicantEntity} SAVE_APPLICANT_DETAILS`;
export const SET_APPLICANT_SUCCESS = `${applicantEntity} SET_APPLICANT_SUCCESS`;
export const SAVE_APPLICANT_LOADER = `${applicantEntity} SAVE_APPLICANT_LOADER`;
export const SET_APPLICANT_DETAILS = `${applicantEntity} SET_APPLICANT_DETAILS`;
export const SET_PREFILL_DETAILS = `${applicantEntity} SET_PREFILL_DETAILS`;
export const SET_USER_PROPERTY_INFORMATION = `${applicantEntity} SET_USER_PROPERTY_INFORMATION`;
export const SET_ADD_PRODUCT_SAVE_APPLICANT_LOADER = `${applicantEntity} SET_ADD_PRODUCT_SAVE_APPLICANT_LOADER`;
export const GET_PROPERTY_INFO_DETAILS = `${applicantEntity} GET_PROPERTY_INFO_DETAILS`;
export const SET_PROPERTY_INFO_LOADER = `${applicantEntity} SET_PROPERTY_INFO_LOADER`;

// Vehicle
const vehicleEntity = '[Vehicle]';
export const VEHICLE_INIT = `${vehicleEntity} VEHICLE_INIT`;
export const VEHICLE_TYPE_SELECTED = `${vehicleEntity} VEHICLE_TYPE_SELECTED`;
export const STORE_VEHICLE_TYPE_LIST = `${vehicleEntity} STORE_VEHICLE_TYPE_LIST`;
export const VEHICLE_SELECTOR_LOADER = `${vehicleEntity} VEHICLE_SELECTOR_LOADER`;
export const DELETE_VEHICLE_DETAILS = `${vehicleEntity} DELETE_VEHICLE_DETAILS`;
export const DELETE_VEHICLE_DETAILS_SUCCESS = `${vehicleEntity} DELETE_VEHICLE_DETAILS_SUCCESS`;
export const EDIT_SELECTED_VEHICLE = `${vehicleEntity} EDIT_SELECTED_VEHICLE`;
export const ADD_CURRENT_VEHICLE = `${vehicleEntity} ADD_CURRENT_VEHICLE`;
export const SAVE_BEFORE_EDIT = `${vehicleEntity} SAVE_BEFORE_EDIT`;
export const SET_VEHICLE_COVERAGES = `${vehicleEntity} SET_VEHICLE_COVERAGES`;
export const UPDATE_VEHICLE_NAME = `${vehicleEntity} UPDATE_VEHICLE_NAME`;
export const VEHICLE_CONTINUE = `${vehicleEntity} VEHICLE_CONTINUE`;
export const VEHICLE_BACK = `${vehicleEntity} VEHICLE_BACK`;
export const VEHICLE_SELECT = `${vehicleEntity} VEHICLE_SELECT`;
// Set missing vehicles flag to show modal.
export const SET_VEHICLES_MISSING = `${vehicleEntity} SET_VEHICLES_MISSING`;
// Set body type when return from view quote
export const SET_BODY_TYPE_FROM_VIEW_QUOTE = `${vehicleEntity} SET_BODY_TYPE_FROM_VIEW_QUOTE`;
export const STORE_QUOTE_VEHICLE_TYPE_LIST = `${vehicleEntity} STORE_QUOTE_VEHICLE_TYPE_LIST`;
export const SAVE_VEHICLE_UPDATES = `${vehicleEntity} SAVE_VEHICLE_UPDATES`;
export const VIN_BACK = `${vehicleEntity} VIN_BACK`;
export const TOGGLE_VEHICLE_INCLUDED_STATUS = `${vehicleEntity} TOGGLE_VEHICLE_INCLUDED_STATUS`;
export const TOGGLE_VEHICLE_INCLUDED_STATUS_SUCCESS = `${vehicleEntity} TOGGLE_VEHICLE_INCLUDED_STATUS_SUCCESS`;
export const SORT_VEHICLE = `${vehicleEntity} SORT_VEHICLE`;
export const GET_STORED_VEHICLES_LIST = `${vehicleEntity} GET_STORED_VEHICLES_LIST`;
export const SET_VEHICLE_LIST = `${vehicleEntity} SET_VEHICLE_LIST`;
export const CLEAR_AUTO_DATA_ON_APPLICANT_CHANGES = `${vehicleEntity} CLEAR_AUTO_DATA_ON_APPLICANT_CHANGES`;

// Vehicle selector
export const ADD_VEHICLE = `${vehicleEntity} ADD_VEHICLE`;
export const VEHICLE_LOADER = `${vehicleEntity} VEHICLE_LOADER`;
// Update vehicle list for new vehicle
export const UPDATE_VEHICLE_LIST = `${vehicleEntity} UPDATE_VEHICLE_LIST`;
// Update vehicle list when continue existing vehicle
export const UPDATE_CONTINUE_VEHICLE_LIST = `${vehicleEntity} UPDATE_CONTINUE_VEHICLE_LIST`;
// Clear vehicle list
export const CLEAR_VEHICLE_LIST = `${vehicleEntity} CLEAR_VEHICLE_LIST`;
// Toggle between vehicle selector and vehicle editor
export const SET_VEHICLE_SELECTED = `${vehicleEntity} SET_VEHICLE_SELECTED`;

// VIN input
export const SET_VIN_SERVER_ERROR = `${vehicleEntity} SET_VIN_SERVER_ERROR`;
export const VIN_SUBMIT = `${vehicleEntity} VIN_SUBMIT`;
export const VIN_SUBMIT_SUCCESS = `${vehicleEntity} VIN_SUBMIT_SUCCESS`;
export const SET_VIN_LOADER = `${vehicleEntity} SET_VIN_LOADER`;
export const VIN_CLEARED = `${vehicleEntity} VIN_CLEARED`;
export const SET_VIN_ERROR_FLAG = `${vehicleEntity} SET_VIN_ERROR_FLAG`;
export const RESTORE_VEHICLE_DETAILS = `${vehicleEntity} RESTORE_VEHICLE_DETAILS`;
export const COMMON_VEHICLE_ACTION = `${vehicleEntity} COMMON_VEHICLE_ACTION`;

// Driver
const driverEntity = '[Driver]';
export const DRIVER_INIT = `${driverEntity} DRIVER_INIT`;
export const ADD_DRIVER = `${driverEntity} ADD_DRIVER`;
export const DRIVER_LOADER = `${driverEntity} DRIVER_LOADER`;
export const UPDATE_DRIVER_NAME = `${driverEntity} UPDATE_DRIVER_NAME`;
export const UPDATE_DRIVER_LIST = `${driverEntity} UPDATE_DRIVER_LIST`;
export const SAVE_DRIVER_BEFORE_EDIT = `${driverEntity} SAVE_DRIVER_BEFORE_EDIT`;
export const DELETE_DRIVER_DETAILS = `${driverEntity} DELETE_DRIVER_DETAILS`;
export const DELETE_DRIVER_DETAILS_SUCCESS = `${driverEntity} DELETE_DRIVER_DETAILS_SUCCESS`;
export const EDIT_SELECTED_DRIVER = `${driverEntity} EDIT_SELECTED_DRIVER`;
export const UPDATE_ACCIDENT_DETAILS = `${driverEntity} UPDATE_ACCIDENT_DETAILS`;
export const CLEAR_DRIVER_ACCIDENT_LIST = `${driverEntity} CLEAR_DRIVER_ACCIDENT_LIST`;
export const CLEAR_DRIVER_VIOLATION_LIST = `${driverEntity} CLEAR_DRIVER_VIOLATION_LIST`;
export const CLEAR_DRIVER_LOSS_LIST = `${driverEntity} CLEAR_DRIVER_LOSS_LIST`;
export const ASSIGN_SPOUSE = `${driverEntity} ASSIGN_SPOUSE`;
export const ADD_DRIVER_FROM_OTHERDETAILS = `${driverEntity} ADD_DRIVER_FROM_OTHERDETAILS`;
export const CHECK_VALID_DRIVER = `${driverEntity} CHECK_VALID_DRIVER`;
export const UPDATE_DRIVER_OTHER_DETAILS = `${driverEntity} UPDATE_DRIVER_OTHER_DETAILS`;
export const DRIVER_CONTINUE = `${driverEntity} DRIVER_CONTINUE`;
export const DRIVER_BACK = `${driverEntity} DRIVER_BACK`;
export const SPOUSE_EXISTS = `${driverEntity} SPOUSE_EXISTS`;
export const UPDATE_DRIVER_DATA = `${driverEntity} UPDATE_DRIVER_DATA`;
export const SET_INCIDENTS_FLAG = `${driverEntity} SET_INCIDENTS_FLAG`;
export const SET_POLICY_FLAG = `${driverEntity} SET_POLICY_FLAG`;
export const SET_FETCH_AUTO_QUOTES = `${driverEntity} SET_FETCH_AUTO_QUOTES`;
export const SET_GO_TO_RATES_TAB_FROM_POLICY = `${driverEntity} SET_GO_TO_RATES_TAB_FROM_POLICY`;
export const UPDATE_SPECIFIC_DRIVER = `${driverEntity} UPDATE_SPECIFIC_DRIVER`;
export const TOGGLE_DRIVER_INCLUDED_STATUS = `${driverEntity} TOGGLE_DRIVER_INCLUDED_STATUS`;
export const TOGGLE_DRIVER_INCLUDED_STATUS_SUCCESS = `${driverEntity} TOGGLE_DRIVER_INCLUDED_STATUS_SUCCESS`;
export const SORT_DRIVER = `${driverEntity} SORT_DRIVER`;
export const GET_STORED_DRIVERS_LIST = `${driverEntity} GET_STORED_DRIVERS_LIST`;

// Clear Driver State
export const CLEAR_DRIVER_STATE = `${driverEntity} CLEAR_DRIVER_STATE`;

// Accidents, Claims, Damages or Violations
const otherEntity = '[Accident/Losses]';
export const INIT_ACCIDENTS = `${otherEntity} INIT_ACCIDENTS`;
export const INIT_VIOLATIONS = `${otherEntity} INIT_VIOLATIONS`;
export const INIT_LOSSES = `${otherEntity} INIT_LOSSES`;
export const SET_OTHER_ERRORS = `${otherEntity} SET_OTHER_ERRORS`;
export const SET_OTHER_DETAILS = `${otherEntity} SET_OTHER_DETAILS`;
export const CLEAR_ACCIDENT_LIST = `${otherEntity} CLEAR_ACCIDENT_LIST`;
export const CLEAR_VIOLATION_LIST = `${otherEntity} CLEAR_VIOLATION_LIST`;
export const CLEAR_LOSS_LIST = `${otherEntity} CLEAR_LOSS_LIST`;

export const ACCIDENT_FIELD_UPDATED = `${otherEntity} ACCIDENT_FIELD_UPDATED`;
export const VIOLATION_FIELD_UPDATED = `${otherEntity} VIOLATION_FIELD_UPDATED`;
export const LOSS_FIELD_UPDATED = `${otherEntity} LOSS_FIELD_UPDATED`;
export const ACCIDENT_FIELD_DELETED = `${otherEntity} ACCIDENT_FIELD_DELETED`;
export const VIOLATION_FIELD_DELETED = `${otherEntity} VIOLATION_FIELD_DELETED`;
export const LOSS_FIELD_DELETED = `${otherEntity} LOSS_FIELD_DELETED`;

// Coverage
const coverageEntity = '[Coverage]';
export const ADD_COVERAGE = `${coverageEntity} ADD_COVERAGE`;
export const CLEAR_COVERAGE = `${coverageEntity} CLEAR_COVERAGE`;
export const SET_COVERAGE = `${coverageEntity} SET_COVERAGE`;

// Insurance
const insuranceEntity = '[Insurance]';
export const ADD_INSURANCE_DETAILS = `${insuranceEntity} ADD_INSURANCE_DETAILS`;
export const GENERAL_COVERAGES = `${insuranceEntity} GENERAL_COVERAGES`;
export const CLEAR_INSURANCE_DETAILS = `${insuranceEntity} CLEAR_INSURANCE_DETAILS`;

// Quotes
const quoteEntity = '[Quote]';
export const INIT_AUTO_QUOTES = `${quoteEntity} INIT_AUTO_QUOTES`;
export const CLEAR_POLLING_DETAILS = `${quoteEntity} CLEAR_POLLING_DETAILS`;
export const INIT_POLLING = `${quoteEntity} INIT_POLLING`;
export const START_POLLING = `${quoteEntity} START_POLLING`;
export const STOP_POLLING = `${quoteEntity} STOP_POLLING`;
export const AUTO_TIMEOUT_POLLING = `${quoteEntity} AUTO_TIMEOUT_POLLING`;
export const HOME_TIMEOUT_POLLING = `${quoteEntity} HOME_TIMEOUT_POLLING`;
export const SEND_CALL_DETAILS = `${quoteEntity} SEND_CALL_DETAILS`;
export const FLUSH_QUOTE_DETAILS = `${quoteEntity} FLUSH_QUOTE_DETAILS`;
export const SET_QUOTES_LOADED = `${quoteEntity} SET_QUOTES_LOADED`;
export const REFRESH_AUTO_QUOTES = `${quoteEntity} REFRESH_AUTO_QUOTES`;

export const UPDATE_QUOTE_DETAILS = `${quoteEntity} UPDATE_QUOTE_DETAILS`;
export const QUOTES_LOADING = `${quoteEntity} QUOTES_LOADING`;
export const SET_POLLING_DETAILS = `${quoteEntity} SET_POLLING_DETAILS`;
export const POLLING_COMPLETED = `${quoteEntity} POLLING_COMPLETED`;
export const INIT_VIEW_QUOTES = `${quoteEntity} INIT_VIEW_QUOTES`;
export const SET_POLLING_ERROR_DETAILS = `${quoteEntity} SET_POLLING_ERROR_DETAILS`;
export const UPDATE_EZLYNX_ERROR = `${quoteEntity} UPDATE_EZLYNX_ERROR`;
export const SET_QUOTE_COMPLETED = `${quoteEntity} SET_QUOTE_COMPLETED`;
export const SET_AUTO_QUOTE_BIND = `${quoteEntity} SET_AUTO_QUOTE_BIND`;
export const SET_ERROR_LIST = `${quoteEntity} SET_ERROR_LIST`;
export const GET_QUOTE_LOB_DETAILS = `${quoteEntity} GET_QUOTE_LOB_DETAILS`;
export const SET_QUOTE_LOB_DETAILS = `${quoteEntity} SET_QUOTE_LOB_DETAILS`;
export const SET_AUTO_QUOTE_DETAILS = `${quoteEntity} SET_AUTO_QUOTE_DETAILS`;
export const CLEAR_QUOTE_LOB_DETAILS = `${quoteEntity} CLEAR_QUOTE_LOB_DETAILS`;
export const SAVE_AUTO_QUOTE_DETAILS = `${quoteEntity} SAVE_AUTO_QUOTE_DETAILS`;
export const REQUEST_VERISK_API = `${quoteEntity} REQUEST_VERISK_API`;
export const CLEAR_AUTO_QUOTE_DETAILS = `${quoteEntity} CLEAR_AUTO_QUOTE_DETAILS`;
export const SET_VIEW_QUOTE_LOADER = `${quoteEntity} SET_VIEW_QUOTE_LOADER`;
export const SET_AUTO_DOWNLOAD_QUOTE_LOADER = `${quoteEntity} SET_AUTO_DOWNLOAD_QUOTE_LOADER`;
export const DOWNLOAD_AUTO_QUOTE_DETAILS = `${quoteEntity} DOWNLOAD_AUTO_QUOTE_DETAILS`;

// Dashboard
const dashboardEntity = '[Dashboard]';
export const DASHBOARD_INIT = `${dashboardEntity} DASHBOARD_INIT`;
export const SET_INSURED_LIST = `${dashboardEntity} SET_INSURED_LIST`;
export const TABLE_PAGE_CHANGED = `${dashboardEntity} TABLE_PAGE_CHANGED`;
export const SET_INSURED_TABLE_LOADER = `${dashboardEntity} SET_INSURED_TABLE_LOADER`;
export const FLUSH_DASHBOARD_DETAILS = `${dashboardEntity} FLUSH_DASHBOARD_DETAILS`;
export const DASHBOARD_SEARCH = `${dashboardEntity} DASHBOARD_SEARCH`;
export const STORE_SEARCH_TEXT = `${dashboardEntity} STORE_SEARCH_TEXT`;
export const AUTOFILL_VISITED_PAGES = `${dashboardEntity} AUTOFILL_VISITED_PAGES`;
export const GET_FILTERED_LIST = `${dashboardEntity} GET_FILTERED_LIST`;
export const CLEAR_INSURED_LIST = `${dashboardEntity} CLEAR_INSURED_LIST`;
export const SET_APPLIED_FILTERS = `${dashboardEntity} SET_APPLIED_FILTERS`;
export const CHANGE_DATE_RANGE = `${dashboardEntity} CHANGE_DATE_RANGE`;
export const SET_DATE_RANGE = `${dashboardEntity} SET_DATE_RANGE`;
export const ADHOC_BIND_SEARCH = `${dashboardEntity} ADHOC_BIND_SEARCH`;
export const SET_INSURED_DROP_DOWN_LOADER = `${dashboardEntity} SET_INSURED_DROP_DOWN_LOADER`;
export const ADHOC_BIND_ORGANIZATION_LIST_LOADER = `${dashboardEntity} ADHOC_BIND_ORGANIZATION_LIST_LOADER`;
export const FETCH_ADHOC_BIND_ORGANIZATION_LIST = `${dashboardEntity} FETCH_ADHOC_BIND_ORGANIZATION_LIST`;
export const FETCH_SFDC_AMS_FORM_DATA = `${dashboardEntity} FETCH_SFDC_AMS_FORM_DATA `;
export const SET_SFDC_AMS_FORM_DATA = `${dashboardEntity} SET_SFDC_AMS_FORM_DATA `;
export const STORE_ADHOC_BIND_ORGANIZATION_LIST = `${dashboardEntity} STORE_ADHOC_BIND_ORGANIZATION_LIST`;

// Home line
const homeEntity = '[Home Product]';
export const FETCH_PCONFIG = `${homeEntity} FETCH_PCONFIG`;
export const SET_CONFIG_LOADER = `${homeEntity} SET_CONFIG_LOADER`;
export const SET_IS_CONDO = `${homeEntity} SET_IS_CONDO`;
export const SET_PREFERRED_DEDUCTIBLE = `${homeEntity} SET_PREFERRED_DEDUCTIBLE`;
export const SET_HOME_STATE = `${homeEntity} SET_HOME_STATE`;
export const SET_HOME_CODE = `${homeEntity} SET_HOME_CODE`;
export const HOME_APPLICATION_SUBMIT = `${homeEntity} HOME_APPLICATION_SUBMIT`;
export const SET_VALIDATING_ADDRESS = `${homeEntity} SET_VALIDATING_ADDRESS`;
export const HOME_SIDEBAR_EDIT = `${homeEntity} HOME_SIDEBAR_EDIT`;
export const CLEAR_HOME_PCONFIG = `${homeEntity} CLEAR_HOME_PCONFIG`;

// Result page action
export const SET_RESULT_DETAILS = `${homeEntity} SET_RESULT_DETAILS`;
export const FETCH_HOME_QUOTE_DETAILS = `${homeEntity} FETCH_HOME_QUOTE_DETAILS`;
export const INIT_HOME_QUOTE_POLLING = `${homeEntity} INIT_HOME_QUOTE_POLLING`;
export const STOP_HOME_QUOTE_POLLING = `${homeEntity} STOP_HOME_QUOTE_POLLING`;
// Cancel and reset home quoting details.
export const CANCEL_HOME_QUOTE_POLLING = `${homeEntity} CANCEL_HOME_QUOTE_POLLING`;
export const UPDATE_HOME_QUOTE_LIST = `${homeEntity} UPDATE_HOME_QUOTE_LIST`;
// View quotes page actions
export const FETCH_HOME_VIEW_QUOTES = `${homeEntity} FETCH_HOME_VIEW_QUOTES`;
export const SET_HOME_VIEW_QUOTES = `${homeEntity} SET_HOME_VIEW_QUOTES`;
export const HOME_VIEW_QUOTES_LOADER = `${homeEntity} HOME_VIEW_QUOTES_LOADER`;
export const FLUSH_HOME_DETAILS = `${homeEntity} FLUSH_HOME_DETAILS`;
export const SET_HOME_QUOTE_BIND = `${homeEntity} SET_HOME_QUOTE_BIND`;
export const SET_LOB_UPDATED = `${homeEntity} SET_LOB_UPDATED`;
export const CLEAR_APPLICANT_STATE = `${homeEntity} CLEAR_APPLICANT_STATE`;
export const CLEAR_HOME_STATE = `${homeEntity} CLEAR_HOME_STATE`;
export const SET_NO_QUOTE_AVAILABLE_FOR_HOME = `${homeEntity} SET_NO_QUOTE_AVAILABLE_FOR_HOME`;
export const SET_DOWNLOAD_QUOTE_LOADER = `${homeEntity} SET_DOWNLOAD_QUOTE_LOADER`;
export const DOWNLOAD_QUOTE_DETAILS = `${homeEntity} DOWNLOAD_QUOTE_DETAILS`;

// Bulk Quote
const bulkQuoteEntity = '[Bulk Quote]';
export const BULK_QUOTE_INIT = `${bulkQuoteEntity} BULK_QUOTE_INIT`;
export const SET_BULK_QUOTE_TABLE_LOADER = `${bulkQuoteEntity} SET_BULK_QUOTE_TABLE_LOADER`;
export const SET_BULK_QUOTE_LIST = `${bulkQuoteEntity} SET_BULK_QUOTE_LIST`;
export const SET_PAGE_LOADER = `${bulkQuoteEntity} SET_PAGE_LOADER`;
export const TERMINATE_RECORD = `${bulkQuoteEntity} TERMINATE_RECORD`;
export const SHOW_TERMINATED_MESSAGE = `${bulkQuoteEntity} SHOW_TERMINATED_MESSAGE`;
export const SET_TERMINATED_RECORD = `${bulkQuoteEntity} SET_TERMINATED_RECORD`;
export const OPEN_MODAL = `${bulkQuoteEntity} OPEN_MODAL`;
export const UPDATE_STEP = `${bulkQuoteEntity} UPDATE_STEP`;
export const SUBMIT_BULK_QUOTE_STEP = `${bulkQuoteEntity} SUBMIT_BULK_QUOTE_STEP`;
export const SELECTOR_LOADER = `${bulkQuoteEntity} SELECTOR_LOADER`;
export const SET_SERVER_ERROR = `${bulkQuoteEntity} SET_SERVER_ERROR`;
export const UPLOAD_SELECTED_FILE = `${bulkQuoteEntity} UPLOAD_SELECTED_FILE`;
export const STEPS_COMPLETED = `${bulkQuoteEntity} STEPS_COMPLETED`;
export const SET_ORGANIZATION_DETAILS = `${bulkQuoteEntity} SET_ORGANIZATION_DETAILS`;
export const SET_SELECTED_CARRIER_LIST = `${bulkQuoteEntity} SET_SELECTED_CARRIER_LIST`;
export const RESET_STEP_DETAILS = `${bulkQuoteEntity} RESET_STEP_DETAILS`;
export const RESTORE_BULK_QUOTE_DETAILS = `${bulkQuoteEntity} RESTORE_BULK_QUOTE_DETAILS`;
export const SET_TERMINATE_SUCCESS = `${bulkQuoteEntity} SET_TERMINATE_SUCCESS`;
export const SET_TERMINATE_MESSAGE = `${bulkQuoteEntity} SET_TERMINATE_MESSAGE`;
export const DOWNLOAD_FILE = `${bulkQuoteEntity} DOWNLOAD_FILE`;
export const PROCESS_RECORD = `${bulkQuoteEntity} PROCESS_RECORD`;
export const SET_ACTION_LOADER = `${bulkQuoteEntity} SET_ACTION_LOADER`;
export const UPDATE_PARTNER_CONFIG = `${bulkQuoteEntity} UPDATE_PARTNER_CONFIG`;
export const SET_SERVER_MESSAGE = `${bulkQuoteEntity} SET_SERVER_MESSAGE`;

// AMS Modal
const AMSEntity = '[AMS]';
export const AMS_DETAILS_SUBMIT = `${AMSEntity} AMS_DETAILS_SUBMIT`;
export const SFDC_AMS_DETAILS_SUBMIT = `${AMSEntity} SFDC_AMS_DETAILS_SUBMIT`;
export const SET_POLICY_BOUNDED = `${AMSEntity} SET_POLICY_BOUNDED`;
export const DISABLE_DIVISION = `${AMSEntity} DISABLE_DIVISION`;
export const SET_AMS_LOB_DETAILS = `${AMSEntity} SET_AMS_LOB_DETAILS`;
export const SET_AMS_LOB_CHANGED = `${AMSEntity} SET_AMS_LOB_CHANGED`;
export const FETCH_REPORT_DETAILS = `${AMSEntity} FETCH_REPORT_DETAILS`;
export const SET_REPORT_LOADER = `${AMSEntity} SET_REPORT_LOADER`;
export const SET_REPORT_DETAILS = `${AMSEntity} SET_REPORT_DETAILS`;
export const REMOVE_SAVED_FILTER = `${AMSEntity} REMOVE_SAVED_FILTER`;
export const SET_REMOVE_FILTER_LOADER = `${AMSEntity} SET_REMOVE_FILTER_LOADER`;
export const UPDATE_SAVED_FILTERS = `${AMSEntity} UPDATE_SAVED_FILTERS`;
export const LOB_FILTER_UPDATED = `${AMSEntity} LOB_FILTER_UPDATED`;
export const SAVED_FILTER_SELECTED = `${AMSEntity} SAVED_FILTER_SELECTED`;
export const UPDATE_REPORT_CURRENT_DETAILS = `${AMSEntity} UPDATE_REPORT_CURRENT_DETAILS`;
export const SUBMIT_REPORT_DETAILS = `${AMSEntity} SUBMIT_REPORT_DETAILS`;
export const SET_SUBMIT_REPORT_LOADER = `${AMSEntity} SET_SUBMIT_REPORT_LOADER`;
export const SET_REPORT_API_ERROR = `${AMSEntity} SET_REPORT_API_ERROR`;
export const SET_ADHOC_ORGANIZATION_DETAILS = `${AMSEntity} SET_ADHOC_ORGANIZATION_DETAILS`;
export const SET_SFDC_ORGANIZATION_DETAILS = `${AMSEntity} SET_SFDC_ORGANIZATION_DETAILS`;

// Flood detail questions
const floodEntity = '[Flood]';
export const SHOW_FLOOD_CONFIRMATION_MODAL = `${floodEntity} SHOW_FLOOD_CONFIRMATION_MODAL`;
export const SHOW_FLOOD_DETAILS_MODAL = `${floodEntity} SHOW_FLOOD_DETAILS_MODAL`;
export const SET_PREFILL_DATA = `${floodEntity} SET_PREFILL_DATA`;
export const SET_FLOOD_DETAILS_LOADER = `${floodEntity} SET_FLOOD_DETAILS_LOADER`;
export const QUESTIONNAIRE_SUBMIT = `${floodEntity} QUESTIONNAIRE_SUBMIT`;
export const QUESTIONNAIRE_BACK = `${floodEntity} QUESTIONNAIRE_BACK`;
export const QUESTIONNAIRE_SUBMIT_LOADER = `${floodEntity} QUESTIONNAIRE_SUBMIT_LOADER`;
export const SET_NEXT_TAB_STEP = `${floodEntity} SET_NEXT_TAB_STEP`;
export const SET_FLOOD_REQUEST_SUCCESS = `${floodEntity} SET_FLOOD_REQUEST_SUCCESS`;
export const SET_ANNEX_PREFILL = `${floodEntity} SET_ANNEX_PREFILL`;
export const CLEAR_FLOOD_STATE = `${floodEntity} CLEAR_FLOOD_STATE`;
export const FETCH_ANNEX_QUOTES = `${floodEntity} FETCH_ANNEX_QUOTES`;
export const AUTO_SUBMIT_ANNEX = `${floodEntity} AUTO_SUBMIT_ANNEX`;
export const SWITCH_FLOOD_MODAL = `${floodEntity} SWITCH_FLOOD_MODAL`;
export const TRACK_ANNEX_DETAILS = `${floodEntity} TRACK_ANNEX_DETAILS`;
export const SET_FLOOD_QUOTES = `${floodEntity} SET_FLOOD_QUOTES`;
export const SET_FLOOD_QUOTE_BIND = `${floodEntity} SET_FLOOD_QUOTE_BIND`;
export const SET_FLOOD_HERD_ID = `${floodEntity} SET_FLOOD_HERD_ID`;
export const FETCH_FLOOD_QUOTE_DETAILS = `${floodEntity} FETCH_FLOOD_QUOTE_DETAILS`;
export const GET_BRIDGE_TO_CARRIER_DETAILS_FOR_FLOOD = `${floodEntity} GET_BRIDGE_TO_CARRIER_DETAILS_FOR_FLOOD`;
export const FLOOD_BRIDGE_TO_CARRIER_LOADER = `${floodEntity} FLOOD_BRIDGE_TO_CARRIER_LOADER`;
export const INIT_FLOOD_POLLING = `${floodEntity} INIT_FLOOD_POLLING`;
export const FLOOD_TIMEOUT_POLLING = `${floodEntity} FLOOD_TIMEOUT_POLLING`;
export const STOP_FLOOD_POLLING = `${floodEntity} STOP_FLOOD_POLLING`;
export const SET_FLOOD_DETAILS = `${floodEntity} SET_FLOOD_DETAILS`;
export const SET_FLOOD_POLLING_DETAILS = `${floodEntity} SET_FLOOD_POLLING_DETAILS`;
export const SET_FLOOD_POLLING_ERROR_DETAILS = `${floodEntity} SET_FLOOD_POLLING_ERROR_DETAILS`;
export const REFRESH_FLOOD_QUOTES = `${floodEntity} REFRESH_FLOOD_QUOTES`;

// Partner
const partnerEntity = '[Partner]';
export const PARTNER_REQUEST_APPOINTMENTS = `${partnerEntity} PARTNER_REQUEST_APPOINTMENTS`;
export const ON_SHOW_APPOINTMENTS_LOADER = `${partnerEntity} ON_SHOW_APPOINTMENTS_LOADER`;
export const SET_IS_AUTH_PROVIDER_ONELOGIN = `${partnerEntity} SET_IS_AUTH_PROVIDER_ONELOGIN`;
export const SET_APPOINTMENTS = `${partnerEntity} SET_APPOINTMENTS`;
export const SET_REQUEST_ID = `${partnerEntity} SET_REQUEST_ID`;
export const CLEAR_QUOTE_DETAILS = `${partnerEntity} CLEAR_QUOTE_DETAILS`;
export const CLEAR_PARTNER_STATE = `${partnerEntity} CLEAR_PARTNER_STATE`;
export const SET_PARTNER_DETAILS = `${partnerEntity} SET_PARTNER_DETAILS`;

// Theme
const themeEntity = '[Theme]';
export const SET_THEME = `${themeEntity} SET_THEME`;
// Organizations
const organizationEntity = [`Organiztions`];
export const ORGANIZATION_LIST_LOADER = `${organizationEntity} ORGANIZATION_LIST_LOADER`;
export const FETCH_ORGANIZATION_LIST = `${organizationEntity} FETCH_ORGANIZATION_LIST`;
export const STORE_ORGANIZATION_LIST = `${organizationEntity} STORE_ORGANIZATION_LIST`;
export const GET_ORGANIZATION_DATA = `${organizationEntity} GET_ORGANIZATION_DATA`;
export const STORE_ORGANIZATION_DATA = `${organizationEntity} STORE_ORGANIZATION_DATA`;
export const ADD_EDIT_ORGANIZATION_LOADER = `${organizationEntity} ADD_EDIT_ORGANIZATION_LOADER`;
export const ADD_NEW_ORGANIZATION = `${organizationEntity} ADD_NEW_ORGANIZATION`;
export const STORE_NEW_ORGANIZATION = `${organizationEntity} STORE_NEW_ORGANIZATION`;
export const UPDATE_ORGANIZATION_DATA = `${organizationEntity} UPDATE_ORGANIZATION_DATA`;
export const STORE_UPDATED_ORGANIZATION_DATA = `${organizationEntity} STORE_UPDATED_ORGANIZATION_DATA`;
export const SET_ORG_UPDATED_RESPONSE_DATA = `${organizationEntity} SET_ORG_UPDATED_RESPONSE_DATA`;
export const STORE_CARRIER_APPOINTMENTS_DATA = `${organizationEntity} STORE_CARRIER_APPOINTMENTS_DATA`;
export const DELETE_CARRIER_APPOINTMENTS_DATA = `${organizationEntity} DELETE_CARRIER_APPOINTMENTS_DATA`;
export const REMOVE_CARRIER_APPOINTMENTS_FROM_LIST = `${organizationEntity} REMOVE_CARRIER_APPOINTMENTS_FROM_LIST`;
export const GET_ORGANIZATION_LOAGER = `${organizationEntity} GET_ORGANIZATION_LOAGER`;
export const FETCH_LEAD_TYPE_LIST = `${organizationEntity} FETCH_LEAD_TYPE_LIST`;
export const LEAD_TYPE_LIST_LOADER = `${organizationEntity} LEAD_TYPE_LIST_LOADER`;
export const STORE_LEAD_TYPE_LIST_DATA = `${organizationEntity} STORE_LEAD_TYPE_LIST_DATA`;
export const SET_ORG_ACCOUNTS_LIST_DATA = `${organizationEntity} SET_ORG_ACCOUNTS_LIST_DATA`;
export const SET_DUPLICATE_LEAD_ID_ERROR_MESSAGE = `${organizationEntity} SET_DUPLICATE_LEAD_ID_ERROR_MESSAGE`;
export const HCR_IFRAME_CLIENT_LIST_LOADER = `${organizationEntity} HCR_IFRAME_CLIENT_LIST_LOADER`;
export const FETCH_HCR_IFRAME_CLIENT = `${organizationEntity} FETCH_HCR_IFRAME_CLIENT`;
export const STORE_HCR_IFRAME_SEETINGS_LIST = `${organizationEntity} STORE_HCR_IFRAME_SEETINGS_LIST`;
export const ADD_AND_UPDATE_HCR_IFRAME_SETTINGS = `${organizationEntity} ADD_AND_UPDATE_HCR_IFRAME_SETTINGS`;
export const STORE_HCR_IFRAME_SETTINGS = `${organizationEntity} STORE_HCR_IFRAME_SETTINGS`;
export const DELETE_HCR_IFRAME_SETTINGS = `${organizationEntity} DELETE_HCR_IFRAME_SETTINGS`;
export const REMOVE_HCR_IFRAME_SETTINGS_FROM_LIST = `${organizationEntity} REMOVE_HCR_IFRAME_SETTINGS_FROM_LIST`;
export const SET_ALLOW_UPDATE_LEAD_RECORD_TYPE = `${organizationEntity} SET_ALLOW_UPDATE_LEAD_RECORD_TYPE`;
export const FETCH_ACCOUNT_RECORD_TYPE_LIST = `${organizationEntity} FETCH_ACCOUNT_RECORD_TYPE_LIST`;
export const SET_ACCOUNT_RECORD_TYPE_LIST = `${organizationEntity} SET_ACCOUNT_RECORD_TYPE_LIST`;
export const FETCH_EOI_SETTINGS = `${organizationEntity} FETCH_EOI_SETTINGS`;
export const EOI_SETTINGS_LIST_LOADER = `${organizationEntity} EOI_SETTINGS_LIST_LOADER`;
export const STORE_EOI_SETTINGS_LIST = `${organizationEntity} STORE_EOI_SETTINGS_LIST`;
export const ADD_EDIT_EOI_SETTING = `${organizationEntity} ADD_EDIT_EOI_SETTING`;
export const ADD_EDIT_EOI_SETTING_LOADER = `${organizationEntity} ADD_EDIT_EOI_SETTING_LOADER`;
export const SAVE_UPDATED_EOI_SETTINGS_TO_LIST = `${organizationEntity} SAVE_UPDATED_EOI_SETTINGS_TO_LIST`;
export const DELETE_EOI_SETTING = `${organizationEntity} DELETE_EOI_SETTING`;
export const REMOVE_EOI_SETTING_FROM_LIST = `${organizationEntity} REMOVE_EOI_SETTING_FROM_LIST`;


// Policy bound
export const policyBoundDetails = ['Policy bound details'];
export const VIEW_POLICY_BOUND_LOADER = `${policyBoundDetails} VIEW_POLICY_BOUND_LOADER`;
export const FETCH_BOUNDED_POLICY_DATA = `${policyBoundDetails} FETCH_BOUNDED_POLICY_DATA`;
export const STORE_BOUNDED_POLICY_DATA = `${policyBoundDetails} STORE_BOUNDED_POLICY_DATA`;
export const BOUNDED_POLICY_DETAILS = `${policyBoundDetails} BOUNDED_POLICY_DETAILS`;

// Quake detail
const quakeEntity = '[Quake]';
export const QUAKE_SUBMIT_LOADER = `${quakeEntity} QUAKE_SUBMIT_LOADER`;
export const FETCH_QUAKE_QUOTES = `${quakeEntity} FETCH_QUAKE_QUOTES`;
export const SET_QUAKE_QUOTES = `${quakeEntity} SET_QUAKE_QUOTES`;
export const CLEAR_QUAKE_STATE = `${quakeEntity} CLEAR_QUAKE_STATE`;
export const SET_QUAKE_QUOTE_BIND = `${quakeEntity} SET_QUAKE_QUOTE_BIND`;
export const SET_QUAKE_HERD_ID = `${quakeEntity} SET_QUAKE_HERD_ID`;
export const FETCH_QUAKE_QUOTE_DETAILS = `${quakeEntity} FETCH_QUAKE_QUOTE_DETAILS`;
export const STORE_QUAKE_DETAILS = `${quakeEntity} STORE_QUAKE_DETAILS`;

// Feedback
const feedbackEntity = ['Feedback'];
export const ADD_FEEDBACK = `${feedbackEntity} ADD_FEEDBACK`;
export const FEEDBACK_LOADER = `${feedbackEntity} FEEDBACK_LOADER`;
export const STORE_FEEDBACK_RESPONSE = `${feedbackEntity} STORE_FEEDBACK_RESPONSE`;

// Pet detail
const petEntity = ['Pet'];
export const SET_PET_BREEDS_DETAILS = `${petEntity} SET_PET_BREEDS_DETAILS`;
export const FETCH_PET_BREEDS_DETAILS = `${petEntity} FETCH_PET_BREEDS_DETAILS`;
export const PET_BREEDS_DETAILS_LOADER = `${petEntity} PET_BREEDS_DETAILS_LOADER`;
export const UPDATE_BREEDS_DETAILS = `${petEntity} UPDATE_BREEDS_DETAILS`;
export const STORE_PET_LIST_DATA = `${petEntity} STORE_PET_LIST_DATA`;
export const CLEAR_PET_STATE = `${petEntity} CLEAR_PET_STATE`;
export const FETCH_PET_QUOTES = `${petEntity} FETCH_PET_QUOTES`;
export const SET_PET_QUOTES = `${petEntity} SET_PET_QUOTES`;
export const PET_QUOTES_LOADER = `${petEntity} PET_QUOTES_LOADER`;
export const BRIDGE_TO_CARRIER_LOADER = `${petEntity} BRIDGE_TO_CARRIER_LOADER`;
export const GET_BRIDGE_TO_CARRIER_DETAILS_FOR_PET = `${petEntity} GET_BRIDGE_TO_CARRIER_DETAILS_FOR_PET`;
export const STORE_ERRORS_FOR_BRIDGE_TO_CARRIER = `${petEntity} STORE_ERRORS_FOR_BRIDGE_TO_CARRIER`;
export const SET_POLICY_BOUNDED_FOR_PET = `${petEntity} SET_POLICY_BOUNDED_FOR_PET`;
export const STORE_PET_DETAILS = `${petEntity} STORE_PET_DETAILS`;

// View quote detail
const viewQuoteEntity = ['View-quote'];
export const FETCH_VIEW_QUOTES = `${viewQuoteEntity} FETCH_VIEW_QUOTES`;
export const SET_CRM_VIEW_QUOTE_LOADER = `${viewQuoteEntity} SET_CRM_VIEW_QUOTE_LOADER`;
export const SET_VIEW_QUOTES = `${viewQuoteEntity} SET_VIEW_QUOTES`;
export const START_HOME_QUOTE_POLLING = `${viewQuoteEntity} START_HOME_QUOTE_POLLING`;
export const VALIDATE_QUOTE_URL = `${viewQuoteEntity} VALIDATE_QUOTE_URL`;
export const VALIDATE_QUOTE_URL_LOADER = `${viewQuoteEntity} VALIDATE_QUOTE_URL_LOADER`;
export const SET_CRM_SELECTED_LOB = `${viewQuoteEntity} SET_CRM_SELECTED_LOB`;
export const REFRESH_HOME_QUOTES = `${viewQuoteEntity} REFRESH_HOME_QUOTES`;

// New quote organization changes
export const FETCH_QUOTING_ORGANIZATION_LIST = `${commonEntity} FETCH_QUOTING_ORGANIZATION_LIST`;
export const QUOTING_ORGANIZATION_LIST_LOADER = `${commonEntity} QUOTING_ORGANIZATION_LIST_LOADER`;
export const STORE_QUOTING_ORGANIZATION_LIST = `${commonEntity} STORE_QUOTING_ORGANIZATION_LIST`;
export const STORE_QUOTING_ORGANIZATION_LIST_ERROR = `${commonEntity} STORE_QUOTING_ORGANIZATION_LIST_ERROR`;
export const STORE_EMPTY_LOB_ERROR_MESSAGE = `${commonEntity} STORE_EMPTY_LOB_ERROR_MESSAGE`;
export const TRACK_CARRIER_BRIDGING = `${commonEntity} TRACK_CARRIER_BRIDGING`;
export const REQUEST_BIND_FOR_WHOLESALE = `${commonEntity} REQUEST_BIND_FOR_WHOLESALE`;
export const SET_REQUEST_BIND_RESPONSE_DETAILS = `${commonEntity} SET_REQUEST_BIND_RESPONSE_DETAILS`;
export const REQUEST_BIND_FOR_WHOLESALE_LOADER = `${commonEntity} REQUEST_BIND_FOR_WHOLESALE_LOADER`;

// Home warranty detail
const homeWarrantyEntity = ['Home-warranty'];
export const SET_HOME_WARRANTY_QUOTES_DETAILS = `${homeWarrantyEntity} SET_HOME_WARRANTY_QUOTES_DETAILS`;
export const FETCH_HOME_WARRANTY_QUOTES = `${homeWarrantyEntity} FETCH_HOME_WARRANTY_QUOTES`;
export const HOME_WARRANTY_DETAILS_LOADER = `${homeWarrantyEntity} HOME_WARRANTY_DETAILS_LOADER`;
export const STORE_HOME_WARRANTY_QUOTE_LIST = `${homeWarrantyEntity} STORE_HOME_WARRANTY_QUOTE_LIST`;
export const CLEAR_HOME_WARRANTY_STATE = `${homeWarrantyEntity} CLEAR_HOME_WARRANTY_STATE`;
export const STORE_HOME_WARRANTY_DETAILS = `${homeWarrantyEntity} STORE_HOME_WARRANTY_DETAILS`;

// Life detail
const lifeEntity = ['Life'];
export const SET_LIFE_QUOTES_DETAILS = `${lifeEntity} SET_LIFE_QUOTES_DETAILS`;
export const FETCH_LIFE_QUOTES = `${lifeEntity} FETCH_LIFE_QUOTES`;
export const LIFE_DETAILS_LOADER = `${lifeEntity} LIFE_DETAILS_LOADER`;
export const STORE_LIFE_QUOTE_LIST = `${lifeEntity} STORE_LIFE_QUOTE_LIST`;
export const CLEAR_LIFE_STATE = `${lifeEntity} CLEAR_LIFE_STATE`;
export const UPDATE_LIFE_QUOTE_DETAILS = `${lifeEntity} UPDATE_LIFE_QUOTE_DETAILS`;
export const SET_LIFE_POLLING_ERROR_DETAILS = `${lifeEntity} SET_LIFE_POLLING_ERROR_DETAILS`;
export const INIT_LIFE_POLLING = `${lifeEntity} INIT_LIFE_POLLING`;
export const LIFE_TIMEOUT_POLLING = `${lifeEntity} LIFE_TIMEOUT_POLLING`;
export const SET_LIFE_POLLING_DETAILS = `${lifeEntity} SET_LIFE_POLLING_DETAILS`;
export const STOP_LIFE_POLLING = `${lifeEntity} STOP_LIFE_POLLING`;
export const GET_BRIDGE_TO_CARRIER_DETAILS_FOR_LIFE = `${lifeEntity} GET_BRIDGE_TO_CARRIER_DETAILS_FOR_LIFE`;
export const LIFE_BRIDGE_TO_CARRIER_LOADER = `${lifeEntity} LIFE_BRIDGE_TO_CARRIER_LOADER`;
export const REFRESH_LIFE_QUOTES = `${lifeEntity} REFRESH_LIFE_QUOTES`;

// Cyber security detail
const cyberSecurityEntity = ['Cyber-security'];
export const SET_CYBER_SECURITY_QUOTES_DETAILS = `${cyberSecurityEntity} SET_CYBER_SECURITY_QUOTES_DETAILS`;
export const FETCH_CYBER_SECURITY_QUOTES = `${cyberSecurityEntity} FETCH_CYBER_SECURITY_QUOTES`;
export const CYBER_SECURITY_DETAILS_LOADER = `${cyberSecurityEntity} CYBER_SECURITY_DETAILS_LOADER`;
export const STORE_CYBER_SECURITY_QUOTE_LIST = `${cyberSecurityEntity} STORE_CYBER_SECURITY_QUOTE_LIST`;
export const CLEAR_CYBER_SECURITY_STATE = `${cyberSecurityEntity} CLEAR_CYBER_SECURITY_STATE`;
export const UPDATE_CYBER_SECURITY_QUOTE_DETAILS = `${cyberSecurityEntity} UPDATE_CYBER_SECURITY_QUOTE_DETAILS`;
export const SET_CYBER_SECURITY_POLLING_ERROR_DETAILS = `${cyberSecurityEntity} SET_CYBER_SECURITY_POLLING_ERROR_DETAILS`;
export const INIT_CYBER_SECURITY_POLLING = `${cyberSecurityEntity} INIT_CYBER_SECURITY_POLLING`;
export const CYBER_SECURITY_TIMEOUT_POLLING = `${cyberSecurityEntity} CYBER_SECURITY_TIMEOUT_POLLING`;
export const SET_CYBER_SECURITY_POLLING_DETAILS = `${cyberSecurityEntity} SET_CYBER_SECURITY_POLLING_DETAILS`;
export const STOP_CYBER_SECURITY_POLLING = `${cyberSecurityEntity} STOP_CYBER_SECURITY_POLLING`;
export const GET_BRIDGE_TO_CARRIER_DETAILS_FOR_CYBER_SECRUITY = `${cyberSecurityEntity} GET_BRIDGE_TO_CARRIER_DETAILS_FOR_CYBER_SECRUITY`;
export const CYBER_BRIDGE_TO_CARRIER_LOADER = `${cyberSecurityEntity} CYBER_BRIDGE_TO_CARRIER_LOADER`;
export const REFRESH_CYBERSECURITY_QUOTES = `${cyberSecurityEntity} REFRESH_CYBERSECURITY_QUOTES`;

// Umbrella detail
const umbrellaEntity = ['Umbrella'];
export const SET_UMBRELLA_QUOTES_DETAILS = `${umbrellaEntity} SET_UMBRELLA_QUOTES_DETAILS`;
export const FETCH_UMBRELLA_QUOTES = `${umbrellaEntity} FETCH_UMBRELLA_QUOTES`;
export const UMBRELLA_DETAILS_LOADER = `${umbrellaEntity} UMBRELLA_DETAILS_LOADER`;
export const STORE_UMBRELLA_QUOTE_DETAILS = `${umbrellaEntity} STORE_UMBRELLA_QUOTE_DETAILS`;
export const CLEAR_UMBRELLA_STATE = `${umbrellaEntity} CLEAR_UMBRELLA_STATE`;
export const UPDATE_UMBRELLA_QUOTE_DETAILS = `${umbrellaEntity} UPDATE_UMBRELLA_QUOTE_DETAILS`;
export const SET_UMBRELLA_POLLING_ERROR_DETAILS = `${umbrellaEntity} SET_UMBRELLA_POLLING_ERROR_DETAILS`;
export const INIT_UMBRELLA_POLLING = `${umbrellaEntity} INIT_UMBRELLA_POLLING`;
export const UMBRELLA_TIMEOUT_POLLING = `${umbrellaEntity} UMBRELLA_TIMEOUT_POLLING`;
export const SET_UMBRELLA_POLLING_DETAILS = `${umbrellaEntity} SET_UMBRELLA_POLLING_DETAILS`;
export const STOP_UMBRELLA_POLLING = `${umbrellaEntity} STOP_UMBRELLA_POLLING`;
export const SET_UMBRELLA_HERD_ID = `${umbrellaEntity} SET_UMBRELLA_HERD_ID`;
export const SET_UMBRELLA_INFO_LOADER = `${umbrellaEntity} SET_UMBRELLA_INFO_LOADER`;
export const STORE_UMBRELLA_INFO = `${umbrellaEntity} STORE_UMBRELLA_INFO`;
export const FETCH_UMBRELLA_QUESTIONS_INFO = `${umbrellaEntity} FETCH_UMBRELLA_QUESTIONS_INFO`;
export const REFRESH_UMBRELLA_QUOTES = `${umbrellaEntity} REFRESH_UMBRELLA_QUOTES`;
// Property Info
const propertyInfo = ['Property Info'];
export const SAVE_PROPERTY_INFO_DETAILS_LOADER = `${propertyInfo} SAVE_PROPERTY_INFO_DETAILS_LOADER`;
export const SAVE_PROPERTY_INFO_DETAILS = `${propertyInfo} SAVE_PROPERTY_INFO_DETAILS`;
export const SET_IS_UPDATED_PROPERTY_INFO = `${propertyInfo} SET_IS_UPDATED_PROPERTY_INFO`;
