/* istanbul ignore file */
import { AxiosError } from 'axios';
import { all, call, fork, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import moment from 'moment';
import { v4 } from 'uuid';
import { get, isEmpty } from 'lodash';
import axiosRequest from '../../api';
import {
  getSeperateQuoteList,
  clearLobQuotes,
  dateFormat,
  createPageTracking,
  simplifyPhoneNumber,
  setAnnexInitialQuestion,
  trackFirstLoadQuoteInHeap,
  trackLastLoadQuoteInHeap,
  getIsCondoProduct,
  getPreferredDeductible,
  getResidencyStatus,
} from '../../../utils';
import { showFields, updateApostrophe } from '../../home/utils';
import { EARTH_QUAKE_LOB, INSURANCE_PRODUCTS } from '../../../constants';
import * as actions from '../constants';
import {
  errorHandler,
  quakeDetailSubmitLoader,
  setQuakeQuotes,
  setHomeState,
  setQuakeHerdId,
  setAnnexPrefill,
  fetchQuakeQuotes,
  storeQuakeDetails,
} from '../actions';

const quakeDetailsAction: any = actions;
function* fetchAnnexQuotesDetails({ payload }: any): any {
  const {
    floodDetails: { annexPrefill },
    partner: { appointments, isProviderOneLogin, storedRequestId },
    common,
    home,
    quakeDetails,
  } = yield select();
  try {
    trackFirstLoadQuoteInHeap();
    yield put(quakeDetailSubmitLoader(true));
    yield put(
      storeQuakeDetails({
        quoteStatus: '',
      })
    );
    const annexDetails = { ...annexPrefill, ...payload };
    const keys = Object.keys(appointments);
    annexDetails.selected_lob = keys;
    const { lobQuotesToClear, clearAutoInfo, clearPetInfo, clearLifeInfo, clearUmbrellaInfo } = clearLobQuotes();
    let pageTracking = createPageTracking(common?.pageTracking, appointments);
    const daleConfig = common?.pconfig?.pconfig?.dale_config || home?.pconfig?.dale_config;
    const organizationId = common?.pconfig?.pconfig?.id || daleConfig?.id;
    annexDetails.authToken = annexDetails.authToken || daleConfig?.auth_token;
    annexDetails.lobQuotesToClear = lobQuotesToClear;
    annexDetails.clearAutoInfo = clearAutoInfo;
    annexDetails.clearPetInfo = clearPetInfo;
    annexDetails.clearLifeInfo = clearLifeInfo;
    annexDetails.clearUmbrellaInfo = clearUmbrellaInfo;
    annexDetails.pageTracking = pageTracking;
    annexDetails.organizationId = organizationId;
    annexDetails.lob = EARTH_QUAKE_LOB;
    annexDetails.herdId = quakeDetails?.herdId || '';
    const details = yield axiosRequest('POST', '/annex/get-earthquake-quotes', isProviderOneLogin, {
      ...annexDetails,
      provider_request_id: storedRequestId,
    });
    if (details?.data) {
      const { home } = yield select();
      let { earthquakeQuotes, errorDetails, upcomingCarrierList } = details.data;
      const { errorQuotes } = getSeperateQuoteList(errorDetails, home);
      yield put(setQuakeQuotes({ quoteList: [...earthquakeQuotes, ...errorQuotes] }));
      trackLastLoadQuoteInHeap(INSURANCE_PRODUCTS[3]);
      yield put(
        storeQuakeDetails({
          upcomingCarrierList: !isEmpty(upcomingCarrierList) ? upcomingCarrierList : [],
        })
      );
    } else {
      yield put(
        setHomeState({
          showError: true,
        } as any)
      );
    }
  } catch (error) {
    const axiosError = error as AxiosError<any>;
    if (!!axiosError?.response?.data?.errorDetails) {
      const { errorDetails } = axiosError?.response?.data;
      const { errorQuotes } = getSeperateQuoteList(errorDetails, {});
      yield put(setQuakeQuotes({ quoteList: [...errorQuotes] }));
    } else {
      yield put(
        setHomeState({
          showError: true,
        } as any)
      );
      yield put(errorHandler(axiosError));
    }
  }
  yield put(quakeDetailSubmitLoader(false));
}

export function* fetchAnnexQuotesWatcher() {
  yield takeEvery(quakeDetailsAction.FETCH_QUAKE_QUOTES, fetchAnnexQuotesDetails);
}

function* formFieldsVisibility() {
  const {
    home: { pconfig },
  } = yield select();
  return {
    phone_number: showFields('phone_number', false),
    email: showFields('email', pconfig.dale_config.form_parameters_visibility),
  };
}

export function* getQuoteDetails(): any {
  const reduxStore = yield select();
  const {
    home: {
      code,
      address,
      pconfig,
      is_condo,
      personal_details,
      productType,
    },
    common: { pageTracking },
    auth,
    partner: { storedRequestId, producerDetails, appointments },
    applicant: { address: applicantAddress },
    quakeDetails,
  } = reduxStore;
  const showValidFields = yield call(formFieldsVisibility);
  const quoteBy = !isEmpty(get(auth, 'email', ''))
    ? get(auth, 'email')
    : producerDetails && !isEmpty(producerDetails.producerEmail)
    ? producerDetails.producerEmail
    : '';
  const { lobQuotesToClear, clearAutoInfo, clearPetInfo, clearLifeInfo } = clearLobQuotes();
  let pageTrackings = createPageTracking(!clearAutoInfo ? pageTracking : {}, appointments);
  return {
    pageTracking: pageTrackings,
    herd_id: quakeDetails?.herdId || '',
    lobQuotesToClear,
    clearAutoInfo,
    clearPetInfo,
    clearLifeInfo,
    lob: EARTH_QUAKE_LOB,
    address: address ? address : applicantAddress,
    is_condo: getIsCondoProduct(is_condo),
    home_type: productType,
    quote_by: quoteBy,
    id: get(auth, 'userId', ''),
    first_name: updateApostrophe(personal_details.first_name),
    middle_name: updateApostrophe(personal_details.middle_name),
    last_name: updateApostrophe(personal_details.last_name),
    dob: moment(personal_details.date_of_birth, dateFormat).format(dateFormat),
    phone_number: showValidFields.phone_number
      ? simplifyPhoneNumber(personal_details.phone_number)
      : '',
    email: showValidFields.email ? personal_details.email : '',
    partner_code: code ? code : 'naaisg',
    request_id: v4(),
    auth_token: pconfig?.dale_config?.auth_token,
    organizationId: pconfig?.dale_config?.id,
    industry: personal_details.industry,
    occupation: personal_details.occupation,
    education: personal_details.education,
    preferredDeductible: getPreferredDeductible(),
    provider_request_id: storedRequestId,
    residency_status: getResidencyStatus(),
  };
}

function* fetchQuakeQuotesDetails(): any {
  const reduxStore = yield select();
  yield put(quakeDetailSubmitLoader(true));
  const {
    common: { policyBounded },
  } = reduxStore;
  try {
    const {
      partner: { isProviderOneLogin, appointments },
    } = reduxStore;
    const keys = Object.keys(appointments);
    const quoteDetails = yield call(getQuoteDetails);
    quoteDetails.selected_lob = keys;
    const details = yield axiosRequest('POST', '/partners/getquotes', isProviderOneLogin, {
      ...quoteDetails,
    });
    if (details.data) {
      const { annexRequiredPrefill, upcomingCarrierList, herd_id } = details.data;
      yield put(setQuakeHerdId(herd_id));
      if (!isEmpty(annexRequiredPrefill)) {
        setAnnexInitialQuestion(details.data);
        yield put(setAnnexPrefill(annexRequiredPrefill));
      }
      if (isEmpty(annexRequiredPrefill)) {
        yield put(quakeDetailSubmitLoader(false));
        yield put(setQuakeQuotes({ quoteList: [] }));
      }
      yield put(
        storeQuakeDetails({
          upcomingCarrierList: !isEmpty(upcomingCarrierList) ? upcomingCarrierList : [],
        })
      );
      let isQuakePolicyBounded = !isEmpty(policyBounded) && policyBounded[INSURANCE_PRODUCTS[3]];
      if (
        !isQuakePolicyBounded &&
        !isEmpty(annexRequiredPrefill)
      ) {
        yield put(fetchQuakeQuotes());
      } else {
        yield put(quakeDetailSubmitLoader(false));
      }
    }
  } catch (error) {
    const axiosError = error as AxiosError<any>;
    if (!!axiosError?.response?.data?.error_details) {
      const { error_details, herd_id } = axiosError?.response?.data;
      yield put(setQuakeHerdId(herd_id));
      const { errorQuotes } = getSeperateQuoteList(error_details, {});
      yield put(setQuakeQuotes({ quoteList: [...errorQuotes] }));
    } else {
      yield put(errorHandler(error));
    }
    yield put(quakeDetailSubmitLoader(false));
  }
}

export function* fetchQuakeQuotesDetailsWatcher() {
  yield takeLatest(quakeDetailsAction.FETCH_QUAKE_QUOTE_DETAILS, fetchQuakeQuotesDetails);
}

export default function* rootSaga() {
  yield all([fork(fetchAnnexQuotesWatcher), fork(fetchQuakeQuotesDetailsWatcher)]);
}
