import React, { useState } from 'react';
import { isEmpty, startCase } from 'lodash';
import {
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  NativeSelect,
  Switch,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { Help, Visibility, VisibilityOff } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import PhoneMask from '../../components/phone-mask';
import { getNestedTernaryData, statusList, themeList } from '../../../utils';
import {
  ABBREVATION,
  ALLOW_DOWNLOAD_QUOTE,
  ASTERISK,
  AUTHENTICATION,
  AUTHENTICATION_OPTIONS,
  CONSUMER_POD_ID,
  CONSUMER_TOKEN,
  DOWNLOAD_QUOTE_PREFIX,
  EMAIL,
  FEEDBACK_BUTTON,
  HCR_ORGANIZATION,
  HEAP_ANALYTICS,
  HEAP_PROJECT_ID,
  INCLUDE_GATOR_QUOTING,
  INCLUDE_IN_REPORT,
  JV_HOME_URL,
  LOGO_URL,
  PASSWORD,
  PHONE,
  POD_AUTH_TOKEN,
  POD_ORGANIZATION_CODE,
  POD_ORGANIZATION_ID,
  POD_ORGANIZATION_NAME,
  PUBLIC_ACCESS_TO_VIEW_QUOTE,
  SIGNATURE,
  STATUS,
  THEME,
  USERNAME,
  VISIBLE_ON_GATOR_UI,
  WEBHOOK,
  TEXT_TYPE,
} from '../../../constants/';
import './add-edit-organization-modal.scss';

export const CustomSwitch = withStyles({
  switchBase: {
    color: '#ccc',
    '&$checked': {
      color: '#00cf83',
    },
    '&$checked + $track': {
      backgroundColor: '#00cf83',
    },
  },
  checked: {},
  track: {},
})(Switch);

export const OrganizationForm = ({
  formDetails,
  handleOnChange,
  handleContactOnChage,
  handleBrandingOnChange,
  setDefaultAuthentication,
  setDefaultTheme,
  setDefaultStatus,
  errors,
  loader,
  onCheckBoxChanges,
}: any) => {
  const [isShowPODAuthToken, setIsShowPODAuthToken] = useState(false);
  const [isShowConsumerToken, setIsShowConsumerToken] = useState(false);
  return (
    <div className='add-new-org-model'>
      <form>
        <div className='row'>
          <div className='col-md-6 mb-4'>
            <FormControl fullWidth>
              <InputLabel
                shrink
                htmlFor={LOGO_URL.KEY}
                error={!!errors?.logoUrlError ? true : false}
              >
                {LOGO_URL.LABEL}
                <span className='star-error'>{ASTERISK}</span>
              </InputLabel>
              <Input
                inputProps={{
                  name: LOGO_URL.KEY,
                  id: LOGO_URL.KEY,
                  spellCheck: false,
                  maxLength: 200,
                }}
                className='form-item__input'
                value={formDetails.branding_on_dale_details.logo_url || ''}
                onChange={handleBrandingOnChange}
                disabled={loader}
              />
            </FormControl>
            <FormHelperText className='text-red'>{errors?.logoUrlError}</FormHelperText>
          </div>
          <div className='col-md-6 mb-4'>
            <FormControl fullWidth>
              <InputLabel
                shrink
                htmlFor={POD_ORGANIZATION_NAME.KEY}
                error={!!errors?.nameError ? true : false}
              >
                {POD_ORGANIZATION_NAME.LABEL}
                <span className='star-error'>{ASTERISK}</span>
              </InputLabel>
              <Input
                inputProps={{
                  name: POD_ORGANIZATION_NAME.KEY,
                  id: POD_ORGANIZATION_NAME.KEY,
                  spellCheck: false,
                  maxLength: 30,
                }}
                className='form-item__input'
                value={formDetails.name || ''}
                onChange={handleOnChange}
                disabled={loader}
              />
            </FormControl>
            <FormHelperText className='text-red'>{errors?.nameError}</FormHelperText>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-3 mb-4'>
            <FormControl fullWidth>
              <InputLabel
                shrink
                htmlFor={POD_ORGANIZATION_CODE.KEY}
                error={!!errors?.codeError ? true : false}
              >
                {POD_ORGANIZATION_CODE.LABEL}
                <span className='star-error'>{ASTERISK}</span>
              </InputLabel>
              <Input
                inputProps={{
                  name: POD_ORGANIZATION_CODE.KEY,
                  id: POD_ORGANIZATION_CODE.KEY,
                  spellCheck: false,
                  maxLength: 30,
                }}
                className='form-item__input'
                value={formDetails.code || ''}
                onChange={handleOnChange}
                disabled={loader}
              />
            </FormControl>
            <FormHelperText className='text-red'>{errors?.codeError}</FormHelperText>
          </div>
          <div className='col-md-3 mb-4'>
            <FormControl fullWidth>
              <InputLabel
                shrink
                htmlFor={POD_ORGANIZATION_ID.KEY}
                error={!!errors?.partnerError ? true : false}
              >
                {POD_ORGANIZATION_ID.LABEL}
                <span className='star-error'>{ASTERISK}</span>
              </InputLabel>
              <Input
                inputProps={{
                  name: POD_ORGANIZATION_ID.KEY,
                  id: POD_ORGANIZATION_ID.KEY,
                  spellCheck: false,
                  maxLength: 30,
                }}
                className='form-item__input'
                value={formDetails.partner_id || ''}
                onChange={handleOnChange}
                disabled={loader}
              />
            </FormControl>
            <FormHelperText className='text-red'>{errors?.partnerError}</FormHelperText>
          </div>
          <div className='col-md-3 mb-4'>
            <FormControl fullWidth>
              <InputLabel shrink htmlFor={ABBREVATION}>
                {HCR_ORGANIZATION?.LABEL}
              </InputLabel>
              <Input
                inputProps={{
                  name: HCR_ORGANIZATION?.KEY,
                  id: HCR_ORGANIZATION?.KEY,
                  spellCheck: false,
                  maxLength: 50,
                }}
                className='form-item__input'
                value={!!formDetails?.hcr_org_name ? formDetails?.hcr_org_name : ''}
                onChange={handleOnChange}
                disabled={loader}
              />
            </FormControl>
          </div>
          <div className='col-md-3 mb-4'>
            <FormControl fullWidth>
              <InputLabel shrink htmlFor={ABBREVATION}>
                {DOWNLOAD_QUOTE_PREFIX}
              </InputLabel>
              <Input
                inputProps={{
                  name: ABBREVATION,
                  id: ABBREVATION,
                  spellCheck: false,
                  maxLength: 6,
                }}
                className='form-item__input'
                value={!!formDetails?.abbreviation ? formDetails?.abbreviation?.toUpperCase() : ''}
                onChange={handleOnChange}
                disabled={loader}
                error={!!errors?.abbreviationError ? true : false}
              />
            </FormControl>
            <FormHelperText className='text-red'>{errors?.abbreviationError}</FormHelperText>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-4 mb-4' id='pod-auth-token-input-wrapper'>
            <FormControl fullWidth>
              <InputLabel
                shrink
                htmlFor={POD_AUTH_TOKEN.KEY}
                error={!!errors?.authTokenError ? true : false}
              >
                {POD_AUTH_TOKEN.LABEL}
                <span className='star-error'>{ASTERISK}</span>
              </InputLabel>
              <Input
                inputProps={{
                  name: POD_AUTH_TOKEN.KEY,
                  id: POD_AUTH_TOKEN.KEY,
                  spellCheck: false,
                  maxLength: 100,
                }}
                className='form-item__input'
                value={formDetails.auth_token || ''}
                onChange={handleOnChange}
                disabled={loader}
                type={isShowPODAuthToken ? TEXT_TYPE : PASSWORD}
                endAdornment={
                  <InputAdornment position='end'>
                    <IconButton
                      className='visibility-icon'
                      onClick={() => setIsShowPODAuthToken(!isShowPODAuthToken)}
                    >
                      {isShowPODAuthToken ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            <FormHelperText className='text-red'>{errors?.authTokenError}</FormHelperText>
          </div>
          <div className='col-md-4 mb-4'>
            <FormControl fullWidth>
              <InputLabel shrink htmlFor={CONSUMER_POD_ID.KEY}>
                {CONSUMER_POD_ID.LABEL}
              </InputLabel>
              <Input
                inputProps={{
                  name: CONSUMER_POD_ID.KEY,
                  id: CONSUMER_POD_ID.KEY,
                  spellCheck: false,
                  maxLength: 30,
                }}
                className='form-item__input'
                value={formDetails.consumer_partner_id || ''}
                onChange={handleOnChange}
                disabled={loader}
              />
            </FormControl>
          </div>
          <div className='col-md-4 mb-4'>
            <FormControl fullWidth>
              <InputLabel shrink htmlFor={CONSUMER_TOKEN.KEY}>
                {CONSUMER_TOKEN.LABEL}
              </InputLabel>
              <Input
                inputProps={{
                  name: CONSUMER_TOKEN.KEY,
                  id: CONSUMER_TOKEN.KEY,
                  spellCheck: false,
                  maxLength: 200,
                }}
                className='form-item__input'
                value={formDetails.consumer_token || ''}
                onChange={handleOnChange}
                disabled={loader}
                type={isShowConsumerToken ? TEXT_TYPE : PASSWORD}
                endAdornment={
                  <InputAdornment position='end'>
                    <IconButton
                      className='visibility-icon'
                      onClick={() => setIsShowConsumerToken(!isShowConsumerToken)}
                    >
                      {isShowConsumerToken ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-6 mb-4'>
            <FormControl fullWidth>
              <InputLabel shrink htmlFor={JV_HOME_URL.KEY} error={!isEmpty(errors?.baseUrlError)}>
                <span className='position-relative'>{JV_HOME_URL.LABEL}</span>{' '}
                <div className='tooltip-que-icon'>
                  <Tooltip className='tooltip' placement='right' title={JV_HOME_URL.TOOLTIP_TITLE}>
                    <Help />
                  </Tooltip>
                </div>
              </InputLabel>
              <Input
                inputProps={{
                  name: JV_HOME_URL.KEY,
                  id: JV_HOME_URL.KEY,
                  spellCheck: false,
                  maxLength: 150,
                }}
                className='form-item__input'
                value={formDetails.base_url || ''}
                onChange={handleOnChange}
                disabled={loader}
              />
            </FormControl>
            <FormHelperText className='text-red'>{errors?.baseUrlError}</FormHelperText>
          </div>
          <div className='col-md-6 mb-4'>
            <FormControl fullWidth>
              <InputLabel shrink htmlFor='Status' error={!!errors?.statusError ? true : false}>
                {startCase(STATUS)}
                <span className='star-error'>{ASTERISK}</span>
              </InputLabel>
              <NativeSelect
                id={STATUS}
                name={STATUS}
                value={
                  !isEmpty(formDetails.status)
                    ? formDetails.status
                    : setDefaultStatus(statusList[0].value)
                }
                onChange={handleOnChange}
                className='form-item__select'
                disabled={loader}
              >
                {statusList.map(option => (
                  <option key={option.value} value={option.value}>
                    {startCase(option.label)}
                  </option>
                ))}
              </NativeSelect>
            </FormControl>
            <FormHelperText className='text-red'>{errors?.statusError}</FormHelperText>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-3 mb-4'>
            <FormControl fullWidth>
              <InputLabel
                shrink
                htmlFor={THEME.KEY}
                error={!!errors?.templateNameError ? true : false}
              >
                {THEME.LABEL}
                <span className='star-error'>{ASTERISK}</span>
              </InputLabel>
              <NativeSelect
                id={THEME.KEY}
                name={THEME.KEY}
                value={
                  !isEmpty(formDetails.branding_on_dale_details.template_name)
                    ? formDetails.branding_on_dale_details.template_name
                    : setDefaultTheme(themeList[0].value)
                }
                onChange={handleBrandingOnChange}
                className='form-item__select'
                disabled={loader}
              >
                {themeList.map((option: any) => (
                  <option className='select__item' key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </NativeSelect>
            </FormControl>
            <FormHelperText className='text-red'>{errors?.templateNameError}</FormHelperText>
          </div>
          <div className='col-md-3 mb-4'>
            <FormControl fullWidth>
              <InputLabel
                shrink
                htmlFor={AUTHENTICATION}
                error={!!errors?.authenticationError ? true : false}
              >
                {startCase(AUTHENTICATION)}
                <span className='star-error'>{ASTERISK}</span>
              </InputLabel>
              <NativeSelect
                id={AUTHENTICATION}
                name={AUTHENTICATION}
                value={
                  !isEmpty(formDetails.authentication)
                    ? formDetails.authentication
                    : setDefaultAuthentication(AUTHENTICATION_OPTIONS[0])
                }
                onChange={handleOnChange}
                className='form-item__select'
                disabled={loader}
              >
                {AUTHENTICATION_OPTIONS.map(option => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </NativeSelect>
            </FormControl>
            <FormHelperText className='text-red'>{errors?.authenticationError}</FormHelperText>
          </div>
          <div className='col-md-3 mb-4'>
            <FormControl fullWidth>
              <InputLabel shrink htmlFor={EMAIL} error={!!errors?.emailError ? true : false}>
                {startCase(EMAIL)}
                <span className='star-error'>{ASTERISK}</span>
              </InputLabel>
              <Input
                inputProps={{
                  name: EMAIL,
                  id: EMAIL,
                  spellCheck: false,
                  maxLength: 50,
                }}
                className='form-item__input'
                value={formDetails.contact.email || ''}
                onChange={handleContactOnChage}
                type={EMAIL}
                disabled={loader}
              />
            </FormControl>
            <FormHelperText className='text-red'>{errors?.emailError}</FormHelperText>
          </div>
          <div className='col-md-3 mb-4'>
            <FormControl fullWidth>
              <InputLabel shrink htmlFor={PHONE} error={!!errors?.phoneError ? true : false}>
                {startCase(PHONE)}
                <span className='star-error'>{ASTERISK}</span>
              </InputLabel>
              <Input
                inputProps={{
                  name: PHONE,
                  id: PHONE,
                  spellCheck: false,
                }}
                className='form-item__input'
                value={formDetails.contact.phone || ''}
                onChange={handleContactOnChage}
                inputComponent={PhoneMask}
                disabled={loader}
              />
            </FormControl>
            <FormHelperText className='text-red'>{errors?.phoneError}</FormHelperText>
          </div>
        </div>
        {formDetails?.authentication === AUTHENTICATION_OPTIONS[1] ? (
          <>
            <div className='row'>
              <div className='col-md-3 mb-4'>
                <FormControl fullWidth>
                  <InputLabel
                    shrink
                    htmlFor={USERNAME}
                    error={getNestedTernaryData(!!errors?.usernameError, true, false)}
                  >
                    {startCase(USERNAME)}
                    <span className='star-error'>{ASTERISK}</span>
                  </InputLabel>
                  <Input
                    inputProps={{
                      name: USERNAME,
                      id: USERNAME,
                      spellCheck: false,
                      maxLength: 30,
                    }}
                    className='form-item__input'
                    value={formDetails?.username || ''}
                    onChange={handleOnChange}
                    disabled={loader}
                  />
                </FormControl>
                <FormHelperText className='text-red'>{errors?.usernameError}</FormHelperText>
              </div>
              <div className='col-md-3 mb-4'>
                <FormControl fullWidth>
                  <InputLabel
                    shrink
                    htmlFor={PASSWORD}
                    error={getNestedTernaryData(!!errors?.passwordError, true, false)}
                  >
                    {startCase(PASSWORD)}
                    <span className='star-error'>{ASTERISK}</span>
                  </InputLabel>
                  <Input
                    inputProps={{
                      name: PASSWORD,
                      id: PASSWORD,
                      spellCheck: false,
                      maxLength: 30,
                      type: PASSWORD,
                    }}
                    className='form-item__input'
                    value={formDetails?.password || ''}
                    onChange={handleOnChange}
                    disabled={loader}
                  />
                </FormControl>
                <FormHelperText className='text-red'>{errors?.passwordError}</FormHelperText>
              </div>
              <div className='col-md-3 mb-4'>
                <FormControl fullWidth>
                  <InputLabel
                    shrink
                    htmlFor={WEBHOOK}
                    error={getNestedTernaryData(!!errors?.webhookError, true, false)}
                  >
                    {startCase(WEBHOOK)}
                    <span className='star-error'>{ASTERISK}</span>
                  </InputLabel>
                  <Input
                    inputProps={{
                      name: WEBHOOK,
                      id: WEBHOOK,
                      spellCheck: false,
                      maxLength: 50,
                    }}
                    className='form-item__input'
                    value={formDetails?.webhook || ''}
                    onChange={handleOnChange}
                    disabled={loader}
                  />
                </FormControl>
                <FormHelperText className='text-red'>{errors?.webhookError}</FormHelperText>
              </div>
              <div className='col-md-3 mb-4'>
                <FormControl fullWidth>
                  <InputLabel
                    shrink
                    htmlFor={SIGNATURE}
                    error={getNestedTernaryData(!!errors?.signatureError, true, false)}
                  >
                    {startCase(SIGNATURE)}
                    <span className='star-error'>{ASTERISK}</span>
                  </InputLabel>
                  <Input
                    inputProps={{
                      name: SIGNATURE,
                      id: SIGNATURE,
                      spellCheck: false,
                      maxLength: 50,
                    }}
                    className='form-item__input'
                    value={formDetails?.signature || ''}
                    onChange={handleOnChange}
                    disabled={loader}
                  />
                </FormControl>
                <FormHelperText className='text-red'>{errors?.signatureError}</FormHelperText>
              </div>
            </div>
          </>
        ) : null}
        <div className='row'>
          <div className='col-md-6 mb-4 form-label d-flex justify-lg-content-center switch-label'>
            <FormControlLabel
              className='heap-radio-label'
              control={
                <Switch
                  color='primary'
                  checked={!!formDetails?.heap_analytics_enabled}
                  onChange={onCheckBoxChanges}
                  name={HEAP_ANALYTICS.KEY}
                  disabled={loader}
                />
              }
              label={`${HEAP_ANALYTICS.LABEL}:`}
              labelPlacement='start'
            />
          </div>
          <div className='col-md-6 mb-4'>
            <FormControl fullWidth>
              <InputLabel
                shrink
                disabled={loader || !formDetails?.heap_analytics_enabled}
                htmlFor={HEAP_PROJECT_ID?.KEY}
                error={!!errors?.heapProjectIdError ? true : false}
              >
                {HEAP_PROJECT_ID?.LABEL}
                {!!formDetails?.heap_analytics_enabled && (
                  <span className='star-error'>{ASTERISK}</span>
                )}
              </InputLabel>
              <Input
                inputProps={{
                  name: HEAP_PROJECT_ID?.KEY,
                  id: HEAP_PROJECT_ID?.KEY,
                  spellCheck: false,
                  maxLength: 15,
                }}
                className='form-item__input'
                value={formDetails?.heap_project_id || ''}
                onChange={handleOnChange}
                disabled={loader || !formDetails?.heap_analytics_enabled}
              />
            </FormControl>
            <FormHelperText className='text-red'>{errors?.heapProjectIdError}</FormHelperText>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-4 mb-4 form-label d-flex justify-lg-content-center switch-label'>
            <FormControlLabel
              className='radio-button-label'
              control={
                <Switch
                  color='primary'
                  checked={!!formDetails?.public_access_view_quote ? true : false}
                  onChange={onCheckBoxChanges}
                  name='public_access_view_quote'
                  disabled={loader}
                />
              }
              label={`${PUBLIC_ACCESS_TO_VIEW_QUOTE}:`}
              labelPlacement='start'
            />
          </div>
          <div className='col-md-4 mb-4 form-label d-flex justify-lg-content-center switch-label'>
            <FormControlLabel
              className='radio-button-label'
              control={
                <Switch
                  color='primary'
                  checked={!!formDetails?.visible_on_gator_ui ? true : false}
                  onChange={onCheckBoxChanges}
                  name='visible_on_gator_ui'
                  disabled={loader}
                />
              }
              label={`${VISIBLE_ON_GATOR_UI}:`}
              labelPlacement='start'
            />
          </div>
          <div className='col-md-4 mb-4 form-label d-flex justify-lg-content-center switch-label'>
            <FormControlLabel
              className='radio-button-label'
              control={
                <Switch
                  color='primary'
                  checked={!!formDetails?.feedback_enabled ? true : false}
                  onChange={onCheckBoxChanges}
                  name='feedback_enabled'
                  disabled={loader}
                />
              }
              label={`${FEEDBACK_BUTTON}:`}
              labelPlacement='start'
            />
          </div>
          <div className='col-md-4 mb-4 form-label d-flex justify-lg-content-center switch-label'>
            <FormControlLabel
              className='radio-button-label'
              control={
                <Switch
                  color='primary'
                  checked={!!formDetails?.report_enabled ? true : false}
                  onChange={onCheckBoxChanges}
                  name={INCLUDE_IN_REPORT.KEY}
                  disabled={loader}
                />
              }
              label={`${INCLUDE_IN_REPORT.LABEL}:`}
              labelPlacement='start'
            />
          </div>
          <div className='col-md-4 mb-4 form-label d-flex justify-lg-content-center switch-label'>
            <FormControlLabel
              className='radio-button-label'
              control={
                <Switch
                  color='primary'
                  checked={!!formDetails?.gator_quoting_enabled ? true : false}
                  onChange={onCheckBoxChanges}
                  name={INCLUDE_GATOR_QUOTING.KEY}
                  disabled={loader}
                />
              }
              label={`${INCLUDE_GATOR_QUOTING.LABEL}:`}
              labelPlacement='start'
            />
          </div>
        </div>
        <Divider />
        <div className='row mt-2'>
          <div className='col-lg-12'>
            <Typography className='mt-2 mb-2 title-heading'>{ALLOW_DOWNLOAD_QUOTE.LABEL}</Typography>
          </div>
          <div className='col-md-4 mb-4 form-label d-flex justify-lg-content-center switch-label'>
            <FormControlLabel
              className='radio-button-label'
              control={
                <Switch
                  color='primary'
                  checked={!!formDetails?.enable_home_quote_doc_download}
                  onChange={onCheckBoxChanges}
                  name={ALLOW_DOWNLOAD_QUOTE.HOME_QUOTE.KEY}
                  disabled={loader}
                />
              }
              label={`${ALLOW_DOWNLOAD_QUOTE.HOME_QUOTE.LABEL}:`}
              labelPlacement='start'
            />
          </div>
          <div className='col-md-4 mb-4 form-label d-flex justify-lg-content-center switch-label'>
            <FormControlLabel
              className='radio-button-label'
              control={
                <Switch
                  color='primary'
                  checked={!!formDetails?.enable_auto_quote_doc_download}
                  onChange={onCheckBoxChanges}
                  name={ALLOW_DOWNLOAD_QUOTE.AUTO_QUOTE.KEY}
                  disabled={loader}
                />
              }
              label={`${ALLOW_DOWNLOAD_QUOTE.AUTO_QUOTE.LABEL}:`}
              labelPlacement='start'
            />
          </div>
        </div>
        <FormHelperText className='text-red'>{errors?.commonErrorMessage}</FormHelperText>
      </form>
    </div>
  );
};
export default OrganizationForm;
