import React, { useEffect } from 'react';
import 'date-fns';
import { createMuiTheme } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import isEmpty from 'lodash/isEmpty';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ThemeProvider } from '@material-ui/styles';

import { dateFormat, formatDate } from '../../../utils';

const defaultMaterialTheme = (createMuiTheme as any)({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#00cf83',
      },
    },
    MuiPickersDay: {
      daySelected: {
        backgroundColor: '#00cf83',
        '&:hover': {
          backgroundColor: '#00cf83',
        },
      },
      dayHover: {
        backgroundColor: '#00cf83',
      },
      current: {
        color: '#00cf83',
      },
    },
    MuiPickersYear: {
      year: {
        color: '#00cf83',
      },
      yearSelected: {
        color: '#00cf83',
      },
      current: {
        color: '#00cf83',
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: '#00cf83 !important',
      },
    },
    MuiButton: {
      textPrimary: {
        color: 'black',
      },
    },
  },
});

const MaterialUIPickers = (props: AppComponents.DatePickerProps) => {
  const [selectedDate, setSelectedDate] = React.useState(
    !isEmpty(props.dateValue) ? new Date(props.dateValue) : null
  );

  useEffect(() => {
    setSelectedDate(props.dateValue);
  }, [props.dateValue]);

  const onDateInputChange = async (event: any) => {
    let date: any = await !!event ? formatDate(event) : '';
    setSelectedDate(date);
    props.onChange(!!date ? date : '');
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <ThemeProvider theme={defaultMaterialTheme}>
        <KeyboardDatePicker
          maxDate={props.maxDate}
          label={props.label}
          format='MM/dd/yyyy'
          margin='normal'
          id={props?.id ? props.id : 'date-picker-dialog'}
          value={!isEmpty(selectedDate) ? selectedDate : null}
          onChange={(event) => onDateInputChange(event)}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            autoComplete: 'none',
          }}
          placeholder={dateFormat}
          className={`DatePickerComponent ${!props.error ? 'blackBorder' : 'redBorder'}`}
          error={props.error}
          disabled={props.disabled}
          helperText={''}
        />
      </ThemeProvider>
    </MuiPickersUtilsProvider>
  );
};

export default MaterialUIPickers;
