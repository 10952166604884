import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { createStyles, WithStyles, withStyles } from '@material-ui/core/styles';
import { every, find, forEach, get, isEmpty, isEqual, map, omit } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import ConfirmationModal from '../confirmation-modal';
import UploadFile from '../upload-file';
import template from './template';
import {
  adhocBindSearch,
  AMSDetailsSubmit,
  disableDivision,
  fetchAMSDetails,
  fetchLOBAMSDetails,
  getCarrierUnderwriterList,
  setAMSDetails,
  setAMSDetailsLoader,
  setAMSLOBChanged,
  setInsuredDropDownLoader,
  setLineOfBusiness,
  showLOBAMSDetails,
  setMaxAttemptReached,
  setPolicyHeadersResponse,
} from '../../redux/actions';
import {
  adhocLobMapping,
  dateFormat,
  formatNumberToCurrencyWithDecimal,
  getApplicantDetails,
  getCarrierName,
  getCarrierNameFromCarrierList,
  getCarrierOptionList,
  getCarrierPremium,
  getCarrierPremiumTerm,
  getLineOfBusiness,
  getQuoteListOfLob,
  getStartCase,
  numberRegex,
  phoneRegex,
  removeExtraSpaces,
  segregateListOptions,
  showEoiFileUpload,
  stringRegex,
  stringWithSpaceRegex,
  validateAlphaNumeric,
  validateDOB,
  validateEmail,
  validatePhoneNumber,
  validatePremiumValue,
  validateUUID,
} from '../../../utils';
import {
  getIsShowSl2OrDueDiligenceForm,
  isShowCarrierRiskConsentCheckbox,
} from '../../../utils/ams-details';
import {
  ADHOC,
  AMS_EFFECTIVE_DATE_DURATION,
  AMS_EXCLUDE_PROPS,
  AMS_FORM_FIELD,
  BUSINESS_LINE,
  CANCEL,
  CAROUSEL,
  CONTINUE,
  DROP,
  EARTH_QUAKE_LOB,
  HOME_OWNERS,
  INSURANCE_PRODUCTS,
  INVALID_PDF_FILE_ERROR,
  PDF_TYPE,
  PREMIUM_VALIDATION_DETAILS,
  PRODUCT,
  QUOTES,
  REQUIRED,
  SELECTED_CARRIER,
  SHOW_LOAN_NUMBER_FOR_LOB,
  STATEDATA,
  UPLOAD,
  UPLOAD_AMS_DOCUMENT_TITLE,
} from '../../../constants';
import './ams-details-modal.scss';
const { DUE_DILIGENCE_FORM_DOCUMENT, SL2_FORM_DOCUMENT, EOI_DOCUMENT } = AMS_FORM_FIELD;

const getAddressDetails = (address: any) => {
  return `${get(address, 'street', '')} ${get(address, 'unit', '')} ${get(
    address,
    'city',
    ''
  )} ${get(address, 'state', '').toUpperCase()} ${get(address, 'zip', '')}`;
};

const getApplicantName = ({ first_name, middle_name, last_name }: any): string => {
  return `${first_name.trim()} ${middle_name.trim()} ${last_name.trim()}`.trim();
};

const styles = () =>
  createStyles({
    title: {
      fontSize: 16,
      textAlign: 'center',
    },
    lineDivider: {
      marginBottom: 0,
    },
    dialogContent: {
      padding: '8px 24px 20px 24px',
    },
    buttonWrapper: {
      margin: '0 auto',
      marginTop: 15,
      marginBottom: 20,
    },
    fieldError: {
      position: 'relative',
      top: -4,
      color: '#FF0000',
      height: '0px',
      fontSize: '0.70rem',
    },
    closeButton: {
      position: 'absolute',
      width: 20,
      height: 20,
      right: 10,
      top: 10,
      zIndex: 1,
    },
    submitSpinner: {
      color: 'white !important',
      marginBottom: '2px',
      marginRight: '0px',
    },
    commonError: {
      fontSize: '0.75rem',
      padding: '0px 0px 0px 22px',
      top: '5px',
    },
    errorText: {
      fontSize: '12px',
      position: 'relative',
      top: -2,
      color: '#FF0000',
      height: '0px',
    },
    addressTextField: {
      width: '100%',
    },
    formControl: {
      minWidth: '100%',
      marginBottom: '0px',
      margin: '0 0px !important',
    },
    table: {
      minWidth: 650,
    },
    tableHead: {
      whiteSpace: 'nowrap',
      borderBottom: '3px solid #808080a1',
    },
    tableHeadRow: {
      padding: '8px 16px',
      background: '#eee',
      position: 'sticky',
      top: 0,
      zIndex: 1,
      boxShadow: 'inset 0px 0px #000, 0 1px #808080a1',
    },
    tableRow: {
      cursor: 'pointer',
      whiteSpace: 'nowrap',
      '&:nth-of-type(odd)': {
        backgroundColor: '#f4fffa',
      },
    },
    tableBodyRow: {
      position: 'sticky',
      left: 0,
    },
    fieldWidth: {
      whiteSpace: 'pre-line',
      width: '25%',
    },
  });
const headCells = [
  {
    id: 'select',
  },
  {
    id: 'first_name',
    label: 'First Name',
  },
  {
    id: 'last_name',
    label: 'Last Name',
  },
  { id: 'address', label: 'Address' },
  { id: 'email', label: 'Email' },
];

const INIT_FORM_ERRORS: AppComponents.AMSFormDetailsError = {
  effectiveDateError: '',
  carrierNameError: '',
  premiumError: '',
  termError: '',
  carrierUnderwriterError: '',
  policyNumberError: '',
  divisionError: '',
  executiveError: '',
  representativeError: '',
  groupError: '',
  departmentError: '',
  employeeError: '',
  brokerError: '',
  customId: '',
  loanNumber: '',
  street: '',
  unit: '',
  city: '',
  state: '',
  zip: '',
  firstNameError: '',
  lastNameError: '',
  phoneNumberError: '',
  businessLineError: '',
  emailError: '',
  dobError: '',
  eoiFileError: '',
  productError: '',
  salesforceCustomerUUIDErr: '',
  carrierRiskConsentedError: '',
  sl2FormFileNameError: '',
  dueDiigenceFormFileNameError: '',
};

const INIT_FORM_DETAILS: AppComponents.AMSFormDetails = {
  fullName: '',
  firstName: '',
  lastName: '',
  phoneNumber: '',
  email: '',
  dob: '',
  address: '',
  addressDetails: {
    street: '',
    unit: '',
    city: '',
    zip: '',
    state: '',
  },
  businessLine: '',
  effectiveDate: '',
  carrierId: '',
  carrierName: '',
  selectedCarrier: {},
  premium: '',
  term: '',
  carrierUnderwriter: '',
  policyNumber: '',
  division: '',
  executive: '',
  representative: '',
  group: '',
  department: '',
  employee: '',
  broker: '',
  customId: '',
  loanNumber: '',
  carrierOptions: {},
  carrierUnderwriterOptions: [],
  divisionOptions: [],
  executiveOptions: [],
  representativeOptions: [],
  groupOptions: [],
  departmentOptions: [],
  employeeOptions: [],
  brokerOptions: [],
  lobOption: [],
  existingCustomer: [],
  eoiFile: null,
  selectedAdhocLob: '',
  product: '',
  assistedBy: '',
  salesforceCustomerUUID: '',
  isCarrierRiskConsented: false,
  sl2FormFile: null,
  dueDiigenceFormFile: null,
};

const INIT_PREMIUM_MODAL_DETAILS: AppComponents.PremiumModalDetails = {
  isShowModal: false,
  heading: '',
  message: '',
  contentValue: '',
};

class AMSDetailsModal extends React.Component<
  AppComponents.AMSDetailsModalProps & WithStyles<typeof styles>,
  AppComponents.AMSDetailsModalState
> {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    type: PropTypes.string.isRequired,
    quoteList: PropTypes.array,
    closehandler: PropTypes.func.isRequired,
    isQuoteAdhocBind: PropTypes.bool,
  };
  state: AppComponents.AMSDetailsModalState = {
    formSubmitted: false,
    formDetails: { ...INIT_FORM_DETAILS },
    formDetailsErrors: { ...INIT_FORM_ERRORS },
    open: false,
    commonError: '',
    AMSLOBChanged: false,
    newInsured: false,
    existingUser: false,
    stateName: [...STATEDATA.states],
    dataClear: false,
    searchText: '',
    selectedLobQuoteListData: [],
    eoiFileName: '',
    selectedCarrierId: '',
    selectedLineOfBuissness: '',
    isAdhocHomeLob: false,
    isEoiFileUploadEnabled: false,
    isShowLoanNumberField: false,
    premiumModalDetails: { ...INIT_PREMIUM_MODAL_DETAILS },
    isPrimumConfirmed: false,
    sl2FormFileName: '',
    dueDiigenceFormFileName: '',
    fileTypeError: '',
    isOpenUploadAMSDocumentModal: false,
    amsUploadDocumentFieldName: '',
  };

  componentDidMount() {
    const { common, ratePageTypes } = this.props;
    let lobList = getLineOfBusiness();
    let isShowLoanNumberField: boolean = false;
    let selectedLob: string =
      !!ratePageTypes && ratePageTypes !== CAROUSEL
        ? common?.selectedBusinessOption?.toLowerCase()
        : lobList[0]?.toLowerCase();
    if (!isEmpty(selectedLob) && this.props.isQuote) {
      this.props.fetchAMSDetails({
        entity: selectedLob,
      });
      this.props.showLOBAMSDetails(false);
    }
    if (this.props.open !== this.state.open && !this.props.isQuote) {
      this.setState({
        open: this.props.open,
        newInsured: !!this.props.isQuoteAdhocBind ? true : false,
      });
      this.props.fetchLOBAMSDetails();
      this.props.showLOBAMSDetails(true);
    }
    isShowLoanNumberField = lobList.includes(selectedLob)
      ? SHOW_LOAN_NUMBER_FOR_LOB.includes(selectedLob)
      : SHOW_LOAN_NUMBER_FOR_LOB.includes(lobList[0]);
    if (!isEmpty(selectedLob)) {
      this.props.setLineOfBusiness(selectedLob);
      this.setState(
        (prevState: any) => ({
          ...prevState,
          selectedLineOfBuissness: selectedLob,
          isShowLoanNumberField: isShowLoanNumberField,
        }),
        () => {
          this.getSelectedQuoteData(selectedLob);
        }
      );
    }
  }

  UNSAFE_componentWillReceiveProps(newProps: AppComponents.AMSDetailsModalProps) {
    const { selectedLineOfBuissness, selectedCarrierId } = this.state;
    if (newProps.open !== this.state.open) {
      this.setState({
        open: newProps.open,
      });
    }
    if (
      !isEqual(newProps.common.AMSDetails, this.state.formDetails) &&
      !this.state.formSubmitted &&
      isEmpty(this.state.commonError) &&
      !this.state.dataClear
    ) {
      this.setPropsToState(newProps);
    }

    if (newProps.home.AMSLOBChanged !== this.state.AMSLOBChanged) {
      const { formDetails } = this.state;
      !!formDetails?.businessLine &&
        this.props.setLineOfBusiness(formDetails.businessLine.toLowerCase());
      this.setState({
        AMSLOBChanged: newProps.home.AMSLOBChanged,
        formDetails: {
          ...formDetails,
          ...newProps.common.AMSDetails,
          businessLine: formDetails.businessLine || newProps.common.AMSDetails.lob,
          division: formDetails.division,
          department: formDetails.department,
        },
      });
    }

    if (!isEmpty(newProps.common.AMSCommonError) && isEmpty(this.state.commonError)) {
      this.setState({
        formDetails: {
          ...this.state.formDetails,
          carrierUnderwriterOptions: [...newProps.common.AMSDetails.carrierUnderwriterOptions],
        },
        commonError: newProps.common.AMSCommonError,
      });
    }
    if (
      !isEmpty(selectedLineOfBuissness) &&
      !isEmpty(newProps?.common?.AMSLOBDetails?.carrierList) &&
      !!newProps?.common?.isGetPolicyHeaderSuccess &&
      !!selectedCarrierId
    ) {
      let selectedCarrierName = getCarrierNameFromCarrierList(
        selectedLineOfBuissness === INSURANCE_PRODUCTS[3]
          ? EARTH_QUAKE_LOB
          : selectedLineOfBuissness,
        selectedCarrierId
      );
      this.props.setAMSDetails({ carrierName: selectedCarrierName });
      this.props.getCarrierUnderwriterList({
        carrierId: selectedCarrierId,
      });
      this.props.setPolicyHeadersResponse(false);
      this.setState((prevState: any) => ({
        ...prevState,
        selectedLineOfBuissness: '',
        formDetails: {
          ...this.state.formDetails,
          carrierName: selectedCarrierName,
        },
      }));
    }
  }

  componentWillUnmount() {
    this.props.setAMSDetails({ ...INIT_FORM_DETAILS, lob: '' });
    this.setState({
      open: false,
      formSubmitted: false,
      formDetails: { ...INIT_FORM_DETAILS },
      formDetailsErrors: { ...INIT_FORM_ERRORS },
      commonError: '',
      AMSLOBChanged: false,
      newInsured: false,
      existingUser: false,
    });
  }

  setPropsToState = (newProps: any) => {
  const {
    effectiveDate,
    businessLine,
    policyNumber,
    carrierName,
    executive,
    representative,
    group,
    department,
    employee,
    broker,
    customId,
    loanNumber,
    fullName,
    phoneNumber,
    addressDetails,
    firstName,
    lastName,
    email,
    dob,
    product,
    selectedAdhocLob,
    address,
    eoiFile,
    assistedBy,
    salesforceCustomerUUID,
    carrierUnderwriter,
    dueDiigenceFormFile,
    sl2FormFile,
  } = this.state.formDetails;
  const { AMSDetails, lineOfBusiness } = newProps.common;
  const applicantData = getApplicantDetails();
  const { personal_details } = applicantData;
  if (newProps?.isQuoteAdhocBind) {
    if (!isEmpty(AMSDetails?.lobOption)) {
      const tempLOBOption = this.getSelectedLobForAdhoc360();
      if (!isEmpty(tempLOBOption) && !isEqual(tempLOBOption, AMSDetails?.lobOption)) {
        this.props.setAMSDetails({
          lobOption: tempLOBOption,
        });
      }
    }
  }
  this.setState(
    prevState => ({
      formDetails: {
        ...this.state.formDetails,
        ...AMSDetails,
        address: removeExtraSpaces(getAddressDetails(AMSDetails?.addressDetails)) || address || '',
        effectiveDate: this.getPropertyValue(effectiveDate, AMSDetails.effectiveDate),
        policyNumber: this.getPropertyValue(
          policyNumber,
          !newProps?.isQuoteAdhocBind ? AMSDetails.policyNumber : ''
        ),
        carrierName: !!prevState?.formDetails?.carrierName
          ? prevState.formDetails.carrierName.trim()
          : !!carrierName
          ? carrierName.trim()
          : this.getPropertyValue(
              carrierName,
              !newProps?.isQuoteAdhocBind ? AMSDetails.carrierName : ''
            ),
        carrierUnderwriter: carrierUnderwriter || AMSDetails?.carrierUnderwriter,
        isCarrierRiskConsented: AMSDetails?.isCarrierRiskConsented,
        selectedCarrier: prevState?.formDetails?.selectedCarrier,
        carrierId: prevState?.formDetails?.carrierId,
        premium: prevState?.formDetails?.premium
          ? prevState.formDetails.premium
          : !newProps?.isQuoteAdhocBind
          ? AMSDetails?.premium
          : '',
        division: prevState?.formDetails?.division
          ? prevState.formDetails.division
          : AMSDetails?.division,
        term: !!prevState?.formDetails?.term ? prevState.formDetails.term : AMSDetails?.term,
        executive: this.getPropertyValue(
          executive,
          AMSDetails.executive
        ),
        representative: this.getPropertyValue(
          representative,
          AMSDetails.representative
        ),
        group: this.getPropertyValue(group, AMSDetails.group),
        department: this.getPropertyValue(department, AMSDetails.department),
        employee: this.getPropertyValue(
          employee,
          AMSDetails.employee
        ),
        broker: this.getPropertyValue(
          broker,
          AMSDetails.broker
        ),
        customId:
          this.getPropertyValue(customId, !newProps?.isQuoteAdhocBind ? AMSDetails.customId : '') ||
          '',
        salesforceCustomerUUID:
          this.getPropertyValue(
            salesforceCustomerUUID,
            AMSDetails?.salesforceCustomerUUID,
          ) || '',
        loanNumber:
          this.getPropertyValue(
            loanNumber,
            !newProps?.isQuoteAdhocBind ? AMSDetails.loanNumber : ''
          ) || '',
        businessLine: businessLine
          ? businessLine
          : !newProps?.isQuoteAdhocBind
          ? lineOfBusiness
          : '',
        fullName: this.getPropertyValue(
          fullName,
          !newProps?.isQuoteAdhocBind ? AMSDetails.fullName : ''
        ),
        phoneNumber: this.getPropertyValue(
          phoneNumber,
          !newProps?.isQuoteAdhocBind ? AMSDetails.phoneNumber : personal_details.phone_number
        ),
        addressDetails:
          isEmpty(addressDetails?.street) && newProps?.isQuoteAdhocBind
            ? {
                street: applicantData?.address?.street,
                unit: applicantData?.address?.unit,
                city: applicantData?.address?.city,
                zip: applicantData?.address?.zip,
                state: applicantData?.address?.state
                  ? applicantData?.address?.state?.toUpperCase()
                  : '',
                county: applicantData?.address?.county,
              }
            : newProps.common.isLOBAMSDetails && this.state.existingUser
            ? AMSDetails.addressDetails
            : addressDetails,
        firstName: this.getPropertyValue(
          firstName,
          !newProps?.isQuoteAdhocBind ? AMSDetails.firstName : personal_details.first_name
        ),
        lastName: this.getPropertyValue(
          lastName,
          !newProps?.isQuoteAdhocBind ? AMSDetails.lastName : personal_details.last_name
        ),
        email: this.getPropertyValue(
          email,
          !newProps?.isQuoteAdhocBind ? AMSDetails.email : personal_details.email
        ),
        dob: this.getPropertyValue(
          dob,
          !newProps?.isQuoteAdhocBind ? AMSDetails.dob : personal_details.date_of_birth
        ),
        product: product || '',
        selectedAdhocLob: selectedAdhocLob || '',
        eoiFile: eoiFile || null,
        assistedBy: this.getPropertyValue(assistedBy, AMSDetails.assistedBy),
        sl2FormFile: sl2FormFile || null,
        dueDiigenceFormFile: dueDiigenceFormFile || null,
      },
      isEoiFileUploadEnabled: this.state.isEoiFileUploadEnabled || false,
      isShowLoanNumberField: prevState.isShowLoanNumberField || false,
    }),
    () => this.checkEoiUploadEnabled()
  );
  }

  getSelectedLobForAdhoc360() {
    const {
      common: {
        adhocBoundedLobs,
        AMSDetails: { lobOption },
      },
      partner: { appointments },
    } = this.props;
    let selectedLobList: Array<any> = !isEmpty(appointments) ? Object.keys(appointments) : [];
    let lobList: Array<any> = [];
    if (!isEmpty(selectedLobList)) {
      if (selectedLobList.includes(INSURANCE_PRODUCTS[0]?.toUpperCase())) {
        lobList.push(INSURANCE_PRODUCTS[0]);
      }
      if (selectedLobList.includes(INSURANCE_PRODUCTS[1]?.toUpperCase())) {
        lobList.push(INSURANCE_PRODUCTS[1]);
      }
      if (selectedLobList.includes(INSURANCE_PRODUCTS[2]?.toUpperCase())) {
        lobList.push(INSURANCE_PRODUCTS[2]);
      }
      if (selectedLobList.includes(INSURANCE_PRODUCTS[3]?.toUpperCase())) {
        lobList.push(INSURANCE_PRODUCTS[3]);
      }
    }
    let selectedLineOfBusinessCodeList = lobList.map((lob: string) => {
      const selectedLobData: any = Object.values(adhocLobMapping).filter(lobData => {
        return lobData.key === lob;
      });
      if (!isEmpty(selectedLobData[0])) {
        return selectedLobData[0].code;
      } else {
        return lob.toUpperCase();
      }
    });
    if (adhocBoundedLobs && !isEmpty(adhocBoundedLobs)) {
      const tempAdhocBoundedLobs = adhocBoundedLobs.map((element: any) => {
        return element.toUpperCase();
      });
      selectedLineOfBusinessCodeList = [...selectedLineOfBusinessCodeList, ...tempAdhocBoundedLobs];
    }
    let tempLobOption = lobOption.filter((lobDetails: any) => {
      return !selectedLineOfBusinessCodeList.includes(lobDetails.LineOfBusinessCode);
    });
    if (selectedLobList.includes(INSURANCE_PRODUCTS[8]?.toUpperCase()) && !isEmpty(tempLobOption)) {
      let lobDropDownList = tempLobOption?.filter(
        (item: any) => item?.LineOfBusinessCode !== adhocLobMapping.umbrella.code
      );
      tempLobOption = lobDropDownList;
    }
    return tempLobOption;
  }

  checkEoiUploadEnabled = () => {
    const { common, home } = this.props;
    const { formDetails } = this.state;
    let eoiLob: any = common?.isLOBAMSDetails
      ? formDetails?.selectedAdhocLob
      : formDetails?.businessLine;
    if (!isEmpty(eoiLob) && eoiLob?.toLowerCase() === INSURANCE_PRODUCTS[0]) {
      eoiLob = common?.isLOBAMSDetails ? formDetails?.product : home?.productType;
    }
    this.setState((prevState: any) => ({
      ...prevState,
      isEoiFileUploadEnabled: !!showEoiFileUpload(eoiLob) ? true : false,
    }));
  };

  getSelectedQuoteData = (lob: string) => {
    const { selectedLineOfBuissness } = this.state;
    const selectedQuoteList = sessionStorage?.selectedQuoteList
      ? JSON.parse(sessionStorage.selectedQuoteList)
      : [];
    if (!isEmpty(selectedQuoteList)) {
      if (!!lob) {
        let newSelectedCarrier = selectedQuoteList.find((item: any) => {
          let newData = '';
          if (
            !!item?.type &&
            lob &&
            item?.type.toLowerCase() === lob.toLowerCase() &&
            (!!item?.quote?.name || !!item?.quote?.carrier_name)
          ) {
            newData = !!item?.quote?.name
              ? item.quote.name
              : !!item?.quote?.carrier_name
              ? item?.quote?.carrier_name
              : '';
          }
          return newData;
        });
        if (!!newSelectedCarrier?.quote) {
          let carrierName = !!newSelectedCarrier?.quote?.carrier_id
            ? getCarrierNameFromCarrierList(
                lob === INSURANCE_PRODUCTS[3] ? EARTH_QUAKE_LOB : lob,
                newSelectedCarrier?.quote?.carrier_id
              )
            : '';
          let selectedCarrier = !isEmpty(carrierName)
            ? carrierName
            : !!newSelectedCarrier?.quote?.name
            ? newSelectedCarrier.quote.name
            : !!newSelectedCarrier?.quote?.carrier_name
            ? newSelectedCarrier?.quote?.carrier_name
            : '';
          let carrierData: any = { carrierName: '', premium: '', term: '', isCarrierRiskConsented: false };
          let carrierId =
            lob === INSURANCE_PRODUCTS[2] || lob === INSURANCE_PRODUCTS[3]
              ? newSelectedCarrier?.quote?.carrier_id
              : parseInt(newSelectedCarrier?.quote?.carrier_id) ?? '';
          this.setState((prevState: any) => ({
            ...prevState,
            formDetails: {
              ...prevState.formDetails,
              carrierId,
              isCarrierRiskConsented: false,
            },
            selectedCarrierId: carrierId ?? '',
          }));
          if (isEmpty(selectedLineOfBuissness) && !!carrierId) {
            this.props.getCarrierUnderwriterList({
              carrierId: carrierId,
            });
          }
          carrierData = {
            carrierId: carrierId || '',
            carrierName: !isEmpty(selectedCarrier) ? selectedCarrier : '',
            premium: newSelectedCarrier?.quote?.price,
            term: getCarrierPremiumTerm(newSelectedCarrier?.quote),
            isCarrierRiskConsented: false,
          };
          this.props.setAMSDetails({ ...carrierData });
        }
      }
    }
    this.checkEoiUploadEnabled();
  };

  onChangeDocumentModel = (flag: boolean, key: string) => {
    const stateData: any = { ...this.state };
    stateData.isOpenUploadAMSDocumentModal = flag;
    stateData.amsUploadDocumentFieldName = key;
    if (!flag) {
      stateData[`${key}Name`] = '';
      stateData.fileTypeError = '';
      stateData.formDetails[key] = null;
      stateData.amsUploadDocumentFieldName = '';
    }
    this.setState({
      ...stateData,
    });
  };

  onUploadOrDropAMSDocument = (event: any, key: string, type: string = '') => {
    event.preventDefault();
    const stateData: any = { ...this.state };
    const { files } = type === UPLOAD ? event.target : event.dataTransfer;
    if (!!files[0]?.type && files[0]?.type !== PDF_TYPE) {
      stateData[`${key}Name`] = '';
      stateData.fileTypeError = INVALID_PDF_FILE_ERROR;
    } else {
      stateData[`${key}Name`] = files[0]?.name;
      stateData.fileTypeError = '';
      stateData.isOpenUploadAMSDocumentModal = false;
      stateData.formDetails[key] = files[0];
      stateData.amsUploadDocumentFieldName = '';
    }
    this.setState({
      ...stateData,
    });
  };

  onClearAmsDocumentUpload = (key: string) => {
    const stateData: any = { ...this.state };
    stateData[`${key}Name`] = '';
    stateData.fileTypeError = '';
    stateData.formDetails[key] = null;
    this.setState({
      ...stateData,
    });
  };

  getPropertyValue = (value: any, propValue: any) => (!!value ? value : propValue);

  dropDownValuesUpdated = (newData: any, label: string) => {
    const { common } = this.props;
    let formDetails: any = {
      ...this.state.formDetails,
      [label]: !isEmpty(newData) ? newData : '',
    };
    let formDetailsErrors: any = {
      ...this.state.formDetailsErrors,
    };
    let isAdhocHomeLob: boolean = this.state.isAdhocHomeLob;
    let isShowLoanNumberField: boolean = this.state.isShowLoanNumberField;
    if (!!common?.isLOBAMSDetails && label === BUSINESS_LINE) {
      const { lobOption } = this.state.formDetails;
      let selectedLob: any = lobOption.find((lob: any) => lob.Description === newData);
      formDetails.selectedAdhocLob = !isEmpty(selectedLob?.LineOfBusinessCode)
        ? selectedLob.LineOfBusinessCode
        : '';
      isShowLoanNumberField = SHOW_LOAN_NUMBER_FOR_LOB.includes(
        formDetails?.selectedAdhocLob?.toLowerCase()
      );
      if (!isShowLoanNumberField) {
        formDetails.loanNumber = '';
        formDetailsErrors.loanNumber = '';
      }
      if (formDetails?.selectedAdhocLob?.toLowerCase() !== INSURANCE_PRODUCTS[0]) {
        formDetails.product = '';
      }
      isAdhocHomeLob =
        common?.isLOBAMSDetails &&
        !isEmpty(formDetails.selectedAdhocLob) &&
        formDetails?.selectedAdhocLob?.toLowerCase() === INSURANCE_PRODUCTS[0]
          ? true
          : false;
    }
    this.setState(
      {
        formDetails:
          label === SELECTED_CARRIER
            ? {
                ...this.state.formDetails,
                selectedCarrier: newData,
                carrierName: newData?.Name,
                carrierUnderwriter: '',
              }
            : formDetails,
        formDetailsErrors: formDetailsErrors,
        isAdhocHomeLob: isAdhocHomeLob,
        isShowLoanNumberField: isShowLoanNumberField,
      },
      () => {
        label === SELECTED_CARRIER &&
          this.setCarrierUnderwriter(!isEmpty(newData?.Name) ? newData?.Name : '');
        this.checkEoiUploadEnabled();
        if (
          label === BUSINESS_LINE &&
          !showEoiFileUpload(newData === HOME_OWNERS ? INSURANCE_PRODUCTS[0] : newData)
        ) {
          this.onClearAmsDocumentUpload(EOI_DOCUMENT.KEY);
          this.onClearAmsDocumentUpload(DUE_DILIGENCE_FORM_DOCUMENT.KEY);
          this.onClearAmsDocumentUpload(SL2_FORM_DOCUMENT.KEY);
        }
      }
    );
  };

  setCarrierUnderwriter = (selectedValue: string) => {
    this.setState({
      commonError: '',
    });
    const { carrierOptions } = this.state.formDetails;

    if (selectedValue) {
      const selectedCarrier: any = find(
        getCarrierOptionList(carrierOptions),
        (carrierDetails: any) => carrierDetails.Name === selectedValue
      );
      this.setState(
        {
          formDetails: {
            ...this.state.formDetails,
            term: getCarrierPremiumTerm(null),
          },
        },
        () => {
          if (!isEmpty(selectedCarrier.CompanyCode)) {
            this.props.getCarrierUnderwriterList({ carrierCode: selectedCarrier.CompanyCode });
          }
        }
      );
    } else {
      this.setState({
        formDetails: {
          ...this.state.formDetails,
          carrierUnderwriter: '',
          carrierUnderwriterOptions: [],
        },
      });
    }
  };

  handleInputChange = ({ target }: any) => {
    if (target.name === BUSINESS_LINE) {
      const amsFormData = {
        formDetails: {
          ...this.state.formDetails,
          carrierName: '',
          premium: '',
          term: '',
          [target.name]: target.value,
          isCarrierRiskConsented: false,
        },
        formDetailsErrors: {
          ...this.state.formDetailsErrors,
        },
        selectedLineOfBuissness: '',
        isShowLoanNumberField: false
      }
      if (!SHOW_LOAN_NUMBER_FOR_LOB.includes(target.value)) {
        amsFormData.formDetails.loanNumber = '';
        amsFormData.formDetailsErrors.loanNumber = '';
      } else amsFormData.isShowLoanNumberField = true;
      this.setState(
        {
          ...amsFormData
        },
        () => {
          this.props.setAMSLOBChanged(target.value);
          this.getSelectedQuoteData(target?.value?.toLowerCase());
          this.props.setAMSDetails({
            customId: amsFormData?.formDetails?.customId || '',
            loanNumber: amsFormData?.formDetails?.loanNumber || '',
            salesforceCustomerUUID: get(amsFormData, 'formDetails.salesforceCustomerUUID', ''),
            isCarrierRiskConsented: false,
          });
        }
      );
      this.onClearAmsDocumentUpload(EOI_DOCUMENT.KEY);
      this.onClearAmsDocumentUpload(DUE_DILIGENCE_FORM_DOCUMENT.KEY);
      this.onClearAmsDocumentUpload(SL2_FORM_DOCUMENT.KEY);
    } else {
      this.setState({
        formDetails: {
          ...this.state.formDetails,
          [target.name]: target.value,
        },
      });
    }
  };

  handleClose = (isClose: boolean) => {
    this.setState({
      open: isClose,
      formSubmitted: false,
      formDetails: { ...INIT_FORM_DETAILS },
      formDetailsErrors: { ...INIT_FORM_ERRORS },
      commonError: '',
      AMSLOBChanged: false,
      newInsured: false,
      existingUser: false,
    });

    this.props.setMaxAttemptReached(false);
    this.props.setInsuredDropDownLoader(false);
    this.props.closehandler();
  };

  checkDateValidations = (dateValue: string) => {
    const startDate = moment().subtract(AMS_EFFECTIVE_DATE_DURATION, 'months').format(dateFormat);
    if (!moment(dateValue).isValid()) {
      return 'Invalid';
    } else if (!moment(startDate).isBefore(dateValue)) {
      return 'Invalid';
    } else {
      return '';
    }
  };

  checkForOtherValidations = (key: string, errorDetails: any) => {
    const details = { ...errorDetails };
    if (key === 'effectiveDate') {
      details[`${key}Error`] = this.checkDateValidations(this.state.formDetails[key]);
    } else if (key === 'policyNumber' && /[&"]/.test(String(this.state.formDetails[key]))) {
      details[`${key}Error`] = 'Invalid';
    } else if (key === 'premium' && parseFloat(this.state.formDetails[key]) === 0) {
      details[`${key}Error`] = 'Invalid';
    } else {
      details[`${key}Error`] = '';
    }
    return details;
  };

  checkFormValues = () => {
    const { formDetails, formDetailsErrors, isAdhocHomeLob, isEoiFileUploadEnabled } = this.state;
    let errorDetails: any = { ...formDetailsErrors };
    if (!isEoiFileUploadEnabled) {
      this.onClearAmsDocumentUpload(EOI_DOCUMENT.KEY);
    }
    errorDetails.addressError =
      isEmpty(formDetails?.address) && !this.props.common.isLOBAMSDetails ? REQUIRED : '';
    this.setState({
      formDetailsErrors: { ...INIT_FORM_ERRORS },
    });
    let excludeValdationCheckList: Array<string> = [
      'lob',
      'product',
      'selectedAdhocLob',
      'customId',
      'loanNumber',
      'carrierId',
      'assistedBy',
      'salesforceCustomerUUID',
      'sl2FormFile',
      'dueDiigenceFormFile',
    ];
    forEach(omit(formDetails, AMS_EXCLUDE_PROPS), (element, key) => {
      if (!excludeValdationCheckList.includes(key)) {
        if (isEmpty(String(element).trim())) {
          errorDetails[`${key}Error`] = REQUIRED;
        } else {
          errorDetails = this.checkForOtherValidations(key, errorDetails);
        }
      }
      if (key === PRODUCT) {
        errorDetails[`${PRODUCT}Error`] =
          isAdhocHomeLob && isEmpty(formDetails.product) ? REQUIRED : '';
      }
    });
    errorDetails = { ...this.phoneFieldErrorCheck(errorDetails) };
    errorDetails.customId = !isEmpty(formDetails.customId)
      ? validateAlphaNumeric(formDetails.customId)
      : '';
    errorDetails.loanNumber = !isEmpty(formDetails.loanNumber)
      ? validateAlphaNumeric(formDetails.loanNumber)
      : '';
    if (!isEmpty(formDetails.premium))
      errorDetails.premiumError = validatePremiumValue(parseFloat(formDetails.premium));
    if (this.props.common.isLOBAMSDetails && !this.state.existingUser) {
      errorDetails = {
        ...this.addressErrorCheck(errorDetails),
      };
      errorDetails.emailError = validateEmail(formDetails?.email, true);
      if (isEmpty(formDetails.dob)) {
        errorDetails.dobError = REQUIRED;
      } else {
        if (!isEmpty(formDetails.dob)) {
          errorDetails.dobError = validateDOB(formDetails.dob);
        } else {
          errorDetails.dobError = '';
        }
      }
    }
    errorDetails.eoiFileError = !!isEoiFileUploadEnabled && !formDetails?.eoiFile ? REQUIRED : '';
    errorDetails.salesforceCustomerUUIDErr = validateUUID(formDetails?.salesforceCustomerUUID);
    errorDetails.carrierRiskConsentedError =
      isShowCarrierRiskConsentCheckbox(formDetails) && !formDetails?.isCarrierRiskConsented
        ? REQUIRED
        : '';
    const nonAdmitedDocumentName = getIsShowSl2OrDueDiligenceForm(formDetails);
    errorDetails.sl2FormFileNameError =
      nonAdmitedDocumentName === SL2_FORM_DOCUMENT.KEY && !formDetails?.sl2FormFile ? REQUIRED : '';
    errorDetails.dueDiigenceFormFileNameError =
      nonAdmitedDocumentName === DUE_DILIGENCE_FORM_DOCUMENT.KEY &&
      !formDetails?.dueDiigenceFormFile
        ? REQUIRED
        : '';
    if (!every(errorDetails, isEmpty)) {
      this.setState({
        formDetailsErrors: { ...errorDetails },
      });
      return false;
    }
    return true;
  };

  addressErrorCheck = (formErrors: any) => {
    const error = { ...formErrors };
    forEach(this.state.formDetails.addressDetails, (value, key) => {
      if ((!value || value.length === 0) && key !== 'unit' && key !== 'county') {
        error[`${key}`] = 'Required';
      } else {
        error[`${key}`] = '';
      }
    });
    return error;
  };

  phoneFieldErrorCheck = (formErrors: any) => {
    const { phoneNumber } = this.state.formDetails;
    const error = { ...formErrors };
    if (phoneNumber.length === 0) {
      error.phoneNumberError = 'Required';
    }
    const validUserPhone = validatePhoneNumber(phoneNumber);
    if (phoneNumber.length > 0 && (!phoneRegex.test(phoneNumber) || !validUserPhone)) {
      error.phoneNumberError = 'Invalid';
    }
    return error;
  };

  handleRadioButtonChange = (customerSelected: any) => {
    this.setState({
      formDetails: {
        ...this.state.formDetails,
        customerId: customerSelected.customerId,
      },
    });
  };

  selectExistingCustomer = (customerData: any) => {
    if (!isEmpty(get(this.state.formDetails, 'customerId', ''))) {
      const customerSelected: any = find(customerData, o => {
        return o.customerId === this.state.formDetails.customerId;
      });

      if (!isEmpty(customerSelected)) {
        this.setState(
          {
            formDetails: {
              ...this.state.formDetails,
              addressDetails: {
                ...customerSelected.address,
                state: get(customerSelected, 'address.state', '').toUpperCase(),
              },
              address: removeExtraSpaces(getAddressDetails(customerSelected.address)),
              fullName: removeExtraSpaces(
                getApplicantName({
                  first_name: get(customerSelected, 'first_name', ''),
                  middle_name: get(customerSelected, 'middle_name', ''),
                  last_name: get(customerSelected, 'last_name', ''),
                })
              ),
              email: get(customerSelected, 'email', ''),
              dob: get(customerSelected, 'dob', ''),
              phoneNumber: get(customerSelected, 'phone_number', ''),
              firstName: get(customerSelected, 'first_name', ''),
              middleName: get(customerSelected, 'middle_name', ''),
              lastName: get(customerSelected, 'last_name', ''),
            },
          },
          () => {
            this.props.setAMSDetails({
              addressDetails: {
                ...customerSelected.address,
                state: get(customerSelected, 'address.state', '').toUpperCase(),
              },
              address: removeExtraSpaces(getAddressDetails(customerSelected.address)),
              fullName: removeExtraSpaces(
                getApplicantName({
                  first_name: get(customerSelected, 'first_name', ''),
                  middle_name: get(customerSelected, 'middle_name', ''),
                  last_name: get(customerSelected, 'last_name', ''),
                })
              ),
              email: get(customerSelected, 'email', ''),
              dob: get(customerSelected, 'dob', ''),
              phoneNumber: get(customerSelected, 'phone_number', ''),
              firstName: get(customerSelected, 'first_name', ''),
              middleName: get(customerSelected, 'middle_name', ''),
              lastName: get(customerSelected, 'last_name', ''),
            });
            this.handleFormSubmit();
          }
        );
      }
    }
  };

  handleClosePhoneNumberModal = () => {
    this.props.setAMSDetails({
      openPopup: false,
    });
    this.setState({
      formDetails: {
        ...this.state.formDetails,
        customerId: null,
      },
    });
  };

  handleCreateNewCustomer = () => {
    this.setState(
      {
        formDetails: {
          ...this.state.formDetails,
          createCustomerFlag: true,
          customerId: null,
        },
      },
      () => {
        this.handleFormSubmit();
      }
    );
  };

  getHeadCells = () => {
    return headCells;
  };

  handleFormSubmit = () => {
    const { common, home } = this.props;
    const {
      lineOfBusiness,
      AMSDetails: { openPopup },
    } = !!common && common;
    let quoteList = getQuoteListOfLob(lineOfBusiness);
    if (this.checkFormValues()) {
      const {
        isPrimumConfirmed,
        formDetails: { premium, email },
      } = this.state;
      if (
        !openPopup &&
        !isPrimumConfirmed &&
        parseFloat(premium) > PREMIUM_VALIDATION_DETAILS.MINIMUM_PREMIUM_VALUE_TO_SHOW_MODAL
      ) {
        this.showPremiumModalHandler(parseFloat(premium));
        return false;
      }
      let productDetails: string = '';
      let lob: string = !isEmpty(this.state.formDetails.businessLine)
        ? this.state.formDetails.businessLine?.toLowerCase()
        : '';
      if (common?.isLOBAMSDetails && !isEmpty(this.state.formDetails.product)) {
        productDetails = this.state.formDetails.product.toUpperCase();
      } else {
        productDetails =
          !isEmpty(home?.productType) && lob === INSURANCE_PRODUCTS[0]
            ? home?.productType.toUpperCase()
            : '';
      }
      this.setState(
        {
          formDetails: {
            ...this.state.formDetails,
            businessLine: !!this?.state?.formDetails?.businessLine
              ? this.state.formDetails.businessLine
              : !!lineOfBusiness
              ? lineOfBusiness
              : '',
            product: productDetails || '',
            phoneNumber: this?.state?.formDetails?.phoneNumber || '',
            email: email?.trim() || '',
          },
          formSubmitted: true,
          commonError: '',
          isPrimumConfirmed: false,
        },
        () => {
          this.props.AMSDetailsSubmit({
            entity: this.props.type,
            quoteList: [...quoteList],
            amsBindType: this.props.common.isLOBAMSDetails ? ADHOC : QUOTES,
            ...this.state.formDetails,
            isQuoteAdhocBind: this.props.isQuoteAdhocBind,
          });
        }
      );
      return true;
    }
    return false;
  };

  getCarrier = (carrierId: any) => {
    const { businessLine, carrierOptions } = this.state.formDetails;
    let quoteList = getQuoteListOfLob(businessLine);
    const id = carrierId ? String(carrierId).trim() : '';
    const existingCarrier = find(quoteList, quoteDetails => {
      return (
        quoteDetails?.carrier_id === id ||
        quoteDetails?.carrier_id === parseInt(id) ||
        quoteDetails?.carrier_id === carrierId
      );
    });
    return !isEmpty(existingCarrier)
      ? existingCarrier
      : find(getCarrierOptionList(carrierOptions, businessLine), (quoteDetails: any) => {
          return String(quoteDetails.carrierId).trim() === id;
        });
  };

  onCarrierSelected = (event: React.ChangeEvent<{ value: unknown }>) => {
    const { common } = this.props;
    const { AMSDetails } = !!common && common;
    const { selectedFloodQuote } = AMSDetails;
    const selectedValue = event.target.value as any;
    const { businessLine, carrierOptions } = this.state.formDetails;
    this.setState({
      commonError: '',
    });
    if (selectedValue) {
      const selectedCarrier: any = find(
        getCarrierOptionList(carrierOptions, businessLine),
        (carrierDetails: any) => {
          if (carrierDetails?.carrierId?.toString() === selectedValue?.toString()) {
            return true;
          }
        }
      );
      this.setState({
        formDetails: {
          ...this.state.formDetails,
          carrierId: selectedCarrier?.carrierId,
          carrierName: selectedCarrier?.name,
          carrierUnderwriter: '',
          isCarrierRiskConsented: false,
        },
      });
      this.props.setAMSDetails({ premium: '', isCarrierRiskConsented: false });
      if (selectedFloodQuote && selectedFloodQuote.name === selectedCarrier?.name?.toLowerCase()) {
        this.setCarrierNameWithPremiumAndTerm(selectedFloodQuote, selectedCarrier.name);
        this.props.getCarrierUnderwriterList({
          carrierId: selectedFloodQuote.carrier_id,
        });
      } else {
        const getListedCarrier = this.getCarrier(selectedCarrier.carrierId);
        this.setCarrierNameWithPremiumAndTerm(getListedCarrier, selectedCarrier.name);
        this.props.getCarrierUnderwriterList({
          carrierId: selectedCarrier.carrierId,
        });
      }
    } else {
      this.setState({
        formDetails: {
          ...this.state.formDetails,
          carrierName: '',
          premium: '',
          term: '',
          carrierUnderwriter: '',
          carrierUnderwriterOptions: [],
          isCarrierRiskConsented: false,
        },
      });
    }
    event.stopPropagation();
  };

  onPremiumTermSelected = (event: React.ChangeEvent<{ value: number }>) => {
    const { AMSDetails } = this.props.common;
    const { selectedFloodQuote } = AMSDetails;
    const { formDetails } = this.state;
    let currentPremium = '';
    let currentTerm;
    if (!isEmpty(formDetails.carrierName)) {
      if (
        selectedFloodQuote &&
        selectedFloodQuote.name.includes(formDetails.carrierName.toLowerCase())
      ) {
        currentTerm = getCarrierPremiumTerm(selectedFloodQuote);
        if (String(currentTerm) === String(event.target.value)) {
          currentPremium = getCarrierPremium(selectedFloodQuote);
        }
      } else {
        const { common } = this.props;
        const { lineOfBusiness } = !!common && common;
        let quoteList = getQuoteListOfLob(lineOfBusiness);
        const quoteDetails = find(quoteList, quote => {
          const currentName = String(getCarrierName(quote)).toLowerCase();
          if (currentName.includes(formDetails.carrierName.toLowerCase())) {
            return quote;
          }
          return undefined;
        });

        currentTerm = getCarrierPremiumTerm(quoteDetails);
        if (String(currentTerm) === String(event.target.value)) {
          currentPremium = getCarrierPremium(quoteDetails);
        }
      }
    }
    this.setState({
      formDetails: {
        ...formDetails,
        premium: currentPremium,
        term: event.target.value,
      },
    });
    event.stopPropagation();
  };

  setCarrierNameWithPremiumAndTerm = (carrierDetails: any, carrierName: string) => {
    this.setState(prevState => ({
      formDetails: {
        ...prevState.formDetails,
        carrierName,
        premium: getCarrierPremium(carrierDetails),
        term: !isEmpty(carrierName) ? getCarrierPremiumTerm(carrierDetails) : '',
        carrierUnderwriter: '',
      },
    }));
  };

  handleCalendarDate = (date: any) => {
    this.setState({
      formDetails: {
        ...this.state.formDetails,
        effectiveDate: date,
      },
    });
  };

  getListOptions = (list: Array<any>) =>
    map(list, (quote, key) => {
      if (!quote?.quoteError) {
        const carrierId = quote?.carrierId || '';
        return (
          <option key={key} value={carrierId}>
            {getCarrierName(quote)}
          </option>
        );
      }
      return '';
    });

  getCarrierOptions = () => {
    const { common } = this.props;
    let selectedLob = !!common?.lineOfBusiness ? common?.lineOfBusiness.toLowerCase() : '';
    let selectedLobQuoteList: any;
    selectedLobQuoteList = getQuoteListOfLob(selectedLob);
    const { existingOptions, remainingOptions } = segregateListOptions(
      selectedLobQuoteList,
      getCarrierOptionList(
        this.state.formDetails.carrierOptions,
        !!selectedLob && selectedLob === INSURANCE_PRODUCTS[3] ? EARTH_QUAKE_LOB : selectedLob
      )
    );
    return (
      <React.Fragment>
        {!isEmpty(existingOptions) && (
          <optgroup label='Quoted Carriers'>{this.getListOptions(existingOptions)}</optgroup>
        )}

        {!isEmpty(remainingOptions) && (
          <optgroup label='Other Carriers'>{this.getListOptions(remainingOptions)}</optgroup>
        )}
      </React.Fragment>
    );
  };

  getCurrentLobs = () => {
    return getLineOfBusiness();
  };

  insuredChanged = (flag: boolean) => {
    this.setState({
      newInsured: flag,
      formDetails: {
        ...this.state.formDetails,
        herdId: '',
      },
    });
    this.props.setInsuredDropDownLoader(false);
    this.props.setAMSDetails({
      existingCustomer: [],
      herdId: '',
    });
  };

  handleDateOfBirth = (date: any) => {
    this.setState({
      formDetails: {
        ...this.state.formDetails,
        dob: date,
      },
    });
  };

  clearData = () => {
    this.setState({ dataClear: true }, () => {
      this.setState({
        formSubmitted: false,
        formDetails: {
          ...this.state.formDetails,
          fullName: '',
          firstName: '',
          lastName: '',
          email: '',
          dob: '',
          phoneNumber: '',
          address: '',
          addressDetails: {
            street: '',
            unit: '',
            city: '',
            zip: '',
            state: '',
          },
          businessLine: '',
          effectiveDate: '',
          carrierName: '',
          premium: '',
          term: '',
          carrierUnderwriter: '',
          policyNumber: '',
          division: '',
          executive: '',
          representative: '',
          group: '',
          department: '',
          employee: '',
          broker: '',
          herdId: '',
          eoiFile: null,
          assistedBy: '',
          sl2FormFile: null,
          dueDiigenceFormFile: null,
        },
        formDetailsErrors: { ...INIT_FORM_ERRORS },
        commonError: '',
        AMSLOBChanged: false,
        newInsured: false,
        dataClear: false,
        existingUser: false,
        searchText: '',
        fileTypeError: '',
        eoiFileName: '',
        sl2FormFileName: '',
        dueDiigenceFormFileName: '',
      });
    });

    this.props.setMaxAttemptReached(false);
    this.props.setAMSDetails({
      email: '',
      dob: '',
      fullName: '',
      firstName: '',
      middleName: '',
      lastName: '',
      addressDetails: {
        street: '',
        unit: '',
        city: '',
        zip: '',
        state: '',
      },
      phoneNumber: '',
      address: '',
      lob: '',
      existingCustomer: [],
    });
    this.props.setInsuredDropDownLoader(false);
  };

  handleHomeAddressDetailsChange = (event: any) => {
    const updatedAddress: any = { ...this.state.formDetails.addressDetails };
    if (
      (event.target.name === 'zip' &&
        (event.target.value.length > 5 || event.target.value.match(numberRegex) == null)) ||
      (event.target.name === 'city' && event.target.value.match(stringWithSpaceRegex) === null)
    ) {
      return false;
    }
    updatedAddress[event.target.name] = event.target.value;
    this.setState({
      formDetails: {
        ...this.state.formDetails,
        addressDetails: {
          ...updatedAddress,
        },
      },
    });
    event.preventDefault();
    return true;
  };

  handleAddressBlur = (e: any) => {
    const updatedAddress: any = { ...this.state.formDetails.addressDetails };
    if (e.target.name === 'unit') {
      updatedAddress[e.target.name] = e.target.value.trim();
    } else {
      updatedAddress[e.target.name] = getStartCase(e.target.value.toLowerCase());
    }
    this.setState({
      formDetails: {
        ...this.state.formDetails,
        addressDetails: {
          ...updatedAddress,
        },
      },
    });
    e.preventDefault();
  };

  clearAddressInputs = () => {
    this.setState({
      formDetails: {
        ...this.state.formDetails,
        addressDetails: {
          ...this.state.formDetails.addressDetails,
          street: '',
        },
      },
    });
  };

  onAddressSelected = (data: any) => {
    this.setState(state => {
      return {
        formDetails: {
          ...this.state.formDetails,
          addressDetails: {
            ...data,
          },
          address: removeExtraSpaces(getAddressDetails(data)),
        },
      };
    });
  };

  onChangeAddressField = (event: any) => {
    const { value } = event.target;
    this.setState((prevState: any) => ({
      ...prevState,
      formDetails: {
        ...prevState.formDetails,
        address: value,
      }
    }))
  }

  handlePersonalDetails = (event: any) => {
    const { target } = event;
    const fields: Array<string> = ['phoneNumber', 'email'];

    if (
      !isEmpty(target.value) &&
      !stringRegex.test(target.value) &&
      !fields.includes(target.name)
    ) {
      return;
    }

    const updatedFormDetails: any = { ...this.state.formDetails };
    updatedFormDetails[target.name] = target.value;
    this.setState({
      formDetails: { ...updatedFormDetails },
    });
    event.preventDefault();
  };

  handlePersonalDetailsBlur = (event: any) => {
    const { target } = event;
    const updatedFormDetails: any = { ...this.state.formDetails };
    updatedFormDetails[target.name] = getStartCase(target.value.toLowerCase());
    this.setState({
      formDetails: { ...updatedFormDetails },
    });
    event.preventDefault();
  };

  insuredDetailsSearchHandler = (searchText: string) => {
    if (!isEmpty(searchText)) {
      this.setState(
        {
          searchText: searchText,
        },
        () => {
          if (!isEmpty(searchText)) {
            this.props.adhocBindSearch({ searchText });
          }
        }
      );
    }
  };

  setInputCustomer = (currentDetails: any) => {
    this.setState({
      newInsured: true,
      existingUser: true,
    });

    this.props.setAMSDetails({
      email: get(currentDetails, 'email', ''),
      dob: get(currentDetails, 'dob', ''),
      fullName: removeExtraSpaces(
        getApplicantName({
          first_name: get(currentDetails, 'first_name', ''),
          middle_name: get(currentDetails, 'middle_name', ''),
          last_name: get(currentDetails, 'last_name', ''),
        })
      ),
      firstName: get(currentDetails, 'first_name', ''),
      middleName: get(currentDetails, 'middle_name', ''),
      lastName: get(currentDetails, 'last_name', ''),
      addressDetails: {
        ...currentDetails.address,
        state: get(currentDetails, 'address.state', '').toUpperCase(),
      },
      phoneNumber: get(currentDetails, 'phone_number', ''),
      address: removeExtraSpaces(getAddressDetails(currentDetails.address)),
      lob: '',
      existingCustomer: [],
      herdId: currentDetails.herd_id,
    });
    this.props.setInsuredDropDownLoader(false);
  };

  closeInsuredDropdown = () => {
    this.props.setAMSDetails({
      existingCustomer: [],
    });
    this.props.setInsuredDropDownLoader(false);
  };

  showPremiumModalHandler = (premium: number) => {
    this.setState({
      isPrimumConfirmed: false,
      premiumModalDetails: {
        isShowModal: true,
        heading: '',
        message: PREMIUM_VALIDATION_DETAILS.CONFIRMATION_MESSAGE + formatNumberToCurrencyWithDecimal(premium),
        contentValue: '',
      }
    }
    )
  }

  premiumConfirmationCloseHandler = (isConfirmed: boolean) => {
    this.setState((prestate) => {
      return {
        ...prestate,
        isPrimumConfirmed: isConfirmed,
        premiumModalDetails: {
          isShowModal: false,
          heading: '',
          message: '',
          contentValue: '',
        }
      }
    }, () => {
      if (isConfirmed) this.handleFormSubmit()
    })
  };

  getPremiumConfirmationModal = () => {
    const { isShowModal, heading, message, contentValue } = this.state.premiumModalDetails
    return <ConfirmationModal
      open={isShowModal}
      closehandler={this.premiumConfirmationCloseHandler}
      heading={heading ? heading : ' '}
      message={message}
      contentValue={contentValue}
      noText={CANCEL}
      yesText={CONTINUE}
    />
  };

  onCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    this.setState(prestate => {
      return {
        ...prestate,
        formDetails: {
          ...prestate.formDetails,
          isCarrierRiskConsented: checked,
        },
      };
    });
    this.props.setAMSDetails({ isCarrierRiskConsented: checked });
  };

  getFileName = () => {
    const { amsUploadDocumentFieldName, sl2FormFileName, dueDiigenceFormFileName, eoiFileName } =
      this.state;
    if (isEmpty(amsUploadDocumentFieldName)) {
      return '';
    }
    return amsUploadDocumentFieldName === SL2_FORM_DOCUMENT.KEY
      ? sl2FormFileName
      : amsUploadDocumentFieldName === DUE_DILIGENCE_FORM_DOCUMENT.KEY
      ? dueDiigenceFormFileName
      : amsUploadDocumentFieldName === EOI_DOCUMENT.KEY
      ? eoiFileName
      : '';
  };

  render() {
    const { isOpenUploadAMSDocumentModal, amsUploadDocumentFieldName, fileTypeError } = this.state;
    const { common } = this.props;
    return (
      <>
        {isOpenUploadAMSDocumentModal && (
          <UploadFile
            open={isOpenUploadAMSDocumentModal}
            onClose={flag => this.onChangeDocumentModel(flag, amsUploadDocumentFieldName)}
            onUploadChanges={event =>
              this.onUploadOrDropAMSDocument(event, amsUploadDocumentFieldName, UPLOAD)
            }
            fileTypeError={fileTypeError}
            fileName={this.getFileName()}
            loader={common?.AMSDetailsLoading || common?.carrierUnderwriterLoader}
            onDrop={event =>
              this.onUploadOrDropAMSDocument(event, amsUploadDocumentFieldName, DROP)
            }
            uploadTitle={UPLOAD_AMS_DOCUMENT_TITLE[amsUploadDocumentFieldName] || ''}
          />
        )}
        {template(this)}
        {this.getPremiumConfirmationModal()}
      </>
    );
  }
}

const mapStateToProps = ({
  common,
  dashboard,
  home,
  quote,
  floodDetails,
  quakeDetails,
  partner,
}: AppComponents.AMSDetailsModalStore) => {
  return { common, dashboard, home, quote, floodDetails, quakeDetails, partner };
};

const mapDispatchToProps = (dispatch: any): AppComponents.AMSDetailsModalDispatch => {
  return bindActionCreators(
    {
      AMSDetailsSubmit,
      setAMSLOBChanged,
      fetchAMSDetails,
      fetchLOBAMSDetails,
      setAMSDetails,
      getCarrierUnderwriterList,
      setMaxAttemptReached,
      setAMSDetailsLoader,
      setLineOfBusiness,
      showLOBAMSDetails,
      adhocBindSearch,
      setInsuredDropDownLoader,
      disableDivision,
      setPolicyHeadersResponse,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
  // tslint:disable-next-line: max-file-line-count
)(withStyles(styles)<any>(AMSDetailsModal));
