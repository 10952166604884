/* istanbul ignore file */
import { AxiosError } from 'axios';
import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { NotificationManager } from 'react-notifications';
import config from '../../../config/config';
import { get, isEmpty, omit } from 'lodash';
import * as actions from '../constants';
import {
  applicantDetailsFromHomeInsurance,
  applicantFormLoader,
  applicantFormSubmitFailed,
  applicantFormSubmitSuccess,
  driverContinue,
  errorHandler,
  fetchHomeQuoteDetails,
  fetchUmbrellaQuestionListInfo,
  getVeriskAutoDetails,
  petBreedsDetailsLoader,
  setSaveApplicantLoader,
  setApplicantDetails,
  setAddProductSaveApplicantLoader,
  setHomeAddressCheck,
  setHomeState,
  setNoQuotesAvailableForHome,
  setPetBreedsDetails,
  setPetQuotes,
  setPollingErrorDetails,
  setRequestId,
  setApplicantSuccess,
  setUmbrellaHerdId,
  saveApplicantError,
  stepChanged,
  storeLifeQuoteDetails,
  updateDriverName,
  setPropertyInfoLoader,
  updateQuoteDetails,
  validateAddress,
  vehicleSelectedForEdit,
} from '../actions';
import { getQuoteDetails } from './home';
import {
  createPageTracking,
  clearLobQuotes,
  getLobHerdId,
  mappedResponseToStoreForPropertyDetails,
  shouldApplicantHasPreviousAddress,
  getPreferredDeductible,
  getResidencyStatus,
} from '../../../utils';
import axiosRequest from '../../api';
import { INSURANCE_PRODUCTS, SUCCESSFULLY } from '../../../constants';
import { PrefillDetails } from '../reducers/applicant';

const applicantActions: any = actions;
const displayErrorProperty = 'error_details.display_errors_on_ui';
const errorProperty = 'error_details.errors';
function* applicantFormSubmit({ payload }: AppRedux.ActionPayload) {
  try {
    yield put(validateAddress(payload));
  } catch (error) {
    yield put(errorHandler(error));
  }
}

export function* applicantFormSubmitWatcher() {
  yield takeEvery(applicantActions.APPLICANT_FORM_SUBMIT, applicantFormSubmit);
}

function* validateApplicantAddress({ payload }: AppRedux.ActionPayload): any {
  yield put(applicantFormLoader(true));
  try {
    const {
      partner: { isProviderOneLogin },
    } = yield select();
    const details = yield axiosRequest(
      'POST',
      '/address/validate',
      { ...payload.address },
      isProviderOneLogin
    );
    if (details) {
      yield put(
        applicantFormSubmitSuccess({
          ...payload,
          address: {
            ...payload.address,
            county: get(details, 'data.county', ''),
            zip: get(details, 'data.zip', payload.address.zip),
          },
        })
      );
      const reduxState = yield select();
      const {
        driver,
        common: { isHomeRedirect },
      } = reduxState;
      /**
       * If not redirected from home and applicant address is valid
       * then set updated home address details
       */
      if (!isHomeRedirect) {
        yield put(
          setHomeState({
            address: {
              ...payload.address,
              county: get(details, 'data.county', ''),
              zip: get(details, 'data.zip', payload.address.zip),
            },
          } as any)
        );
      }
      if (driver.driversList.length === 0) {
        yield put(
          updateDriverName({
            first_name: payload.personal_details.first_name,
            last_name: payload.personal_details.last_name,
            date_of_birth: payload.personal_details.date_of_birth,
            industry: payload.personal_details.industry,
            occupation: payload.personal_details.occupation,
            education: payload.personal_details.education,
          } as any)
        );
      }
      if (payload.getQuoteClick) {
        // Check all values for each driver and then continue to quotes page
        yield put(driverContinue({ entity: 'otherDetails', lastDriver: true } as any));
      } else {
        if (reduxState.vehicle.vehiclesList.length >= 1) {
          yield put(vehicleSelectedForEdit(reduxState.vehicle.vehiclesList[0].vuid));
        }
        yield put(stepChanged({ entity: 'vehicles', value: true } as any));
        yield put(push('vehicle'));
      }
    }
  } catch (error) {
    yield put(applicantFormSubmitFailed());
    yield put(errorHandler(error));
  }
  yield put(applicantFormLoader(false));
}

export function* validateAddressWatcher() {
  yield takeEvery(applicantActions.VALIDATE_ADDRESS, validateApplicantAddress);
}

// Validate home address if county missing
function* validateHomeAddress({ payload }: AppRedux.ActionPayload): any {
  yield put(setHomeAddressCheck(true));
  const { personal_details, address } = payload;
  try {
    const {
      partner: { isProviderOneLogin },
    } = yield select();
    const details = yield axiosRequest(
      'POST',
      '/address/validate',
      { ...address },
      isProviderOneLogin
    );
    if (details.data) {
      const { street, unit, city, zip, state, county } = details.data;
      yield put(
        applicantDetailsFromHomeInsurance({
          personal_details: personal_details,
          address: { street, unit, city, zip, state, county },
        } as any)
      );
    }
  } catch (error) {
    if (get(error, 'response.status', 0) === 404) {
      yield put(stepChanged({ entity: 'vehicles', value: false } as any));
      yield put(applicantDetailsFromHomeInsurance({ personal_details, address } as any));
      yield put(push('applicant'));
    } else {
      yield put(errorHandler(error));
    }
  }
  yield put(setHomeAddressCheck(false));
}

export function* validateHomeAddressWatcher() {
  yield takeEvery(applicantActions.VALIDATE_HOME_ADDRESS, validateHomeAddress);
}

function* saveApplicantInfo({ payload }: AppRedux.ActionPayload): any {
  yield put(setSaveApplicantLoader(true));
  yield put(saveApplicantError(false));
  yield put(setNoQuotesAvailableForHome(false));
  try {
    const reduxStore = yield select();
    const {
      applicant,
      auth,
      common,
      driver,
      home,
      partner: { appointments, isProviderOneLogin, storedRequestId, producerDetails },
      quote,
      umbrellaDetails,
    } = reduxStore;
    const keys = Object.keys(appointments);
    const daleConfig = common?.pconfig?.pconfig?.dale_config || home?.pconfig?.dale_config;
    const { carrier_credentials_grid, is_verisk_prefill_available, ...configWithoudCreds } =
      daleConfig;
    const organizationName = common?.pconfig?.pconfig?.name || daleConfig?.name;
    const organizationId = common?.pconfig?.pconfig?.id || daleConfig?.id;
    const { code, auth_token } = configWithoudCreds;
    const quoteBy = !isEmpty(get(auth, 'email', ''))
      ? get(auth, 'email')
      : !isEmpty(producerDetails?.producerEmail)
      ? producerDetails?.producerEmail
      : '';
    let herdId = getLobHerdId(payload) || '';
    if (INSURANCE_PRODUCTS[4] === payload) {
      yield put(setPetBreedsDetails({ cat: {}, dog: {}, isBreedError: false }));
    }
    const { lobQuotesToClear, clearAutoInfo, clearPetInfo, clearLifeInfo, clearUmbrellaInfo } =
      clearLobQuotes();
    let pageTracking = createPageTracking(!clearAutoInfo ? common?.pageTracking : {}, appointments);
    let requestedPayload: any = {
      pageTracking,
      lobQuotesToClear,
      clearAutoInfo,
      clearPetInfo,
      clearLifeInfo,
      clearUmbrellaInfo,
      personal_information: { ...omit(applicant?.personal_details) },
      address: { ...applicant.address },
      organization: { config: { code, auth_token, name: organizationName, organizationId } },
      herd_id: herdId,
      quote_by: quoteBy,
      id: get(auth, 'userId', ''),
      provider_request_id: storedRequestId,
      selected_lob: keys,
      lob: payload,
    };
    if (shouldApplicantHasPreviousAddress()) {
      requestedPayload.isMovedInPastMonths = !!applicant.isMovedInPastMonths;
      requestedPayload.previousAddress = !!applicant.isMovedInPastMonths
        ? { ...applicant.previousAddress }
        : null;
    }
    if (payload === INSURANCE_PRODUCTS[0]) {
      const quoteDetails = yield call(getQuoteDetails);
      yield put(
        setApplicantDetails({
          prefillDetails: null,
          userPropertyInformation: null,
        })
      );
      requestedPayload = {
        ...requestedPayload,
        home_type: quoteDetails?.home_type,
        is_condo: quoteDetails?.is_condo,
        loanOfficer_details: quoteDetails?.loanOfficer_details || null,
        preferredDeductible: getPreferredDeductible(),
        request_id: quoteDetails?.request_id,
        residency_status: getResidencyStatus(),
        retrieveQuote: false,
      };
    }
    const details = yield axiosRequest(
      'POST',
      '/applicant/save-applicant-info',
      isProviderOneLogin,
      {
        ...requestedPayload,
      }
    );
    if (!isEmpty(details?.data?.message) && details?.data?.message.includes(SUCCESSFULLY)) {
      if (payload === INSURANCE_PRODUCTS[0]) {
        const { herdId, prefillData, isPropertyInfoRequired, prefillStatus } = details?.data;
        yield put(
          setHomeState({
            herd_id: herdId,
          } as any)
        );
        yield put(setPropertyInfoLoader(false));
        const prefillDetails: any = !isEmpty(prefillData)
          ? mappedResponseToStoreForPropertyDetails(prefillData)
          : {
              propertyData: {},
              label: {},
            };
        yield put(
          setApplicantDetails({
            prefillDetails: prefillDetails,
            isApplicantFilled: false,
            saveApplicantLoader: false,
            isPropertyInfoRequired: !!isPropertyInfoRequired,
            prefillStatus: prefillStatus || null,
            noOfRetries: 0,
          })
        );
        if (
          (!isPropertyInfoRequired && !!config?.enablePrefillOverride) ||
          !config?.enablePrefillOverride
        ) {
          yield put(
            setHomeState({
              hippoQuoteLoaded: false,
              ezlynxQuotesLoaded: false,
              quoteList: [],
              quoteListError: [],
              upcomingCarrierList: [],
            } as any)
          );
          yield put(fetchHomeQuoteDetails());
        }
      } else if (INSURANCE_PRODUCTS[1] === payload) {
        yield put(
          updateQuoteDetails({
            ...quote.quotingDetails,
            herd_id: get(details, 'data.herd_id', null),
          })
        );
        if (
          get(details.data, displayErrorProperty, false) &&
          !isEmpty(get(details.data, errorProperty, {}))
        ) {
          yield put(setPollingErrorDetails({ ...details.data }));
        }
        if (is_verisk_prefill_available && (!driver.driversList.length || clearAutoInfo)) {
          yield put(getVeriskAutoDetails());
        }
      } else if (INSURANCE_PRODUCTS[4] === payload) {
        yield put(petBreedsDetailsLoader(true));
        yield put(setApplicantSuccess(true));
        yield put(
          setPetQuotes({
            quoteList: [],
            herdId: get(details, 'data.herd_id', null),
            upcomingCarrierList: [],
          })
        );
      } else if (INSURANCE_PRODUCTS[6] === payload) {
        yield put(storeLifeQuoteDetails({ herdId: get(details, 'data.herd_id', null) }));
      } else if (INSURANCE_PRODUCTS[8] === payload) {
        yield put(setUmbrellaHerdId(get(details, 'data.herd_id', null)));
        if (isEmpty(umbrellaDetails?.umbrellaInfo?.questionGroups) || !!clearUmbrellaInfo) {
          yield put(fetchUmbrellaQuestionListInfo());
        }
      }
      if (details?.data?.provider_request_id) {
        yield put(setRequestId(get(details, 'data.provider_request_id', null)));
      }
    }
  } catch (error) {
    yield put(saveApplicantError(true));
    yield put(errorHandler(error));
  }
  yield put(setSaveApplicantLoader(false));
  yield put(setAddProductSaveApplicantLoader(false));
}

export function* saveApplicantInfoWatcher() {
  yield takeEvery(applicantActions.SAVE_APPLICANT_DETAILS, saveApplicantInfo);
}

function* getPropertyInfoDetails({ payload }: any): any {
  try {
    yield put(setPropertyInfoLoader(true));
    const {
      partner: { isProviderOneLogin },
    } = yield select();
    const responseDetails = yield axiosRequest(
      'POST',
      '/applicant/get-prefill-info',
      isProviderOneLogin,
      {
        ...payload,
      }
    );
    if (!!responseDetails?.data && !!responseDetails?.status && responseDetails?.status === 200) {
      const { prefillData, noOfRetries, prefillStatus } = responseDetails.data;
      yield put(
        setApplicantDetails({
          prefillDetails: !isEmpty(prefillData)
            ? (mappedResponseToStoreForPropertyDetails(prefillData) as PrefillDetails)
            : {
                propertyData: {},
                label: {},
              },
          prefillStatus: prefillStatus || null,
          noOfRetries: noOfRetries || 0,
        })
      );
    }
  } catch (error) {
    const axiosError = error as AxiosError<any>;
    if (!!axiosError.response && axiosError.response.status === 404) {
      const { data } = axiosError?.response;
      const errorMessage = data?.Message || data?.message || '';
      if (!isEmpty(errorMessage)) {
        yield NotificationManager.error(errorMessage);
      }
    } else {
      yield put(errorHandler(axiosError));
    }
  }
  yield put(setPropertyInfoLoader(false));
}

export function* getPropertyInfoDetailsWatcher() {
  yield takeEvery(applicantActions.GET_PROPERTY_INFO_DETAILS, getPropertyInfoDetails);
}

export default function* rootSaga() {
  yield all([
    fork(applicantFormSubmitWatcher),
    fork(validateAddressWatcher),
    fork(validateHomeAddressWatcher),
    fork(saveApplicantInfoWatcher),
    fork(getPropertyInfoDetailsWatcher),
  ]);
}
