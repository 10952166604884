import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { createStyles, WithStyles, withStyles } from '@material-ui/core/styles';
import { forEach, get, isEmpty, omit } from 'lodash';
import {
  allowEditingQuote,
  checkProducerLicense,
  getApplicanLobKey,
  getDefaultOccupation,
  getIsHeapAnalyticsEnabled,
  getIsLobIncludesInAppointments,
  getIsPropertyInfoEnabledDetails,
  getOccupationList,
  getStartCase,
  isAutoQuote,
  isEmptyValues,
  numberRegex,
  phoneRegex,
  removeExistingRate,
  shouldApplicantHasPreviousAddress,
  stringRegex,
  stringWithSpaceRegex,
  validateDOB,
  validateEmail,
  validatePhoneNumber,
} from '../../../utils';
import { showFields, stringNumberRegex, getProductTypeByKey } from '../../home/utils';
import {
  homeApplicationSubmit,
  setApplicantDetails,
  setGoToRatesTabFromPolicy,
  setIncidentsFlag,
  setNextTabStep,
  setPolicyFlag,
  setFetchAutoQuotes,
  setHomeState,
  fetchQuakeQuoteDetails,
  fetchFloodQuoteDetails,
  setFloodDetailsLoader,
  saveApplicantDetails,
  setApplicantFormChange,
  quakeDetailSubmitLoader,
  setLineOfBusiness,
  setQuakeQuotes,
  setPrefillCall,
  setFloodQuotes,
  homeWarrantyQuotesLoader,
  fetchHomeWarrantyQuotes,
  fetchCyberSecurityQuotes,
  cyberSecurityQuotesLoader,
  setViewQuote,
  storeHomeWarrantyQuoteList,
  storeCyberSecurityQuoteDetails,
  setIsVeriskFetched,
} from '../../redux/actions';
import { INDUSTRY_DATA } from '../../../constants/industry';
import {
  DEFAULT_PERSONAL_DETAILS_VALUES,
  DP1,
  DP3,
  EARTH_QUAKE_LOB,
  EDUCATION,
  HEAP_LABELS,
  HO4,
  INDUSTRY,
  INSURANCE_BUSINESS_TYPES,
  INSURANCE_PRODUCTS,
  INVALID,
  INVALID_PREVIOUS_ADDRESS,
  MOVED_IN_PAST_MONTHS,
  OCCUPATION,
  PROPERTY_INFO,
  QUOTING_STEPS_PAGES_NAME,
  REQUIRED,
  STATE,
  STATEDATA,
  STEPPER_KEYS_MAPPING,
  TRUE,
  UNIT,
} from '../../../constants';
import './home-applicant-form.scss';
import template from './template';

export const styles = () =>
  createStyles({
    icon: {
      fontSize: '35px',
    },
    continueSpinner: {
      color: 'white !important',
      marginBottom: '2px',
    },
    errorText: {
      color: '#FF0000',
      fontSize: '12px',
      textAlign: 'left',
      marginLeft: '10px',
    },
    homeFormControl: {
      margin: '8px',
      minWidth: '100%',
      marginBottom: '0px',
    },
    homeTextField: {
      width: '100%',
      marginLeft: '8px',
      marginRight: '8px',
    },
  });

const INIT_ADDRESS = {
  street: '',
  unit: '',
  city: '',
  zip: '',
  state: '',
};

class ApplicantFormComponent extends React.Component<
  AppComponents.HomeContentProps & WithStyles<typeof styles>,
  AppComponents.HomeContentState
> {
  initFormErrors: AppComponents.HomeContentFormErrors = {
    street: '',
    unit: '',
    city: '',
    zip: '',
    state: '',
    first_name: '',
    middle_name: '',
    last_name: '',
    date_of_birth: '',
    loan_officer_phone: '',
    loan_officer_email: '',
    phone_number: '',
    email: '',
    industry: '',
    occupation: '',
    education: '',
    condo: '',
    previousAddress: INIT_ADDRESS,
  };
  state: AppComponents.HomeContentState = {
    disableForm: false,
    addressInvalid: false,
    previousAddressInvalid: false,
    dataLoader: false,
    product: '',
    visibilityParams: { email: true, phone_number: true },
    formErrors: { ...this.initFormErrors },
    address: INIT_ADDRESS,
    isMovedInPastMonths: false,
    previousAddress: INIT_ADDRESS,
    personal_details: {
      first_name: '',
      middle_name: '',
      last_name: '',
      date_of_birth: '',
      // todo: change this till we get from BE
      phone_number: '',
      email: '',
      industry: '',
      occupation: '',
      education: '',
      preferredDeductible: '',
      residenceType: '',
    },
    home_info: {
      condo: '',
    },
    loanOfficer_details: {
      loan_officer_fname: '',
      loan_officer_lname: '',
      loan_officer_phone: '',
      loan_officer_email: '',
      loan_number: '',
    },
    enable_loan_officer_details: false,
    stateName: [...STATEDATA.states],
    disableOccupation: true,
    occupationData: [],
    isFormCompleted: false,
    appointmentsKeys: [],
    showHomeInfo: false,
    isHO5Allowed: false,
    isDP1Allowed: false,
    isDP3Allowed: false,
    isHO4Allowed: false,
    producerHasLicence: true,
    showModal: false,
    hideApplicantFieldsForHW: false,
    isLifeOrCyberOrUmbrellaLob: false,
  };
  componentDidMount() {
    const {
      personal_details: existingPersonalDetails,
      pconfig,
      applicant,
      is_condo,
      appointments,
    } = this.props;
    window.scrollTo(0, 0);
    let isHeapAnalyticsEnabled: boolean = !!window.heap && getIsHeapAnalyticsEnabled();
    if (isHeapAnalyticsEnabled) {
      window.heap.track(QUOTING_STEPS_PAGES_NAME?.APPLICANT);
    }
    const defaultApplicantValues: AppComponents.DefaultApplicantInfoType =
      this.getApplicantDefaultValues();
    const personal_details = existingPersonalDetails ?? defaultApplicantValues;
    this.setState(
      {
        occupationData: this.getHomeOccupationDetails(
          personal_details?.industry ||
            applicant?.personal_details?.industry ||
            defaultApplicantValues.occupation
        ),
      },
      () => {
        this.setState({
          disableForm: false,
          visibilityParams: {
            ...get(pconfig, 'dale_config.form_parameters_visibility', {
              ...this.state.visibilityParams,
            }),
          },
          personal_details: {
            ...this.getPersonalDetails(personal_details),
          },
          address: {
            ...this.getAddressDetails(applicant.address),
          },
          isMovedInPastMonths: !!applicant.isMovedInPastMonths,
          previousAddress: {
            ...this.getAddressDetails(applicant.previousAddress, true),
          },
          home_info: {
            condo: is_condo,
          },
          isHO5Allowed: !isEmpty(pconfig?.dale_config?.lob?.HOME)
            ? !!pconfig?.dale_config?.lob?.HOME?.HO5 || false
            : false,
          isDP1Allowed: !isEmpty(pconfig?.dale_config?.lob?.HOME)
            ? !!pconfig?.dale_config?.lob?.HOME?.DP1 || false
            : true,
          isDP3Allowed: !isEmpty(pconfig?.dale_config?.lob?.HOME)
            ? !!pconfig?.dale_config?.lob?.HOME?.DP3 || false
            : true,
          isHO4Allowed: !!pconfig?.dale_config?.lob?.HOME?.HO4 || false,
        });
      }
    );
    const keys = Object.keys(appointments);
    if (keys && keys.length) {
      this.setState({
        appointmentsKeys: keys,
        showHomeInfo: this.hideProductTypeSelection(keys),
        hideApplicantFieldsForHW: keys.length === 1 && keys.includes('HOMEWARRANTY'),
        isLifeOrCyberOrUmbrellaLob: this.islifeOrCyberOrUmbrellaQuote(keys),
      });
    }
    this.resetTabSwitchVariables();
  }

  getApplicantDefaultValues = () => {
    const { appointments } = this.props;
    const keys: string[] = Object.keys(appointments);
    const isSetDefaultValues =
      this.islifeOrCyberOrUmbrellaQuote(keys) ||
      (keys.length === 1 && keys.includes(INSURANCE_BUSINESS_TYPES.HOMEWARRANTY));
    return {
      ...DEFAULT_PERSONAL_DETAILS_VALUES,
      industry: !isSetDefaultValues ? DEFAULT_PERSONAL_DETAILS_VALUES.industry : '',
      occupation: !isSetDefaultValues ? DEFAULT_PERSONAL_DETAILS_VALUES.occupation : '',
      education: !isSetDefaultValues ? DEFAULT_PERSONAL_DETAILS_VALUES.education : '',
    };
  };

  islifeOrCyberOrUmbrellaQuote = (keys: Array<any>): any => {
    let isLifeCyberUmbrella: boolean =
      keys.includes('LIFE') || keys.includes('CYBERSECURITY') || keys.includes('UMBRELLA') || false;
    return (
      isLifeCyberUmbrella &&
      (keys.length === 1 ||
        (keys.length === 2 &&
          (keys.includes('HOMEWARRANTY') ||
            (keys.includes('LIFE') && keys.includes('CYBERSECURITY')) ||
            (keys.includes('LIFE') && keys.includes('UMBRELLA')) ||
            (keys.includes('CYBERSECURITY') && keys.includes('UMBRELLA')))) ||
        (keys.length === 3 &&
          ((keys.includes('HOMEWARRANTY') &&
            keys.includes('CYBERSECURITY') &&
            keys.includes('LIFE')) ||
            (keys.includes('HOMEWARRANTY') &&
              keys.includes('CYBERSECURITY') &&
              keys.includes('UMBRELLA')) ||
            (keys.includes('LIFE') &&
              keys.includes('CYBERSECURITY') &&
              keys.includes('UMBRELLA')) ||
            (keys.includes('HOMEWARRANTY') &&
              keys.includes('UMBRELLA') &&
              keys.includes('LIFE')))) ||
        (keys.length === 4 &&
          keys.includes('HOMEWARRANTY') &&
          keys.includes('CYBERSECURITY') &&
          keys.includes('LIFE') &&
          keys.includes('UMBRELLA')))
    );
  };

  hideProductTypeSelection = (keys: Array<any>): any => {
    const disableProductSelectionLobs = [
      'AUTO',
      'PET',
      'HOMEWARRANTY',
      'LIFE',
      'CYBERSECURITY',
      'UMBRELLA',
    ];
    return keys.some(lob => !disableProductSelectionLobs.includes(lob));
  };

  UNSAFE_componentWillReceiveProps(
    newProps: AppComponents.HomeContentProps,
    oldProps: AppComponents.HomeContentProps
  ) {
    const {
      onNextTab,
      currentIndex,
      goToLastTab,
      fetchFloodQuoteDetails,
      fetchQuakeQuoteDetails,
      setFloodDetailsLoader,
      setHomeState,
      saveApplicantDetails,
      setApplicantFormChange,
      applicantFormChange,
      quakeDetailSubmitLoader,
      setLineOfBusiness,
      pconfig,
      newProducts,
      setQuakeQuotes,
      setFloodQuotes,
      policyBounded,
      petDetails,
      homeWarrantyQuotesLoader,
      fetchHomeWarrantyQuotes,
      storeHomeWarrantyQuoteList,
      redirectToStep,
      fetchCyberSecurityQuotes,
      cyberSecurityQuotesLoader,
      storeCyberSecurityQuoteDetails,
      redirectionStep,
      isPrefillCall,
      setPrefillCall,
      saveApplicantLoader,
      setApplicantDetails,
      isApplicantFilled,
      driversList,
    } = this.props;
    if (newProps.isAlreadyValidated !== oldProps.isAlreadyValidated) {
      if (newProps.isAlreadyValidated || newProps.isPreviousAddressAlreadyValidated) {
        this.setState({
          disableForm: false,
          addressInvalid: newProps.isAlreadyValidated,
          previousAddressInvalid: newProps.isPreviousAddressAlreadyValidated,
        });
      } else {
        const { appointmentsKeys } = this.state;
        if (
          !newProps.isValidatingAddress &&
          !newProps.isPreviousAddressAlreadyValidated &&
          this.state.isFormCompleted
        ) {
          if (!this.state.producerHasLicence && newProps.applicantFormChange) {
            this.setState({ ...this.state, showModal: true });
          } else if (
            appointmentsKeys &&
            appointmentsKeys.length &&
            ((!appointmentsKeys.includes('HOME') &&
              !appointmentsKeys.includes('QUAKE') &&
              !appointmentsKeys.includes('HOMEWARRANTY') &&
              !appointmentsKeys.includes('CYBERSECURITY')) ||
              (!isEmpty(redirectionStep) && redirectionStep !== STEPPER_KEYS_MAPPING.RATE_PAGE))
          ) {
            let isPetOrLifeOrUmbrella =
              appointmentsKeys.includes('PET') ||
              appointmentsKeys.includes('LIFE') ||
              appointmentsKeys.includes('UMBRELLA');
            if (
              applicantFormChange &&
              (appointmentsKeys.includes('FLOOD') || isPetOrLifeOrUmbrella)
            ) {
              if (
                (isEmpty(redirectionStep) && appointmentsKeys.includes('FLOOD')) ||
                (!isEmpty(redirectionStep) && redirectionStep === STEPPER_KEYS_MAPPING.FLOOD_INFO)
              ) {
                fetchFloodQuoteDetails();
                setFloodDetailsLoader(true);
              } else if (
                (isEmpty(redirectionStep) &&
                  isPetOrLifeOrUmbrella &&
                  (appointmentsKeys.length === 1 ||
                    (appointmentsKeys.length === 2 &&
                      ((appointmentsKeys.includes('LIFE') && appointmentsKeys.includes('PET')) ||
                        (appointmentsKeys.includes('PET') &&
                          appointmentsKeys.includes('UMBRELLA')) ||
                        (appointmentsKeys.includes('LIFE') &&
                          appointmentsKeys.includes('UMBRELLA')))))) ||
                (!isEmpty(redirectionStep) &&
                  (redirectionStep === STEPPER_KEYS_MAPPING.PET_INFO ||
                    redirectionStep === STEPPER_KEYS_MAPPING.LIFE_INFO ||
                    redirectionStep === STEPPER_KEYS_MAPPING.UMBRELLA_INFO))
              ) {
                let lobKey: string = getApplicanLobKey(redirectionStep, appointmentsKeys);
                saveApplicantDetails(lobKey);
              }
              setApplicantFormChange(false);
            }
            let isAutoPolicyBounded =
              !isEmpty(policyBounded) && policyBounded[INSURANCE_PRODUCTS[1]];
            let isFloodPolicyBounded =
              !isEmpty(policyBounded) && policyBounded[INSURANCE_PRODUCTS[2]];
            if (
              !newProps.isVeriskFetched &&
              isPrefillCall &&
              !isAutoPolicyBounded &&
              pconfig.dale_config?.is_verisk_prefill_available &&
              ((isAutoQuote(appointmentsKeys) && isEmpty(redirectionStep)) ||
                (!isEmpty(redirectionStep) &&
                  redirectionStep === STEPPER_KEYS_MAPPING.VEHICLE_INFO))
            ) {
              if (
                (!newProps.vinLoader &&
                  (newProps.isVeriskFetched ||
                    (!newProps?.isVeriskFetched && driversList?.length))) ||
                newProps.showGetQuoteButton
              ) {
                setPrefillCall(false);
                onNextTab(currentIndex);
              } else if (
                (appointmentsKeys.length > 1 &&
                  !(
                    appointmentsKeys.length === 2 &&
                    (appointmentsKeys.includes('PET') ||
                      appointmentsKeys.includes('LIFE') ||
                      appointmentsKeys.includes('UMBRELLA'))
                  ) &&
                  !(
                    appointmentsKeys.length === 3 &&
                    ((appointmentsKeys.includes('PET') && appointmentsKeys.includes('LIFE')) ||
                      (appointmentsKeys.includes('PET') && appointmentsKeys.includes('UMBRELLA')) ||
                      (appointmentsKeys.includes('LIFE') && appointmentsKeys.includes('UMBRELLA')))
                  ) &&
                  !(
                    appointmentsKeys.length === 4 &&
                    appointmentsKeys.includes('PET') &&
                    appointmentsKeys.includes('LIFE') &&
                    appointmentsKeys.includes('UMBRELLA')
                  )) ||
                !appointmentsKeys.includes('AUTO')
              ) {
                if (isEmpty(redirectionStep)) {
                  onNextTab(currentIndex);
                } else {
                  redirectToStep(redirectionStep);
                }
                setPrefillCall(false);
              }
            } else {
              if (isEmpty(redirectionStep)) {
                if (
                  (!appointmentsKeys.includes('FLOOD') && isAutoPolicyBounded) ||
                  (appointmentsKeys.includes('FLOOD') && isFloodPolicyBounded) ||
                  (!appointmentsKeys.includes('FLOOD') &&
                    !appointmentsKeys.includes('AUTO') &&
                    petDetails?.isPolicyBounded)
                ) {
                  goToLastTab(currentIndex);
                } else {
                  if (
                    getIsPropertyInfoEnabledDetails() &&
                    getIsLobIncludesInAppointments(INSURANCE_BUSINESS_TYPES.FLOOD)
                  ) {
                    redirectToStep(PROPERTY_INFO.KEY);
                  } else {
                    onNextTab(currentIndex);
                  }
                }
              } else {
                redirectToStep(redirectionStep);
              }
            }
          } else {
            const isHomeWarrantyOrCyberSecurity =
              appointmentsKeys.includes('HOMEWARRANTY') ||
              appointmentsKeys.includes('CYBERSECURITY');
            const isHomeAndQuake =
              this.state.appointmentsKeys.includes('QUAKE') ||
              this.state.appointmentsKeys.includes('HOME');
            let isRedirectToPropertyInfo = true;
            if (
              !saveApplicantLoader &&
              newProps.applicantFormChange &&
              appointmentsKeys.length &&
              isHomeWarrantyOrCyberSecurity
            ) {
              if (appointmentsKeys.includes('HOMEWARRANTY')) {
                if (sessionStorage.selectedQuoteList) {
                  const selectedQuotes = removeExistingRate(
                    JSON.parse(sessionStorage.selectedQuoteList),
                    INSURANCE_BUSINESS_TYPES.HOMEWARRANTY
                  );
                  sessionStorage.selectedQuoteList = JSON.stringify(selectedQuotes);
                }
                storeHomeWarrantyQuoteList({
                  quoteList: [],
                  upcomingCarrierList: [],
                });
                homeWarrantyQuotesLoader(true);
                fetchHomeWarrantyQuotes();
              }
              if (appointmentsKeys.includes('CYBERSECURITY')) {
                if (sessionStorage.selectedQuoteList) {
                  const selectedQuotes = removeExistingRate(
                    JSON.parse(sessionStorage.selectedQuoteList),
                    INSURANCE_BUSINESS_TYPES.CYBERSECURITY
                  );
                  sessionStorage.selectedQuoteList = JSON.stringify(selectedQuotes);
                }
                storeCyberSecurityQuoteDetails({
                  quoteList: [],
                  quoteErrorList: [],
                  upcomingCarrierList: [],
                });
                cyberSecurityQuotesLoader(true);
                fetchCyberSecurityQuotes();
              }
              let getQuoteForFlood = appointmentsKeys.includes('FLOOD') && !isHomeAndQuake;
              if (getQuoteForFlood) {
                if (sessionStorage.selectedQuoteList) {
                  const selectedQuotes = removeExistingRate(
                    JSON.parse(sessionStorage.selectedQuoteList),
                    INSURANCE_BUSINESS_TYPES.FLOOD
                  );
                  sessionStorage.selectedQuoteList = JSON.stringify(selectedQuotes);
                  setFloodQuotes({
                    quoteList: [],
                    quoteErrorList: [],
                    upcomingCarrierList: [],
                  });
                }
                setLineOfBusiness(INSURANCE_PRODUCTS[2]);
                fetchFloodQuoteDetails();
                setFloodDetailsLoader(true);
              }
              if (getQuoteForFlood || !isHomeAndQuake) {
                setApplicantFormChange(false);
              }
            }
            if (isHomeAndQuake && !saveApplicantLoader) {
              if (
                sessionStorage.selectedQuoteList &&
                this.state.appointmentsKeys.includes('HOME')
              ) {
                const selectedQuotes = removeExistingRate(
                  JSON.parse(sessionStorage.selectedQuoteList),
                  INSURANCE_BUSINESS_TYPES.HOME
                );
                sessionStorage.selectedQuoteList = JSON.stringify(selectedQuotes);
              }
              if (
                newProps.applicantFormChange ||
                (!newProps.quoteList.length && newProps.quoteListError.length)
              ) {
                if (
                  (this.state.appointmentsKeys.includes('QUAKE') ||
                    Object.keys(newProducts).includes('QUAKE')) &&
                  !this.state.appointmentsKeys.includes('HOME') &&
                  !Object.keys(newProducts).includes('HOME')
                ) {
                  setLineOfBusiness(EARTH_QUAKE_LOB);
                } else {
                  setLineOfBusiness(INSURANCE_PRODUCTS[0]);
                }
                if (this.state.appointmentsKeys.includes('HOME')) {
                  setHomeState({
                    quoteList: [],
                    quoteListError: [],
                    upcomingCarrierList: [],
                  });
                  isRedirectToPropertyInfo = false;
                  saveApplicantDetails(INSURANCE_PRODUCTS[0]);
                }
                if (this.state.appointmentsKeys.includes('FLOOD')) {
                  if (sessionStorage.selectedQuoteList) {
                    const selectedQuotes = removeExistingRate(
                      JSON.parse(sessionStorage.selectedQuoteList),
                      INSURANCE_BUSINESS_TYPES.FLOOD
                    );
                    sessionStorage.selectedQuoteList = JSON.stringify(selectedQuotes);
                    setFloodQuotes({
                      quoteList: [],
                      quoteErrorList: [],
                      upcomingCarrierList: [],
                    });
                  }
                  fetchFloodQuoteDetails();
                }
                if (this.state.appointmentsKeys.includes('QUAKE')) {
                  if (
                    sessionStorage.selectedQuoteList &&
                    this.state.appointmentsKeys.includes('QUAKE')
                  ) {
                    setQuakeQuotes({
                      quoteList: [],
                      upcomingCarrierList: [],
                    });
                    quakeDetailSubmitLoader(true);
                    const selectedQuotes = removeExistingRate(
                      JSON.parse(sessionStorage.selectedQuoteList),
                      INSURANCE_BUSINESS_TYPES.QUAKE
                    );
                    sessionStorage.selectedQuoteList = JSON.stringify(selectedQuotes);
                  }
                  fetchQuakeQuoteDetails();
                }
                setApplicantFormChange(false);
              }
            }
            if (this.state.appointmentsKeys.includes('HOME')) {
              if (
                (isRedirectToPropertyInfo && !saveApplicantLoader && !isApplicantFilled) ||
                !isPrefillCall
              ) {
                setApplicantDetails({
                  isApplicantFilled: true,
                });
                if (!!getIsPropertyInfoEnabledDetails()) {
                  redirectToStep(PROPERTY_INFO.KEY);
                } else {
                  goToLastTab(currentIndex);
                }
              }
            } else {
              goToLastTab(currentIndex);
            }
          }
          if (
            this.state.producerHasLicence &&
            appointmentsKeys.length &&
            newProps.applicantFormChange &&
            (isAutoQuote(appointmentsKeys) ||
              (!isEmpty(redirectionStep) && redirectionStep === STEPPER_KEYS_MAPPING.VEHICLE_INFO))
          ) {
            setApplicantFormChange(false);
            saveApplicantDetails(INSURANCE_PRODUCTS[1]);
          }
        }
        this.setState({
          addressInvalid: newProps.isAlreadyValidated,
          previousAddressInvalid: newProps.isPreviousAddressAlreadyValidated,
          disableForm: false,
        });
      }
    }
    if (
      isEmpty(this.state.personal_details.first_name) &&
      !isEmpty(get(newProps, 'personal_details.first_name', ''))
    ) {
      const defaultApplicantValues: AppComponents.DefaultApplicantInfoType =
        this.getApplicantDefaultValues();
      this.setState({
        address: { ...newProps.address },
        personal_details: { ...newProps.personal_details },
        occupationData: this.getHomeOccupationDetails(
          newProps?.personal_details?.industry || defaultApplicantValues.occupation
        ),
      });
    }
  }

  resetTabSwitchVariables() {
    this.props.setNextTabStep(false);
    this.props.setIncidentsFlag(false);
    this.props.setPolicyFlag(false);
    this.props.setGoToRatesTabFromPolicy(false);
    this.props.setFetchAutoQuotes(false);
  }

  getPersonalDetails = (personalDetails: any) => {
    const { applicant } = this.props;
    const { occupationData } = this.state;
    const personalDetailsFields = [
      'first_name',
      'middle_name',
      'last_name',
      'date_of_birth',
      'phone_number',
      'email',
      'industry',
      'education',
      'occupation',
      'preferredDeductible',
      'residenceType',
    ];
    let updatedPersonalDetails = {};
    const defaultApplicantValues: AppComponents.DefaultApplicantInfoType =
      this.getApplicantDefaultValues();
    forEach(personalDetailsFields, fieldName => {
      if ([EDUCATION, INDUSTRY, OCCUPATION].includes(fieldName)) {
        let fieldValue: string =
          personalDetails?.[fieldName] || defaultApplicantValues?.[fieldName] || '';
        if (fieldName === OCCUPATION) {
          updatedPersonalDetails = {
            ...updatedPersonalDetails,
            [fieldName]: get(personalDetails, `${fieldName}`, '')
              ? personalDetails[fieldName]
              : getDefaultOccupation(
                  get(applicant, `personal_details.${fieldName}`, ''),
                  occupationData
                ),
          };
        }
        updatedPersonalDetails = {
          ...updatedPersonalDetails,
          [fieldName]: fieldValue,
        };
      } else {
        updatedPersonalDetails = {
          ...updatedPersonalDetails,
          [fieldName]: get(personalDetails, `${fieldName}`, '')
            ? personalDetails[fieldName]
            : get(applicant, `personal_details.${fieldName}`, ''),
        };
      }
    });
    return updatedPersonalDetails as AppComponents.ApplicantPersonalDetails;
  };

  getAddressDetails = (address: any, isPreviousAddress: boolean = false) => {
    const { applicant } = this.props;
    const addressDetailsFields = ['street', 'unit', 'city', 'zip', 'state'];
    let updatedAddressDetails = {};

    forEach(addressDetailsFields, fieldName => {
      updatedAddressDetails = {
        ...updatedAddressDetails,
        [fieldName]: get(address, `${fieldName}`, '')
          ? fieldName === STATE?.toLowerCase()
            ? address[fieldName].toUpperCase()
            : address[fieldName]
          : get(applicant, `${isPreviousAddress ? 'previousAddress' : 'address'}.${fieldName}`, ''),
      };
    });
    return updatedAddressDetails as AppComponents.ApplicantAddress;
  };

  handleHomePersonalDetailsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { target } = event;
    const fields: Array<string> = [
      'date_of_birth',
      'phone_number',
      'email',
      'industry',
      'occupation',
      'education',
    ];
    if (
      !isEmpty(target.value) &&
      !stringRegex.test(target.value) &&
      !fields.includes(target.name)
    ) {
      return;
    }
    const personalDetails: any = { ...this.state.personal_details };
    personalDetails[target.name] = target.value;
    this.setState((prevState: AppComponents.HomeContentState) => ({
      ...prevState,
      personal_details: { ...personalDetails },
    }));
    event.preventDefault();
    if (target.name === 'industry') {
      this.setState({
        personal_details: { ...personalDetails, occupation: '' },
        occupationData: this.getHomeOccupationDetails(target.value),
      });
    }
  };

  getHomeOccupationDetails = (value: string) => {
    this.setState({
      disableOccupation: isEmpty(value),
      formErrors: { ...this.state.formErrors, occupation: '' },
    });
    return getOccupationList(INDUSTRY_DATA, value);
  };

  handleHomePersonalDetailsBlur = (event: FocusEvent<HTMLInputElement>) => {
    const personalDetails: any = { ...this.state.personal_details };
    personalDetails[event.target.name] = getStartCase(event.target.value.toLowerCase());
    this.setState((prevState: AppComponents.HomeContentState) => ({
      ...prevState,
      personal_details: { ...personalDetails },
    }));
    event.preventDefault();
  };

  homeApplicantInformationCheck = (formErrors: AppComponents.HomeContentFormErrors) => {
    const { first_name, last_name } = this.state.personal_details;
    const { loan_officer_phone, loan_officer_email } = this.state.loanOfficer_details;
    const validOfficerPhone = validatePhoneNumber(loan_officer_phone);
    const error = { ...formErrors };

    if (first_name.length === 0) {
      error.first_name = REQUIRED;
    }
    if (last_name.length === 0) {
      error.last_name = REQUIRED;
    }
    if (loan_officer_phone && (!phoneRegex.test(loan_officer_phone) || !validOfficerPhone)) {
      error.loan_officer_phone = INVALID;
    }
    error.loan_officer_email = validateEmail(loan_officer_email, false);
    return error;
  };

  homeAddressErrorCheck = (formErrors: AppComponents.HomeContentFormErrors) => {
    const tempError: any = {};
    const tempState = { ...this.state };
    forEach(this.state.address, (value: any, key: string) => {
      if ((!value || value.length === 0) && key !== UNIT?.toLowerCase()) {
        tempError[key] = REQUIRED;
      }
    });
    if (tempState.isMovedInPastMonths) {
      tempError.previousAddress = {};
      const previousAddress: any = { ...this.state.previousAddress };
      const currentAddress: any = { ...this.state.address };
      forEach(previousAddress, (value: any, key: string) => {
        if ((!value || value.length === 0) && key !== UNIT?.toLowerCase()) {
          tempError.previousAddress[key] = REQUIRED;
        }
      });
      if (
        !isEmpty(previousAddress?.street) &&
        currentAddress?.street?.trim() === previousAddress?.street?.trim()
      ) {
        tempError.previousAddress.street = INVALID_PREVIOUS_ADDRESS;
      }
    }
    return { ...formErrors, ...tempError };
  };

  phoneFieldErrorCheck = (formErrors: AppComponents.HomeContentFormErrors) => {
    const { phone_number } = this.state.personal_details;
    const error = { ...formErrors };
    if (showFields('phone_number', this.state.visibilityParams)) {
      if (phone_number.length === 0) {
        error.phone_number = REQUIRED;
      }
      const validUserPhone = validatePhoneNumber(phone_number);
      if (phone_number.length > 0 && (!phoneRegex.test(phone_number) || !validUserPhone)) {
        error.phone_number = INVALID;
      }
    }
    return error;
  };

  emailFieldErrorCheck = (formErrors: AppComponents.HomeContentFormErrors) => {
    const { email } = this.state.personal_details;
    const error = { ...formErrors };
    if (showFields('email', this.state.visibilityParams)) {
      error.email = validateEmail(email, true);
    }
    return error;
  };

  // tslint:disable-next-line:cyclomatic-complexity
  validateInputs = (): boolean => {
    const { address, hideApplicantFieldsForHW, isLifeOrCyberOrUmbrellaLob, isMovedInPastMonths } =
      this.state;
    let formErrors: AppComponents.HomeContentFormErrors = { ...this.initFormErrors };
    const { date_of_birth, industry, occupation, education } = this.state.personal_details;
    const { condo } = this.state.home_info;
    formErrors = { ...this.phoneFieldErrorCheck(formErrors) };
    formErrors = { ...this.emailFieldErrorCheck(formErrors) };
    formErrors = {
      ...this.homeApplicantInformationCheck(formErrors),
    };
    formErrors = {
      ...this.homeAddressErrorCheck(formErrors),
    };
    if (String(address.zip).length > 0 && String(address.zip).length < 4) {
      formErrors.zip = INVALID;
    }
    if (!hideApplicantFieldsForHW && !isLifeOrCyberOrUmbrellaLob) {
      formErrors.date_of_birth = validateDOB(date_of_birth);
      if (!industry) {
        formErrors.industry = REQUIRED;
      }
      if (!education) {
        formErrors.education = REQUIRED;
      }
      if (!this.state.disableOccupation) {
        if (!occupation) {
          formErrors.occupation = REQUIRED;
        }
      }
      if (this.state.showHomeInfo && !condo) {
        formErrors.condo = REQUIRED;
      }
    } else if (isLifeOrCyberOrUmbrellaLob) {
      formErrors.date_of_birth = validateDOB(date_of_birth);
    }
    const { previousAddress, ...errors } = formErrors;
    if (!isEmptyValues(errors) || (isMovedInPastMonths && !isEmptyValues(previousAddress))) {
      this.setState({ formErrors });
      return false;
    }
    return true;
  };

  handleUpdatedChange = () => {
    let isChange = false;
    const { is_condo, address, personal_details, floodDetails, appointments, viewQuoteDetails } =
      this.props;
    let propStateKeys = [
      'address.city',
      'address.state',
      'address.street',
      'address.unit',
      'address.zip',
      'personal_details.first_name',
      'personal_details.middle_name',
      'personal_details.last_name',
      'personal_details.email',
      'personal_details.phone_number',
    ];
    let propStateKeysForPreviousAddress = [
      'previousAddress.city',
      'previousAddress.state',
      'previousAddress.street',
      'previousAddress.unit',
      'previousAddress.zip',
    ];
    let propStateOtherKeys = [
      'personal_details.date_of_birth',
      'personal_details.education',
      'personal_details.industry',
      'personal_details.occupation',
    ];
    let prefillKeys = [
      'address.city',
      'address.state',
      'address.street',
      'address.unit',
      'address.zip',
      'personal_details.first_name',
      'personal_details.last_name',
    ];
    const appointmentsKeys = Object.keys(appointments);
    let isPrefillDataChange = false;
    if (!(appointmentsKeys.includes('HOMEWARRANTY') && appointmentsKeys.length === 1)) {
      propStateKeys = propStateKeys.concat(propStateOtherKeys);
    }
    const checkForPropertyType =
      appointmentsKeys.includes(INSURANCE_BUSINESS_TYPES.HOME) ||
      appointmentsKeys.includes(INSURANCE_BUSINESS_TYPES.FLOOD) ||
      appointmentsKeys.includes(INSURANCE_BUSINESS_TYPES.QUAKE);
    const { condo } = this.state.home_info;
    if (!address || !personal_details || viewQuoteDetails?.isRedirectToApplicant) {
      viewQuoteDetails?.isRedirectToApplicant &&
        this.props.setViewQuote({ isRedirectToApplicant: false });
      isChange = true;
    } else if (isEmpty(floodDetails?.annexPrefill) && checkForPropertyType) {
      isChange = true;
    } else if (is_condo !== condo && checkForPropertyType) {
      isChange = true;
    } else {
      if (shouldApplicantHasPreviousAddress()) {
        propStateKeys.push(MOVED_IN_PAST_MONTHS.KEY);
        if (this.state.isMovedInPastMonths)
          propStateKeys = propStateKeys.concat(propStateKeysForPreviousAddress);
      }
      for (let key of propStateKeys) {
        let propValue = get(this.props, key, null) ? get(this.props, key) : '';
        if (!isEmpty(propValue) && typeof propValue === 'string') {
          propValue = propValue.toLowerCase();
        }
        let stateValue = get(this.state, key, '');
        if (!isEmpty(stateValue) && typeof stateValue === 'string') {
          stateValue = stateValue.toLowerCase();
        }
        if (propValue !== stateValue) {
          isChange = true;
          break;
        }
      }
      for (let key of prefillKeys) {
        let propValue = get(this.props, key, null) ? get(this.props, key) : '';
        if (!isEmpty(propValue) && typeof propValue === 'string') {
          propValue = propValue.toLowerCase();
        }
        let stateValue = get(this.state, key, '');
        if (!isEmpty(stateValue) && typeof stateValue === 'string') {
          stateValue = stateValue.toLowerCase();
        }
        if (propValue !== stateValue) {
          isPrefillDataChange = true;
          break;
        }
      }
    }
    if (isChange) {
      isPrefillDataChange && this.props.setIsVeriskFetched(false);
      this.props.setPrefillCall(true);
      this.props.setApplicantFormChange(true);
    } else {
      this.props.setPrefillCall(false);
      this.props.setApplicantFormChange(false);
    }
    return isChange;
  };

  submitHeapDetails = () => {
    const { address, personal_details } = this.state;
    const { state } = address;
    let isHeapAnalyticsEnabled: boolean = !!window.heap && getIsHeapAnalyticsEnabled();
    if (isHeapAnalyticsEnabled) {
      const propertyTypeMapping: any = {
        n: 'Home',
        y: 'Condo',
        h: 'HO5',
        x: 'DP1',
        z: 'DP3',
        o: 'HO4',
      };
      const getDate = new Date(personal_details?.date_of_birth);
      const getYear = getDate.getFullYear();
      window.heap.track(QUOTING_STEPS_PAGES_NAME?.APPLICANT_SUBMIT, {
        [STATE]: state,
        [HEAP_LABELS?.BIRTH_YEAR]: getYear,
        [HEAP_LABELS?.PRODUCT_TYPE]: propertyTypeMapping[this.state.home_info.condo] || '',
        [HEAP_LABELS?.INDUSTRY]: personal_details?.industry,
        [HEAP_LABELS?.EDUCATION]: personal_details?.education,
      });
    }
  };

  handleSubmit = (e: React.FormEvent<HTMLFormElement>, getQuoteClicked: boolean) => {
    e.preventDefault();
    const disableApplicant = allowEditingQuote();
    const { policyBounded } = this.props;
    this.submitHeapDetails();
    let isHomePolicyBounded =
      !isEmpty(policyBounded) &&
      (policyBounded[INSURANCE_PRODUCTS[0]] || policyBounded[INSURANCE_PRODUCTS[3]]);
    if (disableApplicant?.applicant || isHomePolicyBounded) {
      const { onNextTab, currentIndex, goToLastTab, appointments, floodDetails, redirectToStep } =
        this.props;
      const appointmentsKeys = Object.keys(appointments);
      if (
        appointmentsKeys &&
        appointmentsKeys.length &&
        !appointmentsKeys.includes('HOME') &&
        !appointmentsKeys.includes('QUAKE') &&
        !appointmentsKeys.includes('HOMEWARRANTY') &&
        !appointmentsKeys.includes('CYBERSECURITY')
      ) {
        if (appointmentsKeys.includes('FLOOD') && !floodDetails?.annexPrefill?.isFloodInfo) {
          goToLastTab(currentIndex);
        } else {
          onNextTab(currentIndex);
        }
      } else {
        if (appointmentsKeys.includes('HOME') && getIsPropertyInfoEnabledDetails()) {
          redirectToStep(PROPERTY_INFO.KEY);
        } else {
          goToLastTab(currentIndex);
        }
      }
    } else if (this.validateInputs()) {
      const { address, personal_details, previousAddress } = this.state;
      const { pconfig, producerDetails } = this.props;
      const { city, unit, street } = address;
      const { condo } = this.state.home_info;
      this.setState(
        {
          ...this.state,
          personal_details: {
            ...personal_details,
            email: personal_details.email?.trim(),
          },
          formErrors: { ...this.initFormErrors },
          disableForm: true,
          address: {
            ...this.state.address,
            city: !isEmpty(city) ? city.trim() : '',
            unit: !isEmpty(unit) ? unit.trim() : '',
            street: !isEmpty(street) ? street.trim() : '',
          },
          previousAddress: {
            ...this.state.previousAddress,
            city: !isEmpty(previousAddress?.city) ? previousAddress.city.trim() : '',
            unit: !isEmpty(previousAddress?.unit) ? previousAddress.unit.trim() : '',
            street: !isEmpty(previousAddress?.street) ? previousAddress.street.trim() : '',
          },
          dataLoader: getQuoteClicked,
          isFormCompleted: true,
          producerHasLicence: !checkProducerLicense({
            pconfig,
            address,
            producerDetails,
          })
            ? false
            : true,
        },
        // todo: check whether we need to add is_condo here
        () => {
          const { quoteList, newProducts, quoteListError } = this.props;
          if (
            this.handleUpdatedChange() ||
            (!quoteList.length && quoteListError.length) ||
            (newProducts && Object.keys(newProducts).length)
          ) {
            if (!this.state.producerHasLicence) {
              return;
            } else {
              const {
                personal_details: old_personal_details,
                address: old_address,
                productType,
              } = this.props;
              this.props.setHomeState({
                productType: !isEmpty(condo) && getProductTypeByKey(condo),
                is_condo: condo,
                old_applicant_details:
                  !isEmpty(old_address) && !isEmpty(old_personal_details)
                    ? {
                        address: old_address,
                        personal_details: old_personal_details,
                        productType: productType,
                      }
                    : {},
              });
              this.props.homeApplicationSubmit(
                omit({ ...this.state, getQuoteClick: getQuoteClicked }, [
                  'disableForm',
                  'formErrors',
                  'stateName',
                  'dataLoader',
                  'disableOccupation',
                  'occupationData',
                ])
              );
            }
          } else {
            this.props.setHomeState({
              hippoQuoteLoaded: true,
              ezlynxQuotesLoaded: true,
            });
          }
        }
      );
    }
  };

  onChangesRadioButton = (e: React.ChangeEvent<HTMLInputElement>) => {
    const tempState = { ...this.state };
    const value = e.target.value?.toLowerCase() === TRUE.toLowerCase();
    tempState.isMovedInPastMonths = value;
    if (!value) {
      tempState.previousAddress = {
        street: '',
        unit: '',
        city: '',
        zip: '',
        state: '',
      };
    } else {
      tempState.previousAddress = this.getAddressDetails(
        { ...this.props.applicant.previousAddress },
        true
      );
    }
    if (tempState.previousAddressInvalid) tempState.previousAddressInvalid = false;
    this.setState({ ...tempState });
  };

  handleHomeAddressDetailsChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    isPreviousAddress: boolean = false
  ) => {
    const updatedAddress: any = !isPreviousAddress
      ? { ...this.state.address }
      : { ...this.state.previousAddress };
    if (
      (event.target.name === 'zip' &&
        (event.target.value.length > 5 || event.target.value.match(numberRegex) == null)) ||
      (event.target.name === 'city' && event.target.value.match(stringWithSpaceRegex) === null)
    ) {
      return false;
    }
    updatedAddress[event.target.name] = event.target.value;
    if (!isPreviousAddress) {
      this.setState({ address: updatedAddress });
    } else {
      this.setState({ previousAddress: updatedAddress });
    }
    event.preventDefault();
    return true;
  };

  onAddressSelected = (
    addressDetails: AppComponents.ApplicantAddress,
    isPreviousAddress: boolean = false
  ) => {
    let updatedAddress: AppComponents.ApplicantAddress = !isPreviousAddress
      ? { ...this.state.address }
      : { ...this.state.previousAddress };
    updatedAddress = {
      ...updatedAddress,
      ...addressDetails,
      zip: !isEmpty(addressDetails.zip) ? addressDetails.zip : updatedAddress.zip,
    };
    if (!isPreviousAddress) {
      this.setState({ address: updatedAddress });
    } else {
      this.setState({ previousAddress: updatedAddress });
    }
  };

  clearAddressInputs = (isPreviousAddress: boolean = false) => {
    if (!!isPreviousAddress) {
      this.setState(state => {
        return { previousAddress: { ...state.previousAddress, street: '' } };
      });
    } else {
      this.setState(state => {
        return { address: { ...state.address, street: '' } };
      });
    }
  };

  handleAddressBlur = (e: FocusEvent<HTMLInputElement>, isPreviousAddress: boolean = false) => {
    const updatedAddress: any = !isPreviousAddress
      ? { ...this.state.address }
      : { ...this.state.previousAddress };
    if (e.target.name === UNIT?.toLowerCase()) {
      updatedAddress[e.target.name] = e.target.value.trim();
    } else {
      updatedAddress[e.target.name] = getStartCase(e.target.value.toLowerCase());
    }
    if (!isPreviousAddress) {
      this.setState({ address: updatedAddress });
    } else {
      this.setState({ previousAddress: updatedAddress });
    }
    e.preventDefault();
  };

  handleCalendarDateChange = (calenderDate: string) => {
    const personalDetails: any = { ...this.state.personal_details };
    personalDetails['date_of_birth'] = calenderDate;
    this.setState(prevState => ({ ...prevState, personal_details: { ...personalDetails } }));
  };

  phoneNumberDetails = () => (e: React.ChangeEvent<HTMLInputElement>) => {
    this.updateLoanOfficerDetails(e);
    e.preventDefault();
  };

  updateLoanOfficerDetails = (e: React.ChangeEvent<HTMLInputElement>) => {
    const loanOfficerDetails: any = { ...this.state.loanOfficer_details };
    loanOfficerDetails[e.target.name] = e.target.value;
    this.setState(prevState => ({ ...prevState, loanOfficer_details: { ...loanOfficerDetails } }));
  };

  handleOfficerDetailsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    if (
      ((!isEmpty(e.target.value) &&
        (name === 'loan_officer_fname' || name === 'loan_officer_lname') &&
        !stringRegex.test(e.target.value)) ||
        (name === 'loan_number' && e.target.value.match(stringNumberRegex) === null)) &&
      // @ts-ignore
      name !== 'loan_officer_email'
    ) {
      return;
    }
    this.updateLoanOfficerDetails(e);
    e.preventDefault();
  };

  handleOfficerDetailsBlur = (e: FocusEvent<HTMLInputElement>) => {
    const loanOfficerDetails: any = { ...this.state.loanOfficer_details };
    loanOfficerDetails[e.target.name] = getStartCase(e.target.value.toLowerCase());
    this.setState(prevState => ({ ...prevState, loanOfficer_details: { ...loanOfficerDetails } }));
    e.preventDefault();
  };

  adjustFieldWidth = () => {
    const { visibilityParams } = this.state;
    let cols = 0;
    if (showFields('phone_number', visibilityParams)) {
      cols++;
    }
    if (showFields('email', visibilityParams)) {
      cols++;
    }
    if (cols === 2) {
      return 6;
    } else {
      return 12;
    }
  };

  homeTypeSelectionClass = (value: string, isDisabled: boolean) =>
    this.state.home_info.condo === value ? (isDisabled ? 'border-disable' : 'active') : '';

  propertyTypeClicked = (e: any) => {
    const { disableForm } = this.state;
    if (disableForm) {
      return false;
    }
    const disableApplicant = allowEditingQuote();
    if (e.currentTarget.id && !disableApplicant?.applicant) {
      (document as any).getElementById('continueButton').focus();
    }
    this.setState({
      formErrors: { ...this.state.formErrors, condo: '' },
    });
    if (e.currentTarget.id === 'condo') {
      this.setState({
        home_info: { ...this.state.home_info, condo: 'y' },
      });
    } else if (e.currentTarget.id === 'home') {
      this.setState({
        home_info: { ...this.state.home_info, condo: 'n' },
      });
    } else if (e.currentTarget.id === DP1) {
      this.setState({
        home_info: { ...this.state.home_info, condo: 'x' },
      });
    } else if (e.currentTarget.id === DP3) {
      this.setState({
        home_info: { ...this.state.home_info, condo: 'z' },
      });
    } else if (e.currentTarget.id === 'ho5') {
      this.setState({
        home_info: { ...this.state.home_info, condo: 'h' },
      });
    } else if (e.currentTarget.id === HO4?.toLowerCase()) {
      this.setState({
        home_info: { ...this.state.home_info, condo: 'o' },
      });
    }
    e.preventDefault();
    return true;
  };

  getStateName = (stateInitials: string) => {
    let stateName = '';

    STATEDATA.states.forEach(state => {
      if (stateInitials === state.value) {
        stateName = state.label;
      }
    });
    return stateName;
  };

  render() {
    return template(this);
  }
}

const mapStateToProps = ({
  applicant,
  quote,
  home,
  common,
  partner,
  vehicle,
  floodDetails,
  petDetails,
  viewQuoteDetails,
  driver,
}: AppComponents.HomeContentStore): AppComponents.HomeContentStoreProps => {
  const { 
    formLoader,
    addressInvalid,
    isMovedInPastMonths,
    previousAddressInvalid,
    saveApplicantLoader,
    isApplicantFilled,
 } = applicant;
  const { vinLoader } = vehicle;
  const {
    isValidatingAddress,
    isAlreadyValidated,
    isPreviousAddressAlreadyValidated,
    pconfig,
    personal_details,
    address,
    previousAddress,
    is_condo,
    productType,
    quoteList,
    quoteListError,
  } = home;
  const { quoteCompleted } = quote;
  const {
    lineOfBusiness,
    newProducts,
    applicantFormChange,
    showGetQuoteButton,
    isVeriskFetched,
    isVeriskLoading,
    policyBounded,
    redirectionStep,
    isPrefillCall,
  } = common;
  const { appointments, producerDetails, quoteType } = partner;
  const { driversList } = driver;
  return {
    pconfig,
    formLoader,
    addressInvalid,
    previousAddressInvalid,
    personal_details,
    address,
    isMovedInPastMonths,
    previousAddress,
    quoteCompleted,
    isValidatingAddress,
    isAlreadyValidated,
    isPreviousAddressAlreadyValidated,
    applicant,
    lineOfBusiness,
    is_condo,
    productType,
    appointments,
    quoteList,
    quoteListError,
    newProducts,
    applicantFormChange,
    showGetQuoteButton,
    producerDetails,
    isVeriskFetched,
    isVeriskLoading,
    vinLoader,
    floodDetails,
    policyBounded,
    petDetails,
    redirectionStep,
    home,
    common,
    viewQuoteDetails,
    isPrefillCall,
    quoteType,
    saveApplicantLoader,
    isApplicantFilled,
    driversList,
  };
};

const mapDispatchToProps = (dispatchProp: any): AppComponents.HomeContentDispatch => {
  return bindActionCreators(
    {
      cyberSecurityQuotesLoader,
      fetchCyberSecurityQuotes,
      fetchFloodQuoteDetails,
      fetchHomeWarrantyQuotes,
      storeHomeWarrantyQuoteList,
      fetchQuakeQuoteDetails,
      homeApplicationSubmit,
      homeWarrantyQuotesLoader,
      quakeDetailSubmitLoader,
      saveApplicantDetails,
      setApplicantDetails,
      setApplicantFormChange,
      setFetchAutoQuotes,
      setFloodDetailsLoader,
      setFloodQuotes,
      setGoToRatesTabFromPolicy,
      setHomeState,
      setIncidentsFlag,
      setIsVeriskFetched,
      setLineOfBusiness,
      setNextTabStep,
      setPolicyFlag,
      setPrefillCall,
      setQuakeQuotes,
      setViewQuote,
      storeCyberSecurityQuoteDetails,
    },
    dispatchProp
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)<any>(ApplicantFormComponent));
